import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { SideBar, NavBar, TitleAndDescription, Footer } from "../../components";
import "react-toastify/dist/ReactToastify.css";
import ReactDOM from "react-dom";
import Cropper from "react-easy-crop";
import { getOrientation } from "get-orientation/browser";
import { getCroppedImg, getRotatedImage } from "../../canvasUtils";

function notificarImg(type, message) {
  toast[type](message, {
    position: "top-right",
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
}

function readFile(file) {
  return new Promise((resolve) => {
    if (file.size > 250000) {
      notificarImg("warning", "Escolha uma imagem com no máximo 200kb");
      return false;
    }
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
}

const ORIENTATION_TO_ANGLE = {
  3: 180,
  6: 90,
  8: -90,
};

const Configuracao = (props) => {
  const [desc, setDesc] = useState("");
  const [email, setEmail] = useState("");
  const [senha, setSenha] = useState("");
  const [senhaConfirmar, setSenhaConfirmar] = useState("");
  const [errors, setErrors] = useState({
    desc: false,
    email: false,
    senha: false,
    senhaConfirmar: false,
  });

  const [imageSrc, setImageSrc] = React.useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [linkImagem, setLinkImagem] = useState("images/no-image.png");

  useEffect(() => {
    loadConfiguracoes();
    let imagemStorage = localStorage.getItem("imagem");
    if (imagemStorage !== null) {
      setLinkImagem(imagemStorage);
    }
  }, []);

  async function loadConfiguracoes() {
    await axios
      .get(
        `${props.getBaseUrlApi()}/retaguarda/configuracoes`,
        props.parameters()
      )
      .then((response) => {
        const vendedor = response.data.vendedor;
        setDesc(vendedor.desc);
        setEmail(vendedor.email);
      });
  }

  function notificar(type, message) {
    toast[type](message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  function validarConfiguracoes(tipo) {
    let errorsLocal = errors;
    let valido = true;

    if (tipo === "dados") {
      if (desc === "") {
        errorsLocal.desc = true;
        valido = false;
      } else {
        errorsLocal.desc = false;
      }

      if (email === "") {
        errorsLocal.email = true;
        valido = false;
      } else {
        errorsLocal.email = false;
      }
    } else if (tipo === "senha") {
      if (senha !== "" || senhaConfirmar !== "") {
        if (senha !== senhaConfirmar) {
          errorsLocal.senha = true;
          errorsLocal.senhaConfirmar = true;
          valido = false;
        } else {
          errorsLocal.senha = false;
          errorsLocal.senhaConfirmar = false;
        }
      } else {
        errorsLocal.senha = true;
        errorsLocal.senhaConfirmar = true;
        valido = false;
      }
    }

    setErrors(errorsLocal);
    return valido;
  }

  async function gravarConfiguracoes(tipo) {
    if (!validarConfiguracoes(tipo)) {
      notificar("warning", "Preencha os dados corretamente");
      return false;
    }

    const data = {
      desc: desc,
      email: email,
      senha: senha,
      tipo,
    };

    await axios
      .post(
        `${props.getBaseUrlApi()}/retaguarda/configuracoes`,
        data,
        props.parameters()
      )
      .then((response) => {
        let oldDesc = localStorage.getItem("descricaoVendedor");
        localStorage.setItem("descricaoVendedor", desc);

        if (oldDesc !== desc) {
          window.location.reload();
        }

        notificar("success", "Configurações alteradas com sucesso.");
      });
  }

  ////

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        imageSrc,
        croppedAreaPixels,
        rotation
      );
      //console.log("donee", { croppedImage });
      localStorage.setItem("imagem", croppedImage);
      setLinkImagem(croppedImage);
      setImageSrc(null);
      setCroppedImage(croppedImage);

      await axios
        .post(
          `${props.getBaseUrlApi()}/retaguarda/configuracoes/cadastrar-imagem`,
          { imagem: croppedImage },
          props.parameters()
        )
        .then((response) => {
          window.location.reload();
        });
    } catch (e) {
      console.error(e);
    }
  }, [imageSrc, croppedAreaPixels, rotation]);

  const onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      let imageDataUrl = await readFile(file);

      // apply rotation if needed
      const orientation = await getOrientation(file);
      const rotation = ORIENTATION_TO_ANGLE[orientation];
      if (rotation) {
        imageDataUrl = await getRotatedImage(imageDataUrl, rotation);
      }

      setImageSrc(imageDataUrl);
    }
  };

  ///

  async function removerFoto() {
    localStorage.removeItem("imagem");
    setLinkImagem("images/no-image.png");
    await axios
      .get(
        `${props.getBaseUrlApi()}/retaguarda/configuracoes/remover-imagem`,
        props.parameters()
      )
      .then((response) => {
        window.location.reload();
      });
  }

  return (
    <React.Fragment>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover
      />
      <div className="container-scroller font-app">
        <NavBar title="Configurações" />
        <div className="container-fluid page-body-wrapper">
          <SideBar itemActive={"comissao"} />
          <div className="main-panel">
            <div className="content-wrapper">
              {/* <TitleAndDescription
                title="Configurações"
                description=""
              /> */}
              {/* <TitleAndDescription
                title="Configurações"
                description="Acompanhamento de conta-corrente da comissão."
              /> */}

              <div className="row">
                <div className="col-md-12 grid-margin stretch-card">
                  <div className="card">
                    <div className="card-body dashboard-tabs p-0">
                      <div className="tab-content py-0 px-0">
                        <div
                          className="tab-pane fade show active"
                          id="overview"
                          role="tabpanel"
                          aria-labelledby="overview-tab"
                        >
                          <div className="d-flex flex-wrap justify-content-xl-between">
                            <div
                              style={{
                                padding: "15px",
                                float: "left",
                                width: "100%",
                              }}
                            >
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="row">
                                    <div className="col-md-6">
                                      <div className="form-group form-group-chegoo">
                                        <label>Descrição</label>
                                        <input
                                          type="text"
                                          className={`form-control form-control-chegoo font-size-14 ${
                                            errors.desc ? "input-error" : ""
                                          }`}
                                          value={desc}
                                          onChange={(e) =>
                                            setDesc(e.target.value)
                                          }
                                          disabled={true}
                                        />
                                      </div>
                                    </div>

                                    <div className="col-md-6">
                                      <div className="form-group form-group-chegoo">
                                        <label>Email</label>
                                        <input
                                          type="text"
                                          className={`form-control form-control-chegoo font-size-14 ${
                                            errors.email ? "input-error" : ""
                                          }`}
                                          value={email}
                                          onChange={(e) =>
                                            setEmail(e.target.value)
                                          }
                                          disabled={true}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-3">
                                      <div className="form-group form-group-chegoo">
                                        <button
                                          className="btn btn-success font-size-14"
                                          // onClick={() =>
                                          //   gravarConfiguracoes("dados")
                                          // }
                                        >
                                          GRAVAR
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className="row"
                                    style={{ paddingTop: "10px" }}
                                  >
                                    <div className="col-md-2">
                                      <img
                                        src={linkImagem}
                                        alt="profile"
                                        style={{
                                          width: "64px",
                                          height: "64px",
                                          borderRadius: "100%",
                                        }}
                                      />
                                    </div>
                                    <div className="col-md-10">
                                      <div className="float-left width-100">
                                        <span>
                                          <label
                                            style={{
                                              cursor: "pointer",
                                              fontSize: "14px",
                                              color: "#6ACA25",
                                              fontWeight: "bold",
                                            }}
                                           // for="upload-photo"
                                          >
                                            ENVIAR UMA NOVA FOTO
                                          </label>{" "}
                                        </span>{" "}
                                        <span
                                          //onClick={removerFoto}
                                          style={{
                                            margin: "14px",
                                            cursor: "pointer",
                                            color: "#dc494a",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          REMOVER FOTO
                                        </span>{" "}
                                      </div>

                                      <div
                                        className="float-left width-100 font-size-14"
                                        style={{ color: "#8d8989" }}
                                      >
                                        A foto do perfil deve ter no máximo
                                        200kb no formato jpeg ou png.
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-6">
                                  <div className="row">
                                    <div className="col-md-6">
                                      <div className="form-group form-group-chegoo">
                                        <label>Senha</label>
                                        <input
                                          type="password"
                                          className={`form-control form-control-chegoo font-size-14 ${
                                            errors.senha ? "input-error" : ""
                                          }`}
                                          value={senha}
                                          onChange={(e) =>
                                            setSenha(e.target.value)
                                          }
                                          disabled={true}
                                        />
                                      </div>
                                    </div>

                                    <div className="col-md-6">
                                      <div className="form-group form-group-chegoo">
                                        <label>Confirmar Senha</label>
                                        <input
                                          type="password"
                                          className={`form-control form-control-chegoo font-size-14 ${
                                            senhaConfirmar ? "input-error" : ""
                                          }`}
                                          value={senhaConfirmar}
                                          onChange={(e) =>
                                            setSenhaConfirmar(e.target.value)
                                          }
                                          disabled={true}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-6">
                                      <div className="form-group form-group-chegoo">
                                        <button
                                          className="btn btn-success font-size-14"
                                          // onClick={() =>
                                          //   gravarConfiguracoes("senha")
                                          // }
                                        >
                                          ALTERAR SENHA
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {imageSrc ? (
                            <div style={{ padding: "5px" }}>
                              <div className="teste90">
                                <Cropper
                                  image={imageSrc}
                                  crop={crop}
                                  rotation={rotation}
                                  zoom={zoom}
                                  aspect={1}
                                  onCropChange={setCrop}
                                  onRotationChange={setRotation}
                                  onCropComplete={onCropComplete}
                                  onZoomChange={setZoom}
                                  cropShape="round"
                                  showGrid={false}
                                />
                              </div>
                              <div style={{ padding: "15px 0" }}>
                                <button
                                  className="btn btn-success"
                                  onClick={showCroppedImage}
                                  variant="contained"
                                  color="primary"
                                >
                                  GRAVAR IMAGEM
                                </button>
                              </div>
                            </div>
                          ) : (
                            // <div style={{ padding: "15px" }}>
                              <input
                                type="file"
                                onChange={onFileChange}
                                accept=".jpeg, .png, .jpg"
                                name="photo"
                                id="upload-photo"
                                style={{ display: "none" }}
                              />
                            // </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Configuracao;
