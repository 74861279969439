import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import { SideBar, NavBar, Footer } from "../../components";
import "react-toastify/dist/ReactToastify.css";

class Icone extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lista: [
        "fa-exclamation",
        "fa-times",
        "fa-check",
        "fa-search",
        "fa-eraser",
        "fa-edit",
        "fa-trash",
        "fa-plus",
        "fa-file-excel-o",
        "fa-calendar",
        "fa-clock-o",
        "fa-file-pdf-o",
        "fa-map-marker",
        "fa-play",
        "fa-refresh",
        "fa-image",
        "fa-eye",
      ],
    };
  }

  render() {
    return (
      <React.Fragment>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />

        <div className="container-scroller font-app">
          <NavBar title="Ícones" />
          <div className="container-fluid page-body-wrapper">
            <SideBar itemActive={"perfis"} />
            <div className="main-panel">
              <div className="content-wrapper">
                <div className="row">
                  <div className="col-md-12 stretch-card">
                    <div className="card">
                      <div className="card-body3">
                        <React.Fragment>
                          <div className="d-flex" style={{ padding: "35px 0" }}>
                            {this.state.lista.map((item) => (
                              <div style={{ margin: "8px" }}>
                                <i
                                  className={`fa ${item}`}
                                  style={{ fontSize: "35px" }}
                                />
                              </div>
                            ))}
                          </div>
                        </React.Fragment>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Icone;
