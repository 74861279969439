import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import { SideBar, NavBar, Footer, TitleAndDescription } from "../../components";
import "react-toastify/dist/ReactToastify.css";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_dataviz from "@amcharts/amcharts4/themes/dataviz";
am4core.useTheme(am4themes_dataviz);
am4core.useTheme(am4themes_animated);
require("dotenv").config();

class RoteiroDetalheMapa extends Component {
  constructor(props) {
    super(props);

    this.state = {
      roteiro: null
    };
  }

  async componentDidMount() {
  }

  notificar(type, message) {
    toast[type](message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  render() {
    return (
      <React.Fragment>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />

        <div className="container-scroller font-app">
          <NavBar title="Ordens de visita / Detalhe / Mapa" />
          <div className="container-fluid page-body-wrapper">
            <SideBar itemActive={"roteiros"} />
            <div className="main-panel">
              <div className="content-wrapper">
                {/* <TitleAndDescription
                  title="Ordens de visita / Detalhe / Mapa"
                  description=""
                /> */}

                <div className="row" style={{ paddingBottom: "40px" }}>
                  <div className="col-md-12 stretch-card">
                    <div
                      className="main-panel1"
                      style={{ height: "calc(100vh - 50px)" }}
                    >
                      <iframe
                        src={`${process.env.REACT_APP_DEV_MAPS_URL}?locais=${localStorage.getItem('locais')}`}
                        title="Programação"
                        height="800px"
                      ></iframe>

                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default RoteiroDetalheMapa;
