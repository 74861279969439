import React, { Component } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { SideBar, NavBar, Footer, TitleAndDescription } from "../../components";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import Select from "react-select";
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from "reactstrap";
import { UncontrolledTooltip } from "reactstrap";
const { formatarReal } = require("../../utils");

class Checklist extends Component {
  constructor(props) {
    super(props);
    this.load = this.load.bind(this);
    this.loadChecklists = this.loadChecklists.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleClickDeletar = this.handleClickDeletar.bind(this);
    this.handleClickConfirmaDeletar =
      this.handleClickConfirmaDeletar.bind(this);

    this.state = {
      checklists: [],
      checklistDeletar: null,
      modal: false,
    };
  }

  async componentDidMount() {
    await this.load();
  }

  async load() {
    await this.loadChecklists();
  }

  async loadChecklists() {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/retaguarda/checklists`,
        this.props.parameters()
      )
      .then((response) => {
        this.setState({ checklists: response.data.checklists });
      });
  }

  notificar(type, message) {
    toast[type](message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  handleClickDeletar(id) {
    this.setState({ checklistDeletar: id, modal: true });
  }

  toggle() {
    this.setState({ modal: !this.state.modal });
  }

  async handleClickConfirmaDeletar() {
    await axios
      .delete(
        `${this.props.getBaseUrlApi()}/retaguarda/checklists/${
          this.state.checklistDeletar
        }`,
        this.props.parameters()
      )
      .then((response) => {
        this.setState({ checklistDeletar: null, modal: false }, () => {
          this.loadChecklists();
          this.notificar("success", "Checklist deletado com sucesso!");
        });
      })
      .catch((error) => {
        this.setState({ checklistDeletar: null, modal: false }, () => {
          this.notificar("warning", error.response.data.erro);
        });
      });
  }

  render() {
    return (
      <React.Fragment>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />

        <div className="container-scroller font-app">
          <NavBar title="Checklists" />
          <div className="container-fluid page-body-wrapper">
            <SideBar itemActive={"checklists"} />
            <div className="main-panel">
              <div className="content-wrapper">
                {/* <TitleAndDescription title="Veículos" description="" /> */}

                <div
                  className="row"
                  style={{ marginBottom: "0.8rem", alignItems: "center" }}
                >
                  <div className="col-md-12 stretch-card">
                    {true && (
                      <div className="card">
                        <div
                          className="card-body5"
                          style={{ position: "relative" }}
                        >
                          <React.Fragment>
                            {/* <div
                              style={{
                                position: "absolute",
                                top: "11px",
                                right: "11px",
                              }}
                            >
                              <button
                                className="btn btn-success btn-app-verde-sm"
                                onClick={() => {
                                  this.props.history.push("/fornecedor-novo");
                                }}
                              >
                               CADASTRAR
                              </button>
                            </div> */}
                            <div className="row">
                          

                              <div className="col-md-2 pd-veiculo2">
                                <div className="form-group form-group-chegoo2">
                                  <label>Status</label>
                                  <select className="form-control form-control-chegoo font-size-14">
                                    <option>Ativo</option>
                                    <option>Inativo</option>
                                  </select>
                                </div>
                              </div>


                              <div
                                className="col-md-2 pd-veiculo"
                                style={{ display: "flex", marginTop: "15px" }}
                              >
                                <div
                                  className="form-group-chegoo2"
                                  style={{ float: "left" }}
                                >
                                  <label></label>
                                  <button
                                    className="btn btn-secondary btn-sm"
                                    style={{
                                      padding: "0.275rem 0.55rem",
                                      margin: "7px 5px 0px",
                                    }}
                                    onClick={() => this.load()}
                                    id={`tooltip-filtro-atendimentos`}
                                  >
                                    <i
                                      className="fa fa-search"
                                      aria-hidden="true"
                                      style={{ fontSize: "px" }}
                                    ></i>
                                  </button>
                                  <UncontrolledTooltip
                                    placement="bottom"
                                    target={`tooltip-filtro-atendimentos`}
                                  >
                                    Filtrar
                                  </UncontrolledTooltip>
                                </div>
                                <div className="form-group-chegoo2">
                                  <label></label>
                                  <button
                                    className="btn btn-warning btn-sm"
                                    style={{
                                      padding: "0.275rem 0.55rem",
                                      margin: "7px 5px 0px",
                                      color:"#FFF"
                                    }}
                                    onClick={() => this.limparFiltros()}
                                    id={`tooltip-limpar-filtro-atendimentos`}
                                  >
                                    <i
                                      className="fa fa-eraser"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                  <UncontrolledTooltip
                                    placement="bottom"
                                    target={`tooltip-limpar-filtro-atendimentos`}
                                  >
                                    Limpar filtro
                                  </UncontrolledTooltip>
                                </div>
                              </div>

                              <div
                                className="offset-md-6 col-md-2"
                                style={{
                                  marginTop: "15px",
                                  marginTop: "15px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "end",
                                }}
                              >
                                <button
                                  className="btn btn-success btn-app-verde-sm"
                                  onClick={() => {
                                    this.props.history.push("/checklists-novo");
                                  }}
                                >
                                  CADASTRAR
                                </button>
                              </div>

                           
                            </div>
                          </React.Fragment>
                        </div>
                      </div>
                    )}
                  </div>
                </div>


                <div className="row">
                  <div className="col-md-12 stretch-card">
                    {this.state.checklists.length > -1 && (
                      <div className="card">
                        <div className="card-body2">
                          <React.Fragment>
                            
                            <div className="table-responsive">
                              <table
                                id="recent-purchases-listing"
                                className="table"
                              >
                                <thead className="thead-dark">
                                  <tr>
                                    <th>Descrição</th>
                                    <th className="text-center">Itens</th>
                                    <th>Ações</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.checklists.map(
                                    (checklist, key) => (
                                      <tr key={key}>
                                        <td style={{ fontWeight: "bold" }}>
                                          {checklist.desc}
                                        </td>
                                        <td className="text-center">
                                          {checklist.itens.length}
                                        </td>

                                        <td
                                          className="d-flex"
                                          style={{ fontSize: "18px" }}
                                        >
                                          <div
                                            style={{
                                              margin: "2px",
                                              cursor: "pointer",
                                            }}
                                          >
                                            <i
                                              className="fa fa-edit"
                                              onClick={() => {
                                                this.props.history.push(
                                                  `/checklists-novo?id=${checklist._id}`
                                                );
                                              }}
                                            ></i>
                                          </div>
                                          <div
                                            style={{
                                              margin: "2px",
                                              cursor: "pointer",
                                            }}
                                          >
                                            <i
                                              className="fa fa-trash"
                                              style={{ color: "red" }}
                                              onClick={() =>
                                                this.handleClickDeletar(
                                                  checklist._id
                                                )
                                              }
                                            ></i>
                                          </div>
                                        </td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </table>
                            </div>                         
                      
                          </React.Fragment>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>

        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Deletar Checklist</ModalHeader>
          <ModalBody>Tem certeza que deseja deletar este checklist?</ModalBody>
          <ModalFooter>
            <Button
              className="btn-modal-recuperar"
              color="secondary"
              onClick={this.toggle}
            >
              Não
            </Button>
            <Button
              className="btn-modal-recuperar"
              color="primary"
              onClick={this.handleClickConfirmaDeletar}
            >
              Sim
            </Button>{" "}
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default Checklist;
