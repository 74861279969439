import React, { Component } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { SideBar, NavBar, Footer, TitleAndDescription } from "../../components";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import Select from "react-select";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  UncontrolledTooltip,
} from "reactstrap";
const { formatarReal } = require("../../utils");

class TipoDespesa extends Component {
  constructor(props) {
    super(props);
    this.load = this.load.bind(this);
    this.loadTiposDespesa = this.loadTiposDespesa.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleClickDeletar = this.handleClickDeletar.bind(this);
    this.handleClickConfirmaDeletar =
      this.handleClickConfirmaDeletar.bind(this);
    this.onChangeInputFiltro =
      this.onChangeInputFiltro.bind(this);
    this.limparFiltros =
      this.limparFiltros.bind(this);

    this.state = {
      tiposDespesa: [],
      tipoDespesaDeletar: null,
      modal: false,
      filtro:{
        tipo: "99"
      }
    };
  }

  async componentDidMount() {
    const userLogado = JSON.parse(localStorage.getItem('usuario'));
    if(userLogado.nivel === "3"){
      return this.props.history.push('/pedidos-material')
    }
    if (userLogado.nivel === "4") {
      return this.props.history.push("/nao-conformidades");
    }
    if (userLogado.nivel === "5") {
      return this.props.history.push("/despesas");
    }
    await this.load();
  }

  async load() {
    await this.loadTiposDespesa();
  }

  async loadTiposDespesa() {
    let options = {}

    if(this.state.filtro.tipo !== "99"){
      options.tipo = this.state.filtro.tipo
    }

    await axios
      .post(
        `${this.props.getBaseUrlApi()}/retaguarda/lista-tipos-despesa`,
        options,
        this.props.parameters()
      )
      .then((response) => {
        let tiposDespesa = response.data.tiposDespesa;

        
    tiposDespesa.sort(function(a, b) {
      var descricaoA = a.desc.toUpperCase(); // Ignora maiúsculas/minúsculas durante a ordenação
      var descricaoB = b.desc.toUpperCase();
  
      if (descricaoA < descricaoB) {
        return -1;
      }
      if (descricaoA > descricaoB) {
        return 1;
      }
  
      // Descrições são iguais
      return 0;
    });

    

        this.setState({ tiposDespesa });
      });
  }

  notificar(type, message) {
    toast[type](message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  handleClickDeletar(id) {
    this.setState({ tipoDespesaDeletar: id, modal: true });
  }

  toggle() {
    this.setState({ modal: !this.state.modal });
  }

  async handleClickConfirmaDeletar() {
    await axios
      .delete(
        `${this.props.getBaseUrlApi()}/retaguarda/tipos-despesa/${
          this.state.tipoDespesaDeletar
        }`,
        this.props.parameters()
      )
      .then((response) => {
        this.setState({ tipoDespesaDeletar: null, modal: false }, () => {
          this.loadTiposDespesa();
          this.notificar("success", "Tipo Despesa deletada com sucesso!");
        });
      })
      .catch((error) => {
        this.setState({ tipoDespesaDeletar: null, modal: false }, () => {
          this.notificar("warning", error.response.data.erro);
        });
      });
  }

  onChangeInputFiltro(type, value){
    let filtro = this.state.filtro;
    filtro[type] = value;
    this.setState({filtro})
  }

  limparFiltros(){
    this.setState({
      filtro: {
        tipo: "99"
      }
    }, () => {
      this.load()
    })
  }

  render() {
    return (
      <React.Fragment>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />

        <div className="container-scroller font-app">
          <NavBar title="Tipos de Lançamento" />
          <div className="container-fluid page-body-wrapper">
            <SideBar itemActive={"tipos-despesa"} />
            <div className="main-panel">
              <div className="content-wrapper">
                {/* <TitleAndDescription title="Usuários" description="" /> */}

                <div
                  className="row"
                  style={{ marginBottom: "0.8rem", alignItems: "center" }}
                >
                  <div className="col-md-12 stretch-card">
                    {true && (
                      <div className="card">
                        <div
                          className="card-body5"
                          style={{ position: "relative" }}
                        >
                          <React.Fragment>
                            <div className="row">
                              

                              <div className="col-md-2 pd-veiculo2">
                                <div className="form-group form-group-chegoo2">
                                  <label>Tipo</label>
                                  <select 
                                    className="form-control form-control-chegoo font-size-14"
                                    value={this.state.filtro.tipo}
                                    onChange={(e) => this.onChangeInputFiltro('tipo', e.target.value)}
                                  >
                                    <option value="99">Todos</option>
                                    <option value="D">Despesa</option>
                                    <option value="C">Receita</option>
                                  </select>
                                </div>
                              </div>
                              <div
                                className="col-md-2 pd-veiculo"
                                style={{ display: "flex", marginTop: "15px" }}
                              >
                                <div
                                  className="form-group-chegoo2"
                                  style={{ float: "left" }}
                                >
                                  <label></label>
                                  <button
                                    className="btn btn-secondary btn-sm"
                                    style={{
                                      padding: "0.275rem 0.55rem",
                                      margin: "10px 5px 0px",
                                    }}
                                    onClick={() => this.load()}
                                    id={`tooltip-filtro-atendimentos`}
                                  >
                                    <i
                                      className="fa fa-search"
                                      aria-hidden="true"
                                      style={{ fontSize: "px" }}
                                    ></i>
                                  </button>
                                  <UncontrolledTooltip
                                    placement="bottom"
                                    target={`tooltip-filtro-atendimentos`}
                                  >
                                    Filtrar
                                  </UncontrolledTooltip>
                                </div>
                                <div className="form-group-chegoo2">
                                  <label></label>
                                  <button
                                    className="btn btn-warning btn-sm"
                                    style={{
                                      padding: "0.275rem 0.55rem",
                                      margin: "10px 5px 0px",
                                      color: "#FFF",
                                    }}
                                    onClick={() => this.limparFiltros()}
                                    id={`tooltip-limpar-filtro-atendimentos`}
                                  >
                                    <i
                                      className="fa fa-eraser"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                  <UncontrolledTooltip
                                    placement="bottom"
                                    target={`tooltip-limpar-filtro-atendimentos`}
                                  >
                                    Limpar filtro
                                  </UncontrolledTooltip>
                                </div>
                              </div>
                              <div
                                className="offset-md-6 col-md-2 pd-veiculo3"
                                style={{
                                  marginTop: "15px",
                                  marginTop: "15px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "end",
                                }}
                              >
                                <button
                                  className="btn btn-success btn-app-verde-sm"
                                  onClick={() => {
                                    this.props.history.push(
                                      "/tipos-despesa-novo"
                                    );
                                  }}
                                >
                                  CADASTRAR
                                </button>
                              </div>
                            </div>
                          </React.Fragment>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12 stretch-card">
                    {this.state.tiposDespesa.length > -1 && (
                      <div className="card">
                        <div className="card-body2">
                          <React.Fragment>
                            <div className="table-responsive">
                              <table
                                id="recent-purchases-listing"
                                className="table"
                              >
                                <thead className="thead-dark">
                                  <tr>
                                    <th>Desc</th>
                                    <th className="">Tipo</th>
                                    <th className="text-center">Balanço ?</th>
                                    <th className="text-right">
                                      Total Despesas
                                    </th>
                                    <th>Ações</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.tiposDespesa.map(
                                    (tipoDespesa, key) => (
                                      <tr key={key}>
                                        <td>{tipoDespesa.desc}</td>
                                        <td>
                                          {tipoDespesa.tipo === "D"
                                            ? "Despesa"
                                            : "Receita"}
                                        </td>
                                        <td className="text-center">
                                          {tipoDespesa.balanco ? "Sim" : "Não"}
                                        </td>
                                        <td className="text-right">
                                          {tipoDespesa.totalDespesas}
                                        </td>

                                        <td
                                          className="d-flex"
                                          style={{ fontSize: "18px" }}
                                        >
                                          <div
                                            style={{
                                              margin: "2px",
                                              cursor: "pointer",
                                            }}
                                          >
                                            <i
                                              className="fa fa-edit"
                                              onClick={() => {
                                                this.props.history.push(
                                                  `/tipos-despesa-novo?id=${tipoDespesa._id}`
                                                );
                                              }}
                                              id={`btn-editar-${tipoDespesa._id}`}
                                            ></i>

                                            <UncontrolledTooltip
                                              placement="bottom"
                                              target={`btn-editar-${tipoDespesa._id}`}
                                            >
                                              Editar
                                            </UncontrolledTooltip>
                                          </div>
                                          <div
                                            style={{
                                              margin: "2px",
                                              cursor: "pointer",
                                            }}
                                          >
                                            <i
                                              className="fa fa-trash"
                                              style={{ color: "red" }}
                                              onClick={() => {
                                                this.handleClickDeletar(
                                                  tipoDespesa._id
                                                );
                                              }}
                                              id={`btn-excluir-${tipoDespesa._id}`}
                                            ></i>

                                            <UncontrolledTooltip
                                              placement="bottom"
                                              target={`btn-excluir-${tipoDespesa._id}`}
                                            >
                                              Excluir
                                            </UncontrolledTooltip>
                                          </div>
                                        </td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </React.Fragment>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>

        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>
            Deletar Tipo de Despesa
          </ModalHeader>
          <ModalBody>
            Tem certeza que deseja deletar este tipo de despesa?
          </ModalBody>
          <ModalFooter>
            <Button
              className="btn-modal-recuperar"
              color="secondary"
              onClick={this.toggle}
            >
              Não
            </Button>
            <Button
              className="btn-modal-recuperar"
              color="primary"
              onClick={this.handleClickConfirmaDeletar}
            >
              Sim
            </Button>{" "}
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default TipoDespesa;
