import React, { Component } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { SideBar, NavBar, Footer } from "../../components";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import Select from "react-select";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  UncontrolledTooltip,
} from "reactstrap";

class UnidadeMedida extends Component {
  constructor(props) {
    super(props);
    this.load = this.load.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleClickDeletar = this.handleClickDeletar.bind(this);
    this.handleClickConfirmaDeletar =
      this.handleClickConfirmaDeletar.bind(this);
    this.onChangeInputFiltro = this.onChangeInputFiltro.bind(this);
    this.limparFiltros = this.limparFiltros.bind(this);

    this.state = {
      unidadesMedida: [],
      unidadeMedidaDeletar: null,
      modal: false,
      filtro: {
        status: "A",
      },
    };
  }

  async componentDidMount() {
    const userLogado = JSON.parse(localStorage.getItem("usuario"));
    if (userLogado.nivel === "4") {
      return this.props.history.push("/nao-conformidades");
    }
    if (userLogado.nivel === "5") {
      return this.props.history.push("/despesas");
    }
    await this.load();
  }

  async load() {
    let filtro = this.state.filtro;

    await axios
      .post(
        `${this.props.getBaseUrlApi()}/retaguarda/lista-unidades-medida`,
        { filtro },
        this.props.parameters()
      )
      .then((response) => {
        let unidadesMedida = response.data.unidadesMedida;

        this.setState({ unidadesMedida });
      });
  }

  notificar(type, message) {
    toast[type](message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  handleClickDeletar(id) {
    this.setState({ unidadeMedidaDeletar: id, modal: true });
  }

  toggle() {
    this.setState({ modal: !this.state.modal });
  }

  async handleClickConfirmaDeletar() {
    await axios
      .delete(
        `${this.props.getBaseUrlApi()}/retaguarda/unidades-medida/${
          this.state.unidadeMedidaDeletar
        }`,
        this.props.parameters()
      )
      .then((response) => {
        this.setState({ unidadeMedidaDeletar: null, modal: false }, () => {
          this.load();
          this.notificar("success", "Unidade de medida deletada com sucesso!");
        });
      })
      .catch((error) => {
        this.setState({ unidadeMedidaDeletar: null, modal: false }, () => {
          this.notificar("warning", error.response.data.erro);
        });
      });
  }

  onChangeInputFiltro(type, value) {
    let filtro = this.state.filtro;
    filtro[type] = value;
    this.setState({ filtro });
  }

  limparFiltros() {
    this.setState({
      filtro: {
        status: "99",
      },
    });
  }

  render() {
    return (
      <React.Fragment>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />

        <div className="container-scroller font-app">
          <NavBar title="Unidades de Medida" />
          <div className="container-fluid page-body-wrapper">
            <SideBar itemActive={"unidades-medida"} />
            <div className="main-panel">
              <div className="content-wrapper">
                <div
                  className="row"
                  style={{ marginBottom: "0.8rem", alignItems: "center" }}
                >
                  <div className="col-md-12 stretch-card">
                    {true && (
                      <div className="card">
                        <div
                          className="card-body5"
                          style={{ position: "relative" }}
                        >
                          <React.Fragment>
                            <div className="row">
                              <div className="col-md-2 pd-veiculo2">
                                <div className="form-group form-group-chegoo2">
                                  <label>Status</label>
                                  <select
                                    className="form-control form-control-chegoo font-size-14"
                                    value={this.state.filtro.status}
                                    onChange={(e) =>
                                      this.onChangeInputFiltro(
                                        "status",
                                        e.target.value
                                      )
                                    }
                                  >
                                    <option value="99">Todos</option>
                                    <option value="A">Ativo</option>
                                    <option value="I">Inativo</option>
                                  </select>
                                </div>
                              </div>

                              <div
                                className="col-md-2 pd-veiculo"
                                style={{ display: "flex", marginTop: "15px" }}
                              >
                                <div
                                  className="form-group-chegoo2"
                                  style={{ float: "left" }}
                                >
                                  <label></label>
                                  <button
                                    className="btn btn-secondary btn-sm"
                                    style={{
                                      padding: "0.275rem 0.55rem",
                                      margin: "10px 5px 0px",
                                    }}
                                    onClick={() => this.load()}
                                    id={`tooltip-filtro-atendimentos`}
                                  >
                                    <i
                                      className="fa fa-search"
                                      aria-hidden="true"
                                      style={{ fontSize: "px" }}
                                    ></i>
                                  </button>
                                  <UncontrolledTooltip
                                    placement="bottom"
                                    target={`tooltip-filtro-atendimentos`}
                                  >
                                    Filtrar
                                  </UncontrolledTooltip>
                                </div>
                                <div className="form-group-chegoo2">
                                  <label></label>
                                  <button
                                    className="btn btn-warning btn-sm"
                                    style={{
                                      padding: "0.275rem 0.55rem",
                                      margin: "10px 5px 0px",
                                      color: "#FFF",
                                    }}
                                    onClick={() => this.limparFiltros()}
                                    id={`tooltip-limpar-filtro-atendimentos`}
                                  >
                                    <i
                                      className="fa fa-eraser"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                  <UncontrolledTooltip
                                    placement="bottom"
                                    target={`tooltip-limpar-filtro-atendimentos`}
                                  >
                                    Limpar filtro
                                  </UncontrolledTooltip>
                                </div>
                              </div>
                              <div
                                className="offset-md-6 col-md-2 pd-veiculo3"
                                style={{
                                  marginTop: "15px",
                                  marginTop: "15px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "end",
                                }}
                              >
                                <button
                                  className="btn btn-success btn-app-verde-sm"
                                  onClick={() => {
                                    this.props.history.push(
                                      "/unidades-medida-nova"
                                    );
                                  }}
                                >
                                  CADASTRAR
                                </button>
                              </div>
                            </div>
                          </React.Fragment>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12 stretch-card">
                    {this.state.unidadesMedida.length > -1 && (
                      <div className="card">
                        <div className="card-body2">
                          <React.Fragment>
                            <div className="table-responsive">
                              <table
                                id="recent-purchases-listing"
                                className="table"
                              >
                                <thead className="thead-dark">
                                  <tr>
                                    <th>Descrição</th>
                                    <th className="text-center">Status</th>
                                    <th className="text-center">
                                      Data Cadastro
                                    </th>
                                    <th>Ações</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.unidadesMedida.map(
                                    (item, key) => (
                                      <tr key={key}>
                                        <td>{item.desc}</td>
                                        <td className="text-center">
                                          {item.status === "A"
                                            ? "Ativo"
                                            : "Inativo"}
                                        </td>
                                        <td className="text-center">
                                          {" "}
                                          {moment(item.dtCadastro).format(
                                            "DD/MM/YYYY"
                                          )}
                                        </td>

                                        <td
                                          className="d-flex"
                                          style={{ fontSize: "18px" }}
                                        >
                                          <div
                                            style={{
                                              margin: "2px",
                                              cursor: "pointer",
                                            }}
                                          >
                                            <i
                                              className="fa fa-edit"
                                              onClick={() => {
                                                this.props.history.push(
                                                  `/unidades-medida-nova?id=${item._id}`
                                                );
                                              }}
                                              id={`btn-editar-${item._id}`}
                                            ></i>

                                            <UncontrolledTooltip
                                              placement="bottom"
                                              target={`btn-editar-${item._id}`}
                                            >
                                              Editar
                                            </UncontrolledTooltip>
                                          </div>
                                          <div
                                            style={{
                                              margin: "2px",
                                              cursor: "pointer",
                                            }}
                                          >
                                            <i
                                              className="fa fa-trash"
                                              style={{ color: "red" }}
                                              onClick={() => {
                                                this.handleClickDeletar(
                                                  item._id
                                                );
                                              }}
                                              id={`btn-excluir-${item._id}`}
                                            ></i>

                                            <UncontrolledTooltip
                                              placement="bottom"
                                              target={`btn-excluir-${item._id}`}
                                            >
                                              Excluir
                                            </UncontrolledTooltip>
                                          </div>
                                        </td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </React.Fragment>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>

        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>
            Deletar Unidade de Medida?
          </ModalHeader>
          <ModalBody>
            Tem certeza que deseja deletar esta unidade de medida?
          </ModalBody>
          <ModalFooter>
            <Button
              className="btn-modal-recuperar"
              color="secondary"
              onClick={this.toggle}
            >
              Não
            </Button>
            <Button
              className="btn-modal-recuperar"
              color="primary"
              onClick={this.handleClickConfirmaDeletar}
            >
              Sim
            </Button>{" "}
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default UnidadeMedida;
