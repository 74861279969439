import React, { Component } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { SideBar, NavBar, Footer, TitleAndDescription } from "../../components";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import Select from "react-select";
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from "reactstrap";
import queryString from "query-string";
import DatePicker from "react-datepicker";
import FileDownload from "js-file-download";
import { UncontrolledTooltip } from "reactstrap";
const { formatarReal } = require("../../utils");

const SITUACAO_PENDENTE = "0";
const SITUACAO_ANALISE = "1";
const SITUACAO_APROVADO = "2";
const SITUACAO_REVISAO = "3";
const SITUACAO_REPROVADO = "4";
const SITUACAO_CANCELADO = "5";

class DespesaUsuario extends Component {
  constructor(props) {
    super(props);
    this.loadDespesas = this.loadDespesas.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleClickDeletar = this.handleClickDeletar.bind(this);
    this.handleClickConfirmaDeletar =
      this.handleClickConfirmaDeletar.bind(this);
    this.toggleModalStatus = this.toggleModalStatus.bind(this);
    this.handleClickStatus = this.handleClickStatus.bind(this);
    this.handleClickConfirmaStatus = this.handleClickConfirmaStatus.bind(this);
    this.toggleModalComprovante = this.toggleModalComprovante.bind(this);
    this.handleClickFotoComprovante =
      this.handleClickFotoComprovante.bind(this);
    this.onChangeFiltro = this.onChangeFiltro.bind(this);
    this.gerarRelatorio = this.gerarRelatorio.bind(this);
    this.abrirPaginaEditarLancamento =
      this.abrirPaginaEditarLancamento.bind(this);
    this.onChangeFiltroNovo = this.onChangeFiltroNovo.bind(this);
    this.loadOpcoesFiltroNovo = this.loadOpcoesFiltroNovo.bind(this);
    this.nomeDoMes = this.nomeDoMes.bind(this);

    this.state = {
      _id: null,
      despesas: [],
      dataInicial: moment().startOf("month").toDate(),
      dataFinal: moment().endOf("month").toDate(),
      usuario: null,
      filtroNovo: null,
      listaOpcoes: [],
    };
  }

  async componentDidMount() {
    const userLogado = JSON.parse(localStorage.getItem("usuario"));
    if (userLogado.nivel === "3") {
      return this.props.history.push("/pedidos-material");
    }
    if (userLogado.nivel === "4") {
      return this.props.history.push("/nao-conformidades");
    }
    let idUsuario = queryString.parse(this.props.location.search).id;

    if (idUsuario === undefined) {
      this.props.history.push("/despesas");
      return false;
    }

    this.loadOpcoesFiltroNovo(idUsuario);
  }

  async loadDespesas(idUsuario) {
    let filtroEnvio = {};

    filtroEnvio["idUsuario"] = idUsuario;

    let filtroNovo = null;
    if (
      this.state.filtroNovo !== null &&
      this.state.filtroNovo.value !== "99"
    ) {
      filtroNovo = this.state.filtroNovo.value;
    }

    let options = {
      ...filtroEnvio,
      dataInicial: this.state.dataInicial,
      dataFinal: this.state.dataFinal,
      filtroNovo,
    };

    ////console.clear()
    console.log("bbb", options);

    await axios
      .post(
        `${this.props.getBaseUrlApi()}/retaguarda/lista-despesas-usuario-felipe/`,
        { options },
        this.props.parameters()
      )
      .then((response) => {
        let despesas = response.data.despesas;
        let usuario = response.data.usuario;
        let saldoInicial = 0;

        for (let item of despesas) {
          if (item.saldoInicial !== undefined && item.saldoInicial) {
            saldoInicial = item.valor;
          }
          if (
            item.situacao !== SITUACAO_CANCELADO &&
            item.situacao !== SITUACAO_REPROVADO &&
            item.balanco &&
            item.saldoInicial === undefined
          ) {
            if (item.tipo === "D") {
              saldoInicial = saldoInicial - item.valor;
            } else {
              saldoInicial = saldoInicial + item.valor;
            }
            item.saldoAtual = 0 + saldoInicial;
          } else {
            item.saldoAtual = saldoInicial;
          }
        }

        this.setState({ despesas, usuario, _id: idUsuario });
      });
  }

  notificar(type, message) {
    toast[type](message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  handleClickDeletar(id) {
    this.setState({ despesaDeletar: id, modal: true });
  }

  toggle() {
    this.setState({ modal: !this.state.modal });
  }

  async handleClickConfirmaDeletar() {
    await axios
      .delete(
        `${this.props.getBaseUrlApi()}/retaguarda/despesas/${
          this.state.despesaDeletar
        }`,
        this.props.parameters()
      )
      .then((response) => {
        this.setState({ despesaDeletar: null, modal: false }, () => {
          this.loadDespesas(this.state.idMotorista);
          this.notificar("success", "Despesa deletada com sucesso!");
        });
      })
      .catch((error) => {
        this.setState({ despesaDeletar: null, modal: false }, () => {
          this.notificar("warning", error.response.data.erro);
        });
      });
  }

  toggleModalStatus() {
    this.setState({ modalStatus: !this.state.modalStatus });
  }

  handleClickStatus(despesaEditarStatus) {
    this.setState({ despesaEditarStatus, modalStatus: true });
  }

  async handleClickConfirmaStatus() {
    await axios
      .post(
        `${this.props.getBaseUrlApi()}/retaguarda/despesas-status/${
          this.state.despesaEditarStatus._id
        }`,
        this.props.parameters()
      )
      .then((response) => {
        this.setState({ despesaEditarStatus: null, modalStatus: false }, () => {
          this.loadDespesas(this.state.idMotorista);
          this.notificar("success", "Despesa editada com sucesso!");
        });
      })
      .catch((error) => {
        this.setState({ despesaEditarStatus: null, modalStatus: false }, () => {
          this.notificar("warning", error.response.data.erro);
        });
      });
  }

  toggleModalComprovante() {
    this.setState({ modalComprovante: !this.state.modalComprovante });
  }

  handleClickFotoComprovante(foto) {
    this.setState(
      {
        fotoComprovante: foto,
      },
      () => {
        this.toggleModalComprovante();
      }
    );
  }

  onChangeFiltro(e) {
    let value = e.target.value;
    this.setState({ filtro: value }, () => {
      let dataInicial = moment();
      let dataFinal = moment();
      if (value === "ontem") {
        dataInicial = dataInicial.subtract(1, "days");
        dataFinal = dataFinal.subtract(1, "days");
      }
      if (value === "semana") {
        dataInicial = dataInicial.subtract(moment().day(), "days");
        dataFinal = dataFinal.subtract(moment().day(), "days");
        dataFinal = dataFinal.add(6, "days");
      }
      if (value === "mes") {
        dataInicial = dataInicial.startOf("month");
        dataFinal = dataFinal.endOf("month");
      }

      this.setState(
        {
          dataInicial: new Date(
            dataInicial.year(),
            dataInicial.month(),
            dataInicial.date()
          ),
          dataFinal: new Date(
            dataFinal.year(),
            dataFinal.month(),
            dataFinal.date()
          ),
        },
        () => {
          this.loadDespesas(this.state.idMotorista);
        }
      );
    });
  }

  async gerarRelatorio() {
    let listaFormatada = [];
    let lista = this.state.despesas;

    if (lista.length === 0) {
      this.notificar("warning", "Lista vazia para gerar relatório");
      return false;
    }

    for (let item of lista) {
      let motorista = item.usuario.desc;
      let categoria = "";
      if (item.tipo === "despesa") {
        categoria = item.categoria.desc;
      }

      let valor = "";
      if (item.tipo === "despesa") {
        valor = `-${item.valor.toFixed(2).replace(".", ",")}`;
      } else {
        valor = item.valor.toFixed(2).replace(".", ",");
      }

      listaFormatada.push({
        data: moment(item.dtDespesa).format("DD/MM/YYYY"),
        motorista: motorista,
        lancamento: item.desc,
        categoria: categoria,
        valor: valor,
        saldo: item.saldoAtual.toFixed(2).replace(".", ","),
      });
    }

    let dataInicial = this.state.dataInicial;
    let dataFinal = this.state.dataFinal;

    // let nomeArquivo = `relatorio-fornecedores.xlsx`;
    let nomeArquivo = `despesas-motorista-${moment(dataInicial)
      .startOf("day")
      .format("YYYY-MM-DD")}-${moment(dataFinal)
      .startOf("day")
      .format("YYYY-MM-DD")}`;

    let parametros = {
      despesas: listaFormatada,
      dataInicial,
      dataFinal,
      motorista: this.state.motorista.descUsuario,
    };

    await axios
      .post(`${this.props.getBaseUrlImpressao()}/despesas-motorista-pdf`, {
        parametros,
      })
      .then(async (response) => {
        // FileDownload(response.data, nomeArquivo);
        localStorage.setItem("html", response.data.html);
        localStorage.setItem("tituloPagina", nomeArquivo);
        window.open("/impressao", "_blank");
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          // this.notificar("warning", error.response.data.erro);
        }
      });
    // import FileDownload from "js-file-download";
  }
  async gerarRelatorio2() {
    let listaFormatada = [];
    let lista = this.state.despesas;

    if (lista.length === 0) {
      this.notificar("warning", "Lista vazia para gerar relatório");
      return false;
    }

    for (let item of lista) {
      let motorista = item.usuario.desc;
      let categoria = "";
      if (item.tipo === "despesa") {
        categoria = item.categoria.desc;
      }

      let valor = "";
      if (item.tipo === "despesa") {
        valor = `-${item.valor.toFixed(2).replace(".", ",")}`;
      } else {
        valor = item.valor.toFixed(2).replace(".", ",");
      }

      listaFormatada.push({
        DATA: moment(item.dtDespesa).format("DD/MM/YYYY"),
        MOTORISTA: motorista,
        LANCAMENTO: item.desc,
        CATEGORIA: categoria,
        "VALOR(R$)": valor,
        "SALDO(R$)": item.saldoAtual.toFixed(2).replace(".", ","),
      });
    }

    let dataInicial = this.state.dataInicial;
    let dataFinal = this.state.dataFinal;

    // let nomeArquivo = `relatorio-fornecedores.xlsx`;
    let nomeArquivo = `despesas-motorista-${moment(dataInicial)
      .startOf("day")
      .format("YYYY-MM-DD")}-${moment(dataFinal)
      .startOf("day")
      .format("YYYY-MM-DD")}.xlsx`;

    let params = {};
    params.responseType = "blob";

    await axios
      .post(
        `${this.props.getBaseUrlApi()}/retaguarda/relatorios`,
        { lista: listaFormatada },
        params
      )
      .then(async (response) => {
        FileDownload(response.data, nomeArquivo);
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          // this.notificar("warning", error.response.data.erro);
        }
      });
    // import FileDownload from "js-file-download";
  }

  abrirPaginaEditarLancamento(lancamento) {
    if (lancamento.saldoInicial === undefined) {
      return this.props.history.push(`/despesas-nova?id=${lancamento._id}`);
    }
  }

  onChangeFiltroNovo(value) {
    let listaOpcoes = this.state.listaOpcoes;
    let itemFind = listaOpcoes.find((it) => it.value === value);
    this.setState({ filtroNovo: itemFind });
  }

  async loadOpcoesFiltroNovo(idUsuario) {
    let listaOpcoes = [];
    let inicioControle = moment("2023-12-01T03:00:00.000Z").utc();
    let dataAtual = moment().startOf("month").utc();
    let dataSelecionada = {
      value: dataAtual.format("YYYY-MM-DD"),
      desc: this.nomeDoMes(parseInt(dataAtual.format("MM"))),
    };

    while (dataAtual.isSameOrAfter(inicioControle)) {
      listaOpcoes.push({
        value: dataAtual.format("YYYY-MM-DD"),
        desc: this.nomeDoMes(parseInt(dataAtual.format("MM"))),
      });

      dataAtual = dataAtual.subtract(1, "month");
    }

    listaOpcoes = listaOpcoes.reverse();
    listaOpcoes.push({
      value: "99",
      desc: "Todo o período",
    });

    this.setState({ listaOpcoes, filtroNovo: dataSelecionada }, async () => {
      this.loadDespesas(idUsuario);
    });
  }

  nomeDoMes(numero) {
    const meses = [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro",
    ];

    if (numero >= 1 && numero <= 12) {
      return meses[numero - 1];
    } else {
      return "Mês inválido";
    }
  }

  render() {
    return (
      <React.Fragment>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />

        <div className="container-scroller font-app">
          <NavBar title="Lançamentos / Lançamentos usuário" />
          <div className="container-fluid page-body-wrapper">
            <SideBar itemActive={"despesas"} />
            <div className="main-panel">
              <div className="content-wrapper">
                {/* <TitleAndDescription
                  title="Despesas / Despesas motorista"
                  description=""
                /> */}

                <div className="row" style={{ paddingBottom: "0.8rem" }}>
                  <div className="col-md-12 stretch-card">
                    {this.state.usuario && (
                      <div className="card">
                        <div className="card-body3">
                          <React.Fragment>
                            <div
                              className="float-left width-100 d-flex"
                              style={{
                                // padding: "5px 0",
                                // marginBottom: "20px",
                                position: "relative",
                                //  borderBottom: "1px solid #CCC",
                                alignItems: "center",
                                marginBottom: "0.8rem",
                              }}
                            >
                              <span className="float-left font-size-14">
                                FILTRAR POR PERÍODO:
                              </span>

                              <span
                                className="float-left"
                                style={{
                                  maxWidth: "200px",
                                  marginLeft: "10px",
                                }}
                              >
                                <select
                                  className="form-control"
                                  value={this.state.filtroNovo?.value}
                                  onChange={(e) =>
                                    this.onChangeFiltroNovo(e.target.value)
                                  }
                                >
                                  {this.state.listaOpcoes.map((it) => (
                                    <option value={it.value}>{it.desc}</option>
                                  ))}
                                </select>
                              </span>

                              {/* <span
                                className="float-left"
                                style={{
                                  maxWidth: "120px",
                                  marginLeft: "10px",
                                }}
                              >
                                <DatePicker
                                  selected={this.state.dataInicial}
                                  customInput={
                                    <input className="form-control form-control-chegoo font-size-14 text-center" />
                                  }
                                  dateFormat="dd/MM/yyyy"
                                  onChange={(date) => {
                                    this.setState({ dataInicial: date });
                                  }}
                                  locale="pt-BR"
                                />
                              </span> */}
                              {/* <span
                                className="float-left text-center"
                                style={{ width: "20px" }}
                              >
                                {" "}
                                -{" "}
                              </span> */}

                              {/* <span
                                className="float-left"
                                style={{
                                  maxWidth: "120px",
                                }}
                              >
                                <DatePicker
                                  selected={this.state.dataFinal}
                                  customInput={
                                    <input className="form-control form-control-chegoo font-size-14 text-center" />
                                  }
                                  dateFormat="dd/MM/yyyy"
                                  onChange={(date) => {
                                    this.setState({ dataFinal: date });
                                  }}
                                  locale="pt-BR"
                                />
                              </span> */}

                              <span
                                style={{
                                  position: "relative",
                                  top: "-4px",
                                }}
                              >
                                <button
                                  className="btn btn-secondary btn-sm"
                                  style={{
                                    padding: "0.275rem 0.55rem",
                                    margin: "7px 5px 0px",
                                  }}
                                  onClick={() =>
                                    this.loadDespesas(this.state._id)
                                  }
                                  id={`btn-filtrar-despesas`}
                                >
                                  <i
                                    className="fa fa-search"
                                    aria-hidden="true"
                                  ></i>

                                  <UncontrolledTooltip
                                    placement="bottom"
                                    target={`btn-filtrar-despesas`}
                                  >
                                    Filtrar
                                  </UncontrolledTooltip>
                                </button>
                              </span>

                              {/* <span
                                style={{
                                  position: "relative",
                                  top: "-4px",
                                }}
                              >
                                <button
                                  className="btn btn-danger btn-sm"
                                  style={{
                                    padding: "0.275rem 0.55rem",
                                    margin: "7px 5px 0px",
                                  }}
                                  onClick={() => this.gerarRelatorio()}
                                  id={`tooltip-limpar-filtro-atendimentos`}
                                >
                                  <i
                                    className="fa fa-file-pdf-o"
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              </span> */}
                            </div>

                            <div className="row">
                              <div className="col-4 font-size-14">
                                Nome
                                <div className="d-flex">
                                  <div
                                    style={{
                                      fontWeight: "bold",
                                      // fontSize: "20px",
                                      fontSize: "18px",
                                    }}
                                  >
                                    {this.state.usuario.desc}
                                  </div>
                                  {/* <div style={{position:"relative", top: "-10px"}}>
                                
                                </div> */}
                                </div>
                              </div>

                              <div className="col-8">
                                <div className="row">
                                  <div
                                    className="col-4 font-size-14"
                                    style={{ borderLeft: "1px solid #CCC" }}
                                  >
                                    <i
                                      className={`fa fa-circle color-deletado`}
                                      style={{}}
                                    ></i>{" "}
                                    Despesas{" "}
                                    <div
                                      style={{
                                        fontSize: "18px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      R${" "}
                                      {formatarReal(
                                        this.state.usuario.totalDespesas
                                      )}
                                    </div>
                                  </div>{" "}
                                  <div
                                    className="col-4 font-size-14"
                                    style={{ borderLeft: "1px solid #CCC" }}
                                  >
                                    <i
                                      className={`fa fa-circle color-aprovado`}
                                      style={{}}
                                    ></i>{" "}
                                    Receitas{" "}
                                    <div
                                      style={{
                                        fontSize: "18px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      R${" "}
                                      {formatarReal(
                                        this.state.usuario.totalAdiantamentos
                                      )}
                                    </div>
                                  </div>{" "}
                                  <div
                                    className="col-4 font-size-14"
                                    style={{ borderLeft: "1px solid #CCC" }}
                                  >
                                    <i
                                      className={`fa fa-circle ${
                                        this.state.usuario.saldoNovo < 0
                                          ? "color-deletado"
                                          : "color-aprovado"
                                      }`}
                                      style={{}}
                                    ></i>{" "}
                                    Saldo{" "}
                                    <div
                                      style={{
                                        fontSize: "18px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      R${" "}
                                      {formatarReal(
                                        this.state.usuario.saldoNovo
                                      )}
                                    </div>
                                  </div>{" "}
                                  {/* <div
                                    className="col-3"
                                    style={{ borderLeft: "1px solid #CCC" }}
                                  >
                                    <i
                                      className={`fa fa-circle color-pago`}
                                      style={{}}
                                    ></i>{" "}
                                    Pagos{" "}
                                    <div
                                      style={{
                                        fontSize: "20px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      R${" "}
                                      {formatarReal(this.state.motorista.pagos)}
                                    </div>
                                  </div>{" "} */}
                                </div>
                              </div>
                            </div>
                          </React.Fragment>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12 stretch-card">
                    {this.state.despesas.length > -1 && (
                      <div className="card">
                        <div className="card-body2">
                          <React.Fragment>
                            {/* <div
                              className="float-left width-100"
                              style={{
                                borderBottom: "1px solid #CCC",
                                padding: "5px",
                                marginBottom: "20px",
                                position: "relative",
                              }}
                            >
                              DETALHAMENTO
                              <div>
                                <button
                                  className="btn btn-success btn-app-verde"
                                  onClick={() => {
                                    this.props.history.push(
                                      `/despesas-nova?motorista=${this.state.idMotorista}`
                                    );
                                  }}
                                  style={{
                                    position: "absolute",
                                    right: 0,
                                    top: "-15px",
                                  }}
                                >
                                  Novo Lançamento
                                </button>
                              </div>
                            </div> */}
                            <div className="table-responsive">
                              <table
                                id="recent-purchases-listing"
                                className="table"
                              >
                                <thead className="thead-dark">
                                  <tr>
                                    <th
                                      style={{
                                        maxWidth: "100px",
                                        width: "100px",
                                      }}
                                    >
                                      Data
                                    </th>
                                    {/* <th>Categoria</th> */}
                                    {/* <th>Situação</th> */}
                                    <th>Lançamento</th>

                                    <th className="text-right">Valor</th>
                                    <th className="text-right">Saldo</th>
                                    {/* <th className="text-right">Valor</th>
                                    <th className="text-center">Comprovante</th> */}
                                    <th className="text-right">Ações</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.despesas.map((despesa, key) => (
                                    <tr key={key}>
                                      <td
                                        style={{
                                          maxWidth: "100px",
                                          width: "100px",
                                        }}
                                      >
                                        <span
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            this.abrirPaginaEditarLancamento(
                                              despesa
                                            )
                                          }
                                        >
                                          {moment(despesa.dtDespesa).format(
                                            "DD/MM/YYYY"
                                          )}
                                        </span>
                                      </td>

                                      {/* <td style={{ fontWeight: "bold" }}>
                                        {despesa.categoria.desc}
                                      </td> */}
                                      {/* <td
                                        style={{ textTransform: "capitalize" }}
                                        className={`color-${despesa.status}`}
                                      >
                                        <i className={`fa fa-circle`}> </i>{" "}
                                        {despesa.status}
                                      </td> */}
                                      <td>
                                        {/* {despesa.comprovante !== "" && (
                                          <span
                                            style={{
                                              cursor: "pointer",
                                              color: "#009ba4",
                                            }}
                                            onClick={() =>
                                              this.handleClickFotoComprovante(
                                                despesa.comprovante
                                              )
                                            }
                                          >
                                            {despesa.desc}
                                            {"  "}
                                          </span>
                                        )} */}
                                        {true && (
                                          <React.Fragment>
                                            <span
                                              onClick={() =>
                                                this.abrirPaginaEditarLancamento(
                                                  despesa
                                                )
                                              }
                                              style={{ cursor: "pointer" }}
                                              className={`${
                                                despesa.situacao ===
                                                  SITUACAO_CANCELADO ||
                                                despesa.situacao ===
                                                  SITUACAO_REPROVADO
                                                  ? "linha-cancelado"
                                                  : ""
                                              }`}
                                            >
                                              {despesa.tipo === "D"
                                                ? despesa.desc
                                                : despesa.tipoDespesa !== null
                                                ? despesa.tipoDespesa.desc
                                                : despesa.desc}
                                            </span>
                                          </React.Fragment>
                                        )}
                                        {despesa.tipo === "D" && (
                                          <span
                                            style={{
                                              fontSize: "13px",
                                              color: "#666666",
                                            }}
                                          >
                                            {" "}
                                            {despesa.tipoDespesa !== null
                                              ? "-"
                                              : ""}{" "}
                                            {despesa.tipoDespesa?.desc}
                                          </span>
                                        )}
                                        {despesa.situacao !==
                                          SITUACAO_REPROVADO &&
                                          despesa.situacao !==
                                            SITUACAO_CANCELADO &&
                                          !despesa.balanco && (
                                            <React.Fragment>
                                              <span>
                                                &nbsp;&nbsp;
                                                <i
                                                  className="fa fa-warning"
                                                  style={{
                                                    color: "#f1c40f",
                                                    fontSize: "20px",
                                                  }}
                                                  id={`item-balanco-${despesa._id}`}
                                                />
                                              </span>
                                              <UncontrolledTooltip
                                                placement="right"
                                                target={`item-balanco-${despesa._id}`}
                                              >
                                                Lançamento não conta no balanço
                                              </UncontrolledTooltip>
                                            </React.Fragment>
                                          )}
                                      </td>
                                      <td
                                        className="text-right"
                                        style={{
                                          // color:
                                          //   despesa.tipo === "despesa"
                                          //     ? "#f2542d"
                                          //     : "#100d99",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        <span
                                          onClick={() =>
                                            this.abrirPaginaEditarLancamento(
                                              despesa
                                            )
                                          }
                                          className={`${
                                            despesa.situacao ===
                                              SITUACAO_CANCELADO ||
                                            despesa.situacao ===
                                              SITUACAO_REPROVADO
                                              ? "linha-cancelado"
                                              : ""
                                          }`}
                                          style={{
                                            cursor: "pointer",
                                            color:
                                              despesa.tipo === "C" ||
                                              despesa.valor === 0
                                                ? "#28a745"
                                                : "#dc3545",
                                          }}
                                        >
                                          R${" "}
                                          {formatarReal(
                                            Math.abs(despesa.valor)
                                          )}
                                        </span>
                                      </td>
                                      <td
                                        className="text-right"
                                        style={{
                                          // color: "#333",
                                          fontWeight: "bold",
                                          color:
                                            despesa.saldoAtual >= 0
                                              ? "#28a745"
                                              : "#dc3545",
                                        }}
                                      >
                                        <span
                                          onClick={() =>
                                            this.abrirPaginaEditarLancamento(
                                              despesa
                                            )
                                          }
                                          style={{ cursor: "pointer" }}
                                        >
                                          R$ {formatarReal(despesa.saldoAtual)}
                                        </span>
                                      </td>

                                      {/* <td className="text-center">
                                        {despesa.exibirComprovante && (
                                          <i
                                            className="fa fa-search"
                                            style={{ cursor: "pointer" }}
                                          ></i>
                                        )}

                                        {!despesa.exibirComprovante && (
                                          <span> - </span>
                                        )}
                                      </td> */}
                                      <td
                                        className="d-flex"
                                        style={{
                                          fontSize: "18px",
                                          justifyContent: "right",
                                        }}
                                      >
                                        {/* <div
                                          style={{
                                            margin: "0 5px",
                                          }}
                                        >
                                          {despesa.status === "pendente" && (
                                            <i
                                              className="fa fa-check"
                                              onClick={() =>
                                                this.handleClickStatus(despesa)
                                              }
                                              style={{
                                                color: "#2da951",
                                                cursor: "pointer",
                                              }}
                                            ></i>
                                          )}

                                          {despesa.status !== "pendente" && (
                                            <i
                                              className="fa fa-check"
                                              // onClick={() => {
                                              //   this.props.history.push(
                                              //     `/d-novo?id=${veiculo._id}`
                                              //   );
                                              // }}
                                              style={{ color: "#959393" }}
                                            ></i>
                                          )}
                                        </div> */}

                                        {despesa.saldoInicial === undefined && (
                                          <div
                                            style={{
                                              margin: "0 5px",
                                            }}
                                          >
                                            <i
                                              className="fa fa-search"
                                              onClick={() => {
                                                this.props.history.push(
                                                  `/despesas-nova?id=${despesa._id}`
                                                );
                                              }}
                                              style={{
                                                color: "#333",
                                                cursor: "pointer",
                                              }}
                                              id={`btn-visualizar-despesa-${despesa._id}`}
                                            ></i>

                                            <UncontrolledTooltip
                                              placement="bottom"
                                              target={`btn-visualizar-despesa-${despesa._id}`}
                                            >
                                              Visualizar
                                            </UncontrolledTooltip>
                                          </div>
                                        )}

                                        {/* <div
                                          style={{
                                            margin: "0 5px",
                                          }}
                                        >
                                          {despesa.situacao === SITUACAO_PENDENTE && (
                                            <i
                                              className="fa fa-trash"
                                              onClick={() => {
                                                this.handleClickDeletar(
                                                  despesa._id
                                                );
                                              }}
                                              style={{
                                                color: "#f2542d",
                                                cursor: "pointer",
                                              }}
                                            ></i>
                                          )}

                                          
                                        </div> */}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>

                            <div style={{ padding: "5px 5px 15px 5px" }}>
                              <button
                                className="btn btn-success btn-app-verde"
                                onClick={() => {
                                  this.props.history.push(
                                    `/despesas-nova?usuario=${this.state._id}`
                                  );
                                }}
                                style={
                                  {
                                    // position: "absolute",
                                    // right: 0,
                                    // top: "-15px",
                                    // padding: "5px"
                                  }
                                }
                              >
                                Novo lançamento
                              </button>
                            </div>
                          </React.Fragment>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default DespesaUsuario;
