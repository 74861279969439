import React from "react";

const TitleAndDescription = (props) => {
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="d-flex justify-content-between flex-wrap">
          <div className="d-flex align-items-end flex-wrap">
            <div className="mr-md-3 mr-xl-5">
              <h5 style={{color:"#009BA4"}} className="m-0">{props.title}</h5>
              <p className="mb-md-0 teste0">{props.description}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TitleAndDescription;
