import React, { Component } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { SideBar, NavBar, Footer } from "../../components";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import Select from "react-select";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  UncontrolledTooltip,
} from "reactstrap";

class NaoConformidade extends Component {
  constructor(props) {
    super(props);
    this.load = this.load.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleClickDeletar = this.handleClickDeletar.bind(this);
    this.handleClickConfirmaDeletar =
      this.handleClickConfirmaDeletar.bind(this);
    this.onChangeInputFiltro = this.onChangeInputFiltro.bind(this);
    this.loadUsuarios = this.loadUsuarios.bind(this);
    this.limparFiltros = this.limparFiltros.bind(this);
    this.gerarRelatorio = this.gerarRelatorio.bind(this);

    this.state = {
      naoConformidades: [],
      naoConformidadeDeletar: null,
      modal: false,

      filtro: {
        usuario: null,
        situacao: "99", //99todos 0=aberta e 1 = finalizada
        obra: "",
        fornecedor: "",
      },
      usuarios: [],
    };
  }

  async componentDidMount() {
    const userLogado = JSON.parse(localStorage.getItem("usuario"));
    if (userLogado.nivel === "3") {
      return this.props.history.push("/pedidos-material");
    }
    if (userLogado.nivel === "5") {
      return this.props.history.push("/despesas");
    }
    await this.load();
    await this.loadUsuarios();
  }

  async load() {
    let filtro = this.state.filtro;

    let data = {};

    if (filtro.usuario !== null) {
      data.usuario = filtro.usuario._id;
    }

    if (filtro.obra !== null) {
      data.obra = filtro.obra._id;
    }

    if (filtro.situacao !== "99") {
      data.situacao = filtro.situacao;
    }

    await axios
      .post(
        `${this.props.getBaseUrlApi()}/retaguarda/lista-nao-conformidades2`,
        data,
        this.props.parameters()
      )
      .then((response) => {
        let naoConformidades = response.data.naoConformidades;

        for (let item of naoConformidades) {
          item.situacaoDesc = item.situacao === "1" ? "Pendente" : "Finalizada";
        }

        //console.clear();
      //  console.log("fff", naoConformidades);

        this.setState({ naoConformidades });
      });
  }

  notificar(type, message) {
    toast[type](message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  handleClickDeletar(id) {
    this.setState({ naoConformidadeDeletar: id, modal: true });
  }

  toggle() {
    this.setState({ modal: !this.state.modal });
  }

  async handleClickConfirmaDeletar() {
    await axios
      .delete(
        `${this.props.getBaseUrlApi()}/retaguarda/nao-conformidades/${
          this.state.naoConformidadeDeletar
        }`,
        this.props.parameters()
      )
      .then((response) => {
        this.setState({ naoConformidadeDeletar: null, modal: false }, () => {
          this.load();
          this.notificar("success", "Não Conformidade deletada com sucesso!");
        });
      })
      .catch((error) => {
        this.setState({ naoConformidadeDeletar: null, modal: false }, () => {
          this.notificar("warning", error.response.data.erro);
        });
      });
  }

  onChangeInputFiltro(type, value) {
    let filtro = this.state.filtro;
    filtro[type] = value;
    this.setState({ filtro });
  }

  async loadUsuarios() {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/retaguarda/usuarios`,
        this.props.parameters()
      )
      .then((response) => {
        let usuarios = response.data.usuarios;

        for (let item of usuarios) {
          item.label = item.desc;
          item.value = item._id;
        }

        this.setState({ usuarios });
      });
  }

  limparFiltros() {
    this.setState(
      {
        filtro: {
          usuario: null,
          obra: "",
          fornecedor: "",
          situacao: "99",
        },
      },
      () => {
        this.load();
      }
    );
  }

  async gerarRelatorio(naoConformidadeSelecionada) {
    let itemFormatado = null;
    let item = naoConformidadeSelecionada;
    // let lista = this.state.naoConformidades;

    if (true) {
      let codigo = item.codigo;
      let usuario = item.usuario.desc;
      let fornecedorOrdemCompra = item.fornecedorOrdemCompra;
      let contato = item.contato;
      let avaliacaoRiscoFinanceiro = item.avaliacaoRisco.financeiro;
      let avaliacaoRiscoImagem = item.avaliacaoRisco.imagem;
      let avaliacaoRiscoSeguranca = item.avaliacaoRisco.seguranca;
      let avaliacaoRiscoPrazo = item.avaliacaoRisco.prazo;
      let descricao = item.descricao.desc;
      let analiseCausaRaizProcesso = item.analiseCausaRaiz[0].analise;
      let analiseCausaRaizMaterial = item.analiseCausaRaiz[1].analise;
      let analiseCausaRaizMaoObra = item.analiseCausaRaiz[2].analise;
      let analiseCausaRaizMaquina = item.analiseCausaRaiz[3].analise;
      let analiseCausaRaizAmbiente = item.analiseCausaRaiz[4].analise;
      let acaoCorretiva = item.acaoCorretiva.desc;
      let responsavelAcaoCorretiva = item.acaoCorretiva.responsavel;
      let acaoPreventiva = item.acaoPreventiva.desc;
      let responsavelAcaoPreventiva = item.acaoPreventiva.responsavel;
      let naoConformidadeReincidencia = "";
      let naoConformidadeVinculacao = "";
      let dtCadastro = "";
      let obra = item.obra;
      let responsavelErro = "";

      if (item.naoConformidadeReincidencia !== null) {
        naoConformidadeReincidencia = item.naoConformidadeReincidencia.codigo;
      }
      if (item.naoConformidadeVinculacao !== null) {
        naoConformidadeVinculacao = item.naoConformidadeVinculacao.codigo;
      }
      if (item.dtCadastro !== null) {
        dtCadastro = moment(item.dtCadastro).format("DD/MM/YYYY");
      }

      let dtEncerramento = "";

      if (item.dtEncerramento !== null) {
        dtEncerramento = moment(item.dtEncerramento).format("DD/MM/YYYY HH:mm");
      }

      let responsavelEncerramento = item.responsavelEncerramento;

      if (item.responsavelErro !== null) {
        for (let i = 0; i < item.responsavelErro.length; i++) {
          responsavelErro = `${responsavelErro}${item.responsavelErro[i]}`;
          if (i !== item.responsavelErro.length - 1) {
            responsavelErro = `${responsavelErro}, `;
          }
        }
      }

      itemFormatado = {
        dtCadastro,
        codigo,
        usuario,
        obra,
        fornecedorOrdemCompra,
        contato,
        responsavelErro,
        avaliacaoRiscoFinanceiro,
        avaliacaoRiscoImagem,
        avaliacaoRiscoSeguranca,
        avaliacaoRiscoPrazo,
        descricao,
        analiseCausaRaizProcesso,
        analiseCausaRaizMaterial,
        analiseCausaRaizMaoObra,
        analiseCausaRaizMaquina,
        analiseCausaRaizAmbiente,
        acaoCorretiva,
        responsavelAcaoCorretiva,
        acaoPreventiva,
        responsavelAcaoPreventiva,
        naoConformidadeReincidencia,
        naoConformidadeVinculacao,
        dtEncerramento,
        responsavelEncerramento,
      };
    }

    let parametros = {
      itemFormatado,
    };

    let nomeArquivo = `Registro de Não Conformidade N° ${itemFormatado.codigo} `;

    await axios
      .post(`${this.props.getBaseUrlImpressao()}/nao-conformidades-pdf`, {
        parametros,
      })
      .then(async (response) => {
        // FileDownload(response.data, nomeArquivo);
        localStorage.setItem("html", response.data.html);
        localStorage.setItem("tituloPagina", nomeArquivo);
        // this.toggle(MODAL_IMPRESSAO);
        window.open("/impressao", "_blank");

        // return false;
        // window.open(
        //   `${this.props.getBaseUrlImpressao()}/nao-conformidades-get?id=${response.data.codigo}`,
        //   "_blank"
        // );
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          // this.notificar("warning", error.response.data.erro);
        }
      });

    // console.log(listaFormatada)

    // let dataInicial = moment(this.state.filtro.dtInicial).format("DD/MM/YYYY");
    // let dataFinal = moment(this.state.filtro.dtFinal).format("DD/MM/YYYY");
    // let usuario = this.state.filtro.usuario;
    // let obra = this.state.filtro.obra;
    // let situacao = this.state.filtro.situacao;

    // if (usuario === null) {
    //   usuario = "Todos";
    // } else {
    //   usuario = usuario.desc;
    // }
    // if (obra === null) {
    //   obra = "Todas";
    // } else {
    //   obra = obra.desc;
    // }
    // if (situacao === "99") {
    //   situacao = "Todas";
    // } else {
    //   if (situacao === "0") {
    //     situacao = "Pedente";
    //   } else if (situacao === "2") {
    //     situacao = "Aprovado";
    //   } else if (situacao === "3") {
    //     situacao = "Revisão";
    //   } else if (situacao === "4") {
    //     situacao = "Reprovado";
    //   } else if (situacao === "5") {
    //     situacao = "Cancelado";
    //   }
    // }
  }

  render() {
    return (
      <React.Fragment>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />

        <div className="container-scroller font-app">
          <NavBar title="Não Conformidades" />
          <div className="container-fluid page-body-wrapper">
            <SideBar itemActive={"naoConformidades"} />
            <div className="main-panel">
              <div className="content-wrapper">
                {/* <TitleAndDescription title="Usuários" description="" /> */}

                <div
                  className="row"
                  style={{ marginBottom: "0.8rem", alignItems: "center" }}
                >
                  <div className="col-md-12 stretch-card">
                    {true && (
                      <div className="card">
                        <div
                          className="card-body5"
                          style={{ position: "relative" }}
                        >
                          <React.Fragment>
                            <div className="row">
                              <div className="col-md-2 pd-veiculo2">
                                <div className="form-group form-group-chegoo2">
                                  <label>Emitente</label>
                                  <Select
                                    value={this.state.filtro.usuario}
                                    onChange={(item) =>
                                      this.onChangeInputFiltro("usuario", item)
                                    }
                                    options={this.state.usuarios}
                                    placeholder="Selecione"
                                    styles={{
                                      control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        fontSize: "14px",
                                        height: "35px",
                                        minHeight: "35px",
                                        backgroundColor: "#fffef6",
                                      }),
                                      menu: (baseStyles, state) => ({
                                        ...baseStyles,
                                        fontSize: "14px",
                                      }),
                                    }}
                                  />
                                </div>
                              </div>

                              <div className="col-md-4 pd-veiculo">
                                <div className="form-group form-group-chegoo2">
                                  <label>Cliente/Obra</label>
                                  <input
                                    type="text"
                                    className="form-control form-control-chegoo font-size-14"
                                    value={this.state.filtro.obra}
                                    onChange={(e) =>
                                      this.onChangeInputFiltro(
                                        "obra",
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>
                              </div>

                              <div className="col-md-2 pd-veiculo">
                                <div className="form-group form-group-chegoo2">
                                  <label>Situação</label>
                                  <select
                                    className="form-control form-control-chegoo font-size-14"
                                    value={this.state.filtro.situacao}
                                    onChange={(e) =>
                                      this.onChangeInputFiltro(
                                        "situacao",
                                        e.target.value
                                      )
                                    }
                                  >
                                    <option value="99">Todos</option>
                                    <option value="1">Pendente</option>
                                    <option value="2">Finalizada</option>
                                  </select>
                                </div>
                              </div>
                              <div
                                className="col-md-2 pd-veiculo"
                                style={{ display: "flex", marginTop: "15px" }}
                              >
                                <div
                                  className="form-group-chegoo2"
                                  style={{ float: "left" }}
                                >
                                  <label></label>
                                  <button
                                    className="btn btn-secondary btn-sm"
                                    style={{
                                      padding: "0.275rem 0.55rem",
                                      margin: "10px 5px 0px",
                                    }}
                                    onClick={() => this.load()}
                                    id={`tooltip-filtro-atendimentos`}
                                  >
                                    <i
                                      className="fa fa-search"
                                      aria-hidden="true"
                                      style={{ fontSize: "px" }}
                                    ></i>
                                  </button>
                                  <UncontrolledTooltip
                                    placement="bottom"
                                    target={`tooltip-filtro-atendimentos`}
                                  >
                                    Filtrar
                                  </UncontrolledTooltip>
                                </div>
                                <div className="form-group-chegoo2">
                                  <label></label>
                                  <button
                                    className="btn btn-warning btn-sm"
                                    style={{
                                      padding: "0.275rem 0.55rem",
                                      margin: "10px 5px 0px",
                                      color: "#FFF",
                                    }}
                                    onClick={() => this.limparFiltros()}
                                    id={`tooltip-limpar-filtro-atendimentos`}
                                  >
                                    <i
                                      className="fa fa-eraser"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                  <UncontrolledTooltip
                                    placement="bottom"
                                    target={`tooltip-limpar-filtro-atendimentos`}
                                  >
                                    Limpar filtro
                                  </UncontrolledTooltip>
                                </div>
                              </div>
                              <div
                                className="col-md-2 pd-veiculo3"
                                style={{
                                  marginTop: "15px",
                                  marginTop: "15px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "end",
                                }}
                              >
                                <button
                                  className="btn btn-success btn-app-verde-sm"
                                  onClick={() => {
                                    this.props.history.push(
                                      "/nao-conformidades-nova"
                                    );
                                  }}
                                >
                                  CADASTRAR
                                </button>
                              </div>
                            </div>
                          </React.Fragment>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12 stretch-card">
                    {this.state.naoConformidades.length > -1 && (
                      <div className="card">
                        <div className="card-body2">
                          <React.Fragment>
                            <div className="table-responsive">
                              <table
                                id="recent-purchases-listing"
                                className="table"
                              >
                                <thead className="thead-dark">
                                  <tr>
                                    <th className="text-right">Código</th>
                                    <th>Obra</th>
                                    <th>Emitente</th>
                                    <th>Situação</th>
                                    <th className="text-right">
                                      Data Cadastro
                                    </th>
                                    <th className="text-right">
                                      Data Finalização
                                    </th>
                                    <th>Ações</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.naoConformidades.map(
                                    (item, key) => (
                                      <tr key={key}>
                                        <td className="text-right">
                                          {item.codigo}
                                        </td>
                                        <td>{item.obra}</td>
                                        <td>{item.usuario.desc}</td>
                                        <td> {item.situacaoDesc}</td>
                                        <td className="text-right">
                                          {" "}
                                          {moment(item.dtCadastro).format(
                                            "DD/MM/YYYY"
                                          )}
                                        </td>
                                        <td className="text-right">
                                          {" "}
                                          {item.dtEncerramento !== null &&
                                          item.situacao === "2"
                                            ? moment(
                                                item.dtEncerramento
                                              ).format("DD/MM/YYYY")
                                            : ""}
                                        </td>

                                        <td
                                          className="d-flex"
                                          style={{ fontSize: "18px" }}
                                        >
                                          <div
                                            style={{
                                              margin: "2px",
                                              cursor: "pointer",
                                            }}
                                          >
                                            <i
                                              className="fa fa-edit"
                                              onClick={() => {
                                                this.props.history.push(
                                                  `/nao-conformidades-nova?id=${item._id}`
                                                );
                                              }}
                                              id={`btn-editar-${item._id}`}
                                            ></i>

                                            <UncontrolledTooltip
                                              placement="bottom"
                                              target={`btn-editar-${item._id}`}
                                            >
                                              Editar
                                            </UncontrolledTooltip>
                                          </div>
                                          <div
                                            style={{
                                              margin: "2px",
                                              cursor: "pointer",
                                            }}
                                          >
                                            <i
                                              className="fa fa-trash"
                                              style={{ color: "red" }}
                                              onClick={() => {
                                                this.handleClickDeletar(
                                                  item._id
                                                );
                                              }}
                                              id={`btn-excluir-${item._id}`}
                                            ></i>

                                            <UncontrolledTooltip
                                              placement="bottom"
                                              target={`btn-excluir-${item._id}`}
                                            >
                                              Excluir
                                            </UncontrolledTooltip>
                                          </div>
                                          <div
                                            style={{
                                              margin: "2px",
                                              cursor: "pointer",
                                            }}
                                          >
                                            <i
                                              className="fa fa-file-pdf-o"
                                              style={{ color: "blue" }}
                                              onClick={() => {
                                                this.gerarRelatorio(item);
                                              }}
                                              id={`btn-excluir22-${item._id}`}
                                            ></i>

                                            <UncontrolledTooltip
                                              placement="bottom"
                                              target={`btn-excluir22-${item._id}`}
                                            >
                                              Imprimir Relatório
                                            </UncontrolledTooltip>
                                          </div>
                                        </td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </React.Fragment>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>

        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>
            Deletar Não Conformidade?
          </ModalHeader>
          <ModalBody>
            Tem certeza que deseja deletar esta Não Conformidade?
          </ModalBody>
          <ModalFooter>
            <Button
              className="btn-modal-recuperar"
              color="secondary"
              onClick={this.toggle}
            >
              Não
            </Button>
            <Button
              className="btn-modal-recuperar"
              color="primary"
              onClick={this.handleClickConfirmaDeletar}
            >
              Sim
            </Button>{" "}
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default NaoConformidade;
