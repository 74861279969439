import React, { Component } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { SideBar, NavBar, Footer, TitleAndDescription } from "../../components";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import Select from "react-select";
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from "reactstrap";
import { UncontrolledTooltip } from "reactstrap";
import DatePicker from "react-datepicker";
import FileDownload from "js-file-download";
const { formatarReal } = require("../../utils");
const { cpfMask, cnpjMask } = require("../../mask");

class Fornecedor extends Component {
  constructor(props) {
    super(props);
    this.load = this.load.bind(this);
    this.loadFornecedores = this.loadFornecedores.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleClickDeletar = this.handleClickDeletar.bind(this);
    this.handleClickConfirmaDeletar =
      this.handleClickConfirmaDeletar.bind(this);
    this.onChangeInputFiltro = this.onChangeInputFiltro.bind(this);
    this.gerarRelatorio = this.gerarRelatorio.bind(this);

    this.state = {
      fornecedores: [],
      fornecedorDeletar: null,
      modal: false,
      filtro: {
        cpfCnpj: "",
        razaoSocial: "",
        status: "ativo"
      },
    };
  }

  async componentDidMount() {
    await this.load();
  }

  async load() {
    await this.loadFornecedores();
  }

  async loadFornecedores() {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/retaguarda/fornecedores`,
        this.props.parameters()
      )
      .then((response) => {
        ////console.clear();
        console.log(JSON.stringify(response.data.fornecedores));

        this.setState({ fornecedores: response.data.fornecedores });
      });
  }

  notificar(type, message) {
    toast[type](message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  handleClickDeletar(id) {
    this.setState({ fornecedorDeletar: id, modal: true });
  }

  toggle() {
    this.setState({ modal: !this.state.modal });
  }

  async handleClickConfirmaDeletar() {
    await axios
      .delete(
        `${this.props.getBaseUrlApi()}/retaguarda/fornecedores/${
          this.state.fornecedorDeletar
        }`,
        this.props.parameters()
      )
      .then((response) => {
        this.setState({ fornecedorDeletar: null, modal: false }, () => {
          this.loadFornecedores();
          this.notificar("success", "Fornecedor deletado com sucesso!");
        });
      })
      .catch((error) => {
        this.setState({ fornecedorDeletar: null, modal: false }, () => {
          this.notificar("warning", error.response.data.erro);
        });
      });
  }

  onChangeInputFiltro(tipo, value) {
    let filtro = this.state.filtro;
    if (tipo === "cpfCnpj") {
      if (value.length > 14) {
        value = cnpjMask(value);
      } else {
        value = cpfMask(value);
      }
    }
    filtro[tipo] = value;

    this.setState({ filtro }, () => {});
  }

  async gerarRelatorio() {
    let listaFormatada = [];
    let lista = this.state.fornecedores;

    for (let item of lista) {
      let categorias = "";

      for (let i = 0; i < item.categorias.length; i++) {
        categorias = `${categorias} ${item.categorias[i].label}`;

        if (i < item.categorias.length - 1) {
          categorias = categorias + ",";
        }
      }

      listaFormatada.push({
        id: item._id,
        cpfCnpj: item.cpfCnpj,
        razaoSocial: item.razaoSocial,
        fantasia: item.fantasia,
        celular: item.celular,
        telefone: item.telefone,
        endereco: `${item.endereco.rua}, N° ${item.endereco.num}, ${item.endereco.bairro}, ${item.endereco.cidade} - ${item.endereco.uf}`,
        cep: item.endereco.cep,
        categorias: categorias,
        status: item.status
      });
    }

    let cpfCnpj = this.state.filtro.cpfCnpj;
    let razaoSocial = this.state.filtro.razaoSocial;
    let status = this.state.filtro.status;

    if(status === 'ativo'){
      status = 'Ativo';
    }else{
      status = "Inativo"
    }

    let parametros = {
      fornecedores: listaFormatada,
      cpfCnpj,
      razaoSocial,
      status
    };

    let nomeArquivo = `relatorio-fornecedores`;

    await axios
      .post(`${this.props.getBaseUrlImpressao()}/fornecedores-pdf`, { parametros })
      .then(async (response) => {
        // FileDownload(response.data, nomeArquivo);
        localStorage.setItem('html', response.data.html)
        localStorage.setItem('tituloPagina', nomeArquivo)
      window.open("/impressao", "_blank");
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          // this.notificar("warning", error.response.data.erro);
        }
      });
  }

  async gerarRelatorio2() {
    let listaFormatada = [];
    let lista = this.state.fornecedores;

    for (let item of lista) {
      let categorias = "";

      for (let i = 0; i < item.categorias.length; i++) {
        categorias = `${categorias} ${item.categorias[i].label}`;

        if (i < item.categorias.length - 1) {
          categorias = categorias + ",";
        }
      }
      listaFormatada.push({
        ID: item._id,
        CPF_CNPJ: item.cpfCnpj,
        RAZAO_SOCIAL: item.razaoSocial,
        FANTASIA: item.fantasia,
        CELULAR: item.celular,
        TELEFONE: item.telefone,
        ENDERECO: item.endereco.rua,
        NUMERO: item.endereco.num,
        BAIRRO: item.endereco.bairro,
        CIDADE: item.endereco.cidade,
        UF: item.endereco.uf,
        CEP: item.endereco.cep,
        CATEGORIAS: categorias,
        STATUS: item.status
      });
    }

    // let dataInicial = this.state.dataInicial;
    // let dataFinal = this.state.dataFinal;

    let nomeArquivo = `relatorio-fornecedores.xlsx`;
    // let nomeArquivo = `fornecedores-${moment(dataInicial)
    //   .startOf("day")
    //   .format("YYYY-MM-DD")}-${moment(dataFinal)
    //   .startOf("day")
    //   .format("YYYY-MM-DD")}.xlsx`;

    let params = {};
    params.responseType = "blob";

    await axios
      .post(
        `${this.props.getBaseUrlApi()}/retaguarda/relatorios`,
        { lista: listaFormatada },
        params
      )
      .then(async (response) => {
        FileDownload(response.data, nomeArquivo);
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          // this.notificar("warning", error.response.data.erro);
        }
      });
  }

  render() {
    return (
      <React.Fragment>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />

        <div className="container-scroller font-app">
          <NavBar title="Fornecedores" />
          <div className="container-fluid page-body-wrapper">
            <SideBar itemActive={"fornecedores"} />
            <div className="main-panel">
              <div className="content-wrapper">
                {/* <TitleAndDescription title="Usuários" description="" /> */}

                <div
                  className="row"
                  style={{ marginBottom: "0.8rem", alignItems: "center" }}
                >
                  <div className="col-md-12 stretch-card">
                    {this.state.fornecedores.length > -1 && (
                      <div className="card">
                        <div
                          className="card-body5"
                          style={{ position: "relative" }}
                        >
                          <React.Fragment>
                            {/* <div
                              style={{
                                position: "absolute",
                                top: "11px",
                                right: "11px",
                              }}
                            >
                              <button
                                className="btn btn-success btn-app-verde-sm"
                                onClick={() => {
                                  this.props.history.push("/fornecedor-novo");
                                }}
                              >
                               CADASTRAR
                              </button>
                            </div> */}
                            <div className="row">
                              <div className="col-md-2 pd-veiculo2">
                                <div className="form-group form-group-chegoo2">
                                  <label>CPF/CNPJ</label>
                                  <input
                                    type="text"
                                    className="form-control form-control-chegoo font-size-14 text-center"
                                    onChange={(e) =>
                                      this.onChangeInputFiltro(
                                        "cpfCnpj",
                                        e.target.value
                                      )
                                    }
                                    value={this.state.filtro.cpfCnpj}
                                  />
                                </div>
                              </div>
                              <div className="col-md-3 pd-veiculo">
                                <div className="form-group form-group-chegoo2">
                                  <label>Razão Social</label>
                                  <input
                                    type="text"
                                    className="form-control form-control-chegoo font-size-14"
                                    value={this.state.filtro.razaoSocial}
                                     onChange={(e) =>
                                      this.onChangeInputFiltro(
                                        "razaoSocial",
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>
                              </div>
                              <div className="col-md-2 pd-veiculo">
                                <div className="form-group form-group-chegoo2">
                                  <label>Status</label>
                                  <select 
                                    className="form-control form-control-chegoo font-size-14"
                                    value={this.state.filtro.status}
                                    onChange={(e) =>
                                      this.onChangeInputFiltro(
                                        "status",
                                        e.target.value
                                      )
                                    }
                                  >
                                    <option value="ativo">Ativo</option>
                                    <option value="inativo">Inativo</option>
                                  </select>
                                </div>
                              </div>
                              <div
                                className="col-md-3 pd-veiculo"
                                style={{ display: "flex", marginTop: "15px" }}
                              >
                                <div
                                  className="form-group-chegoo2"
                                  style={{ float: "left" }}
                                >
                                  <label></label>
                                  <button
                                    className="btn btn-secondary btn-sm"
                                    style={{
                                      padding: "0.275rem 0.55rem",
                                      margin: "10px 5px 0px",
                                    }}
                                    onClick={() => this.load()}
                                    id={`tooltip-filtro-atendimentos`}
                                  >
                                    <i
                                      className="fa fa-search"
                                      aria-hidden="true"
                                      style={{ fontSize: "px" }}
                                    ></i>
                                  </button>
                                  <UncontrolledTooltip
                                    placement="bottom"
                                    target={`tooltip-filtro-atendimentos`}
                                  >
                                    Filtrar
                                  </UncontrolledTooltip>
                                </div>
                                <div className="form-group-chegoo2">
                                  <label></label>
                                  <button
                                    className="btn btn-warning btn-sm"
                                    style={{
                                      padding: "0.275rem 0.55rem",
                                      margin: "10px 5px 0px",
                                      color: "#FFF"
                                    }}
                                    onClick={() => this.limparFiltros()}
                                    id={`tooltip-limpar-filtro-atendimentos`}
                                  >
                                    <i
                                      className="fa fa-eraser"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                  <UncontrolledTooltip
                                    placement="bottom"
                                    target={`tooltip-limpar-filtro-atendimentos`}
                                  >
                                    Limpar filtro
                                  </UncontrolledTooltip>
                                </div>
                                <div className="form-group-chegoo2">
                                  <label></label>
                                  <button
                                    className="btn btn-danger btn-sm"
                                    style={{
                                      padding: "0.275rem 0.55rem",
                                      margin: "10px 5px 0px",
                                    }}
                                    onClick={() => this.gerarRelatorio()}
                                    id={`tooltip-limpar-filtro-atendimentos`}
                                  >
                                    <i
                                      className="fa fa-file-pdf-o"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                  <UncontrolledTooltip
                                    placement="bottom"
                                    target={`tooltip-limpar-filtro-atendimentos`}
                                  >
                                    Limpar filtro
                                  </UncontrolledTooltip>
                                </div>
                              </div>
                              <div
                                className="col-md-2"
                                style={{
                                  marginTop: "15px",
                                  marginTop: "15px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "end",
                                }}
                              >
                                <button
                                  className="btn btn-success btn-app-verde-sm"
                                  onClick={() => {
                                    this.props.history.push("/fornecedor-novo");
                                  }}
                                >
                                  CADASTRAR
                                </button>
                              </div>
                            </div>
                          </React.Fragment>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12 stretch-card">
                    {this.state.fornecedores.length > -1 && (
                      <div className="card">
                        <div className="card-body2">
                          <React.Fragment>
                            {/* <div
                              className="float-left width-100"
                              style={{
                                borderBottom: "1px solid #CCC",
                                padding: "5px",
                                marginBottom: "20px",
                                position: "relative",
                              }}
                            >
                              FORNECEDORES CADASTRADOS
                              <div>
                                <button
                                  className="btn btn-success btn-app-verde"
                                  onClick={() => {
                                    this.props.history.push("/fornecedor-novo");
                                  }}
                                  style={{
                                    position: "absolute",
                                    right: 0,
                                    top: "-15px",
                                  }}
                                >
                                  Novo fornecedor
                                </button>
                              </div>
                            </div> */}
                            <div className="table-responsive">
                              <table
                                id="recent-purchases-listing"
                                className="table"
                              >
                                <thead className="thead-dark">
                                  <tr>
                                    <th>CPF/CNPJ</th>
                                    <th>Razão Social</th>
                                    <th>Fantasia</th>
                                    <th>Ações</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.fornecedores.map(
                                    (usuario, key) => (
                                      <tr key={key}>
                                        <td>{usuario.cpfCnpj}</td>
                                        <td> {usuario.razaoSocial}</td>
                                        <td> {usuario.fantasia}</td>
                                        <td
                                          className="d-flex"
                                          style={{ fontSize: "18px" }}
                                        >
                                          <div
                                            style={{
                                              margin: "2px",
                                              cursor: "pointer",
                                            }}
                                          >
                                            <i
                                              className="fa fa-edit"
                                              onClick={() => {
                                                this.props.history.push(
                                                  `/fornecedor-novo?id=${usuario._id}`
                                                );
                                              }}
                                            ></i>
                                          </div>
                                          <div
                                            style={{
                                              margin: "2px",
                                              cursor: "pointer",
                                            }}
                                          >
                                            <i
                                              className="fa fa-trash"
                                              style={{ color: "red" }}
                                              onClick={() => {
                                                this.handleClickDeletar(
                                                  usuario._id
                                                );
                                              }}
                                            ></i>
                                          </div>
                                        </td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </table>
                            </div>

                            {/* <div style={{ padding: "5px 5px 15px 5px" }}>
                              <button
                                className="btn btn-success btn-app-verde"
                                onClick={() => {
                                  this.props.history.push("/fornecedor-novo");
                                }}
                                style={
                                  {
                                    // position: "absolute",
                                    // right: 0,
                                    // top: "-15px",
                                    // padding: "5px"
                                  }
                                }
                              >
                                Novo fornecedor
                              </button>
                            </div> */}
                            {/* 
                            <div className="paginacao">
                              <Pagination
                                prevPageText={
                                  <i className="fa fa-angle-left" />
                                }
                                nextPageText={
                                  <i className="fa fa-angle-right" />
                                }
                                firstPageText={
                                  <i className="fa fa-angle-double-left" />
                                }
                                lastPageText={
                                  <i className="fa fa-angle-double-right" />
                                }
                                activePage={this.state.paginacao.pagina}
                                itemsCountPerPage={this.state.paginacao.limite}
                                totalItemsCount={this.state.totalcomissoes}
                                onChange={(pagina) => this.paginar(pagina)}
                                itemClass="page-item"
                                linkClass="page-link"
                              />
                            </div> */}
                          </React.Fragment>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>

        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Deletar Fornecedor</ModalHeader>
          <ModalBody>Tem certeza que deseja deletar este fornecedor?</ModalBody>
          <ModalFooter>
            <Button
              className="btn-modal-recuperar"
              color="secondary"
              onClick={this.toggle}
            >
              Não
            </Button>
            <Button
              className="btn-modal-recuperar"
              color="primary"
              onClick={this.handleClickConfirmaDeletar}
            >
              Sim
            </Button>{" "}
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default Fornecedor;
