import React, { Component } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { SideBar, NavBar, Footer, TitleAndDescription } from "../../components";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import Select from "react-select";
import {
  UncontrolledTooltip,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
} from "reactstrap";
import { CurrencyInput } from "../../components";
import DatePicker from "react-datepicker";
import FileDownload from "js-file-download";
const { formatarReal, getBase64 } = require("../../utils");

class OrdemAbastecimento extends Component {
  constructor(props) {
    super(props);
    this.hiddenFileInput = React.createRef();
    this.load = this.load.bind(this);
    this.loadUsuarios = this.loadUsuarios.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleClickDeletar = this.handleClickDeletar.bind(this);
    this.aprovarOrdem = this.aprovarOrdem.bind(this);
    this.recusarOrdem = this.recusarOrdem.bind(this);
    this.handleClickConfirmaDeletar =
      this.handleClickConfirmaDeletar.bind(this);
    this.toggleModalObs = this.toggleModalObs.bind(this);
    this.toggleModalValores = this.toggleModalValores.bind(this);
    this.salvarValoresOrdem = this.salvarValoresOrdem.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.loadVeiculos = this.loadVeiculos.bind(this);
    this.limparFiltros = this.limparFiltros.bind(this);
    this.loadMotoristas = this.loadMotoristas.bind(this);
    this.gerarRelatorio = this.gerarRelatorio.bind(this);

    this.state = {
      ordens: [],
      usuarioDeletar: null,
      modal: false,
      modalObs: false,
      obsOrdemAbastecimento: "",

      litros: "0",
      valor: "0,00",
      modalValores: false,
      idOrdemAbastecimentoEditar: null,
      comprovanteSelecionado: null,

      veiculos: [],
      veiculoSelecionado: null,
      motoristas: [],
      motoristaSelecionado: null,
      statusSelecionado: "todos",
      dataInicial: moment().startOf("month").toDate(),
      dataFinal: moment().endOf("month").toDate(),
    };
  }

  async componentDidMount() {
    await this.load();
    await this.loadVeiculos();
    await this.loadMotoristas();
  }

  async load() {
    await this.loadUsuarios();
  }
  dataFinal;

  async loadUsuarios() {
    let veiculo = this.state.veiculoSelecionado;
    let motorista = this.state.motoristaSelecionado;
    let status = this.state.statusSelecionado;

    // // ////console.clear();
    // console.log(veiculo, motorista, status);

    if (veiculo !== null) {
      veiculo = veiculo.desc;
    }

    let dataInicial = this.state.dataInicial;
    let dataFinal = this.state.dataFinal;

    await axios
      .post(
        `${this.props.getBaseUrlApi()}/retaguarda/ordens-abastecimento`,
        { veiculo, motorista, status, dataInicial, dataFinal },
        this.props.parameters()
      )
      .then((response) => {
        let ordensAbastecimento = response.data.ordensAbastecimento;

        for (let ordem of ordensAbastecimento) {
          if (ordem.status === "naoRealizada") {
            ordem.novoStatus = "Recusada";
          } else if (ordem.status === "realizada") {
            ordem.novoStatus = "Aprovada";
          } else {
            ordem.novoStatus = "Pendente";
          }

          if (ordem.comprovante === undefined) {
            ordem.comprovante = null;
          }
        }

        this.setState({ ordens: ordensAbastecimento });
      });
  }

  notificar(type, message) {
    toast[type](message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  handleClickDeletar(id) {
    this.setState({ usuarioDeletar: id, modal: true });
  }

  toggle() {
    this.setState({ modal: !this.state.modal });
  }

  toggleModalObs() {
    this.setState({ modalObs: !this.state.modalObs });
  }

  toggleModalValores() {
    this.setState({ modalValores: !this.state.modalValores });
  }

  async handleClickConfirmaDeletar() {
    await axios
      .delete(
        `${this.props.getBaseUrlApi()}/retaguarda/usuarios/${
          this.state.usuarioDeletar
        }`,
        this.props.parameters()
      )
      .then((response) => {
        this.setState({ usuarioDeletar: null, modal: false }, () => {
          this.loadUsuarios();
          this.notificar("success", "Usuário deletado com sucesso!");
        });
      })
      .catch((error) => {
        this.setState({ usuarioDeletar: null, modal: false }, () => {
          this.notificar("warning", error.response.data.erro);
        });
      });
  }

  async aprovarOrdem(id) {
    await axios
      .post(
        `${this.props.getBaseUrlApi()}/retaguarda/ordens-abastecimento-aprovar`,
        { id },
        this.props.parameters()
      )
      .then((response) => {
        this.notificar("success", "Ordem aprovada com sucesso!");
        this.load();
      });
  }
  async recusarOrdem(id) {
    await axios
      .post(
        `${this.props.getBaseUrlApi()}/retaguarda/ordens-abastecimento-recusar`,
        { id },
        this.props.parameters()
      )
      .then((response) => {
        this.notificar("success", "Ordem recusada com sucesso!");
        this.load();
      });
  }

  async salvarValoresOrdem() {
    let litros = this.state.litros;
    let valor = this.state.valor;
    let comprovante = null;
    let ordens = this.state.ordens;

    for (let ordem of ordens) {
      if (ordem._id === this.state.idOrdemAbastecimentoEditar) {
        comprovante = ordem.comprovante;
      }
    }

    valor = valor.replace("R$", "");
    valor = valor.replace(",", ".");
    valor = parseFloat(valor);

    if (valor === 0) {
      this.notificar("warning", "Favor informar um valor");
      return false;
    }

    if (litros === null || litros === 0 || litros === "0" || litros === "") {
      this.notificar("warning", "Favor informar um valor de litros");
      return false;
    }

    litros = litros.replace(",", ".");
    litros = parseFloat(litros);

    await axios
      .post(
        `${this.props.getBaseUrlApi()}/retaguarda/ordens-abastecimento/${
          this.state.idOrdemAbastecimentoEditar
        }`,
        { litros, valor, comprovante },
        this.props.parameters()
      )
      .then((response) => {
        this.notificar("success", "Ordem editada com sucesso!");
        this.load();
        this.toggleModalValores();
      });
  }

  handleFocus(e) {
    e.target.select();
  }

  async loadVeiculos() {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/retaguarda/veiculos`,
        this.props.parameters()
      )
      .then((response) => {
        let veiculos = response.data.veiculos;
        veiculos.map((veic) => {
          veic.label = veic.desc;
          veic.value = veic._id;
          return veic;
        });

        this.setState({ veiculos });
      })
      .catch((error) => {
        // this.setState({ despesaDeletar: null, modal: false }, () => {
        //   this.notificar("warning", error.response.data.erro);
        // });
      });
  }

  limparFiltros() {
    this.setState(
      {
        veiculoSelecionado: null,
        motoristaSelecionado: null,
        status: "todos",
        dataInicial: moment().startOf("month").toDate(),
        dataFinal: moment().endOf("month").toDate(),
      },
      () => {
        this.load();
      }
    );
  }

  async loadMotoristas() {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/retaguarda/usuarios`,
        this.props.parameters()
      )
      .then((response) => {
        const data = response.data;
        let motoristas = data.usuarios;

        for (let motorista of motoristas) {
          motorista.label = motorista.desc;
          motorista.value = motorista._id;
        }

        this.setState({ motoristas });
      });
  }

  async gerarRelatorio() {
    let listaFormatada = [];
    let lista = this.state.ordens;

    if (lista.length === 0) {
      this.notificar("warning", "Lista vazia para gerar relatório");
      return false;
    }

    for (let item of lista) {
      let motorista = "Felipe R. Krause";
      let dataAnalise = "";
      let litros = "";
      let valor = "";

      if (item.status !== "pendente") {
        if (item.status === "naoRealizada") {
          dataAnalise = moment(item.dtRecusado).format("DD/MM/YYYY HH:mm");
        } else {
          dataAnalise = moment(item.dtAprovado).format("DD/MM/YYYY HH:mm");
        }
      }

      if (item.litros !== null) {
        litros = item.litros;
      }

      if (item.valor !== null) {
        valor = item.valor.toFixed(2).replace(".", ",");
      }

      listaFormatada.push({
        data: moment(item.dtGravacao).format("DD/MM/YYYY HH:mm"),
        posto: item.descCliente,
        veiculo: item.veiculo,
        placa: item.placa,
        motorista: motorista,
        combustivel: item.combustivel,
        litros: litros,
        valor: valor,
        status: item.novoStatus,
        dataAnalise: dataAnalise,
      });
    }

    let dataInicial = this.state.dataInicial;
    let dataFinal = this.state.dataFinal;

    // let nomeArquivo = `relatorio-fornecedores.xlsx`;
    let nomeArquivo = `ordens-abastecimento-${moment(dataInicial)
      .startOf("day")
      .format("YYYY-MM-DD")}-${moment(dataFinal)
      .startOf("day")
      .format("YYYY-MM-DD")}`;

    let motorista = "Todos";
    let status = this.state.statusSelecionado;

    if (this.state.motoristaSelecionado !== null) {
      motorista = this.state.motoristaSelecionado.desc;
    }

    if (status === "todos") {
      status = "Todos";
    } else if (status === "pendente") {
      status = "Pendente";
    } else if (status === "realizada") {
      status = "Aprovada";
    } else if (status === "naoRealizada") {
      status = "Recusada";
    }

    let parametros = {
      ordensAbastecimento: listaFormatada,
      dataInicial,
      dataFinal,
      motorista,
      status,
    };

    await axios
      .post(
        `${this.props.getBaseUrlImpressao()}/ordens-abastecimento-pdf`,
        { parametros }
      )
      .then(async (response) => {
        // FileDownload(response.data, nomeArquivo);
        localStorage.setItem("html", response.data.html);
        localStorage.setItem('tituloPagina', nomeArquivo)
        window.open("/impressao", "_blank");
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          // this.notificar("warning", error.response.data.erro);
        }
      });
  }
  async gerarRelatorio2() {
    let listaFormatada = [];
    let lista = this.state.ordens;

    if (lista.length === 0) {
      this.notificar("warning", "Lista vazia para gerar relatório");
      return false;
    }

    for (let item of lista) {
      let motorista = "Felipe R. Krause";
      let dataAnalise = "";
      let litros = "";
      let valor = "";

      if (item.status !== "pendente") {
        if (item.status === "naoRealizada") {
          dataAnalise = moment(item.dtRecusado).format("DD/MM/YYYY HH:mm");
        } else {
          dataAnalise = moment(item.dtAprovado).format("DD/MM/YYYY HH:mm");
        }
      }

      if (item.litros !== null) {
        litros = item.litros;
      }

      if (item.valor !== null) {
        valor = item.valor.toFixed(2).replace(".", ",");
      }

      listaFormatada.push({
        DATA: moment(item.dtGravacao).format("DD/MM/YYYY HH:mm"),
        POSTO: item.descCliente,
        VEICULO: item.veiculo,
        PLACA: item.placa,
        MOTORISTA: motorista,
        COMBUSTIVEL: item.combustivel,
        "LITROS(l)": litros,
        "VALOR(R$)": valor,
        STATUS: item.novoStatus,
        DATA_ANALISE: dataAnalise,
      });
    }

    let dataInicial = this.state.dataInicial;
    let dataFinal = this.state.dataFinal;

    // let nomeArquivo = `relatorio-fornecedores.xlsx`;
    let nomeArquivo = `ordens-abastecimento-${moment(dataInicial)
      .startOf("day")
      .format("YYYY-MM-DD")}-${moment(dataFinal)
      .startOf("day")
      .format("YYYY-MM-DD")}.xlsx`;

    let params = {};
    params.responseType = "blob";

    await axios
      .post(
        `${this.props.getBaseUrlApi()}/retaguarda/relatorios`,
        { lista: listaFormatada },
        params
      )
      .then(async (response) => {
        FileDownload(response.data, nomeArquivo);
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          // this.notificar("warning", error.response.data.erro);
        }
      });
    // import FileDownload from "js-file-download";
  }

  render() {
    return (
      <React.Fragment>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />

        <div className="container-scroller font-app">
          <NavBar title="Ordens de Abastecimento" />
          <div className="container-fluid page-body-wrapper">
            <SideBar itemActive={"ordensAbastecimento"} />
            <div className="main-panel">
              <div className="content-wrapper">
                {/* <TitleAndDescription title="Usuários" description="" /> */}

                <div
                  className="row"
                  style={{ marginBottom: "0.8rem", alignItems: "center" }}
                >
                  <div className="col-md-12 stretch-card">
                    {true && (
                      <div className="card">
                        <div
                          className="card-body5"
                          style={{ position: "relative" }}
                        >
                          <React.Fragment>
                            {/* <div
                              style={{
                                position: "absolute",
                                top: "11px",
                                right: "11px",
                              }}
                            >
                              <button
                                className="btn btn-success btn-app-verde-sm"
                                onClick={() => {
                                  this.props.history.push("/fornecedor-novo");
                                }}
                              >
                               CADASTRAR
                              </button>
                            </div> */}
                            <div className="row">
                              <div className="col-md-2 pd-veiculo2">
                                <div className="form-group form-group-chegoo2">
                                  <label>Data Inicial</label>
                                  <DatePicker
                                    selected={this.state.dataInicial}
                                    customInput={
                                      <input
                                        className="form-control font-size-14 form-control-chegoo text-center"
                                        style={{ width: "100%" }}
                                      />
                                    }
                                    dateFormat="dd/MM/yyyy"
                                    onChange={(date) => {
                                      this.setState({ dataInicial: date });
                                    }}
                                    locale="pt-BR"
                                  />
                                </div>
                              </div>
                              <div className="col-md-2 pd-veiculo">
                                <div className="form-group form-group-chegoo2">
                                  <label>Data Final</label>
                                  <DatePicker
                                    selected={this.state.dataFinal}
                                    customInput={
                                      <input className="form-control font-size-14 form-control-chegoo text-center" />
                                    }
                                    dateFormat="dd/MM/yyyy"
                                    onChange={(date) => {
                                      this.setState({ dataFinal: date });
                                    }}
                                    locale="pt-BR"
                                  />
                                </div>
                              </div>

                              {/* <div className="col-md-2 pd-veiculo">
                                <div className="form-group form-group-chegoo2">
                                  <label>Status</label>
                                  <select className="form-control form-control-chegoo font-size-14">
                                    <option>Ativo</option>
                                    <option>Inativo</option>
                                  </select>
                                </div>
                              </div> */}

                              <div className="col-md-2 col-6 pd-veiculo">
                                <div className="form-group-chegoo form-group-chegoo2 font-size-14">
                                  <label>Motorista</label>
                                  <Select
                                    value={this.state.motoristaSelecionado}
                                    onChange={(value) => {
                                      this.setState({
                                        motoristaSelecionado: value,
                                      });
                                    }}
                                    options={this.state.motoristas}
                                    placeholder="Selecione"
                                    styles={{
                                      control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        fontSize: "14px",
                                        height: "35px",
                                        minHeight: "35px",
                                      }),
                                      menu: (baseStyles, state) => ({
                                        ...baseStyles,
                                        fontSize: "14px",
                                      }),
                                    }}
                                  />
                                </div>
                              </div>

                              <div className="col-md-2 col-6 pd-veiculo">
                                <div className="form-group form-group-chegoo2">
                                  <label>Status</label>
                                  <select
                                    className="form-control font-size-14 form-control-chegoo"
                                    value={this.state.statusSelecionado}
                                    onChange={(e) => {
                                      this.setState({
                                        statusSelecionado: e.target.value,
                                      });
                                    }}
                                  >
                                    <option value="todos">Todos</option>
                                    <option value="pendente">Pendente</option>
                                    <option value="realizada">Aprovada</option>
                                    <option value="naoRealizada">
                                      Recusada
                                    </option>
                                  </select>
                                </div>
                              </div>

                              <div
                                className="col-md-2 pd-veiculo"
                                style={{ display: "flex", marginTop: "15px" }}
                              >
                                <div
                                  className="form-group-chegoo2"
                                  style={{ float: "left" }}
                                >
                                  <label></label>
                                  <button
                                    className="btn btn-secondary btn-sm"
                                    style={{
                                      padding: "0.275rem 0.55rem",
                                      margin: "7px 5px 0px",
                                    }}
                                    onClick={() => this.load()}
                                    id={`tooltip-filtro-atendimentos`}
                                  >
                                    <i
                                      className="fa fa-search"
                                      aria-hidden="true"
                                      style={{ fontSize: "px" }}
                                    ></i>
                                  </button>
                                  <UncontrolledTooltip
                                    placement="bottom"
                                    target={`tooltip-filtro-atendimentos`}
                                  >
                                    Filtrar
                                  </UncontrolledTooltip>
                                </div>
                                <div className="form-group-chegoo2">
                                  <label></label>
                                  <button
                                    className="btn btn-warning btn-sm"
                                    style={{
                                      padding: "0.275rem 0.55rem",
                                      margin: "7px 5px 0px",
                                      color: "#FFF",
                                    }}
                                    onClick={() => this.limparFiltros()}
                                    id={`tooltip-limpar-filtro-atendimentos`}
                                  >
                                    <i
                                      className="fa fa-eraser"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                  <UncontrolledTooltip
                                    placement="bottom"
                                    target={`tooltip-limpar-filtro-atendimentos`}
                                  >
                                    Limpar filtro
                                  </UncontrolledTooltip>
                                </div>
                                <div className="form-group-chegoo2">
                                  <label></label>
                                  <button
                                    className="btn btn-danger btn-sm"
                                    style={{
                                      padding: "0.275rem 0.55rem",
                                      margin: "7px 5px 0px",
                                    }}
                                    onClick={() => this.gerarRelatorio()}
                                    id={`tooltip-limpar-filtro-atendimentos2`}
                                  >
                                    <i
                                      className="fa fa-file-pdf-o"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                  <UncontrolledTooltip
                                    placement="bottom"
                                    target={`tooltip-limpar-filtro-atendimentos2`}
                                  >
                                    Gerar relatório
                                  </UncontrolledTooltip>
                                </div>
                              </div>

                              <div
                                className="col-md-2 pd-veiculo3"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "end",
                                }}
                              >
                                <button
                                  className="btn btn-success btn-app-verde-sm"
                                  onClick={() => {
                                    this.props.history.push(
                                      "/ordens-abastecimento-nova"
                                    );
                                  }}
                                >
                                  CADASTRAR
                                </button>
                              </div>

                              {/* <div
                                className="col-md-2"
                                style={{
                                  marginTop: "15px",
                                  marginTop: "15px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "end",
                                }}
                              >
                                <button
                                  className="btn btn-success btn-app-verde-sm"
                                  onClick={() => {
                                    this.props.history.push("/fornecedor-novo");
                                  }}
                                >
                                  CADASTRAR
                                </button>
                              </div> */}
                            </div>
                          </React.Fragment>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12 stretch-card">
                    {this.state.ordens.length > -1 && (
                      <div className="card">
                        <div className="card-body2">
                          <React.Fragment>
                            <div className="table-responsive">
                              <table
                                id="recent-purchases-listing"
                                className="table"
                              >
                                <thead className="thead-dark">
                                  <tr>
                                    <th>Posto </th>
                                    <th>Veículo</th>
                                    <th>Motorista</th>
                                    <th>Combustível</th>
                                    <th className="text-right">Litros</th>
                                    <th className="text-right">Valor</th>
                                    <th className="text-center">Status</th>
                                    <th>Ações</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.ordens.map((usuario, key) => (
                                    <tr key={key}>
                                      <td>{usuario.descCliente}</td>
                                      <td> {usuario.veiculo}</td>
                                      <td> Felipe R. Krause</td>
                                      <td> {usuario.combustivel}</td>
                                      <td className="text-right">
                                        {" "}
                                        {usuario.litros
                                          ?.toString()
                                          .replace(".", ",")}
                                      </td>
                                      <td className="text-right">
                                        {" "}
                                        {usuario.valor !== null && (
                                          <span>
                                            R$ {formatarReal(usuario.valor)}
                                          </span>
                                        )}
                                      </td>
                                      <td className="text-center">
                                        <span
                                          className={`color-${usuario.status}`}
                                          style={
                                            {
                                              //textTransform: "capitalize",
                                            }
                                          }
                                        >
                                          <i className="fa fa-circle"> </i>{" "}
                                          {usuario.novoStatus}
                                        </span>
                                      </td>
                                      <td
                                        className="d-flex"
                                        style={{ fontSize: "18px" }}
                                      >
                                        <div
                                          style={{
                                            margin: "2px",
                                            cursor: "pointer",
                                          }}
                                        >
                                          <i
                                            style={{
                                              color: `${
                                                usuario.obs === ""
                                                  ? "#ccc"
                                                  : "inherit"
                                              }`,
                                            }}
                                            className="fa fa-eye"
                                            onClick={() => {
                                              if (usuario.obs === "") {
                                                return false;
                                              }

                                              this.setState(
                                                {
                                                  obsOrdemAbastecimento:
                                                    usuario.obs,
                                                },
                                                () => {
                                                  this.toggleModalObs();
                                                }
                                              );
                                            }}
                                            id={`ordem-obs-${usuario._id}`}
                                          ></i>

                                          <UncontrolledTooltip
                                            placement="right"
                                            target={`ordem-obs-${usuario._id}`}
                                          >
                                            Visualizar Obs
                                          </UncontrolledTooltip>
                                        </div>
                                        {usuario.status === "realizada" && (
                                          <div
                                            style={{
                                              margin: "2px",
                                              cursor: "pointer",
                                            }}
                                          >
                                            <i
                                              style={{
                                                color: `${
                                                  usuario.litros !== null
                                                    ? "#ccc"
                                                    : "inherit"
                                                }`,
                                              }}
                                              className="fa fa-edit"
                                              onClick={() => {
                                                if (usuario.litros !== null) {
                                                  return false;
                                                }

                                                this.setState(
                                                  {
                                                    idOrdemAbastecimentoEditar:
                                                      usuario._id,
                                                    comprovanteSelecionado:
                                                      usuario.comprovante,
                                                  },
                                                  () => {
                                                    this.toggleModalValores();
                                                  }
                                                );
                                              }}
                                              id={`ordem-valores-${usuario._id}`}
                                            ></i>

                                            <UncontrolledTooltip
                                              placement="right"
                                              target={`ordem-valores-${usuario._id}`}
                                            >
                                              Editar valores
                                            </UncontrolledTooltip>
                                          </div>
                                        )}
                                        {usuario.status === "pendente" && (
                                          <React.Fragment>
                                            <div
                                              style={{
                                                margin: "2px",
                                                cursor: "pointer",
                                              }}
                                            >
                                              <i
                                                className="fa fa-check"
                                                onClick={() => {
                                                  this.aprovarOrdem(
                                                    usuario._id
                                                  );
                                                }}
                                                id={`ordem-aprovar-${usuario._id}`}
                                              ></i>

                                              <UncontrolledTooltip
                                                placement="right"
                                                target={`ordem-aprovar-${usuario._id}`}
                                              >
                                                Aprovar
                                              </UncontrolledTooltip>
                                            </div>
                                            <div
                                              style={{
                                                margin: "2px",
                                                cursor: "pointer",
                                              }}
                                            >
                                              <i
                                                className="fa fa-times"
                                                style={{ color: "red" }}
                                                onClick={() => {
                                                  this.recusarOrdem(
                                                    usuario._id
                                                  );
                                                }}
                                                id={`ordem-recusar-${usuario._id}`}
                                              ></i>

                                              <UncontrolledTooltip
                                                placement="right"
                                                target={`ordem-recusar-${usuario._id}`}
                                              >
                                                Recusar
                                              </UncontrolledTooltip>
                                            </div>
                                          </React.Fragment>
                                        )}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>

                            {/* 
                            <div className="paginacao">
                              <Pagination
                                prevPageText={
                                  <i className="fa fa-angle-left" />
                                }
                                nextPageText={
                                  <i className="fa fa-angle-right" />
                                }
                                firstPageText={
                                  <i className="fa fa-angle-double-left" />
                                }
                                lastPageText={
                                  <i className="fa fa-angle-double-right" />
                                }
                                activePage={this.state.paginacao.pagina}
                                itemsCountPerPage={this.state.paginacao.limite}
                                totalItemsCount={this.state.totalcomissoes}
                                onChange={(pagina) => this.paginar(pagina)}
                                itemClass="page-item"
                                linkClass="page-link"
                              />
                            </div> */}
                          </React.Fragment>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>

        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Deletar usuário</ModalHeader>
          <ModalBody>Tem certeza que deseja deletar este usuário?</ModalBody>
          <ModalFooter>
            <Button
              className="btn-modal-recuperar"
              color="secondary"
              onClick={this.toggle}
            >
              Não
            </Button>
            <Button
              className="btn-modal-recuperar"
              color="primary"
              onClick={this.handleClickConfirmaDeletar}
            >
              Sim
            </Button>{" "}
          </ModalFooter>
        </Modal>

        <Modal isOpen={this.state.modalObs} toggle={this.toggleModalObs}>
          <ModalHeader toggle={this.toggleModalObs}>
            Visualizar Observação
          </ModalHeader>
          <ModalBody>{this.state.obsOrdemAbastecimento}</ModalBody>
          <ModalFooter>
            <Button
              className="btn-modal-recuperar"
              color="primary"
              onClick={this.toggleModalObs}
            >
              Fechar
            </Button>{" "}
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.modalValores}
          toggle={this.toggleModalValores}
        >
          <ModalHeader toggle={this.toggleModalValores}>
            Editar valores
          </ModalHeader>
          <ModalBody>
            <div className="row">
              <div className="col-6">
                <div className="form-group form-group-chegoo">
                  <label htmlFor="nome">Valor</label>
                  <CurrencyInput
                    placeholder="R$0,00"
                    type="text"
                    value={this.state.valor}
                    onChange={(e) => {
                      this.setState({ valor: e.target.value });
                    }}
                    onBlur={(e) => {
                      let valor = this.state.valor;
                      valor = valor.replace("R$", "");
                      valor = valor.replace(",", ".");
                      valor = parseFloat(valor);
                      this.setState({
                        valor: valor.toFixed(2).replace(".", ","),
                      });
                    }}
                    onFocus={this.handleFocus}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="form-group form-group-chegoo">
                  <label htmlFor="nome">Litros</label>
                  <input
                    type="text"
                    className="form-control font-size-14"
                    value={this.state.litros}
                    onChange={(e) => {
                      this.setState({ litros: e.target.value });
                    }}
                    onFocus={this.handleFocus}
                  />
                </div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              <div style={{ margin: "5px" }}>
                <img
                  src={
                    this.state.comprovanteSelecionado !== null
                      ? this.state.comprovanteSelecionado
                      : "../images/no-image-comprovante.png"
                  }
                  style={{
                    width: "120px",
                    border: "1px solid #CCC",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    this.hiddenFileInput.current.click();
                  }}
                  alt="sem imagem"
                />

                <input
                  type="file"
                  ref={this.hiddenFileInput}
                  onChange={(e) => {
                    getBase64(e.target.files[0]).then((data) => {
                      let ordens = this.state.ordens;
                      for (let ordem of ordens) {
                        if (
                          ordem._id === this.state.idOrdemAbastecimentoEditar
                        ) {
                          ordem.comprovante = data;
                        }
                      }

                      this.setState(
                        { ordens, comprovanteSelecionado: data },
                        () => {
                          // console.log("aaa", this.state);
                        }
                      );
                    });
                  }}
                  style={{ display: "none" }}
                />
              </div>

              <div
                className="font-size-14"
                style={{ float: "left", width: "100%", textAlign: "center" }}
              >
                Adicione fotos para detalhar a ordem.
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              className="btn-modal-recuperar"
              color="secondary"
              onClick={this.toggleModalValores}
            >
              Cancelar
            </Button>
            <Button
              className="btn-modal-recuperar"
              color="primary"
              onClick={this.salvarValoresOrdem}
            >
              Salvar
            </Button>{" "}
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default OrdemAbastecimento;
