import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import { SideBar, NavBar, Footer } from "../../components";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import queryString from "query-string";

export default class TipoDespesaNova extends Component {
  constructor(props) {
    super(props);
    this.notificar = this.notificar.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
    this.cadastrarTipoDespesa = this.cadastrarTipoDespesa.bind(this);
    this.validarTipoDespesa = this.validarTipoDespesa.bind(this);
    this.buscarTipoDespesa = this.buscarTipoDespesa.bind(this);
    this.handleFocus = this.handleFocus.bind(this);

    this.state = {
      _id: null,
      action: "CADASTRAR",
      desc: "",
      balanco: true,
      tipo: "D",
      errors: {
        desc: false,
      },
      isSaving: false,
    };
  }

  async componentDidMount() {
    const userLogado = JSON.parse(localStorage.getItem("usuario"));
    if (userLogado.nivel === "3") {
      return this.props.history.push("/pedidos-material");
    }
    if (userLogado.nivel === "4") {
      return this.props.history.push("/nao-conformidades");
    }
    if (userLogado.nivel === "5") {
      return this.props.history.push("/despesas");
    }
    let id = queryString.parse(this.props.location.search).id;
    if (id !== undefined) {
      await this.buscarTipoDespesa(id);
    }
  }

  notificar(type, message) {
    toast[type](message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  onChangeInput(tipo, value) {
    this.setState({ [tipo]: value }, () => {
      if (tipo === "tipo" && value === "C") {
        this.setState({ balanco: true });
      }
    });
  }

  validarTipoDespesa() {
    let errors = this.state.errors;
    let valido = true;

    if (this.state.desc === "") {
      valido = false;
      errors.desc = true;
    } else {
      errors.desc = false;
    }

    this.setState({ errors });
    return valido;
  }

  async cadastrarTipoDespesa() {
    if (this.state.isSaving) {
      return true;
    }

    this.setState({ isSaving: true }, async () => {
      if (!this.validarTipoDespesa()) {
        this.notificar("warning", "Favor preencher todos os dados!");
        this.setState({ isSaving: false });
        return false;
      }

      let tipoDespesaNova = {
        desc: this.state.desc,
        balanco: this.state.balanco,
        tipo: this.state.tipo,
      };

      let urlAtualizar = `${this.props.getBaseUrlApi()}/retaguarda/tipos-despesa`;

      if (this.state.action === "EDITAR") {
        tipoDespesaNova._id = this.state._id;

        urlAtualizar = `${this.props.getBaseUrlApi()}/retaguarda/tipos-despesa/${
          this.state._id
        }`;
      }

      await axios
        .post(
          urlAtualizar,
          { tipoDespesa: tipoDespesaNova },
          this.props.parameters()
        )
        .then((response) => {
          this.setState(
            {
              desc: "",
              tipo: "D",
            },
            () => {
              if (this.state.action === "CADASTRAR") {
                this.notificar(
                  "success",
                  "Tipo de Despesa cadastrada com sucesso!"
                );
              } else {
                this.notificar(
                  "success",
                  "Tipo de Despesa editada com sucesso!"
                );
              }

              setTimeout(() => {
                this.props.history.push("/tipos-despesa");
              }, 1000);
            }
          );
        })
        .catch((error) => {
          this.notificar("warning", error.response.data.erro);
          this.setState({ isSaving: false });
        });
    });
  }

  async buscarTipoDespesa(id) {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/retaguarda/tipos-despesa/${id}`,
        this.props.parameters()
      )
      .then((response) => {
        const tipoDespesa = response.data.tipoDespesa;

        if (tipoDespesa === null) {
          this.props.history.push("/tipos-despesa");
          return false;
        }

        this.setState({
          _id: tipoDespesa._id,
          action: "EDITAR",
          desc: tipoDespesa.desc,
          balanco: tipoDespesa.balanco,
          tipo: tipoDespesa.tipo,
        });
      });
  }

  handleFocus(e) {
    e.target.select();
  }

  render() {
    const InputRequired = () => {
      return <span className="required-icone">*</span>;
    };

    return (
      <React.Fragment>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
        <div className="container-scroller font-app">
          <NavBar
            title={`Tipos Lançamento / ${
              this.state.action === "CADASTRAR" ? "Novo" : "Editar"
            } Tipo`}
          />
          <div className="container-fluid page-body-wrapper">
            <SideBar itemActive={"tipos-despesa"} />
            <div className="main-panel">
              <div className="content-wrapper">
                <div className="row">
                  <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body5 dashboard-tabs">
                        <div className="tab-content py-0 px-0">
                          <div
                            className="tab-pane fade show active"
                            id="overview"
                            role="tabpanel"
                            aria-labelledby="overview-tab"
                          >
                            <div className="d-flex flex-wrap justify-content-xl-between">
                              <div
                                style={{
                                  float: "left",
                                  width: "100%",
                                }}
                              >
                                <div className="row">
                                  <div className="col-md-3 pd-veiculo2">
                                    <div className="form-group form-group-chegoo">
                                      <label>
                                        Descrição&nbsp;
                                        <InputRequired />
                                      </label>
                                      <input
                                        type="text"
                                        value={this.state.desc}
                                        className={`form-control font-size-14 form-control-chegoo required-chegoo ${
                                          this.state.errors.desc
                                            ? "input-error"
                                            : ""
                                        }`}
                                        onChange={(e) =>
                                          this.onChangeInput(
                                            "desc",
                                            e.target.value
                                          )
                                        }
                                        ref={(node) => {
                                          if (node) {
                                            if (this.state.errors.desc) {
                                              node.style.setProperty(
                                                "border-color",
                                                "red",
                                                "important"
                                              );
                                            } else {
                                              node.style.setProperty(
                                                "border-color",
                                                "#ced4da",
                                                "important"
                                              );
                                            }
                                          }
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-4 pd-veiculo2">
                                    <div className="form-group form-group-chegoo">
                                      <label>
                                        Tipo&nbsp;
                                        <InputRequired />
                                      </label>
                                      <select
                                        className="form-control"
                                        value={this.state.tipo}
                                        onChange={(e) =>
                                          this.onChangeInput(
                                            "tipo",
                                            e.target.value
                                          )
                                        }
                                        disabled={
                                          this.state.action === "EDITAR"
                                        }
                                      >
                                        <option value="D">Despesa</option>
                                        <option value="C">Receita</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className="col-md-4 pd-veiculo3">
                                    <div className="form-group form-group-chegoo">
                                      <label>
                                        Balanço ?&nbsp;
                                        <InputRequired />
                                      </label>
                                      <select
                                        className="form-control"
                                        value={this.state.balanco}
                                        onChange={(e) =>
                                          this.onChangeInput(
                                            "balanco",
                                            e.target.value === "true"
                                              ? true
                                              : false
                                          )
                                        }
                                        disabled={this.state.tipo === "C"}
                                      >
                                        <option value="true">Sim</option>
                                        <option value="false">Não</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-md-2">
                                    <div className="form-group form-group-chegoo2">
                                      <button
                                        className="btn btn-success btn-app-verde"
                                        onClick={() => {
                                          this.cadastrarTipoDespesa();
                                        }}
                                      >
                                        {this.state.isSaving
                                          ? "Salvando..."
                                          : "Salvar"}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
