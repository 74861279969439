const DECIMAL_PLACES_VALUE = 2;
const DECIMAL_PLACES_QTD = 4;

const ordenarIdIntegracao = (a, b) => a.idIntegracao - b.idIntegracao;

const formatarReal = (numero) => {
  numero = parseFloat(numero).toFixed(DECIMAL_PLACES_VALUE);
  numero = numero.replace(".", ",");
  numero = numero.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  return numero;
};

const replaceCaracEspeciais = (text) => {
  text = text.replace(new RegExp("[ÁÀÂÃ]", "gi"), "A");
  text = text.replace(new RegExp("[ÉÈÊ]", "gi"), "E");
  text = text.replace(new RegExp("[ÍÌÎ]", "gi"), "I");
  text = text.replace(new RegExp("[ÓÒÔÕ]", "gi"), "O");
  text = text.replace(new RegExp("[ÚÙÛ]", "gi"), "U");
  text = text.replace(new RegExp("[Ç]", "gi"), "C");
  text = text.replace(new RegExp("[áàãâ]", "gi"), "a");
  text = text.replace(new RegExp("[éèê]", "gi"), "e");
  text = text.replace(new RegExp("[íìî]", "gi"), "i");
  text = text.replace(new RegExp("[óòõô]", "gi"), "o");
  text = text.replace(new RegExp("[úùû]", "gi"), "u");
  text = text.replace(new RegExp("[ç]", "gi"), "c");
  return text;
};

const validaCpfCnpj = (val) => {
  if (val.length == 14) {
    var cpf = val.trim();

    cpf = cpf.replace(/\./g, "");
    cpf = cpf.replace("-", "");
    cpf = cpf.split("");

    var v1 = 0;
    var v2 = 0;
    var aux = false;

    for (var i = 1; cpf.length > i; i++) {
      if (cpf[i - 1] != cpf[i]) {
        aux = true;
      }
    }

    if (aux == false) {
      return false;
    }

    for (var i = 0, p = 10; cpf.length - 2 > i; i++, p--) {
      v1 += cpf[i] * p;
    }

    v1 = (v1 * 10) % 11;

    if (v1 == 10) {
      v1 = 0;
    }

    if (v1 != cpf[9]) {
      return false;
    }

    for (var i = 0, p = 11; cpf.length - 1 > i; i++, p--) {
      v2 += cpf[i] * p;
    }

    v2 = (v2 * 10) % 11;

    if (v2 == 10) {
      v2 = 0;
    }

    if (v2 != cpf[10]) {
      return false;
    } else {
      return true;
    }
  } else if (val.length == 18) {
    var cnpj = val.trim();

    cnpj = cnpj.replace(/\./g, "");
    cnpj = cnpj.replace("-", "");
    cnpj = cnpj.replace("/", "");
    cnpj = cnpj.split("");

    var v1 = 0;
    var v2 = 0;
    var aux = false;

    for (var i = 1; cnpj.length > i; i++) {
      if (cnpj[i - 1] != cnpj[i]) {
        aux = true;
      }
    }

    if (aux == false) {
      return false;
    }

    for (var i = 0, p1 = 5, p2 = 13; cnpj.length - 2 > i; i++, p1--, p2--) {
      if (p1 >= 2) {
        v1 += cnpj[i] * p1;
      } else {
        v1 += cnpj[i] * p2;
      }
    }

    v1 = v1 % 11;

    if (v1 < 2) {
      v1 = 0;
    } else {
      v1 = 11 - v1;
    }

    if (v1 != cnpj[12]) {
      return false;
    }

    for (var i = 0, p1 = 6, p2 = 14; cnpj.length - 1 > i; i++, p1--, p2--) {
      if (p1 >= 2) {
        v2 += cnpj[i] * p1;
      } else {
        v2 += cnpj[i] * p2;
      }
    }

    v2 = v2 % 11;

    if (v2 < 2) {
      v2 = 0;
    } else {
      v2 = 11 - v2;
    }

    if (v2 != cnpj[13]) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
};

const validaEmail = (email) => {
  const emailRegExp =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  return emailRegExp.test(email);
};

const phoneMask = (value) => {
  return value
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "($1) $2")
    .replace(/(\d{4})(\d)/, "$1-$2")
    .replace(/(\d{4})-(\d)(\d{4})/, "$1$2-$3")
    .replace(/(-\d{4})\d+?$/, "$1");
};

const getUrlGoogleMaps = (endereco) => {
  let rua = endereco.end;
  rua = rua.replace(" ", "+");
  rua = rua.replace("R:", "Rua");
  rua = rua.replace(/\//g, "");
  let numero = endereco.num;
  numero = numero.replace("S/N", "");
  numero = numero.replace("0", "");
  numero = numero.replace(/\//g, "");
  let bairro = endereco.bairro;
  bairro = bairro.replace(" ", "+");
  bairro = bairro.replace(/\//g, "");
  let cidade = endereco.cidade;
  cidade = cidade.replace(" ", "+");
  cidade = cidade.replace(/\//g, "");
  let estado = endereco.uf;
  estado = estado.replace(/\//g, "");
  return `https://www.google.com/maps/place/${rua}+${numero}+${bairro}+${cidade}+${estado}`;
};

const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

// const getListaUm = () => {
//   return [
//     {
//       _id: "1",
//       desc: "LT",
//       label: "LT",
//       value: "1"
//     },
//     {
//       _id: "2",
//       value: "2",
//       desc: "KG",
//       label: "KG"
//     },
//     {
//       _id: "3",
//       value: "3",
//       desc: "PC",
//       label: "PEÇA"
//     },
//   ]
// }

const typeIsObject = (str) => {
  return str.includes(".");
}


module.exports = {
  ordenarIdIntegracao,
  formatarReal,
  DECIMAL_PLACES_VALUE,
  DECIMAL_PLACES_QTD,
  replaceCaracEspeciais,
  validaCpfCnpj,
  validaEmail,
  phoneMask,
  getUrlGoogleMaps,
  getBase64,
  typeIsObject
};
