import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import { SideBar, NavBar, TitleAndDescription, Footer } from "../../components";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import axios from "axios";
import Select from "react-select";
import queryString from "query-string";
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from "reactstrap";
import { CurrencyInput } from "../../components";

const SITUACAO_PENDENTE = "0";
const SITUACAO_ANALISE = "1";
const SITUACAO_APROVADO = "2";
const SITUACAO_REVISAO = "3";
const SITUACAO_REPROVADO = "4";
const SITUACAO_CANCELADO = "5";

const MODAL_ALTERAR_SITUACAO = "alterarSituacao";

export default class DespesaNova extends Component {
  constructor(props) {
    super(props);
    this.notificar = this.notificar.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
    this.loadUsuarios = this.loadUsuarios.bind(this);
    this.cadastrarDepesa = this.cadastrarDepesa.bind(this);
    this.validarDespesa = this.validarDespesa.bind(this);
    this.buscarDespesa = this.buscarDespesa.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.loadObras = this.loadObras.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleClickConfirmarAlterarSituacao =
      this.handleClickConfirmarAlterarSituacao.bind(this);
    this.buscarImagem = this.buscarImagem.bind(this);

    this.state = {
      _id: null,

      usuario: null,
      usuarios: [],
      tipo: "D", // D ou C
      desc: "",
      valor: "0,00",
      tipoLancamento: null,
      tiposDespesa: [],
      tiposReceita: [],
      tiposLancamento: [],
      balanco: true,
      comprovante: null,
      dtDespesa: new Date(),
      tiposDespesa: [],
      obra: null,
      obras: [],

      errors: {
        usuario: false,
        tipoLancamento: false,
        desc: false,
        valor: false,
        obra: false,
      },

      action: "CADASTRAR",
      base64: null,
      obsCancelado: "",
      obsReprovacao: "",
      obsRevisao: "",
      modal: {
        [MODAL_ALTERAR_SITUACAO]: false,
      },
      novaSituacao: "99",
      situacao: "0",
      isSaving: false,
      dtCadastro: new Date(),
      existeComprovante: false,
      caminhoDaImagem: null,
    };
  }

  async componentDidMount() {
    const userLogado = JSON.parse(localStorage.getItem("usuario"));
    if (userLogado.nivel === "3") {
      return this.props.history.push("/pedidos-material");
    }
    if (userLogado.nivel === "4") {
      return this.props.history.push("/nao-conformidades");
    }
    let id = queryString.parse(this.props.location.search).id;

    if (id !== undefined) {
      await this.buscarDespesa(id);
    }

    await this.loadUsuarios();
    await this.loadTiposDespesa();
    await this.loadObras();
  }

  async loadUsuarios() {
    let idUsuario = queryString.parse(this.props.location.search).usuario;

    await axios
      .get(
        `${this.props.getBaseUrlApi()}/retaguarda/usuarios`,
        this.props.parameters()
      )
      .then((response) => {
        const usuarios = response.data.usuarios;

        for (let item of usuarios) {
          item.label = item.desc;
          item.value = item._id;
        }

        let usuario = null;

        if (this.state._id !== null) {
          if (this.state.usuario !== null) {
            let usuarioFind = usuarios.find(
              (it) => it._id === this.state.usuario._id
            );
            if (usuarioFind !== undefined) {
              usuario = usuarioFind;
            }
          }
        } else {
          if (idUsuario !== undefined) {
            let usuarioFind = usuarios.find((it) => it._id === idUsuario);
            if (usuarioFind !== undefined) {
              usuario = usuarioFind;
            }
          }
        }

        this.setState({ usuarios, usuario });
      });
  }

  notificar(type, message) {
    toast[type](message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  onChangeInput(type, value) {
    this.setState({ [type]: value }, () => {
      if (type === "tipo" && value === "C") {
        this.setState({ tipoLancamento: null, desc: "", balanco: true });
      } else if (type === "tipo" && value === "D") {
        this.setState({ tipoLancamento: null, desc: "" });
      }
    });
  }

  validarDespesa() {
    let errors = this.state.errors;
    let valido = true;

    if (this.state.usuario === null) {
      valido = false;
      errors.usuario = true;
    } else {
      errors.usuario = false;
    }

    if (this.state.tipoLancamento === null) {
      valido = false;
      errors.tipoLancamento = true;
    } else {
      errors.tipoLancamento = false;
    }

    if (this.state.tipo === "D" && this.state.obra === null) {
      valido = false;
      errors.obra = true;
    } else {
      errors.obra = false;
    }

    if (this.state.tipo === "D" && this.state.desc === "") {
      valido = false;
      errors.desc = true;
    } else {
      errors.desc = false;
    }

    let valor = this.state.valor;
    valor = valor.replace("R$", "");
    valor = valor.replace(",", ".");
    valor = parseFloat(valor);

    if (valor === 0) {
      valido = false;
      errors.valor = true;
    } else {
      errors.valor = false;
    }

    this.setState({ errors });
    return valido;
  }

  async cadastrarDepesa() {
    if (this.state.isSaving) {
      return false;
    }

    this.setState({ isSaving: true }, async () => {
      if (!this.validarDespesa()) {
        this.notificar("warning", "Favor preencher todos os dados!");
        this.setState({ isSaving: false });
        return false;
      }

      let acaoPagina = this.state._id === null ? "cadastrar" : "editar";

      let valor = this.state.valor;
      valor = valor.replace("R$", "");
      valor = valor.replace(",", ".");
      valor = parseFloat(valor);

      let despesaNova = {
        desc: this.state.desc,

        tipo: this.state.tipo,
        tipoDespesa: this.state.tipoLancamento,
        usuario: this.state.usuario,

        dtDespesa: this.state.dtDespesa,
        valor,
        obra: this.state.obra,
        balanco: this.state.balanco,
      };

      if (acaoPagina === "editar") {
        despesaNova._id = this.state._id;
      }

      let urlAtualizar = `${this.props.getBaseUrlApi()}/retaguarda/despesas-felipe`;

      if (this.state._id !== null) {
        urlAtualizar = `${this.props.getBaseUrlApi()}/retaguarda/despesas-felipe/${
          this.state._id
        }`;
      }

      await axios
        .post(
          urlAtualizar,
          { despesa: despesaNova, usuario: despesaNova.usuario },
          this.props.parameters()
        )
        .then((response) => {
          if (this.state.action === "CADASTRAR") {
            this.notificar("success", "Despesa cadastrada com sucesso!");

            setTimeout(() => {
              this.props.history.push("/despesas");
            }, 1000);
          } else {
            this.notificar("success", "Despesa editada com sucesso!");

            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
        })
        .catch((error) => {
          this.notificar("warning", error.response.data.erro);
          this.setState({ isSaving: false });
        });
    });
  }

  async buscarDespesa(id) {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/retaguarda/despesas-felipe/${id}`,
        this.props.parameters()
      )
      .then((response) => {
        const despesa = response.data.despesa;
        // const base64 = response.data.base64;
        const caminhoDaImagem = response.data.caminhoDaImagem;

        if (despesa === null) {
          this.props.history.push("/despesas");
          return false;
        }

        this.setState(
          {
            _id: despesa._id,
            valor: despesa.valor.toFixed(2).replace(".", ","),
            desc: despesa.desc,
            dtDespesa: moment(despesa.dtDespesa).toDate(),
            tipoLancamento: despesa.tipoDespesa,
            obra: despesa.obra,
            balanco: despesa.balanco,
            tipo: despesa.tipo,
            usuario: despesa.usuario,
            obsCancelado: despesa.obsCancelado,
            obsReprovacao: despesa.obsReprovacao,
            obsRevisao: despesa.obsRevisao,
            action: "EDITAR",
            situacao: despesa.situacao,
            dtCadastro: moment(despesa.dtCadastro).toDate(),
            existeComprovante: caminhoDaImagem !== null,
            caminhoDaImagem,
          },
          async () => {
            if (caminhoDaImagem !== null) {
              await this.buscarImagem(caminhoDaImagem);
            }
          }
        );
      });
  }

  handleFocus(e) {
    e.target.select();
  }

  async loadTiposDespesa() {
    await axios
      .post(
        `${this.props.getBaseUrlApi()}/retaguarda/lista-tipos-despesa`,
        {},
        this.props.parameters()
      )
      .then((response) => {
        let tiposLancamento = response.data.tiposDespesa;

        let tiposDespesa = [];
        let tiposReceita = [];

        for (let item of tiposLancamento) {
          item.label = item.desc;
          item.value = item._id;
        }

        tiposDespesa = tiposLancamento.filter((it) => it.tipo === "D");
        tiposReceita = tiposLancamento.filter((it) => it.tipo === "C");

        let tipoLancamento = null;

        if (this.state._id !== null) {
          if (this.state.tipoLancamento !== null) {
            let tipoLancamentoFind = tiposLancamento.find(
              (it) => it._id === this.state.tipoLancamento._id
            );
            if (tipoLancamentoFind !== undefined) {
              tipoLancamento = tipoLancamentoFind;
            }
          }
        }

        tiposDespesa.sort(function (a, b) {
          var descricaoA = a.desc.toUpperCase(); // Ignora maiúsculas/minúsculas durante a ordenação
          var descricaoB = b.desc.toUpperCase();

          if (descricaoA < descricaoB) {
            return -1;
          }
          if (descricaoA > descricaoB) {
            return 1;
          }

          // Descrições são iguais
          return 0;
        });

        tiposReceita.sort(function (a, b) {
          var descricaoA = a.desc.toUpperCase(); // Ignora maiúsculas/minúsculas durante a ordenação
          var descricaoB = b.desc.toUpperCase();

          if (descricaoA < descricaoB) {
            return -1;
          }
          if (descricaoA > descricaoB) {
            return 1;
          }

          // Descrições são iguais
          return 0;
        });

        tiposLancamento.sort(function (a, b) {
          var descricaoA = a.desc.toUpperCase(); // Ignora maiúsculas/minúsculas durante a ordenação
          var descricaoB = b.desc.toUpperCase();

          if (descricaoA < descricaoB) {
            return -1;
          }
          if (descricaoA > descricaoB) {
            return 1;
          }

          // Descrições são iguais
          return 0;
        });

        this.setState({
          tiposDespesa,
          tiposReceita,
          tiposLancamento,
          tipoLancamento,
        });
      });
  }

  async loadObras() {
    await axios
      .post(
        `${this.props.getBaseUrlApi()}/retaguarda/lista-obras`,
        {},
        this.props.parameters()
      )
      .then((response) => {
        let obras = response.data.obras;

        for (let item of obras) {
          // item.label = item.desc;
          item.label = `${item.cod.toString().padStart(4, "0")} - ${item.desc}`;
          item.value = item._id;
        }

        let obra = null;

        if (this.state._id !== null) {
          if (this.state.obra !== null) {
            let obraFind = obras.find((it) => it._id === this.state.obra._id);

            if (obraFind !== undefined) {
              obra = obraFind;
            }
          }
        }

        this.setState({ obras, obra });
      });
  }

  toggle(type) {
    let modal = this.state.modal;
    modal[type] = !modal[type];
    this.setState({ modal });
  }

  async handleClickConfirmarAlterarSituacao() {
    let idDespesa = this.state._id;
    let novaSituacao = this.state.novaSituacao;
    let obs = "";

    if (novaSituacao === this.state.situacao) {
      this.notificar("warning", "A Nova Situação deve ser diferente da atual.");
      return false;
    }

    if (novaSituacao === SITUACAO_REVISAO) {
      if (this.state.obsRevisao === "") {
        this.notificar(
          "warning",
          "Favor informar uma observação para a revisão."
        );
        return false;
      } else {
        obs = this.state.obsRevisao;
      }
    } else if (novaSituacao === SITUACAO_REPROVADO) {
      if (this.state.obsReprovacao === "") {
        this.notificar(
          "warning",
          "Favor informar uma observação para a reprovação."
        );
        return false;
      } else {
        obs = this.state.obsReprovacao;
      }
    } else if (novaSituacao === SITUACAO_CANCELADO) {
      if (this.state.obsCancelado === "") {
        this.notificar(
          "warning",
          "Favor informar uma observação para o cancelamento."
        );
        return false;
      } else {
        obs = this.state.obsCancelado;
      }
    }

    await axios
      .post(
        `${this.props.getBaseUrlApi()}/retaguarda/extrato-alterar-situacao`,
        { idDespesa, obs, novaSituacao },
        this.props.parameters()
      )
      .then((response) => {
        this.notificar(
          "success",
          "Situação do lançamento alterado com sucesso!"
        );

        this.toggle(MODAL_ALTERAR_SITUACAO);

        setTimeout(() => {
          window.location.reload();
        }, 1500);
      })
      .catch((error) => {
        this.toggle(MODAL_ALTERAR_SITUACAO);
        this.notificar("warning", error.response.data.erro);
      });
  }

  async buscarImagem(caminhoDaImagem) {
    await axios
      .post(
        `${this.props.getBaseUrlApi()}/retaguarda/buscar-comprovante`,
        { caminhoDaImagem },
        this.props.parameters()
      )
      .then((response) => {
        const base64 = response.data.base64;
        if (base64 !== null) {
          this.setState({
            base64,
          });
        }
      });
  }

  render() {
    const InputRequired = () => {
      return <span className="required-icone">*</span>;
    };

    return (
      <React.Fragment>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
        <div className="container-scroller font-app">
          <NavBar
            title={`Lançamentos / ${
              this.state.action === "CADASTRAR" ? "Novo" : "Editar"
            } lançamento`}
          />
          <div className="container-fluid page-body-wrapper">
            <SideBar itemActive={"despesas"} />
            <div className="main-panel">
              <div className="content-wrapper">
                <div className="row">
                  <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body5 dashboard-tabs">
                        <div className="tab-content py-0 px-0">
                          <div
                            className="tab-pane fade show active"
                            id="overview"
                            role="tabpanel"
                            aria-labelledby="overview-tab"
                          >
                            <div className="d-flex flex-wrap justify-content-xl-between">
                              <div
                                style={{
                                  float: "left",
                                  width: "100%",
                                }}
                              >
                                <div className="row">
                                  <div className="col-md-5 pd-veiculo2">
                                    <div className="form-group form-group-chegoo">
                                      <label>
                                        Usuário
                                        <InputRequired />
                                      </label>
                                      <Select
                                        value={this.state.usuario}
                                        onChange={(item) =>
                                          this.onChangeInput("usuario", item)
                                        }
                                        options={this.state.usuarios}
                                        placeholder="Selecione o usuário"
                                        className={`${
                                          this.state.errors.usuario
                                            ? "select-error"
                                            : ""
                                        } required-chegoo`}
                                        styles={{
                                          control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            fontSize: "14px",
                                            height: "35px",
                                            minHeight: "35px",
                                            backgroundColor: "#fffef6",
                                          }),
                                          menu: (baseStyles, state) => ({
                                            ...baseStyles,
                                            fontSize: "14px",
                                          }),
                                        }}
                                        isDisabled={
                                          this.state.action === "EDITAR"
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-3 pd-veiculo">
                                    <div className="form-group form-group-chegoo">
                                      <label>
                                        Tipo de Lançamento
                                        <InputRequired />
                                      </label>
                                      <select
                                        className="form-control font-size-14 form-control-chegoo required-chegoo"
                                        value={this.state.tipo}
                                        onChange={(e) =>
                                          this.onChangeInput(
                                            "tipo",
                                            e.target.value
                                          )
                                        }
                                        // disabled={
                                        //   this.state._id !== null ? true : false
                                        // }
                                        disabled={
                                          this.state.action === "EDITAR"
                                        }
                                      >
                                        <option value="D">Despesa</option>
                                        <option value="C">Receita</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className="col-md-4 pd-veiculo3">
                                    <div className="form-group form-group-chegoo">
                                      <label>
                                        Tipo <InputRequired />
                                      </label>
                                      <Select
                                        value={this.state.tipoLancamento}
                                        onChange={(item) =>
                                          this.onChangeInput(
                                            "tipoLancamento",
                                            item
                                          )
                                        }
                                        options={
                                          this.state.tipo === "D"
                                            ? this.state.tiposDespesa
                                            : this.state.tiposReceita
                                        }
                                        placeholder="Selecione o tipo"
                                        className={`font-size-14 ${
                                          this.state.errors.tipoLancamento
                                            ? "select-error"
                                            : ""
                                        }`}
                                        // isDisabled={
                                        //   this.state.status !== "pendente"
                                        //     ? true
                                        //     : this.state.tipo === "despesa"
                                        //     ? false
                                        //     : true
                                        // }
                                        styles={{
                                          control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            fontSize: "14px",
                                            height: "35px",
                                            minHeight: "35px",
                                            backgroundColor: "#fffef6",
                                          }),
                                          menu: (baseStyles, state) => ({
                                            ...baseStyles,
                                            fontSize: "14px",
                                          }),
                                        }}
                                        isDisabled={
                                          this.state.situacao !==
                                            SITUACAO_PENDENTE &&
                                          this.state.situacao !==
                                            SITUACAO_REVISAO
                                        }
                                      />
                                    </div>
                                  </div>{" "}
                                </div>

                                <div className="row">
                                  {this.state.tipo === "D" && (
                                    <div className="col-md-5 pd-veiculo2">
                                      <div className="form-group form-group-chegoo">
                                        <label>
                                          Descrição
                                          <InputRequired />
                                        </label>
                                        <input
                                          type="text"
                                          value={this.state.desc}
                                          label="email@email.com.br"
                                          className={`form-control font-size-14 form-control-chegoo required-chegoo ${
                                            this.state.errors.desc
                                              ? "input-error"
                                              : ""
                                          }`}
                                          onChange={(e) =>
                                            this.onChangeInput(
                                              "desc",
                                              e.target.value
                                            )
                                          }
                                          // disabled={
                                          //   this.state.status !== "pendente"
                                          //     ? true
                                          //     : this.state.tipo === "despesa"
                                          //     ? false
                                          //     : true
                                          // }
                                          ref={(node) => {
                                            if (node) {
                                              if (this.state.errors.desc) {
                                                node.style.setProperty(
                                                  "border-color",
                                                  "red",
                                                  "important"
                                                );
                                              } else {
                                                node.style.setProperty(
                                                  "border-color",
                                                  "#ced4da",
                                                  "important"
                                                );
                                              }
                                            }
                                          }}
                                          disabled={
                                            this.state.tipo === "C"
                                              ? true
                                              : this.state.situacao !==
                                                  SITUACAO_PENDENTE &&
                                                this.state.situacao !==
                                                  SITUACAO_REVISAO
                                              ? true
                                              : false
                                          }
                                        />
                                      </div>
                                    </div>
                                  )}
                                  <div
                                    className={`col-md-2 ${
                                      this.state.tipo === "D"
                                        ? "pd-veiculo"
                                        : "pd-veiculo2"
                                    }`}
                                  >
                                    <div className="form-group form-group-chegoo">
                                      <label>
                                        Data de Lançamento
                                        <InputRequired />
                                      </label>
                                      <DatePicker
                                        selected={this.state.dtDespesa}
                                        customInput={
                                          <input className="form-control form-control-chegoo font-size-14 text-center required-chegoo" />
                                        }
                                        dateFormat="dd/MM/yyyy"
                                        onChange={(date) => {
                                          this.setState({ dtDespesa: date });
                                        }}
                                        locale="pt-BR"
                                        disabled={
                                          this.state.action === "EDITAR"
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-2 pd-veiculo3">
                                    <div className="form-group form-group-chegoo">
                                      <label>
                                        Valor
                                        <InputRequired />
                                      </label>
                                      <CurrencyInput
                                        placeholder="R$0,00"
                                        type="text"
                                        value={this.state.valor}
                                        onChange={(e) => {
                                          this.onChangeInput(
                                            "valor",
                                            e.target.value
                                          );
                                        }}
                                        onFocus={this.handleFocus}
                                        className={`font-size-14 form-control-chegoo text-center required-chegoo ${
                                          this.state.errors.valor
                                            ? "input-error"
                                            : ""
                                        }`}
                                        // disabled={
                                        //   this.state.status !== "pendente"
                                        //     ? true
                                        //     : false
                                        // }
                                        onBlur={(e) => {
                                          let valor = this.state.valor;
                                          valor = valor.replace("R$", "");
                                          valor = valor.replace(",", ".");
                                          valor = parseFloat(valor);
                                          this.setState({
                                            valor: valor
                                              .toFixed(2)
                                              .replace(".", ","),
                                          });
                                        }}
                                        ref={(node) => {
                                          if (node) {
                                            if (this.state.errors.valor) {
                                              node.style.setProperty(
                                                "border-color",
                                                "red",
                                                "important"
                                              );
                                            } else {
                                              node.style.setProperty(
                                                "border-color",
                                                "#ced4da",
                                                "important"
                                              );
                                            }
                                          }
                                        }}
                                        disabled={
                                          this.state.situacao !==
                                            SITUACAO_PENDENTE &&
                                          this.state.situacao !==
                                            SITUACAO_REVISAO
                                        }
                                      />
                                    </div>
                                  </div>
                                  {this.state.tipo === "D" && (
                                    <div className="col-md-3 pd-veiculo3">
                                      <div className="form-group form-group-chegoo">
                                        <label>
                                          Obra <InputRequired />
                                        </label>
                                        <Select
                                          value={this.state.obra}
                                          onChange={(item) =>
                                            this.onChangeInput("obra", item)
                                          }
                                          options={this.state.obras}
                                          placeholder="Selecione a obra"
                                          className={`font-size-14 ${
                                            this.state.errors.obra
                                              ? "select-error"
                                              : ""
                                          }`}
                                          // isDisabled={
                                          //   this.state.status !== "pendente"
                                          //     ? true
                                          //     : this.state.tipo === "despesa"
                                          //     ? false
                                          //     : true
                                          // }
                                          styles={{
                                            control: (baseStyles, state) => ({
                                              ...baseStyles,
                                              fontSize: "14px",
                                              height: "35px",
                                              minHeight: "35px",
                                              backgroundColor: "#fffef6",
                                            }),
                                            menu: (baseStyles, state) => ({
                                              ...baseStyles,
                                              fontSize: "14px",
                                            }),
                                          }}
                                          isDisabled={
                                            this.state.situacao !==
                                              SITUACAO_PENDENTE &&
                                            this.state.situacao !==
                                              SITUACAO_REVISAO
                                          }
                                        />
                                      </div>
                                    </div>
                                  )}

                                  <div
                                    className={`col-md-3 ${
                                      this.state.tipo === "D"
                                        ? "pd-veiculo2"
                                        : "pd-veiculo3"
                                    }`}
                                  >
                                    <div className="form-group form-group-chegoo">
                                      <label>
                                        Balanço <InputRequired />
                                      </label>
                                      <select
                                        className="form-control form-control-chegoo font-size-14 required-chegoo"
                                        value={this.state.balanco}
                                        onChange={(e) =>
                                          this.onChangeInput(
                                            "balanco",
                                            e.target.value === "true"
                                              ? true
                                              : false
                                          )
                                        }
                                        disabled={
                                          this.state.action === "EDITAR" ||
                                          this.state.tipo === "C"
                                        }
                                      >
                                        <option value="true">Sim</option>
                                        <option value="false">Não</option>
                                      </select>
                                    </div>
                                  </div>

                                  {this.state.action === "EDITAR" && (
                                    <div
                                      className={`col-md-2 ${
                                        this.state.tipo === "D"
                                          ? "pd-veiculo"
                                          : "pd-veiculo"
                                      }`}
                                    >
                                      <div className="form-group form-group-chegoo">
                                        <label>Data de Cadastro</label>
                                        <DatePicker
                                          selected={this.state.dtCadastro}
                                          customInput={
                                            <input className="form-control form-control-chegoo font-size-14 text-center required-chegoo" />
                                          }
                                          dateFormat="dd/MM/yyyy HH:mm"
                                          locale="pt-BR"
                                          disabled={true}
                                        />
                                      </div>
                                    </div>
                                  )}
                                </div>

                                <div className="row">
                                  {this.state.situacao === SITUACAO_CANCELADO &&
                                    this.state.obsCancelado !== "" && (
                                      <div className="col-md-3 pd-veiculo2">
                                        <div className="form-group form-group-chegoo">
                                          <label>
                                            Motivo Cancelamento{" "}
                                            <InputRequired />
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            value={this.state.obsCancelado}
                                            disabled={true}
                                          ></input>
                                        </div>
                                      </div>
                                    )}
                                  {this.state.situacao === SITUACAO_REPROVADO &&
                                    this.state.obsReprovacao !== "" && (
                                      <div className="col-md-3 pd-veiculo2">
                                        <div className="form-group form-group-chegoo">
                                          <label>
                                            Motivo Reprovação <InputRequired />
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            value={this.state.obsReprovacao}
                                            disabled={true}
                                          ></input>
                                        </div>
                                      </div>
                                    )}
                                  {this.state.situacao === SITUACAO_REVISAO &&
                                    this.state.obsRevisao !== "" && (
                                      <div className="col-md-3 pd-veiculo2">
                                        <div className="form-group form-group-chegoo">
                                          <label>
                                            Obs Revisão <InputRequired />
                                          </label>
                                          <input
                                            type="text"
                                            className="form-control"
                                            value={this.state.obsRevisao}
                                            disabled={true}
                                          ></input>
                                        </div>
                                      </div>
                                    )}
                                </div>
                                {this.state.base64 === null &&
                                  this.state.existeComprovante && (
                                    <div className="row">
                                      <div className="col-md-12 pd-veiculo2">
                                        <div className="form-group form-group-chegoo">
                                          <label style={{ width: "100%" }}>
                                            Comprovante
                                          </label>
                                          <div class="loadingio-spinner-spinner-vdx4vg3b1fo">
                                            <div class="ldio-746up07poge">
                                              <div></div>
                                              <div></div>
                                              <div></div>
                                              <div></div>
                                              <div></div>
                                              <div></div>
                                              <div></div>
                                              <div></div>
                                              <div></div>
                                              <div></div>
                                              <div></div>
                                              <div></div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                {this.state.base64 !== null && (
                                  <div className="row">
                                    <div className="col-md-12 pd-veiculo2">
                                      <div className="form-group form-group-chegoo">
                                        <label style={{ width: "100%" }}>
                                          Comprovante
                                        </label>
                                        <div style={{ width: "100%" }}>
                                          <img
                                            src={this.state.base64}
                                            style={{ maxWidth: "500px" }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                <div className="row">
                                  <div className="col-md-2">
                                    <div className="form-group form-group-chegoo2">
                                      <button
                                        className="btn btn-success btn-app-verde"
                                        onClick={() => {
                                          this.cadastrarDepesa();
                                        }}
                                        disabled={
                                          this.state.situacao !==
                                            SITUACAO_PENDENTE &&
                                          this.state.situacao !==
                                            SITUACAO_REVISAO
                                        }
                                      >
                                        {this.state.isSaving
                                          ? "Salvando..."
                                          : "Salvar"}
                                      </button>
                                    </div>
                                  </div>

                                  {this.state.action === "EDITAR" &&
                                    this.state.tipo === "D" &&
                                    (this.state.situacao ===
                                      SITUACAO_PENDENTE ||
                                      this.state.situacao ===
                                        SITUACAO_REVISAO) && (
                                      <div className="col-md-2">
                                        <div className="form-group form-group-chegoo2">
                                          <button
                                            className="btn btn-secondary btn-app-cinza font-size-14"
                                            onClick={() => {
                                              this.toggle(
                                                MODAL_ALTERAR_SITUACAO
                                              );
                                            }}
                                          >
                                            Alterar Situação
                                          </button>
                                        </div>
                                      </div>
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>

        <Modal
          isOpen={this.state.modal[MODAL_ALTERAR_SITUACAO]}
          toggle={() => this.toggle(MODAL_ALTERAR_SITUACAO)}
        >
          <ModalHeader toggle={() => this.toggle(MODAL_ALTERAR_SITUACAO)}>
            Alterar Situação do Lançamento
          </ModalHeader>
          <ModalBody>
            <div className="form-group form-grou-chegoo font-size-14">
              <label>Nova Situação</label>
              <select
                className="form-control"
                value={this.state.novaSituacao}
                onChange={(e) =>
                  this.onChangeInput("novaSituacao", e.target.value)
                }
              >
                <option value="99">Selecione</option>
                <option value="0">Pendente</option>
                <option value="2">Aprovado</option>
                <option value="3">Revisão</option>
                <option value="4">Reprovado</option>
                <option value="5">Cancelado</option>
              </select>
            </div>
            {this.state.novaSituacao === SITUACAO_REVISAO && (
              <div className="form-group form-grou-chegoo font-size-14">
                <label>Obs Revisão</label>
                <input
                  type="text"
                  className="form-control font-size-14"
                  value={this.state.obsRevisao}
                  onChange={(e) =>
                    this.onChangeInput("obsRevisao", e.target.value)
                  }
                ></input>
              </div>
            )}
            {this.state.novaSituacao === SITUACAO_REPROVADO && (
              <div className="form-group form-grou-chegoo font-size-14">
                <label>Obs Reprovação</label>
                <input
                  type="text"
                  className="form-control font-size-14"
                  value={this.state.obsReprovacao}
                  onChange={(e) =>
                    this.onChangeInput("obsReprovacao", e.target.value)
                  }
                ></input>
              </div>
            )}
            {this.state.novaSituacao === SITUACAO_CANCELADO && (
              <div className="form-group form-grou-chegoo font-size-14">
                <label>Obs Cancelado</label>
                <input
                  type="text"
                  className="form-control font-size-14"
                  value={this.state.obsCancelado}
                  onChange={(e) =>
                    this.onChangeInput("obsCancelado", e.target.value)
                  }
                ></input>
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              className="btn-modal-recuperar"
              color="secondary"
              onClick={() => this.toggle(MODAL_ALTERAR_SITUACAO)}
            >
              Cancelar
            </Button>
            <Button
              className="btn-modal-recuperar"
              color="primary"
              onClick={this.handleClickConfirmarAlterarSituacao}
              disabled={this.state.novaSituacao === "99"}
            >
              Alterar
            </Button>{" "}
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}
