import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import {
  Login,
  Configuracao,
  UsuarioNovo,
  VeiculoNovo,
  Veiculo,
  Usuario,
  NotificacaoNova,
  DespesaNova,
  Despesa,
  DespesaMotorista,
  Roteiro,
  RoteiroDetalhe,
  RoteiroDetalheVisita,
  Home,
  RoteiroNovo,
  RoteiroDetalheMapa,
  Atividade,
  ChecklistNovo,
  Checklist,
  Perfil,
  PerfilNovo,
  FornecedorNovo,
  Fornecedor,
  OrdemAbastecimento,
  OrdemAbastecimentoNova,
  RoteiroConsulta,
  VeiculoManutencao,
  RoteiroDetalheConsulta,
  VeiculoManutencaoDetalhe,
  Roteirizador,
  Template,
  Template2,
  Template3,
  Template4,
  Notificacao,
  Icone,
  Impressao,
  TipoDespesaNova,
  TipoDespesa,
  Obra,
  PedidoMaterialNovo,
  PedidoMaterial,
  DespesaUsuario,
  NaoConformidade,
  NaoConformidadeNova,
  UnidadeMedidaNova,
  UnidadeMedida
} from "./pages";
import { isAuthenticated, getToken, getUser } from "./auth";
import NavBar from "./components/NavBar";
import SideBar from "./components/SideBar";
import { ToastProvider } from "react-awesome-toasts";
import ObraNova from "./pages/ObraNova";
require("dotenv").config();

const logout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  localStorage.removeItem("imagem");
  window.location.href = `/login`;
};



const getParametersApi = () => {
  return {
    headers: {
      "x-access-token": getToken(),
    },
  };
};

const getBaseUrlApi = () => {
  let urlAtual = window.location.origin;

  if (urlAtual.search("localhost") !== -1) {
    return process.env.REACT_APP_DEV_API_URL_LOCAL;
  }
  let urlEnv = process.env.REACT_APP_DEV_API_URL.split(":")[1];
  urlEnv = urlEnv.replace("//", "").trim();

  if (urlAtual.search(urlEnv) !== -1) {
    return process.env.REACT_APP_DEV_API_URL;
  } else {
    return process.env.REACT_APP_DEV_API_URL_LOCAL;
  }
};

const getBaseUrlRoteirizador = () => {
  return process.env.REACT_APP_DEV_ROTEIRIZADOR_URL;
};

const getBaseUrlImpressao = () => {
  return process.env.REACT_APP_DEV_IMPRESSAO_URL;
};

const handlingErrorsApi = (error, props) => {
  error = JSON.parse(error);
  console.log("error iss > ", error);
  let response = error.response;

  if (error.response) {
    if (response.status && response.status === 401) {
      //logout();
      props.history.push("login");
    } else if (response.status && response.status === 422) {
      //logout();
      props.history.push("login");
    } else {
      console.log("error");
    }
  } else {
    console.log(error);

    console.log("fatal error");
  }
};

const detectIE = () => {
  var ua = window.navigator.userAgent;
  var msie = ua.indexOf("MSIE ");
  if (msie > 0) {
    return true;
  }
  var trident = ua.indexOf("Trident/");
  if (trident > 0) {
    return true;
  }
  return false;
};

const PrivateRoute = ({
  component: Component,
  itemActive,
  titulo,
  icone,
  ...rest
}) => {
  if (detectIE()) {
    return (
      <div className="container">
        <div className="row">
          <div
            className="alert alert-warning"
            role="alert"
            style={{ width: "100%" }}
          >
            <p>
              <b>Alerta</b> : a versão do navegador de internet que você está
              utilizando não é suportada por esta aplicação ou é muito antiga.
            </p>
            <p>
              As versões recomendadas são :{" "}
              <a
                href="https://www.google.com/intl/pt-BR/chrome/"
                target="_blank"
                className="link-alerta"
              >
                Google Chrome
              </a>{" "}
              e{" "}
              <a
                href="http://www.mozilla.org/pt-BR/firefox/"
                target="_blank"
                className="link-alerta"
              >
                Firefox
              </a>
            </p>
          </div>
        </div>
      </div>
    );
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated() ? (
          <div>
            {/* <NavBar titulo={titulo} icone={icone} /> */}
            {/* <SideBar
              itemActive={itemActive}
            /> */}
            <Component
              {...props}
              parameters={getParametersApi}
              user={getUser}
              getBaseUrlApi={getBaseUrlApi}
              getBaseUrlRoteirizador={getBaseUrlRoteirizador}
              getBaseUrlImpressao={getBaseUrlImpressao}
              handlingErrorsApi={handlingErrorsApi}
              logout={logout}
            />
          </div>
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
};

const LoginRoute = ({ component: Component, ...rest }) => {
  if (detectIE()) {
    return (
      <div className="container">
        <div className="row">
          <div
            className="alert alert-warning"
            role="alert"
            style={{ width: "100%" }}
          >
            <p>
              <b>Alerta</b> : a versão do navegador de internet que você está
              utilizando não é suportada por esta aplicação ou é muito antiga.
            </p>
            <p>
              As versões recomendadas são :{" "}
              <a
                href="https://www.google.com/intl/pt-BR/chrome/"
                target="_blank"
                className="link-alerta"
              >
                Google Chrome
              </a>{" "}
              e{" "}
              <a
                href="http://www.mozilla.org/pt-BR/firefox/"
                target="_blank"
                className="link-alerta"
              >
                Firefox
              </a>
            </p>
          </div>
        </div>
      </div>
    );
  }
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated() ? (
          <Redirect
            to={{ pathname: "/home", state: { from: props.location } }}
          />
        ) : (
          <Component
            {...props}
            parameters={getParametersApi}
            getBaseUrlApi={getBaseUrlApi}
            getBaseUrlRoteirizador={getBaseUrlRoteirizador}
            getBaseUrlImpressao={getBaseUrlImpressao}
          />
        )
      }
    />
  );
};

const LogoutRoute = ({ component: Component, ...rest }) => {
  logout();
  return (
    <Route
      {...rest}
      render={(props) => (
        <Redirect
          to={{ pathname: "/login", state: { from: props.location } }}
        />
      )}
    />
  );
};

const NoMatch = ({ location }) => (
  <div>
    <center>
      <h3>
        404! <br />
        essa página não existe
      </h3>
    </center>
  </div>
);

const App = (props) => {
  return (
    <ToastProvider timeout={3000} position={"bottom-left"}>
      <BrowserRouter>
        <Switch>
          <LoginRoute path="/login" exact component={Login} />
          <LogoutRoute path="/logout" exact component={Login} />
          <PrivateRoute
            path="/"
            exact
            component={Home}
            titulo="Indicadores"
            itemActive="indicadores"
            icone="fa-tachometer"
          />
          <PrivateRoute
            path="/home"
            exact
            component={Home}
            titulo="Indicadores"
            itemActive="indicadores"
            icone="fa-tachometer"
          />

          <PrivateRoute
            path="/configuracoes"
            exact
            component={Configuracao}
            titulo="Checklist"
            itemActive="checklist"
            icone="fa-check-square-o"
          />


          <PrivateRoute
            path="/usuarios-novo"
            exact
            component={UsuarioNovo}
            titulo="Usuários / Novo usuário"
            itemActive="usuarios"
            icone="fa-check-square-o"
          />

          <PrivateRoute
            path="/usuarios"
            exact
            component={Usuario}
            titulo="Usuários / Novo usuário"
            itemActive="usuarios"
            icone="fa-check-square-o"
          />


          <PrivateRoute
            path="/despesas-nova"
            exact
            component={DespesaNova}
            titulo="Veículos / Novo veículo"
            itemActive="usuarios"
            icone="fa-check-square-o"
          />

     

          <PrivateRoute
            path="/despesas"
            exact
            component={Despesa}
            titulo="Veículos / Novo veículo"
            itemActive="usuarios"
            icone="fa-check-square-o"
          />

          <PrivateRoute
            path="/despesas-usuario"
            exact
            component={DespesaUsuario}
            titulo="Veículos / Novo veículo"
            itemActive="usuarios"
            icone="fa-check-square-o"
          />

          <PrivateRoute
            path="/impressao"
            exact
            component={Impressao}
            titulo="Usuários / Novo usuário"
            itemActive="usuarios1"
            icone="fa-check-square-o"
          />

          <PrivateRoute
            path="/tipos-despesa-novo"
            exact
            component={TipoDespesaNova}
            titulo="Usuários / Novo usuário"
            itemActive="usuarios1"
            icone="fa-check-square-o"
          />

          <PrivateRoute
            path="/tipos-despesa"
            exact
            component={TipoDespesa}
            titulo="Usuários / Novo usuário"
            itemActive="usuarios1"
            icone="fa-check-square-o"
          />

          <PrivateRoute
            path="/obras"
            exact
            component={Obra}
            titulo="Usuários / Novo usuário"
            itemActive="usuarios1"
            icone="fa-check-square-o"
          />
          <PrivateRoute
            path="/obras-nova"
            exact
            component={ObraNova}
            titulo="Usuários / Novo usuário"
            itemActive="usuarios1"
            icone="fa-check-square-o"
          />

          <PrivateRoute
            path="/pedido-material-novo"
            exact
            component={PedidoMaterialNovo}
            titulo="Usuários / Novo usuário"
            itemActive="usuarios1"
            icone="fa-check-square-o"
          />

          <PrivateRoute
            path="/pedidos-material"
            exact
            component={PedidoMaterial}
            titulo="Usuários / Novo usuário"
            itemActive="usuarios1"
            icone="fa-check-square-o"
          />

          <PrivateRoute
            path="/nao-conformidades"
            exact
            component={NaoConformidade}
            titulo="Usuários / Novo usuário"
            itemActive="usuarios1"
            icone="fa-check-square-o"
          />

          <PrivateRoute
            path="/nao-conformidades-nova"
            exact
            component={NaoConformidadeNova}
            titulo="Usuários / Novo usuário"
            itemActive="usuarios1"
            icone="fa-check-square-o"
          />

          <PrivateRoute
            path="/unidades-medida-nova"
            exact
            component={UnidadeMedidaNova}
            titulo="Usuários / Novo usuário"
            itemActive="usuarios1"
            icone="fa-check-square-o"
          />

          <PrivateRoute
            path="/unidades-medida"
            exact
            component={UnidadeMedida}
            titulo="Usuários / Novo usuário"
            itemActive="usuarios1"
            icone="fa-check-square-o"
          />

          <Route component={NoMatch} />
        </Switch>
      </BrowserRouter>
    </ToastProvider>
  );
};

export default App;
