const isAuthenticated = () => {
  // return true;
  if (getToken()) {
    return true;
  } else {
    return false;
  }
};

const logout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  localStorage.removeItem("imagem");
};

const login = (token) => {
  localStorage.setItem("token", token);
};

const getToken = () => {
  return localStorage.getItem("token");
};

const getUser = () => {
  return localStorage.getItem("user");
};

module.exports = { isAuthenticated, logout, login, getToken, getUser };
