import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import { SideBar, NavBar, TitleAndDescription, Footer } from "../../components";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Switch from "react-switch";
import axios from "axios";
import { truncateWithEllipsis } from "@amcharts/amcharts4/.internal/core/utils/Utils";
import queryString from "query-string";
import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from "reactstrap";
import { CurrencyInput } from "../../components";
const { v4: uuidv4 } = require("uuid");
const { phoneMask } = require("../../utils");

export default class UsuarioNovo extends Component {
  constructor(props) {
    super(props);
    this.notificar = this.notificar.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
    this.onChangeSwitch = this.onChangeSwitch.bind(this);
    this.load = this.load.bind(this);
    this.onChangeCargo = this.onChangeCargo.bind(this);
    this.cadastrarUsuario = this.cadastrarUsuario.bind(this);
    this.validarUsuario = this.validarUsuario.bind(this);
    this.buscarUsuario = this.buscarUsuario.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleClickDeletar = this.handleClickDeletar.bind(this);
    this.handleClickConfirmaDeletar =
      this.handleClickConfirmaDeletar.bind(this);
    this.handleFocus = this.handleFocus.bind(this);

    this.state = {
      _id: null,
      action: "CADASTRAR",
      desc: "",
      funcao: "administrativo",
      celular: "",
      cargo: null,
      acessoApp: false,
      acessoAdm: false,
      login: "",
      senha: "",
      senhaRepetir: "",
      cargos: [],
      saldo: "0,00",
      errors: {
        usuario: false,
        funcao: false,
        celular: false,
        cargo: false,
        login: false,
        senha: false,
        senhaRepetir: false,
      },
      usuarioDeletar: null,
      modal: false,
      data: moment().startOf("month").toDate(),
      nivel: "1",
      usuario: {
        nivel: "2",
      },
      isSaving: false,
    };
  }

  async componentDidMount() {
    const userLogado = JSON.parse(localStorage.getItem("usuario"));
    if (userLogado.nivel === "3") {
      return this.props.history.push("/pedidos-material");
    }
    if (userLogado.nivel === "4") {
      return this.props.history.push("/nao-conformidades");
    }
    if (userLogado.nivel === "5") {
      return this.props.history.push("/despesas");
    }
    let id = queryString.parse(this.props.location.search).id;
    let usuarioStorage = JSON.parse(localStorage.getItem("usuario"));

    this.setState({ usuario: usuarioStorage }, () => {
      console.log(this.state.usuario);
    });

    if (id !== undefined) {
      await this.buscarUsuario(id);
    }

    await this.load();
  }

  async load() {}

  async buscarUsuario(id) {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/retaguarda/usuarios/${id}`,
        this.props.parameters()
      )
      .then((response) => {
        const usuario = response.data.usuario;
        let nivelUsuario = JSON.parse(localStorage.getItem("usuario")).nivel;

        if (usuario.nivel === "0" && nivelUsuario !== "0") {
          return this.props.history.push("/usuarios");
        }

        this.setState({
          _id: usuario._id,
          desc: usuario.desc,
          funcao: usuario.funcao,
          nivel: usuario.nivel,
          celular: usuario.celular,
          login: usuario.login,
          saldo: usuario.saldo.toString().replace(".", ","),
          data: moment(usuario.dtInicioBalanco).toDate(),
          action: "EDITAR",
        });
      });
  }

  notificar(type, message) {
    toast[type](message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  onChangeInput(tipo, value) {
    if (tipo === "celular") {
      value = phoneMask(value);
    }
    this.setState({ [tipo]: value });
  }

  onChangeSwitch(checked, tipo) {
    this.setState({ [tipo]: checked });
  }

  onChangeCargo(value) {
    this.setState({ cargo: value });
  }

  validarUsuario() {
    let errors = this.state.errors;
    let valido = true;

    if (this.state.desc === "") {
      valido = false;
      errors.desc = true;
    } else {
      errors.desc = false;
    }

    if (this.state.celular === "") {
      valido = false;
      errors.celular = true;
    } else {
      errors.celular = false;
    }

    if (this.state.login === "") {
      valido = false;
      errors.login = true;
    } else {
      errors.login = false;
    }

    if (this.state.action === "CADASTRAR") {
      if (this.state.senha === "") {
        valido = false;
        errors.senha = true;
      } else {
        errors.senha = false;
      }

      if (this.state.senhaRepetir === "") {
        valido = false;
        errors.senhaRepetir = true;
      } else {
        errors.senhaRepetir = false;
      }
    }

    this.setState({ errors });
    return valido;
  }

  async cadastrarUsuario() {
    if (this.state.isSaving) {
      return false;
    }
    this.setState({ isSaving: true }, async () => {
      if (!this.validarUsuario()) {
        this.notificar("warning", "Favor preencher todos os dados!");
        this.setState({ isSaving: false });
        return false;
      }

      if (this.state.senha !== this.state.senhaRepetir) {
        this.notificar("warning", "As senhas não conferem");
        return false;
      }

      let saldo = this.state.saldo;
      saldo = saldo.replace("R$", "");
      saldo = saldo.replace(",", ".");
      saldo = parseFloat(saldo);

      let usuarioNovo = {
        desc: this.state.desc,
        celular: this.state.celular,
        funcao: this.state.funcao,
        nivel: this.state.nivel,
        login: this.state.login,
        senha: this.state.senha,
        dtInicioBalanco: moment(this.state.data).startOf("day").toDate(),
        saldo,
      };

      if (this.state.action === "EDITAR") {
        usuarioNovo._id = this.state._id;
      }

      let urlAtualizar = `${this.props.getBaseUrlApi()}/retaguarda/usuarios`;

      if (this.state._id !== null) {
        urlAtualizar = `${this.props.getBaseUrlApi()}/retaguarda/usuarios/${
          this.state._id
        }`;
      }

      await axios
        .post(urlAtualizar, { usuario: usuarioNovo }, this.props.parameters())
        .then((response) => {
          if (this.state.action === "CADASTRAR") {
            this.notificar("success", "Usuário cadastrado com sucesso!");
          } else {
            this.notificar("success", "Usuário editado com sucesso!");
          }

          setTimeout(() => {
            this.props.history.push("/usuarios");
          }, 1000);
        })
        .catch((error) => {
          this.notificar("warning", error.response.data.erro);
          this.setState({ isSaving: false });
        });
    });
  }

  handleClickDeletar(id) {
    this.setState({ usuarioDeletar: id, modal: true });
  }

  toggle() {
    this.setState({ modal: !this.state.modal });
  }

  async handleClickConfirmaDeletar() {
    await axios
      .delete(
        `${this.props.getBaseUrlApi()}/retaguarda/usuarios/${
          this.state.usuarioDeletar
        }`,
        this.props.parameters()
      )
      .then((response) => {
        this.setState({ usuarioDeletar: null, modal: false }, () => {
          this.notificar("success", "Usuário deletado com sucesso!");
          setTimeout(() => {
            this.props.history.push("/usuarios");
          }, 1000);
        });
      })
      .catch((error) => {
        this.setState({ usuarioDeletar: null, modal: false }, () => {
          this.notificar("warning", error.response.data.erro);
        });
      });
  }

  handleFocus(e) {
    e.target.select();
  }

  render() {
    const InputRequired = () => {
      return <span className="required-icone">*</span>;
    };

    return (
      <React.Fragment>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
        <div className="container-scroller font-app">
          <NavBar
            title={`Usuários / ${
              this.state.action === "CADASTRAR" ? "Novo" : "Editar"
            } usuário`}
          />
          <div className="container-fluid page-body-wrapper">
            <SideBar itemActive={"usuarios"} />
            <div className="main-panel">
              <div className="content-wrapper">
                {/* <TitleAndDescription
                  title="Usuários / Novo usuário"
                  description=""
                /> */}

                <div className="row">
                  <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body5 dashboard-tabs p-0">
                        <div className="tab-content py-0 px-0">
                          <div
                            className="tab-pane fade show active"
                            id="overview"
                            role="tabpanel"
                            aria-labelledby="overview-tab"
                          >
                            <div className="d-flex flex-wrap justify-content-xl-between">
                              <div
                                style={{
                                  padding: "10px",
                                  float: "left",
                                  width: "100%",
                                }}
                              >
                                {/* <div
                                  className="float-left width-100"
                                  style={{
                                    borderBottom: "1px solid #CCC",
                                    padding: "5px",
                                    marginBottom: "20px",
                                  }}
                                >
                                  DADOS DE USUÁRIO
                                </div> */}
                                <div className="row">
                                  <div className="col-md-4 pd-veiculo2">
                                    <div className="row">
                                      <div className="col-md-12">
                                        <div className="form-group form-group-chegoo ">
                                          <label>
                                            Usuário
                                            <InputRequired />
                                          </label>
                                          <input
                                            type="text"
                                            value={this.state.desc}
                                            label="Nome do usuário"
                                            className={`form-control form-control-chegoo font-size-14 required-chegoo`}
                                            onChange={(e) =>
                                              this.onChangeInput(
                                                "desc",
                                                e.target.value
                                              )
                                            }
                                            ref={(node) => {
                                              if (node) {
                                                if (this.state.errors.desc) {
                                                  node.style.setProperty(
                                                    "border-color",
                                                    "red",
                                                    "important"
                                                  );
                                                } else {
                                                  node.style.setProperty(
                                                    "border-color",
                                                    "#ced4da",
                                                    "important"
                                                  );
                                                }
                                              }
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-8 pd-veiculo">
                                    <div className="row">
                                      <div className="col-md-4">
                                        <div className="form-group form-group-chegoo">
                                          <label>
                                            Função <InputRequired />
                                          </label>
                                          <select
                                            className="form-control font-size-14 required-chegoo"
                                            value={this.state.funcao}
                                            onChange={(e) =>
                                              this.onChangeInput(
                                                "funcao",
                                                e.target.value
                                              )
                                            }
                                          >
                                            <option value="administrativo">
                                              Administrativo
                                            </option>
                                            <option value="mestreObras">
                                              Mestre Obras
                                            </option>
                                          </select>
                                        </div>
                                      </div>

                                      <div className="col-md-4">
                                        <div className="form-group form-group-chegoo">
                                          <label>
                                            Celular <InputRequired />
                                          </label>
                                          <input
                                            type="text"
                                            value={this.state.celular}
                                            label="email@email.com.br"
                                            className={`form-control form-control-chegoo font-size-14 required-chegoo`}
                                            onChange={(e) =>
                                              this.onChangeInput(
                                                "celular",
                                                e.target.value
                                              )
                                            }
                                            ref={(node) => {
                                              if (node) {
                                                if (this.state.errors.celular) {
                                                  node.style.setProperty(
                                                    "border-color",
                                                    "red",
                                                    "important"
                                                  );
                                                } else {
                                                  node.style.setProperty(
                                                    "border-color",
                                                    "#ced4da",
                                                    "important"
                                                  );
                                                }
                                              }
                                            }}
                                          />
                                        </div>
                                      </div>

                                      <div className="col-md-4 pd-veiculo3">
                                        <div className="form-group form-group-chegoo">
                                          <label>
                                            Nível <InputRequired />
                                          </label>
                                          <select
                                            className={`form-control form-control-chegoo font-size-14 required-chegoo`}
                                            // onChange={(e) =>
                                            //   this.onChangeCargo(e.target.value)
                                            // }
                                            onChange={(e) =>
                                              this.onChangeInput(
                                                "nivel",
                                                e.target.value
                                              )
                                            }
                                            value={this.state.nivel}
                                            disabled={
                                              this.state.action === "EDITAR" &&
                                              this.state.usuario.nivel !== "0"
                                            }

                                            // ref={(node) => {
                                            //   if (node) {
                                            //     if (this.state.errors.cargo) {
                                            //       node.style.setProperty(
                                            //         "border-color",
                                            //         "red",
                                            //         "important"
                                            //       );
                                            //     }else{
                                            //       node.style.setProperty(
                                            //         "border-color",
                                            //         "#ced4da",
                                            //         "important"
                                            //       );
                                            //     }
                                            //   }
                                            // }}
                                          >
                                            <option value="1">App</option>
                                            {/* <option value="2">
                                              Retaguarda
                                            </option> */}
                                            <option value="3">Logística</option>
                                            <option value="4">Qualidade</option>
                                            <option value="5">
                                              Financeiro
                                            </option>
                                            {this.state.usuario.nivel ===
                                              "0" && (
                                              <option value="0">Admin</option>
                                            )}
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {this.state.nivel !== "2" &&
                                  this.state.nivel !== "3" &&
                                  this.state.nivel !== "4" &&
                                  this.state.nivel !== "5" && (
                                    <div className="row">
                                      <div className="col-md-4 pd-veiculo2">
                                        <div className="form-group form-group-chegoo">
                                          <label>
                                            Data Ínicio Balanço
                                            <InputRequired />
                                          </label>
                                          <DatePicker
                                            selected={this.state.data}
                                            customInput={
                                              <input className="form-control form-control-chegoo font-size-14 text-center required-chegoo" />
                                            }
                                            dateFormat="dd/MM/yyyy"
                                            onChange={(date) => {
                                              this.setState({ data: date });
                                            }}
                                            disabled={
                                              this.state.action === "EDITAR"
                                            }
                                            locale="pt-BR"
                                          />
                                        </div>
                                      </div>
                                      {/* <div className="col-md-3 pd-veiculo2">
                                      <div className="form-group form-group-chegoo">
                                        <label>
                                          Saldo
                                          <InputRequired />
                                        </label>
                                        <CurrencyInput
                                          placeholder="R$0,00"
                                          type="text"
                                          value={this.state.saldo}
                                          onChange={(e) => {
                                            this.onChangeInput(
                                              "saldo",
                                              e.target.value
                                            );
                                          }}
                                          onFocus={this.handleFocus}
                                          className={`font-size-14 form-control-chegoo text-center required-chegoo ${
                                            this.state.errors.valor
                                              ? "input-error"
                                              : ""
                                          }`}
                                          disabled={
                                            this.state.action === "EDITAR"
                                          }
                                          onBlur={(e) => {
                                            let valor = this.state.saldo;
                                            valor = valor.replace("R$", "");
                                            valor = valor.replace(",", ".");
                                            valor = parseFloat(valor);
                                            this.setState({
                                              saldo: valor
                                                .toFixed(2)
                                                .replace(".", ","),
                                            });
                                          }}

                                          // ref={(node) => {
                                          //   if (node) {
                                          //     if (this.state.errors.valor) {
                                          //       node.style.setProperty(
                                          //         "border-color",
                                          //         "red",
                                          //         "important"
                                          //       );
                                          //     }else{
                                          //       node.style.setProperty(
                                          //         "border-color",
                                          //         "#ced4da",
                                          //         "important"
                                          //       );
                                          //     }
                                          //   }
                                          // }}
                                        />
                                      </div>
                                    </div> */}
                                    </div>
                                  )}

                                <div
                                  className="float-left width-100"
                                  style={{
                                    borderBottom: "1px solid #CCC",
                                    // padding: "5px",
                                    marginBottom: "10px",
                                    marginTop: "5px",
                                    fontSize: "16px",
                                  }}
                                >
                                  DADOS DE ACESSO
                                </div>

                                <div className="row">
                                  <div className="col-md-4 pd-veiculo2">
                                    <div className="row">
                                      <div className="col-md-12">
                                        <div className="form-group form-group-chegoo">
                                          <label>
                                            Login
                                            <InputRequired />
                                          </label>
                                          <input
                                            type="text"
                                            value={this.state.login}
                                            label="Nome do usuário"
                                            className={`form-control form-control-chegoo font-size-14 required-chegoo`}
                                            onChange={(e) =>
                                              this.onChangeInput(
                                                "login",
                                                e.target.value
                                              )
                                            }
                                            ref={(node) => {
                                              if (node) {
                                                if (this.state.errors.login) {
                                                  node.style.setProperty(
                                                    "border-color",
                                                    "red",
                                                    "important"
                                                  );
                                                } else {
                                                  node.style.setProperty(
                                                    "border-color",
                                                    "#ced4da",
                                                    "important"
                                                  );
                                                }
                                              }
                                            }}
                                            disabled={
                                              this.state.action === "EDITAR"
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-6 pd-veiculo font-size-14">
                                    <div className="row">
                                      <div className="col-md-6">
                                        <div className="form-group form-group-chegoo">
                                          <label>
                                            Senha
                                            <InputRequired />
                                          </label>
                                          <input
                                            type="password"
                                            value={this.state.senha}
                                            label="email@email.com.br"
                                            className={`form-control form-control-chegoo font-size-14 required-chegoo`}
                                            onChange={(e) =>
                                              this.onChangeInput(
                                                "senha",
                                                e.target.value
                                              )
                                            }
                                            ref={(node) => {
                                              if (node) {
                                                if (this.state.errors.senha) {
                                                  node.style.setProperty(
                                                    "border-color",
                                                    "red",
                                                    "important"
                                                  );
                                                } else {
                                                  node.style.setProperty(
                                                    "border-color",
                                                    "#ced4da",
                                                    "important"
                                                  );
                                                }
                                              }
                                            }}
                                          />
                                          <small>
                                            Para permanecer a senha atual, deixe
                                            o campo em branco
                                          </small>
                                        </div>
                                      </div>

                                      <div className="col-md-6 pd-veiculo3">
                                        <div className="form-group form-group-chegoo">
                                          <label>
                                            Repita a senha
                                            <InputRequired />
                                          </label>
                                          <input
                                            type="password"
                                            value={this.state.senhaRepetir}
                                            label="email@email.com.br"
                                            className={`form-control form-control-chegoo font-size-14 required-chegoo`}
                                            onChange={(e) =>
                                              this.onChangeInput(
                                                "senhaRepetir",
                                                e.target.value
                                              )
                                            }
                                            ref={(node) => {
                                              if (node) {
                                                if (
                                                  this.state.errors.senhaRepetir
                                                ) {
                                                  node.style.setProperty(
                                                    "border-color",
                                                    "red",
                                                    "important"
                                                  );
                                                } else {
                                                  node.style.setProperty(
                                                    "border-color",
                                                    "#ced4da",
                                                    "important"
                                                  );
                                                }
                                              }
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="row">
                                  {/* <div className="col-md-8"></div> */}
                                  {/* <div className="col-md-2 no-padding text-right">
                                    <div className="form-group">
                                      <button
                                        className="btn btn-danger btn-app-vermelho"
                                        onClick={() => {
                                          this.handleClickDeletar(
                                            this.state._id
                                          );
                                        }}
                                        disabled={
                                          this.state._id === null ? true : false
                                        }
                                      >
                                        Deletar
                                      </button>
                                    </div>
                                  </div>{" "} */}
                                  <div className="col-md-2 pd-veiculo2">
                                    <div className="form-group form-group-chegoo2">
                                      <button
                                        className="btn btn-success btn-app-verde"
                                        onClick={this.cadastrarUsuario}
                                      >
                                        {this.state.isSaving
                                          ? "Salvando..."
                                          : "Salvar"}
                                      </button>
                                    </div>
                                  </div>
                                </div>

                                {/* felipe */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Deletar usuário</ModalHeader>
          <ModalBody>Tem certeza que deseja deletar este usuário?</ModalBody>
          <ModalFooter>
            <Button
              className="btn-modal-recuperar"
              color="secondary"
              onClick={this.toggle}
            >
              Não
            </Button>
            <Button
              className="btn-modal-recuperar"
              color="primary"
              onClick={this.handleClickConfirmaDeletar}
            >
              Sim
            </Button>{" "}
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}
