import React, { Component } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { SideBar, NavBar, Footer, TitleAndDescription } from "../../components";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  UncontrolledTooltip,
} from "reactstrap";
import Select from "react-select";
const { formatarReal } = require("../../utils");

class Template3 extends Component {
  constructor(props) {
    super(props);
    this.load = this.load.bind(this);
    this.loadTemplates = this.loadTemplates.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleClickDeletar = this.handleClickDeletar.bind(this);
    this.handleClickConfirmaDeletar =
      this.handleClickConfirmaDeletar.bind(this);
    this.loadXmlsBanco = this.loadXmlsBanco.bind(this);
    this.onClickVisualizarDanfe = this.onClickVisualizarDanfe.bind(this);
    this.selecionarItem = this.selecionarItem.bind(this);
    this.gerarRoteiros = this.gerarRoteiros.bind(this);
    this.toggleModalRoteiro = this.toggleModalRoteiro.bind(this);
    this.handleChangeRoteiroModal = this.handleChangeRoteiroModal.bind(this);
    this.handleClickSelecionarRoteiro =
      this.handleClickSelecionarRoteiro.bind(this);
    this.handleClickSalvarRoteiroEntrega =
      this.handleClickSalvarRoteiroEntrega.bind(this);
    this.loadMotoristas = this.loadMotoristas.bind(this);
    this.loadVeiculos = this.loadVeiculos.bind(this);
    this.handleChangeMotoristaVeiculo =
      this.handleChangeMotoristaVeiculo.bind(this);
    this.loadCallbackRoteirizador = this.loadCallbackRoteirizador.bind(this);
    this.felipe = this.felipe.bind(this);
    this.handleClickGerarRota = this.handleClickGerarRota.bind(this);
    this.getLetraAlfabeto = this.getLetraAlfabeto.bind(this);
    this.toggleModal2 = this.toggleModal2.bind(this);
    this.validarRoteiros = this.validarRoteiros.bind(this);

    this.iframeRef = React.createRef();

    this.state = {
      templates: [],
      templateDeletar: null,
      modal: false,
      xmlsBanco: [],
      modalRoteiro: false,
      roteiroSelecionado: null,
      idRoteiroSelecinado: null,
      entregaSelecionada: null,
      motoristaSelecionado: null,
      motoristas: [],
      veiculoSelecionado: null,
      veiculos: [],
      roteiros: [],
      lista: [],
      modal2: false,
      itemSelecionadoMapa: null,
    };
  }

  async componentDidMount() {
    await axios
      .post(
        `${this.props.getBaseUrlRoteirizador()}/limpar-rotas`,
        {},
        this.props.parameters()
      )
      .then((response) => {});

    let roteiros = localStorage.getItem("roteirosAgrupados");
    if (roteiros === null) {
      return this.props.history.push("/roteiro");
    }

    roteiros = JSON.parse(roteiros);

    this.setState({ roteiros });

    await this.load();
    // await this.loadXmlsBanco();
  }

  async load() {
    await this.loadMotoristas();
    await this.loadVeiculos();
    await this.loadCallbackRoteirizador();

    // handleClickGerarRota
    let roteiros = this.state.roteiros;

    for (let i = 0; i < roteiros.length; i++) {
      if (i === 0) {
        console.error("executou 1");

        await this.handleClickGerarRota(roteiros[i]._id);
      } else {
        console.error("executou 2");
        setTimeout(async () => {
          await this.handleClickGerarRota(roteiros[i]._id);
        }, 1500);
      }
    }

    // let roteiros = this.state.roteiros;
    // for (let item of roteiros) {
    //   let xmls = [];
    //   for (let xml of item.itens) {
    //     xmls.push(xml.xml);
    //   }

    //   setTimeout(() => {
    //     this.setState({ itemSelecionadoMapa: item._id }, () => {
    //       this.felipe(xmls);
    //     });

    //   }, 3000);
    // }
  }

  async loadTemplates() {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/retaguarda/templates-roteiros`,
        this.props.parameters()
      )
      .then((response) => {
        let templates = response.data.templates;
        //alert(JSON.stringify(templates))
        this.setState({ templates });
      });
  }

  notificar(type, message) {
    toast[type](message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  handleClickDeletar(id) {
    this.setState({ templateDeletar: id, modal: true });
  }

  toggle() {
    this.setState({ modal: !this.state.modal });
  }

  async handleClickConfirmaDeletar() {
    await axios
      .delete(
        `${this.props.getBaseUrlApi()}/retaguarda/templates/${
          this.state.templateDeletar
        }`,
        this.props.parameters()
      )
      .then((response) => {
        this.setState({ templateDeletar: null, modal: false }, () => {
          this.loadTemplates();
          this.notificar("success", "Template deletado com sucesso!");
        });
      })
      .catch((error) => {
        this.setState({ templateDeletar: null, modal: false }, () => {
          this.notificar("warning", error.response.data.erro);
        });
      });
  }

  async loadXmlsBanco() {
    let lista = [];
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/retaguarda/xmls-banco`,
        this.props.parameters()
      )
      .then((response) => {
        let lista = response.data.xmls;
        ///////console.clear();
        console.log("aaaaa", lista);
        lista = lista.map((it) => {
          it.selecionado = false;
          it.dtGravacaoObject = new Date(it.dtEmissao);
          return it;
        });

        lista = lista.sort(function (a, b) {
          return a.dtGravacaoObject.getTime() - b.dtGravacaoObject.getTime();
        });

        for (let i = 0; i < lista.length; i++) {
          lista[i].valido = true;

          if (i === 1) {
            lista[i].valido = false;
          }
        }

        let templates = this.state.templates;

        for (let item of lista) {
          item.selecionado = false;
          item.roteiro = null;

          for (let template of templates) {
            for (let cliente of template.clientes) {
              if (cliente._id === item.cliente._id) {
                item.roteiro = {
                  _id: template._id,
                  cod: template.cod,
                  desc: template.desc,
                };
                continue;
              }
            }
          }

          if (item.roteiro === null) {
            item.roteiro = {
              _id: templates[0]._id,
              cod: templates[0].cod,
              desc: templates[0].desc,
            };
          }
        }

        let novaListaRoteiros = [];

        novaListaRoteiros.push({
          seq: 102030,
          roteiro: "1.1 - Joinvile e Região",
          entregas: 3,
        });
        novaListaRoteiros.push({
          seq: 102031,
          roteiro: "1.2 - Roteiro Semanal",
          entregas: 1,
        });

        // ////console.clear()
        console.log(lista);

        this.setState({ xmlsBanco: novaListaRoteiros });
      });
  }

  async onClickVisualizarDanfe(xml) {
    await axios
      .post(
        `${this.props.getBaseUrlRoteirizador()}/atualizar-danfe`,
        { xml },
        this.props.parameters()
      )
      .then((response) => {
        window.open(
          `${this.props.getBaseUrlRoteirizador()}/visualizador-danfe.html`,
          "_blank"
        );
      })
      .catch((error) => {
        this.notificar("warning", error.response.data.erro);
      });
  }

  selecionarItem(id, value) {
    let lista = this.state.xmlsBanco;

    for (let item of lista) {
      if (item._id === id) {
        item.selecionado = value;
      }
    }

    this.setState({ xmlsBanco: lista });
  }

  validarRoteiros(roteiros) {
    const motoristas = new Set();
    const veiculos = new Set();

    for (const item of roteiros) {
      if (item.motorista === null || item.veiculo === null) {
        return false;
      }
      const motoristaId = item.motorista._id;
      const veiculoId = item.veiculo._id;

      if (motoristas.has(motoristaId) || veiculos.has(veiculoId)) {
        return false;
      }

      motoristas.add(motoristaId);
      veiculos.add(veiculoId);
    }
    return true;
  }

  async gerarRoteiros() {
    let roteiros = this.state.roteiros;
    let listaIdsXmls = [];

    if (!this.validarRoteiros(roteiros)) {
      this.notificar(
        "warning",
        "Não é permitido repetir motorista ou veículo entre os roteiros."
      );
      return false;
    }

    for (let roteiro of roteiros) {
      let nomeRoteiro = roteiro.roteiro;
      let veiculoSelecionado = roteiro.veiculo;
      let motoristaSelecionado = roteiro.motorista;
      let data = new Date();

      if (veiculoSelecionado === null) {
        this.notificar("warning", "Favor selecionar um veículo!");
        return false;
      }
      if (motoristaSelecionado === null) {
        this.notificar("warning", "Favor selecionar um motorista!");
        return false;
      }

      let docNovoRoteiro = {
        type: "roteiro",
        tipo: "sequencial",
        cod: new Date().getTime(),
        desc: nomeRoteiro,
        status: "pendente",
        dtGravacao: new Date(),
        dtPrevisao: data,
        motorista: {
          _id: motoristaSelecionado._id,
          desc: motoristaSelecionado.desc,
        },
        veiculo: {
          _id: veiculoSelecionado._id,
          desc: veiculoSelecionado.desc,
          placa: veiculoSelecionado.placa,
        },
        visitas: [],
      };

      let sequenciaVisita = 1;

      let lista = roteiro.lista;
      let visita = null;

      for (let item2 of lista) {
        if (item2.exibirCobrar) {
          console.log("hhhhhhhhhhhhhhh", item2.produtos);
          console.log("hhhhhhhhhhhhhhh2222", item2.dadosNota);

          visita = {
            codVisita: `${sequenciaVisita}${new Date().getTime().toString()}`,
            seq: sequenciaVisita,
            operacao: "entregar",
            status: "pendente",
            dtEntrega: data,
            obsEntrega: "",
            duracao: "",
            cliente: {
              _id: Math.random().toString().split(".")[1],
              desc: item2.cliente,
              cnpj: item2.cnpj,
              obs: "",
            },
            endereco: {
              end: item2.enderecoCompleto.rua,
              num: item2.enderecoCompleto.numero,
              compl: "",
              bairro: item2.enderecoCompleto.bairro,
              cidade: item2.enderecoCompleto.cidade,
              uf: item2.enderecoCompleto.estado,
              cep: item2.enderecoCompleto.cep,
              coordenadas: {
                lat: item2.coordenadas.lat.toString(),
                long: item2.coordenadas.lng.toString(),
              },
            },
            documentos: [],
            cobranca: {
              cobrar: item2.cobrar,
              valorCobrar: 100,
              recebidoDin: 0,
              recebidoChe: 0,
              recebidoCar: 0,
            },
            recebedor: {
              nome: "",
              cpf: "",
              assinatura: "",
              coordenadas: null,
            },
            canhoto: {
              foto: "",
              valido: false,
            },
            ocorrencias: [],
            volumes: [],
            naoConformidades: [],
          };
          sequenciaVisita = sequenciaVisita + 1;

          console.log("xxxxxxxxxxxxxxxxxxxxxxxx", visita);

          ///aqui adiciona produtos
          let seqItem = 1;

          for (let itemNota of item2.produtos) {
            visita.volumes.push({
              seq: seqItem,
              cod: itemNota.cod,
              desc: itemNota.desc,
              qtd: itemNota.qtd,
              status: itemNota.status,
            });

            seqItem = seqItem + 1;
          }

          ///aqui adiciona produtos

          //aqui adiciona os dados da nota

          visita.documentos.push({
            nro: item2.dadosNota.numeroNf,
            tipo: "nfe",
            valor: item2.dadosNota.valorTotalNota,
            dtEmissao: item2.dadosNota.dtEmissao,
            volumes: item2.produtos.length,
            obs: "",
            danfe: "http://www.google.com",
          });

          //aqui adiciona os dados da nota

          visita.dadosXml = {
            dadosNota: item2.dadosNota,
            xml: item2.xml,
          };

          docNovoRoteiro.visitas.push(visita);
        }
      }

      console.log(docNovoRoteiro);

      this.notificar("success", "Roteiro cadastrado com sucesso!");

      await axios
        .post(
          `${this.props.getBaseUrlApi()}/retaguarda/cadastrar-roteiro`,
          { doc: docNovoRoteiro },
          this.props.parameters()
        )
        .then((response) => {});

      //this.atualizaSequenciaRoteiro();

      await axios
        .post(
          `${this.props.getBaseUrlRoteirizador()}/limpar-rotas`,
          {},
          this.props.parameters()
        )
        .then((response) => {});
    }

    for (let item of roteiros) {
      for (let item2 of item.itens) {
        listaIdsXmls.push(item2._id);
      }
    }

    await axios
      .post(
        `${this.props.getBaseUrlApi()}/retaguarda/baixar-xmls`,
        { lista: listaIdsXmls },
        this.props.parameters()
      )
      .then((response) => {
        localStorage.removeItem("roteirosAgrupados");

        //window.location.reload();
        return this.props.history.push("/roteiro3");
      })
      .catch((error) => {
        this.notificar("warning", error.response.data.erro);
      });
  }

  toggleModalRoteiro() {
    this.setState({ modalRoteiro: !this.state.modalRoteiro });
  }

  handleChangeRoteiroModal(idRoteiro) {
    let roteiros = this.state.templates;

    let roteiroFind = roteiros.find((it) => it._id === idRoteiro);

    this.setState(
      {
        roteiroSelecionado: roteiroFind,
        idRoteiroSelecinado: idRoteiro,
      },
      () => {
        console.log("FFFFFF", this.state);
      }
    );
  }

  handleClickSelecionarRoteiro(entrega) {
    this.setState(
      {
        roteiroSelecionado: null,
        idRoteiroSelecinado: null,
        entregaSelecionada: entrega,
      },
      () => {
        this.toggleModalRoteiro();
      }
    );
  }

  handleClickSalvarRoteiroEntrega() {
    let lista = this.state.xmlsBanco;
    let entregaSelecionada = this.state.entregaSelecionada;
    let roteiroSelecionado = this.state.roteiroSelecionado;

    for (let item of lista) {
      if (item._id === entregaSelecionada._id) {
        item.roteiro = {
          cod: roteiroSelecionado.cod,
          desc: roteiroSelecionado.desc,
        };
      }
    }

    this.setState({ xmlsBanco: lista, entregaSelecionada: null }, () => {
      this.notificar("success", "Roteiro selecionado para esta entrega!");
      this.toggleModalRoteiro();
    });
  }

  async loadMotoristas() {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/retaguarda/usuarios`,
        this.props.parameters()
      )
      .then((response) => {
        console.log("lililll", response.data);

        let motoristas = response.data.usuarios;
        motoristas = motoristas.map((it) => {
          it.value = it._id;
          it.label = it.desc;
          return it;
        });

        this.setState({ motoristas });
      });
  }

  async loadVeiculos() {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/retaguarda/veiculos`,
        this.props.parameters()
      )
      .then((response) => {
        console.log(response.data.veiculos);

        let veiculos = response.data.veiculos;
        veiculos = veiculos.map((it) => {
          it.value = it._id;
          it.label = it.desc;
          return it;
        });

        this.setState({ veiculos });
      });
  }

  handleChangeMotoristaVeiculo(idRoteiro, tipo, value) {
    let roteiros = this.state.roteiros;
    for (let item of roteiros) {
      if (item._id === idRoteiro) {
        item[tipo] = value;
      }
    }
    this.setState({ roteiros });
  }

  async loadCallbackRoteirizador() {
    window.addEventListener("message", (event) => {
      if (event.origin === this.props.getBaseUrlRoteirizador()) {
        //console.log(event.data);
        let data = JSON.parse(event.data);
        let lista = [];

        let waypointsOrder = data.routes[0].waypoint_order;

        let primeiroItem = data.request.origin.query;

        let itemPush2 = {
          _id: Math.random().toString().split(".")[1],
          endereco: primeiroItem,
          distancia: null,
          duracao: null,
          enderecoPartida: null,
          cliente: "",
          telefone: "",
          xml: null,
          exibirCobrar: false,
          coordenadas: null,
          enderecoCompleto: null,
        };

        lista.push(itemPush2);

        //return false;

        for (let i = 0; i < data.routes[0].legs.length; i++) {
          let item = data.routes[0].legs[i];
          console.log(item.end_address);

          let itemPush = {
            _id: Math.random().toString().split(".")[1],
            endereco: item.end_address,
            distancia: item.distance.text,
            duracao: item.duration.text,
            enderecoPartida: item.start_address,
            cliente: "",
            telefone: "",
            xml: null,
            exibirCobrar: false,
            cobrar: true,
            coordenadas: item.end_location,
          };

          console.log("lalalalalal", itemPush);

          if (this.state.listaXml.length > 0) {
            // if (i !== data.routes[0].legs.length - 1) {
            let indexLista = waypointsOrder[i];
            let listaXml = this.state.listaXml;

            let itemXml = listaXml[indexLista];

            if (itemXml !== undefined) {
              let teste22 = Object.assign({}, itemXml);
              delete teste22.xml;

              console.log("lkjh", JSON.stringify(teste22));

              //return false;

              itemPush.cliente = itemXml.nome;
              itemPush.cnpj = itemXml.cnpj;
              itemPush.telefone = itemXml.telefone;
              itemPush.xml = itemXml.xml;
              itemPush.exibirCobrar = true;
              itemPush.enderecoCompleto = itemXml.enderecoCompleto;
              itemPush.produtos = itemXml.produtos;
              itemPush.dadosNota = itemXml.dadosNota;
            }
            // }
          }

          lista.push(itemPush);
        }

        //lista.pop();

        this.setState({ lista }, () => {
          console.log("ggggggggggggggggggggggggggg", lista);

          let roteiros = this.state.roteiros;
          let itemSelecionadoMapa = this.state.itemSelecionadoMapa;

          if (itemSelecionadoMapa !== null) {
            for (let item of roteiros) {
              if (item._id === itemSelecionadoMapa) {
                item.lista = lista;
              }
            }
          }

          this.setState({ roteiros });
        });

        ///console.log(lista);
      }
    });
  }

  async felipe(listaXmls) {
    return new Promise(async (resolve, reject) => {
      let data = {
        origem:
          "Erwino Menegotti, numero 1320, Chico de Paulo, Jaraguá do Sul, SC, 89254-000",
        destino:
          "Erwino Menegotti, numero 1320, Chico de Paulo, Jaraguá do Sul, SC, 89254-000",
        pontos: [],
      };

      await axios
        .post(
          `${this.props.getBaseUrlRoteirizador()}/converter-xml-json`,
          { listaXmls },
          this.props.parameters()
        )
        .then(async (response) => {
          let listaXmlFormatada = response.data.lista;
          let listaXml = [];

          for (let xmlParse of listaXmlFormatada) {
            data.pontos.push({
              local: `${xmlParse.rua}, ${xmlParse.numero}, ${xmlParse.bairro}, ${xmlParse.cidade}, ${xmlParse.estado}, ${xmlParse.cep}`,
              parada: true,
            });

            listaXml.push({
              xml: xmlParse.xml,
              nome: xmlParse.nome,
              cnpj: xmlParse.cnpj,
              telefone: xmlParse.telefone,
              endereco: `${xmlParse.rua}, ${xmlParse.numero}, ${xmlParse.bairro}, ${xmlParse.cidade}, ${xmlParse.estado}, ${xmlParse.cep}`,
              felipezika: true,
              enderecoCompleto: {
                rua: xmlParse.rua,
                numero: xmlParse.numero,
                bairro: xmlParse.bairro,
                cidade: xmlParse.cidade,
                estado: xmlParse.estado,
                cep: xmlParse.cep,
              },
              produtos: xmlParse.produtos,
              dadosNota: xmlParse.dadosNota,
            });
          }

          await axios
            .post(
              `${this.props.getBaseUrlRoteirizador()}/atualizar-rotas`,
              { data },
              this.props.parameters()
            )
            .then((response) => {
              this.iframeRef.current.src = this.iframeRef.current.src;
            });

          this.setState(
            {
              listaXml,
            },
            () => {
              resolve();
            }
          );
        });
    });
  }

  async handleClickGerarRota(idRoteiro) {
    new Promise(async (resolve, reject) => {
      let roteiros = this.state.roteiros;
      let listaXml = [];
      for (let item of roteiros) {
        if (item._id === idRoteiro) {
          this.setState({ itemSelecionadoMapa: idRoteiro });
          for (let xml of item.itens) {
            listaXml.push(xml.xml);
          }
        }
      }
      await this.felipe(listaXml);
      resolve();
    });
  }

  getLetraAlfabeto(index) {
    // index = index + 1;
    const alfabeto = [
      "A",
      "B",
      "C",
      "D",
      "E",
      "F",
      "G",
      "H",
      "I",
      "J",
      "K",
      "L",
      "M",
      "N",
      "O",
      "P",
      "Q",
      "R",
      "S",
      "T",
      "U",
      "V",
      "W",
      "X",
      "Y",
      "Z",
    ];

    return alfabeto[index];
  }

  toggleModal2() {
    this.setState({ modal2: !this.state.modal2 });
  }

  render() {
    return (
      <React.Fragment>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />

        <div className="container-scroller font-app">
          <NavBar title="Roteirizador" />
          <div className="container-fluid page-body-wrapper">
            <SideBar itemActive={"roteirizador"} />
            <div className="main-panel">
              <div className="content-wrapper">
                {/* <TitleAndDescription title="Usuários" description="" /> */}

                <div className="row">
                  <div className="col-md-12 stretch-card">
                    {this.state.templates.length > -1 && (
                      <div className="card">
                        <div className="card-body">
                          <React.Fragment>
                            {/* <div
                              className="float-left width-100"
                              style={{
                                borderBottom: "1px solid #CCC",
                                padding: "5px",
                                position: "relative",
                              }}
                            >
                              Entregas
                            </div> */}

                            <div className="d-flex">
                              <div className="col-6">
                                <div
                                  className="sc-tracking-status-timeline2"
                                  style={{
                                    display: "flex",
                                    width: "100%",
                                    paddingBottom: "35px",
                                    marginLeft: "30px",
                                  }}
                                >
                                  <div className="sc-tracking-status-timeline__box2">
                                    <div
                                      data-desc="Selecionar Entregas"
                                      className="sc-tracking-status-timeline__step sc-tracking-status-timeline__animation-1"
                                    >
                                      <i className="sc-tracking-status-timeline__number animated sc-tracking-status-timeline__number-1">
                                        1
                                      </i>
                                      <i className="sc-tracking-status-timeline__icon animated fa fa-file sc-tracking-status-timeline__icon-1 zoomInUp"></i>
                                    </div>
                                    <div
                                      data-desc="Gerar Roteiros"
                                      className="sc-tracking-status-timeline__step sc-tracking-status-timeline__animation-2 active"
                                    >
                                      <i className="sc-tracking-status-timeline__number animated sc-tracking-status-timeline__number-2">
                                        2
                                      </i>
                                      <i className="sc-tracking-status-timeline__icon animated fa fa-check sc-tracking-status-timeline__icon-2 zoomInDown"></i>
                                    </div>
                                    <div
                                      data-desc="Rotas Geradas"
                                      className="sc-tracking-status-timeline__step sc-tracking-status-timeline__animation-3"
                                    >
                                      <i className="sc-tracking-status-timeline__number animated sc-tracking-status-timeline__number-3">
                                        3
                                      </i>
                                      <i className="sc-tracking-status-timeline__icon animated fa fa-truck sc-tracking-status-timeline__icon-3 zoomInUp"></i>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="table-responsive">
                              <table
                                id="recent-purchases-listing"
                                className="table table-striped"
                                style={{ marginBottom: "20px" }}
                              >
                                <thead className="thead-dark">
                                  <tr>
                                    <th
                                      style={{
                                        minWidth: "90px",
                                        textAlign: "center",
                                      }}
                                    >
                                      Sequencia
                                    </th>
                                    <th
                                      style={{
                                        minWidth: "110px",
                                        textAlign: "center",
                                      }}
                                    >
                                      Roteiro
                                    </th>

                                    <th className="text-center">Motorista</th>
                                    <th className="text-center">Veículo</th>

                                    <th style={{ textAlign: "center" }}>
                                      Nr Entregas
                                    </th>
                                    <th style={{ textAlign: "center" }}>
                                      Ações
                                    </th>
                                    {/* <th style={{ textAlign: "right" }}>
                                      Roteiro
                                    </th> */}
                                    {/* <th className="text-center">Seleciona?</th> */}

                                    {/* <th>Ações</th> */}
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.roteiros.length > 0 &&
                                    this.state.roteiros.map((item, key) => (
                                      <tr key={key}>
                                        <td>
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                            }}
                                          >
                                            {item.seq}
                                          </div>
                                        </td>
                                        <td
                                          style={{
                                            minWidth: "110px",
                                            textAlign: "center",
                                          }}
                                        >
                                          <span> {item.roteiro}</span>
                                        </td>

                                        <td>
                                          {/* <div style={{display:"flex", justifyContent:"center", width:"100%"}}> */}
                                          <Select
                                            value={item.motorista}
                                            onChange={(
                                              motoristaSelecionado
                                            ) => {
                                              this.handleChangeMotoristaVeiculo(
                                                item._id,
                                                "motorista",
                                                motoristaSelecionado
                                              );
                                            }}
                                            options={this.state.motoristas}
                                            placeholder="Selecione"
                                            styles={{
                                              control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                fontSize: "14px",
                                                height: "35px",
                                        minHeight: "35px"
                                              }),
                                              menu: (baseStyles, state) => ({
                                                ...baseStyles,
                                                fontSize: "14px",
                                              }),
                                            }}

                                            // isDisabled={
                                            //   this.state.status !== "pendente" ? true : false
                                            // }
                                          />
                                          {/* </div> */}
                                        </td>
                                        <td>
                                          {/* <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                            }}
                                          > */}
                                          <Select
                                            value={this.state.veiculo}
                                            onChange={(veiculoSelecionado) => {
                                              this.handleChangeMotoristaVeiculo(
                                                item._id,
                                                "veiculo",
                                                veiculoSelecionado
                                              );
                                            }}
                                            options={this.state.veiculos}
                                            placeholder="Selecione"
                                            //   style={{fontSize:"10px"}}
                                            styles={{
                                              control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                fontSize: "14px",
                                                height: "35px",
                                        minHeight: "35px"
                                              }),
                                              menu: (baseStyles, state) => ({
                                                ...baseStyles,
                                                fontSize: "14px",
                                              }),
                                            }}
                                            // isDisabled={
                                            //   this.state.status !== "pendente" ? true : false
                                            // }
                                          />
                                          {/* </div> */}
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                          {item.qtdEntregas}
                                        </td>

                                        <td className="text-center">
                                          <div
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                            }}
                                          >
                                            <div
                                              style={{
                                                cursor: "pointer",
                                                margin: "5px",
                                              }}
                                            >
                                              {/* <button> */}
                                              <i
                                                className="fa fa-map-marker"
                                                style={{ fontSize: "22px" }}
                                                onClick={() =>
                                                  this.handleClickGerarRota(
                                                    item._id
                                                  )
                                                }
                                              ></i>
                                              {/* </button> */}
                                            </div>
                                            {this.state.lista.length > 0 && (
                                              <div
                                                style={{
                                                  cursor: "pointer",
                                                  margin: "5px",
                                                }}
                                              >
                                                {/* <button> */}
                                                <i
                                                  className="fa fa-edit"
                                                  style={{ fontSize: "22px" }}
                                                  onClick={() => {
                                                    this.toggleModal2();
                                                  }}
                                                ></i>
                                                {/* </button> */}
                                              </div>
                                            )}
                                          </div>
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              </table>
                            </div>

                            <div className="row">
                              <div
                                className="col-6 d-flex"
                                style={{ justifyContent: "left" }}
                              >
                                {this.state.roteiros.length > 0 && (
                                  <div style={{ marginBottom: "20px" }}>
                                    <button
                                      className="btn btn-info btn-sm btn-app-verde"
                                      onClick={() => {
                                        this.gerarRoteiros();
                                      }}
                                    >
                                      <i
                                        className="fa fa-check"
                                        style={{
                                          fontSize: "16px",
                                        }}
                                      ></i>{" "}
                                      Gerar Roteiros
                                    </button>
                                  </div>
                                )}
                              </div>

                              <div
                                className="col-6 d-flex"
                                style={{ justifyContent: "right" }}
                              ></div>
                            </div>

                            <div className="row">
                              <div className="col-12">
                                <iframe
                                  src={`${this.props.getBaseUrlRoteirizador()}/index.html`}
                                  width="100%"
                                  height={"400px"}
                                  title="description"
                                  ref={this.iframeRef}
                                ></iframe>
                              </div>
                            </div>
                            {/* <div className="row">
                              <div className="col-md-12">
                                <div className="table-responsive">
                                  <table
                                    id="recent-purchases-listing"
                                    className="table table-striped"
                                  >
                                    <thead className="thead-dark">
                                      <tr>
                                        <th>Endereço</th>
                                        <th>Cliente</th>

                                        <th className="text-center">
                                          Telefone
                                        </th>
                                        <th className="">Obs</th>
                                        <th className="text-center">
                                          Distância
                                        </th>
                                        <th className="text-center">
                                          Tempo Previsto
                                        </th>
                                        <th className="text-center">Cobrar?</th>

                                        <th>Ações</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {this.state.lista.length > 0 &&
                                        this.state.lista.map((item, key) => (
                                          <tr key={key}>
                                            <td
                                              style={
                                                {
                                                  //fontWeight: "bold"
                                                }
                                              }
                                            >
                                              <img
                                                src={`data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2026%2037%22%3E%3Cg%20fill%3D%22none%22%3E%3Cpath%20d%3D%22M13%200C5.817%200%200%205.773%200%2012.918c0%207.655%205.59%2010.526%209.555%2017.16C12.09%2034.321%2011.342%2037%2013%2037c1.723%200%20.975-2.743%203.445-6.858C20.085%2023.86%2026%2020.605%2026%2012.918%2026%205.773%2020.183%200%2013%200z%22%20fill%3D%22%23C5221F%22/%3E%3Cpath%20d%3D%22M13.017%2035c-.233%200-.3-.065-.7-1.275-.332-1.046-.832-2.648-2.13-4.61-1.265-1.928-2.596-3.498-3.861-5.002C3.363%2020.517%201%2017.706%201%2012.64%201.033%206.199%206.393%201%2013.017%201S25%206.23%2025%2012.639c0%205.067-2.33%207.911-5.326%2011.507-1.232%201.504-2.53%203.073-3.795%204.97-1.265%201.928-1.797%203.498-2.13%204.544-.4%201.275-.5%201.34-.732%201.34z%22%20fill%3D%22%23EA4335%22/%3E%3Ctext%20text-anchor%3D%22middle%22%20dy%3D%22.3em%22%20x%3D%2213%22%20y%3D%2214%22%20font-family%3D%22Roboto%2C%20Arial%2C%20sans-serif%22%20font-size%3D%2216px%22%20fill%3D%22%23FFF%22%3E${this.getLetraAlfabeto(
                                                  key
                                                )}%3C/text%3E%3C/g%3E%3C/svg%3E`}
                                              ></img>
                                              {item.endereco}
                                            </td>
                                            <td>
                                              {" "}
                                              {item.cliente !== ""
                                                ? item.cliente
                                                : " - "}{" "}
                                            </td>
                                            <td className="text-center">
                                              {" "}
                                              {item.telefone !== ""
                                                ? item.telefone
                                                : " - "}{" "}
                                            </td>
                                            <td className=""> </td>
                                            <td className="text-center">
                                              {item.distancia !== null
                                                ? item.distancia
                                                : " - "}
                                            </td>
                                            <td className="text-center">
                                              {item.duracao !== null
                                                ? item.duracao
                                                : " - "}
                                            </td>

                                            <td className="text-center">
                                              {item.exibirCobrar ? (
                                                <input
                                                  type="checkbox"
                                                  checked={item.cobrar}
                                                  onChange={(e) =>
                                                    this.handleChangeCobrar(
                                                      item._id,
                                                      e.target.checked
                                                    )
                                                  }
                                                ></input>
                                              ) : (
                                                " - "
                                              )}
                                            </td>

                                            <td
                                              className="d-flex"
                                              style={{ fontSize: "18px" }}
                                            >
                                              {item.xml !== null && (
                                                <React.Fragment>
                                                  <div
                                                    style={{
                                                      margin: "3px",
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                    <i
                                                      id={`danfe-app-${item._id}`}
                                                      className="fa fa-file-pdf-o"
                                                      onClick={() => {
                                                        if (item.xml !== null) {
                                                          this.onClickVisualizarDanfe(
                                                            item.xml
                                                          );
                                                        }
                                                      }}
                                                      style={{
                                                        fontSize: "22px",
                                                        color:
                                                          item.xml === null
                                                            ? "inherit"
                                                            : "blue",
                                                      }}
                                                      // onClick={() => {
                                                      //   this.props.history.push(
                                                      //     `/despesas-motorista?id=${despesa.idUsuario}`
                                                      //   );
                                                      // }}
                                                    ></i>

                                                    <UncontrolledTooltip
                                                      placement="right"
                                                      target={`danfe-app-${item._id}`}
                                                    >
                                                      Visualizar Danfe
                                                    </UncontrolledTooltip>
                                                  </div>
                                                  <div
                                                    style={{
                                                      margin: "3px",
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                    <i
                                                      id={`danfe-app2-${item._id}`}
                                                      className="fa fa-trash"
                                                      onClick={() => {
                                                        this.handleClickDeletar(
                                                          item._id
                                                        );
                                                      }}
                                                      style={{
                                                        color: "red",
                                                        fontSize: "22px",
                                                      }}
                                                      // onClick={() => {
                                                      //   this.props.history.push(
                                                      //     `/despesas-motorista?id=${despesa.idUsuario}`
                                                      //   );
                                                      // }}
                                                    ></i>

                                                    <UncontrolledTooltip
                                                      placement="right"
                                                      target={`danfe-app2-${item._id}`}
                                                    >
                                                      Apagar XML
                                                    </UncontrolledTooltip>
                                                  </div>
                                                </React.Fragment>
                                              )}
                                            </td>
                                          </tr>
                                        ))}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div> */}
                          </React.Fragment>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>

        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Deletar Template</ModalHeader>
          <ModalBody>
            Tem certeza que deseja deletar este template de roteiro?
          </ModalBody>
          <ModalFooter>
            <Button
              className="btn-modal-recuperar"
              color="secondary"
              onClick={this.toggle}
            >
              Não
            </Button>
            <Button
              className="btn-modal-recuperar"
              color="primary"
              onClick={this.handleClickConfirmaDeletar}
            >
              Sim
            </Button>{" "}
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.modalRoteiro}
          toggle={this.toggleModalRoteiro}
        >
          <ModalHeader toggle={this.toggleModalRoteiro}>
            Selecionar Roteiro
          </ModalHeader>
          <ModalBody>
            {/* Tem certeza que deseja deletar este template de roteiro? */}
            <div className="form-group">
              <label>Roteiro Sugerido</label>
              <input
                type="text"
                className="form-control"
                value="Roteiro Litoral PR"
                disabled={true}
              />
            </div>
            <div className="form-group">
              <label>Roteiros</label>
              {/* <input
                type="text"
                className="form-control"
                value="Roteiro Litoral PR"
                disabled={true}
              /> */}
              {this.state.templates.length > 0 && (
                <select
                  className="form-control"
                  value={this.state.idRoteiroSelecinado}
                  onChange={(e) =>
                    this.handleChangeRoteiroModal(e.target.value)
                  }
                >
                  <option value="null">Selecione</option>
                  {this.state.templates.map((it, key) => (
                    <option key={key} value={it._id}>
                      {it.desc}
                    </option>
                  ))}
                </select>
              )}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              className="btn-modal-recuperar"
              color="secondary"
              onClick={this.toggleModalRoteiro}
            >
              Cancelar
            </Button>
            <Button
              className="btn-modal-recuperar"
              color="primary"
              onClick={this.handleClickSalvarRoteiroEntrega}
            >
              Salvar
            </Button>{" "}
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.modal2}
          toggle={this.toggleModal2}
          style={{ minWidth: "1200px" }}
        >
          <ModalHeader toggle={this.toggleModal2}>
            Visualização do Roteiro
          </ModalHeader>
          <ModalBody>
            <div className="table-responsive">
              <table
                id="recent-purchases-listing"
                className="table table-striped"
              >
                <thead className="thead-dark">
                  <tr>
                    <th>Endereço</th>
                    <th>Cliente</th>

                    <th className="text-center">Telefone</th>
                    <th className="">Obs</th>
                    <th className="text-center">Distância</th>
                    <th className="text-center">Tempo Previsto</th>
                    <th className="text-center">Cobrar?</th>

                    <th>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.lista.length > 0 &&
                    this.state.lista.map((item, key) => (
                      <tr key={key}>
                        <td
                          style={
                            {
                              //fontWeight: "bold"
                            }
                          }
                        >
                          <img
                            src={`data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20viewBox%3D%220%200%2026%2037%22%3E%3Cg%20fill%3D%22none%22%3E%3Cpath%20d%3D%22M13%200C5.817%200%200%205.773%200%2012.918c0%207.655%205.59%2010.526%209.555%2017.16C12.09%2034.321%2011.342%2037%2013%2037c1.723%200%20.975-2.743%203.445-6.858C20.085%2023.86%2026%2020.605%2026%2012.918%2026%205.773%2020.183%200%2013%200z%22%20fill%3D%22%23C5221F%22/%3E%3Cpath%20d%3D%22M13.017%2035c-.233%200-.3-.065-.7-1.275-.332-1.046-.832-2.648-2.13-4.61-1.265-1.928-2.596-3.498-3.861-5.002C3.363%2020.517%201%2017.706%201%2012.64%201.033%206.199%206.393%201%2013.017%201S25%206.23%2025%2012.639c0%205.067-2.33%207.911-5.326%2011.507-1.232%201.504-2.53%203.073-3.795%204.97-1.265%201.928-1.797%203.498-2.13%204.544-.4%201.275-.5%201.34-.732%201.34z%22%20fill%3D%22%23EA4335%22/%3E%3Ctext%20text-anchor%3D%22middle%22%20dy%3D%22.3em%22%20x%3D%2213%22%20y%3D%2214%22%20font-family%3D%22Roboto%2C%20Arial%2C%20sans-serif%22%20font-size%3D%2216px%22%20fill%3D%22%23FFF%22%3E${this.getLetraAlfabeto(
                              key
                            )}%3C/text%3E%3C/g%3E%3C/svg%3E`}
                          ></img>
                          {item.endereco}
                        </td>
                        <td> {item.cliente !== "" ? item.cliente : " - "} </td>
                        <td className="text-center">
                          {" "}
                          {item.telefone !== "" ? item.telefone : " - "}{" "}
                        </td>
                        <td className=""> </td>
                        <td className="text-center">
                          {item.distancia !== null ? item.distancia : " - "}
                        </td>
                        <td className="text-center">
                          {item.duracao !== null ? item.duracao : " - "}
                        </td>

                        <td className="text-center">
                          {item.exibirCobrar ? (
                            <input
                              type="checkbox"
                              checked={item.cobrar}
                              onChange={(e) =>
                                this.handleChangeCobrar(
                                  item._id,
                                  e.target.checked
                                )
                              }
                            ></input>
                          ) : (
                            " - "
                          )}
                        </td>

                        <td className="d-flex" style={{ fontSize: "18px" }}>
                          {item.xml !== null && (
                            <React.Fragment>
                              <div
                                style={{
                                  margin: "3px",
                                  cursor: "pointer",
                                }}
                              >
                                <i
                                  id={`danfe-app-${item._id}`}
                                  className="fa fa-file-pdf-o"
                                  onClick={() => {
                                    if (item.xml !== null) {
                                      this.onClickVisualizarDanfe(item.xml);
                                    }
                                  }}
                                  style={{
                                    fontSize: "22px",
                                    color:
                                      item.xml === null ? "inherit" : "blue",
                                  }}
                                  // onClick={() => {
                                  //   this.props.history.push(
                                  //     `/despesas-motorista?id=${despesa.idUsuario}`
                                  //   );
                                  // }}
                                ></i>

                                <UncontrolledTooltip
                                  placement="right"
                                  target={`danfe-app-${item._id}`}
                                >
                                  Visualizar Danfe
                                </UncontrolledTooltip>
                              </div>
                              {/* <div
                                                    style={{
                                                      margin: "3px",
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                    <i
                                                      id={`danfe-app2-${item._id}`}
                                                      className="fa fa-trash"
                                                      onClick={() => {
                                                        this.handleClickDeletar(
                                                          item._id
                                                        );
                                                      }}
                                                      style={{
                                                        color: "red",
                                                        fontSize: "22px",
                                                      }}
                                                      // onClick={() => {
                                                      //   this.props.history.push(
                                                      //     `/despesas-motorista?id=${despesa.idUsuario}`
                                                      //   );
                                                      // }}
                                                    ></i>

                                                    <UncontrolledTooltip
                                                      placement="right"
                                                      target={`danfe-app2-${item._id}`}
                                                    >
                                                      Apagar XML
                                                    </UncontrolledTooltip>
                                                  </div> */}
                            </React.Fragment>
                          )}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              className="btn-modal-recuperar"
              color="secondary"
              onClick={this.toggleModal2}
            >
              Fechar
            </Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default Template3;
