import React, { Component } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { SideBar, NavBar, Footer, TitleAndDescription } from "../../components";
import "react-toastify/dist/ReactToastify.css";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_dataviz from "@amcharts/amcharts4/themes/dataviz";
import Select from "react-select";
import moment from "moment";
am4core.useTheme(am4themes_dataviz);
am4core.useTheme(am4themes_animated);

class RoteiroDetalhe extends Component {
  constructor(props) {
    super(props);
    this.iniciaGraficoSaldo = this.iniciaGraficoSaldo.bind(this);
    this.iniciaGraficoSaldo2 = this.iniciaGraficoSaldo2.bind(this);
    this.iniciaGraficoSaldo3 = this.iniciaGraficoSaldo3.bind(this);
    this.iniciaGraficoEntregas = this.iniciaGraficoEntregas.bind(this);
    this.iniciaGraficoDespesas = this.iniciaGraficoDespesas.bind(this);
    this.iniciaGraficoEntregasRoteiro =
      this.iniciaGraficoEntregasRoteiro.bind(this);
    this.iniciaGraficoNaoConformidades =
      this.iniciaGraficoNaoConformidades.bind(this);
    this.iniciaGraficoOcorrencias = this.iniciaGraficoOcorrencias.bind(this);
    this.handleChangeFiltro = this.handleChangeFiltro.bind(this);
    this.loadGraficoEntregas = this.loadGraficoEntregas.bind(this);
    this.loadGraficoNaoConformidades =
      this.loadGraficoNaoConformidades.bind(this);
    this.load = this.load.bind(this);
    this.onChangeMotorista = this.onChangeMotorista.bind(this);
    this.onChangeCliente = this.onChangeCliente.bind(this);
    this.loadClientes = this.loadClientes.bind(this);
    this.loadAtividades = this.loadAtividades.bind(this);

    this.state = {
      roteiro: null,
      totalizadores: {
        totalVisitas: 0,
        pendentes: 0,
        realizadas: 0,
        divergencias: 0,
        ocorrencias: 0,
        canceladas: 0,
      },
      filtroEntregas: "mesAtual",
      filtroDespesas: "mesAtual",
      filtroEntregasRoteiro: "hoje",
      filtroNaoConformidades: "mesAtual",
      filtroOcorrencias: "mesAtual",
      filtro: "mesAtual",
      totalVisitas: 0,
      motoristas: [],
      clienteSelecionado: null,
      motoristaSelecionado: null,
      clientes: [],
      atividades: [],
    };
  }

  async componentDidMount() {
    const usuario = JSON.parse(localStorage.getItem('usuario'))
    if(usuario.nivel === '3'){
      this.props.history.push('/pedidos-material')
    }else if (usuario.nivel === "4") {
      return this.props.history.push("/nao-conformidades");
    }else{
      this.props.history.push('/despesas')
    }
    
    return false;
    this.load();
    this.loadGraficoEntregas();
    this.loadGraficoDespesas();
    this.loadGraficoNaoConformidades();
    this.loadGraficoOcorrencias();
    this.loadClientes();
    this.loadAtividades();
  }

  async load() {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/retaguarda/dashboard?filtro=${
          this.state.filtro
        }`,
        this.props.parameters()
      )
      .then(async (response) => {
        let totalizadores = response.data.totalizadores;

        await this.iniciaGraficoSaldo(
          totalizadores.realizadas,
          totalizadores.total - totalizadores.realizadas
        );
        await this.iniciaGraficoSaldo2(
          totalizadores.ocorrencias,
          totalizadores.total - totalizadores.ocorrencias
        );
        await this.iniciaGraficoSaldo3(
          totalizadores.naoConformidades,
          totalizadores.total - totalizadores.naoConformidades
        );

        this.setState({ totalVisitas: totalizadores.total, totalizadores });
      });
  }
  async loadGraficoEntregas() {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/retaguarda/dashboard/entregas-motorista?filtro=${
          this.state.filtroEntregas
        }`,
        this.props.parameters()
      )
      .then(async (response) => {
        let usuarios = response.data.usuarios;

        let data = [];

        for (let usuario of usuarios) {
          data.push({
            category: usuario.desc,
            total: usuario.totalizadores.total,
            realizadas: usuario.totalizadores.realizadas,
            pendentes: usuario.totalizadores.pendentes,
          });
        }

        await this.iniciaGraficoEntregas(data);
        let motoristas = response.data.usuarios;

        motoristas = motoristas.map((motorista) => {
          motorista.label = motorista.desc;
          motorista.value = motorista._id;
          return motorista;
        });

        this.setState({ motoristas });
      });
  }

  async loadGraficoDespesas() {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/retaguarda/dashboard/despesas-motorista?filtro=${
          this.state.filtroDespesas
        }`,
        this.props.parameters()
      )
      .then(async (response) => {
        let usuarios = response.data.usuarios;

        let data = [];

        for (let usuario of usuarios) {
          data.push({
            category: usuario.descUsuario,
            total: usuario.total,
            saldo: usuario.saldo,
          });
        }

        await this.iniciaGraficoDespesas(data);
      });
  }
  async loadGraficoNaoConformidades() {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/retaguarda/dashboard/nao-conformidades?filtro=${
          this.state.filtroDespesas
        }`,
        this.props.parameters()
      )
      .then(async (response) => {
        let naoConformidades = response.data.naoConformidades;

        let data = [];

        for (let naoConformidade of naoConformidades) {
          data.push({
            country: naoConformidade.desc,
            litres: naoConformidade.total,
          });
        }

        await this.iniciaGraficoNaoConformidades(data);
      });
  }

  async loadGraficoOcorrencias() {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/retaguarda/dashboard/ocorrencias?filtro=${
          this.state.filtroDespesas
        }`,
        this.props.parameters()
      )
      .then(async (response) => {
        let ocorrencias = response.data.ocorrencias;

        let data = [];

        for (let ocorrencia of ocorrencias) {
          data.push({
            country: ocorrencia.desc,
            litres: ocorrencia.total,
          });
        }

        await this.iniciaGraficoOcorrencias(data);
      });
  }

  notificar(type, message) {
    toast[type](message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  iniciaGraficoSaldo(value1 = 40, value2 = 60) {
    var chart = am4core.create("chartdiv", am4charts.PieChart);
    chart.hiddenState.properties.opacity = 0;

    chart.data = [
      {
        tipo: "Realizadas",
        value: value1,
        color: am4core.color("#2da951"),
      },
      {
        tipo: "Pendentes",
        value: value2,
        color: am4core.color("gainsboro"),
      },
    ];
    chart.radius = am4core.percent(60);
    chart.innerRadius = am4core.percent(40);
    chart.startAngle = 180;
    chart.endAngle = 360;
    var series = chart.series.push(new am4charts.PieSeries());
    series.dataFields.value = "value";
    series.dataFields.category = "tipo";
    series.slices.template.cornerRadius = 10;
    series.slices.template.innerCornerRadius = 7;
    series.slices.template.draggable = false;
    series.slices.template.inert = true;
    series.slices.template.propertyFields.fill = "color";
    series.alignLabels = false;
    series.hiddenState.properties.startAngle = 90;
    series.hiddenState.properties.endAngle = 90;
    series.labels.template.disabled = true;
    this.chart = chart;
  }
  iniciaGraficoSaldo2(value1 = 10, value2 = 90) {
    var chart = am4core.create("chartdiv2", am4charts.PieChart);
    chart.hiddenState.properties.opacity = 0;

    chart.data = [
      {
        tipo: "Com ocorrências",
        value: value1,
        color: am4core.color("#FCAB10"),
      },
      {
        tipo: "Sem ocorrências",
        value: value2,
        color: am4core.color("gainsboro"),
      },
    ];
    chart.radius = am4core.percent(60);
    chart.innerRadius = am4core.percent(40);
    chart.startAngle = 180;
    chart.endAngle = 360;
    var series = chart.series.push(new am4charts.PieSeries());
    series.dataFields.value = "value";
    series.dataFields.category = "tipo";
    series.slices.template.cornerRadius = 10;
    series.slices.template.innerCornerRadius = 7;
    series.slices.template.draggable = false;
    series.slices.template.inert = true;
    series.slices.template.propertyFields.fill = "color";
    series.alignLabels = false;
    series.hiddenState.properties.startAngle = 90;
    series.hiddenState.properties.endAngle = 90;
    series.labels.template.disabled = true;
    this.chart = chart;
  }
  iniciaGraficoSaldo3(value1 = 10, value2 = 90) {
    var chart = am4core.create("chartdiv3", am4charts.PieChart);
    chart.hiddenState.properties.opacity = 0;

    chart.data = [
      {
        tipo: "Não-Conformidades",
        value: value1,
        color: am4core.color("#F2542D"),
      },
      {
        tipo: "Sem nenhuma não-conformidade",
        value: value2,
        color: am4core.color("gainsboro"),
      },
    ];
    chart.radius = am4core.percent(60);
    chart.innerRadius = am4core.percent(40);
    chart.startAngle = 180;
    chart.endAngle = 360;
    var series = chart.series.push(new am4charts.PieSeries());
    series.dataFields.value = "value";
    series.dataFields.category = "tipo";
    series.slices.template.cornerRadius = 10;
    series.slices.template.innerCornerRadius = 7;
    series.slices.template.draggable = false;
    series.slices.template.inert = true;
    series.slices.template.propertyFields.fill = "color";
    series.alignLabels = false;
    series.hiddenState.properties.startAngle = 90;
    series.hiddenState.properties.endAngle = 90;
    series.labels.template.disabled = true;
    this.chart = chart;
  }

  iniciaGraficoDespesas(data) {
    let chart = am4core.create("chartdivDespesas", am4charts.XYChart);
    chart.colors.step = 2;

    chart.legend = new am4charts.Legend();
    chart.legend.position = "top";
    chart.legend.paddingBottom = 20;
    chart.legend.labels.template.maxWidth = 95;

    let xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    xAxis.dataFields.category = "category";
    xAxis.renderer.cellStartLocation = 0.1;
    xAxis.renderer.cellEndLocation = 0.9;
    xAxis.renderer.grid.template.location = 0;

    let yAxis = chart.yAxes.push(new am4charts.ValueAxis());
    yAxis.min = 0;

    function createSeries(value, name) {
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = value;
      series.dataFields.categoryX = "category";
      series.name = name;

      series.events.on("hidden", arrangeColumns);
      series.events.on("shown", arrangeColumns);

      let bullet = series.bullets.push(new am4charts.LabelBullet());
      bullet.interactionsEnabled = false;
      bullet.dy = 30;
      bullet.label.text = "{valueY}";
      bullet.label.fill = am4core.color("#ffffff");

      return series;
    }

    chart.data = data;

    createSeries("total", "Total");
    createSeries("saldo", "Saldo");

    function arrangeColumns() {
      let series = chart.series.getIndex(0);

      let w =
        1 -
        xAxis.renderer.cellStartLocation -
        (1 - xAxis.renderer.cellEndLocation);
      if (series.dataItems.length > 1) {
        let x0 = xAxis.getX(series.dataItems.getIndex(0), "categoryX");
        let x1 = xAxis.getX(series.dataItems.getIndex(1), "categoryX");
        let delta = ((x1 - x0) / chart.series.length) * w;
        if (am4core.isNumber(delta)) {
          let middle = chart.series.length / 2;

          let newIndex = 0;
          chart.series.each(function (series) {
            if (!series.isHidden && !series.isHiding) {
              series.dummyData = newIndex;
              newIndex++;
            } else {
              series.dummyData = chart.series.indexOf(series);
            }
          });
          let visibleCount = newIndex;
          let newMiddle = visibleCount / 2;

          chart.series.each(function (series) {
            let trueIndex = chart.series.indexOf(series);
            let newIndex = series.dummyData;

            let dx = (newIndex - trueIndex + middle - newMiddle) * delta;

            series.animate(
              { property: "dx", to: dx },
              series.interpolationDuration,
              series.interpolationEasing
            );
            series.bulletsContainer.animate(
              { property: "dx", to: dx },
              series.interpolationDuration,
              series.interpolationEasing
            );
          });
        }
      }
    }
  }
  iniciaGraficoEntregas(data) {
    let chart = am4core.create("chartdivEntregas", am4charts.XYChart);
    chart.colors.step = 2;

    chart.legend = new am4charts.Legend();
    chart.legend.position = "top";
    chart.legend.paddingBottom = 20;
    chart.legend.labels.template.maxWidth = 95;

    let xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    xAxis.dataFields.category = "category";
    xAxis.renderer.cellStartLocation = 0.1;
    xAxis.renderer.cellEndLocation = 0.9;
    xAxis.renderer.grid.template.location = 0;

    let yAxis = chart.yAxes.push(new am4charts.ValueAxis());
    yAxis.min = 0;

    function createSeries(value, name) {
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = value;
      series.dataFields.categoryX = "category";
      series.name = name;

      series.events.on("hidden", arrangeColumns);
      series.events.on("shown", arrangeColumns);

      let bullet = series.bullets.push(new am4charts.LabelBullet());
      bullet.interactionsEnabled = false;
      bullet.dy = 30;
      bullet.label.text = "{valueY}";
      bullet.label.fill = am4core.color("#ffffff");

      return series;
    }

    chart.data = data;

    //createSeries("total", "Total");
    createSeries("realizadas", "Realizadas");
    createSeries("pendentes", "Pendentes");

    function arrangeColumns() {
      let series = chart.series.getIndex(0);

      let w =
        1 -
        xAxis.renderer.cellStartLocation -
        (1 - xAxis.renderer.cellEndLocation);
      if (series.dataItems.length > 1) {
        let x0 = xAxis.getX(series.dataItems.getIndex(0), "categoryX");
        let x1 = xAxis.getX(series.dataItems.getIndex(1), "categoryX");
        let delta = ((x1 - x0) / chart.series.length) * w;
        if (am4core.isNumber(delta)) {
          let middle = chart.series.length / 2;

          let newIndex = 0;
          chart.series.each(function (series) {
            if (!series.isHidden && !series.isHiding) {
              series.dummyData = newIndex;
              newIndex++;
            } else {
              series.dummyData = chart.series.indexOf(series);
            }
          });
          let visibleCount = newIndex;
          let newMiddle = visibleCount / 2;

          chart.series.each(function (series) {
            let trueIndex = chart.series.indexOf(series);
            let newIndex = series.dummyData;

            let dx = (newIndex - trueIndex + middle - newMiddle) * delta;

            series.animate(
              { property: "dx", to: dx },
              series.interpolationDuration,
              series.interpolationEasing
            );
            series.bulletsContainer.animate(
              { property: "dx", to: dx },
              series.interpolationDuration,
              series.interpolationEasing
            );
          });
        }
      }
    }
  }
  iniciaGraficoEntregasRoteiro() {
    let chart = am4core.create("chartdivEntregasRoteiro", am4charts.XYChart);
    chart.colors.step = 2;

    chart.legend = new am4charts.Legend();
    chart.legend.position = "top";
    chart.legend.paddingBottom = 20;
    chart.legend.labels.template.maxWidth = 95;

    let xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    xAxis.dataFields.category = "category";
    xAxis.renderer.cellStartLocation = 0.1;
    xAxis.renderer.cellEndLocation = 0.9;
    xAxis.renderer.grid.template.location = 0;

    let yAxis = chart.yAxes.push(new am4charts.ValueAxis());
    yAxis.min = 0;

    function createSeries(value, name) {
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = value;
      series.dataFields.categoryX = "category";
      series.name = name;

      series.events.on("hidden", arrangeColumns);
      series.events.on("shown", arrangeColumns);

      let bullet = series.bullets.push(new am4charts.LabelBullet());
      bullet.interactionsEnabled = false;
      bullet.dy = 30;
      bullet.label.text = "{valueY}";
      bullet.label.fill = am4core.color("#ffffff");

      return series;
    }

    chart.data = [
      {
        category: "Place #1",
        first: 40,
        second: 55,
      },
      {
        category: "Place #2",
        first: 30,
        second: 78,
      },
      {
        category: "Place #3",
        first: 27,
        second: 40,
      },
      {
        category: "Place #4",
        first: 50,
        second: 33,
      },
    ];

    createSeries("first", "The First");
    createSeries("second", "The Second");

    function arrangeColumns() {
      let series = chart.series.getIndex(0);

      let w =
        1 -
        xAxis.renderer.cellStartLocation -
        (1 - xAxis.renderer.cellEndLocation);
      if (series.dataItems.length > 1) {
        let x0 = xAxis.getX(series.dataItems.getIndex(0), "categoryX");
        let x1 = xAxis.getX(series.dataItems.getIndex(1), "categoryX");
        let delta = ((x1 - x0) / chart.series.length) * w;
        if (am4core.isNumber(delta)) {
          let middle = chart.series.length / 2;

          let newIndex = 0;
          chart.series.each(function (series) {
            if (!series.isHidden && !series.isHiding) {
              series.dummyData = newIndex;
              newIndex++;
            } else {
              series.dummyData = chart.series.indexOf(series);
            }
          });
          let visibleCount = newIndex;
          let newMiddle = visibleCount / 2;

          chart.series.each(function (series) {
            let trueIndex = chart.series.indexOf(series);
            let newIndex = series.dummyData;

            let dx = (newIndex - trueIndex + middle - newMiddle) * delta;

            series.animate(
              { property: "dx", to: dx },
              series.interpolationDuration,
              series.interpolationEasing
            );
            series.bulletsContainer.animate(
              { property: "dx", to: dx },
              series.interpolationDuration,
              series.interpolationEasing
            );
          });
        }
      }
    }
  }

  iniciaGraficoNaoConformidades(data) {
    // Create chart instance
    let chart = am4core.create("chartdivNaoConformidades", am4charts.PieChart);

    // Add data
    chart.data = data;

    // Set inner radius
    chart.innerRadius = am4core.percent(50);

    // Add and configure Series
    let pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "litres";
    pieSeries.dataFields.category = "country";
    pieSeries.slices.template.stroke = am4core.color("#fff");
    pieSeries.slices.template.strokeWidth = 2;
    pieSeries.slices.template.strokeOpacity = 1;

    // This creates initial animation
    pieSeries.hiddenState.properties.opacity = 1;
    pieSeries.hiddenState.properties.endAngle = -90;
    pieSeries.hiddenState.properties.startAngle = -90;

    pieSeries.labels.template.disabled = true;
    pieSeries.ticks.template.disabled = true;

    chart.legend = new am4charts.Legend();
  }

  iniciaGraficoOcorrencias(data) {
    // Create chart instance
    let chart = am4core.create("chartdivOcorrencias", am4charts.PieChart);

    // Add data
    chart.data = data;

    // Set inner radius
    chart.innerRadius = am4core.percent(50);

    // Add and configure Series
    let pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = "litres";
    pieSeries.dataFields.category = "country";
    pieSeries.slices.template.stroke = am4core.color("#fff");
    pieSeries.slices.template.strokeWidth = 2;
    pieSeries.slices.template.strokeOpacity = 1;

    // This creates initial animation
    pieSeries.hiddenState.properties.opacity = 1;
    pieSeries.hiddenState.properties.endAngle = -90;
    pieSeries.hiddenState.properties.startAngle = -90;

    pieSeries.labels.template.disabled = true;
    pieSeries.ticks.template.disabled = true;

    chart.legend = new am4charts.Legend();
  }

  handleChangeFiltro(value, tipo) {
    this.setState({ [tipo]: value }, () => {
      if (tipo === "filtroEntregas") {
        this.loadGraficoEntregas();
      } else if (tipo === "filtroDespesas") {
        this.loadGraficoDespesas();
      } else if (tipo === "filtroNaoConformidades") {
        this.loadGraficoNaoConformidades();
      } else if (tipo === "filtroOcorrencias") {
        this.loadGraficoOcorrencias();
      } else if (tipo === "filtro") {
        this.load();
      }
    });
  }

  onChangeMotorista(motorista) {
    this.setState({ motoristaSelecionado: motorista });
  }
  onChangeCliente(cliente) {
    this.setState({ clienteSelecionado: cliente });
  }

  async loadClientes() {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/retaguarda/clientes`,
        this.props.parameters()
      )
      .then(async (response) => {
        let clientes = response.data.clientes;

        //console.log("clientes", clientes);

        clientes = clientes.map((cliente) => {
          cliente.label = cliente.desc;
          cliente.value = cliente._id;
          return cliente;
        });

        this.setState({ clientes });
      });
  }

  async loadAtividades() {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/retaguarda/atividades`,
        this.props.parameters()
      )
      .then(async (response) => {
        let atividades = response.data.atividades;

        for (let atividade of atividades) {
          let dtGravacao = moment(atividade.dtGravacao);
          let diferenca = moment.duration(moment().diff(dtGravacao)).asDays();

          diferenca = parseInt(diferenca);

          let descHora = "";

          //OCORRENCIA NO DIA ATUAL
          if (diferenca === 0) {
            if (moment().format("DD") === dtGravacao.format("DD")) {
              descHora = `Hoje às ${dtGravacao.format("HH:mm")}`;
            } else {
              descHora = `Ontem às ${dtGravacao.format("HH:mm")}`;
            }
          } else {
            descHora = `${dtGravacao.format(
              "DD/MM/YYYY"
            )} às ${dtGravacao.format("HH:ss")}`;
          }

          atividade.descHora = descHora;
        }

        // // ////console.clear();
        console.log("ativ", atividades);

        this.setState({ atividades });
      });
  }

  render() {
    return (
      <React.Fragment>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />

        <div className="container-scroller font-app">
          <NavBar title="Home" />
          <div className="container-fluid page-body-wrapper">
            <SideBar itemActive={"dashboard"} />
            <div className="main-panel">
              <div className="content-wrapper">
                {/* <TitleAndDescription title="Home" description="" /> */}

                <div className="row" style={{ paddingBottom: "20px" }}>
                  <div className="col-md-12 stretch-card">
                    {true && (
                      <React.Fragment>
                        <div className="col-md-8 stretch-card d-flex flex-wrap">
                          <div className="card">
                            <div className="card-body">
                              <React.Fragment>
                                {/* <div
                                  className="float-left width-100"
                                  style={{
                                    padding: "5px",
                                    position: "relative",
                                  }}
                                > */}
                                {/* <div className="float-left">OVERVIEW</div> */}

                                {/* <div
                                    className="float-right"
                                    style={{
                                      width: "170px",
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <div style={{ width: "15%" }}>
                                      <i className="fa fa-calendar"></i>
                                    </div>
                                    <div style={{ width: "85%" }}>
                                      <select
                                        className="form-control"
                                        onChange={(e) => {
                                          this.handleChangeFiltro(
                                            e.target.value,
                                            "filtro"
                                          );
                                        }}
                                        value={this.state.filtro}
                                      >
                                        <option value="hoje">Hoje</option>
                                        <option value="ontem">Ontem</option>
                                        <option value="semana">
                                          Essa Semana
                                        </option>
                                        <option value="mesAtual">
                                          Esse Mês
                                        </option>
                                      </select>
                                    </div>
                                  </div> */}

                                {/*                               
                                </div> */}

                                <div
                                  className="row"
                                  style={{ alignItems: "center" }}
                                >
                                  <div
                                    className="col-3 no-padding"
                                    style={{
                                      borderRight: "1px solid #CCC",
                                      position: "relative",
                                    }}
                                  >
                                    <div className="text-center">
                                      Visitas{" "}
                                      <div
                                        style={{
                                          fontSize: "20px",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {this.state.totalVisitas}
                                        <div
                                          style={{
                                            fontSize: "16px",
                                            marginTop: "48px",
                                            position: "absolute",
                                            top: "23px",
                                            right: "38px",
                                            fontWeight: "normal",
                                          }}
                                        >
                                          {moment().format("DD/MM/YYYY")}
                                        </div>
                                      </div>
                                    </div>
                                  </div>{" "}
                                  <div className="col-3">
                                    <div
                                      className="d-flex"
                                      style={{ height: "90px" }}
                                    >
                                      <div
                                        id="chartdiv"
                                        style={{
                                          width: "100%",
                                          display: "flex",
                                        }}
                                      ></div>
                                    </div>

                                    <div className="text-center float-left w-100">
                                      Pendentes
                                      <div>
                                        {" "}
                                        {this.state.totalVisitas -
                                          this.state.totalizadores.realizadas}
                                      </div>
                                      {/* {this.state.totalVisitas -
                                        this.state.totalizadores
                                          .realizadas}{" "}
                                      visita(s) pendente(s). */}
                                    </div>
                                  </div>
                                  <div className="col-3">
                                    <div
                                      className="d-flex"
                                      style={{ height: "90px" }}
                                    >
                                      <div
                                        id="chartdiv2"
                                        style={{
                                          width: "100%",
                                          display: "flex",
                                        }}
                                      ></div>
                                    </div>

                                    <div className="text-center float-left w-100">
                                      Finalizadas
                                      <div>
                                        {this.state.totalizadores.ocorrencias}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-3">
                                    <div
                                      className="d-flex"
                                      style={{ height: "90px" }}
                                    >
                                      <div
                                        id="chartdiv3"
                                        style={{
                                          width: "100%",
                                          display: "flex",
                                        }}
                                      ></div>
                                    </div>

                                    <div className="text-center float-left w-100">
                                      Canceladas
                                      <div>
                                        {
                                          this.state.totalizadores
                                            .naoConformidades
                                        }
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </React.Fragment>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 stretch-card d-flex flex-wrap">
                          <div className="card">
                            <div className="card-body">
                              <div
                                className="float-left width-100"
                                style={{
                                  borderBottom: "1px solid #CCC",
                                  padding: "5px",
                                  marginBottom: "20px",
                                  position: "relative",
                                }}
                              >
                                PESQUISA RÁPIDA
                              </div>

                              <div>
                                <div className="form-group float-left w-100">
                                  <label>Cliente</label>
                                  <div
                                    style={{
                                      float: "left",
                                      width: "100%",
                                      alignitems: "center",
                                      display: "flex",
                                    }}
                                  >
                                    <div
                                      style={{ width: "90%", float: "left" }}
                                    >
                                      <Select
                                        value={this.state.clienteSelecionado}
                                        onChange={this.onChangeCliente}
                                        options={this.state.clientes}
                                        placeholder="Selecione o cliente"
                                      />
                                    </div>
                                    <div
                                      style={{ width: "10%", float: "left" }}
                                    >
                                      <div
                                        style={{
                                          float: "left",
                                          width: "100%",
                                          marginLeft: "10px",
                                        }}
                                      >
                                        <i
                                          className="fa fa-search"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            if (
                                              this.state.clienteSelecionado !==
                                              null
                                            ) {
                                              this.props.history.push(
                                                `/roteiros?cliente=${this.state.clienteSelecionado._id}`
                                              );
                                            }
                                          }}
                                        ></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="form-group"
                                  style={{ marginTop: "10px" }}
                                >
                                  <label>Motorista</label>
                                  <div
                                    style={{
                                      float: "left",
                                      width: "100%",
                                      alignitems: "center",
                                      display: "flex",
                                    }}
                                  >
                                    <div
                                      style={{ width: "90%", float: "left" }}
                                    >
                                      <Select
                                        value={this.state.motoristaSelecionado}
                                        onChange={this.onChangeMotorista}
                                        options={this.state.motoristas}
                                        placeholder="Selecione o motorista"
                                      />
                                    </div>
                                    <div
                                      style={{ width: "10%", float: "left" }}
                                    >
                                      <div
                                        style={{
                                          float: "left",
                                          width: "100%",
                                          marginLeft: "10px",
                                        }}
                                      >
                                        <i
                                          className="fa fa-search"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            if (this.state.motorista !== null) {
                                              this.props.history.push(
                                                `/roteiros?motorista=${this.state.motoristaSelecionado._id}`
                                              );
                                            }
                                          }}
                                        ></i>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                </div>

                <div className="row" style={{ paddingBottom: "20px" }}>
                  <div className="col-md-12 stretch-card">
                    <div
                      className="col-md-8 stretch-card d-flex flex-wrap"
                      style={{ marginBottom: "20px" }}
                    >
                      <div className="card">
                        <div className="card-body">
                          <div
                            className="float-left width-100"
                            style={{
                              padding: "5px",
                              position: "relative",
                            }}
                          >
                            <div className="float-left">
                              ENTREGAS POR MOTORISTA
                            </div>
                            <div
                              className="float-right"
                              style={{
                                width: "170px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <div style={{ width: "15%" }}>
                                <i className="fa fa-calendar"></i>
                              </div>
                              <div style={{ width: "85%" }}>
                                <select
                                  className="form-control"
                                  onChange={(e) => {
                                    this.handleChangeFiltro(
                                      e.target.value,
                                      "filtroEntregas"
                                    );
                                  }}
                                  value={this.state.filtroEntregas}
                                >
                                  <option value="hoje">Hoje</option>
                                  <option value="ontem">Ontem</option>
                                  <option value="semana">Essa Semana</option>
                                  <option value="mesAtual">Esse Mês</option>
                                </select>
                              </div>
                            </div>
                          </div>

                          <div className="row" style={{ alignItems: "center" }}>
                            <div
                              className="col-12"
                              style={{ float: "left", width: "100%" }}
                            >
                              <div
                                className="d-flex"
                                style={{ height: "350px" }}
                              >
                                <div
                                  id="chartdivEntregas"
                                  style={{
                                    width: "100%",
                                    display: "flex",
                                  }}
                                ></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 stretch-card d-flex flex-wrap">
                      <div className="card">
                        <div className="card-body card-home">
                          <div
                            className="float-left width-100"
                            style={{
                              borderBottom: "1px solid #CCC",
                              padding: "5px",
                              paddingBottom: "20px",
                              position: "relative",
                            }}
                          >
                            ATIVIDADES RECENTES
                          </div>

                          <div className="float-left w-100">
                            {this.state.atividades.map((atividade) => (
                              <div className="item-atividade-home">
                                <div className="col-1">
                                  {/*  */}

                                  {/* < */}

                                  {atividade.icon === "warning" && (
                                    <i
                                      className="fa fa-exclamation"
                                      style={{
                                        fontSize: "22px",
                                        color: "#FCAB10",
                                      }}
                                    ></i>
                                  )}
                                  {atividade.icon === "danger" && (
                                    <i
                                      className="fa fa-times"
                                      style={{
                                        fontSize: "22px",
                                        color: "#F2542D",
                                      }}
                                    ></i>
                                  )}
                                  {atividade.icon === "success" && (
                                    <i
                                      className="fa fa-check"
                                      style={{
                                        fontSize: "22px",
                                        color: "#2da951",
                                      }}
                                    ></i>
                                  )}
                                </div>
                                <div className="col-10">
                                  <div className="item-atividade-home-titulo">
                                    {atividade.titulo}
                                  </div>
                                  <div className="item-atividade-home-desc">
                                    {atividade.desc}
                                  </div>
                                  <div className="item-atividade-home-tempo">
                                    <i className="fa fa-clock-o"></i>{" "}
                                    {atividade.descHora}
                                  </div>
                                </div>
                                <div className="col-1">
                                  <i
                                    className="fa fa-search"
                                    style={{ cursor: "pointer" }}
                                    onClick={(e) => {
                                      this.props.history.push(
                                        `/roteiros-detalhe-visita?id=${atividade.idRoteiro}&seq=${atividade.seq}`
                                      );
                                    }}
                                  ></i>
                                </div>
                              </div>
                            ))}

                            {this.state.atividades.length > 0 && (
                              <div
                                style={{
                                  padding: "10px",
                                  cursor: "pointer",
                                  float: "left",
                                  width: "100%",
                                  textAlign: "center",
                                }}
                                onClick={(e) => {
                                  this.props.history.push("/atividades");
                                }}
                              >
                                Ver mais
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="col-md-8 stretch-card d-flex flex-wrap"
                  style={{ marginBottom: "20px" }}
                >
                  <div className="card">
                    <div className="card-body">
                      <div
                        className="float-left width-100"
                        style={{
                          padding: "5px",
                          position: "relative",
                        }}
                      >
                        <div className="float-left">DESPESAS POR MOTORISTA</div>
                        <div
                          className="float-right"
                          style={{
                            width: "170px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ width: "15%" }}>
                            <i className="fa fa-calendar"></i>
                          </div>
                          <div style={{ width: "85%" }}>
                            <select
                              className="form-control"
                              onChange={(e) => {
                                this.handleChangeFiltro(
                                  e.target.value,
                                  "filtroDespesas"
                                );
                              }}
                              value={this.state.filtroDespesas}
                            >
                              <option value="mesAtual">Mês atual</option>
                              <option value="mesAnterior">Mês anterior</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="row" style={{ alignItems: "center" }}>
                        <div
                          className="col-12"
                          style={{ float: "left", width: "100%" }}
                        >
                          <div className="d-flex" style={{ height: "350px" }}>
                            <div
                              id="chartdivDespesas"
                              style={{
                                width: "100%",
                                display: "flex",
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div
                  className="col-md-12 stretch-card d-flex flex-wrap"
                  style={{ marginBottom: "20px" }}
                >
                  <div className="card">
                    <div className="card-body">
                      <div
                        className="float-left width-100"
                        style={{
                          padding: "5px",
                          position: "relative",
                        }}
                      >
                        <div className="float-left">ENTREGAS POR ROTEIRO</div>
                        <div
                          className="float-right"
                          style={{
                            width: "170px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div style={{ width: "15%" }}>
                            <i className="fa fa-calendar"></i>
                          </div>
                          <div style={{ width: "85%" }}>
                            <select
                              className="form-control"
                              onChange={(e) => {
                                this.handleChangeFiltro(
                                  e.target.value,
                                  "filtroEntregasRoteiro"
                                );
                              }}
                              value={this.state.filtroEntregasRoteiro}
                            >
                              <option value="hoje">Hoje</option>
                              <option value="ontem">Ontem</option>
                              <option value="semana">Essa Semana</option>
                              <option value="mesAtual">Esse Mês</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="row" style={{ alignItems: "center" }}>
                        <div
                          className="col-12"
                          style={{ float: "left", width: "100%" }}
                        >
                          <div className="d-flex" style={{ height: "350px" }}>
                            <div
                              id="chartdivEntregasRoteiro"
                              style={{
                                width: "100%",
                                display: "flex",
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="row padding-dash">
                  <div
                    className="col-md-6 stretch-card d-flex flex-wrap"
                    style={{ marginBottom: "20px" }}
                  >
                    <div className="card">
                      <div className="card-body">
                        <div
                          className="float-left width-100"
                          style={{
                            padding: "5px",
                            position: "relative",
                          }}
                        >
                          <div className="float-left">NÃO CONFORMIDADES</div>
                          <div
                            className="float-right"
                            style={{
                              width: "170px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <div style={{ width: "15%" }}>
                              <i className="fa fa-calendar"></i>
                            </div>
                            <div style={{ width: "85%" }}>
                              <select
                                className="form-control"
                                onChange={(e) => {
                                  this.handleChangeFiltro(
                                    e.target.value,
                                    "filtroNaoConformidades"
                                  );
                                }}
                                value={this.state.filtroNaoConformidades}
                              >
                                <option value="mesAtual">Mês atual</option>
                                <option value="mesAnterior">
                                  Mês anterior
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="row" style={{ alignItems: "center" }}>
                          <div
                            className="col-12"
                            style={{ float: "left", width: "100%" }}
                          >
                            <div className="d-flex" style={{ height: "350px" }}>
                              <div
                                id="chartdivNaoConformidades"
                                style={{
                                  width: "100%",
                                  display: "flex",
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-md-6 stretch-card d-flex flex-wrap"
                    style={{ marginBottom: "20px" }}
                  >
                    <div className="card">
                      <div className="card-body">
                        <div
                          className="float-left width-100"
                          style={{
                            padding: "5px",
                            position: "relative",
                          }}
                        >
                          <div className="float-left">OCORRÊNCIAS</div>
                          <div
                            className="float-right"
                            style={{
                              width: "170px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <div style={{ width: "15%" }}>
                              <i className="fa fa-calendar"></i>
                            </div>
                            <div style={{ width: "85%" }}>
                              <select
                                className="form-control"
                                onChange={(e) => {
                                  this.handleChangeFiltro(
                                    e.target.value,
                                    "filtroOcorrencias"
                                  );
                                }}
                                value={this.state.filtroOcorrencias}
                              >
                                <option value="mesAtual">Mês atual</option>
                                <option value="mesAnterior">
                                  Mês anterior
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="row" style={{ alignItems: "center" }}>
                          <div
                            className="col-12"
                            style={{ float: "left", width: "100%" }}
                          >
                            <div className="d-flex" style={{ height: "350px" }}>
                              <div
                                id="chartdivOcorrencias"
                                style={{
                                  width: "100%",
                                  display: "flex",
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default RoteiroDetalhe;
