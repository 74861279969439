import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import { SideBar, NavBar, Footer } from "../../components";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import Switch from "react-switch";
import axios from "axios";
import queryString from "query-string";
const { phoneMask } = require("../../utils");

export default class PerfilNovo extends Component {
  constructor(props) {
    super(props);
    this.notificar = this.notificar.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
    this.onChangeSwitch = this.onChangeSwitch.bind(this);
    this.cadastrarPerfil = this.cadastrarPerfil.bind(this);
    this.validarPerfil = this.validarPerfil.bind(this);
    this.buscarPerfil = this.buscarPerfil.bind(this);

    this.state = {
      _id: null,
      desc: "",
      acessoApp: false,
      acessoAdm: false,
      errors: {
        desc: false,
      },
      roteiroVisualizar: false,
      roteiroCadastrar: false,
      despesaVisualizar: false,
      despesaCadastrar: false,
      usuarioVisualizar: false,
      usuarioCadastrar: false,
      veiculoVisualizar: false,
      veiculoCadastrar: false,
      checklistVisualizar: false,
      checklistCadastrar: false,
      notificacaoVisualizar: false,
      notificacaoCadastrar: false,
      perfilVisualizar: false,
      perfilCadastrar: false,
      dashboardVisualizar: false,
    };
  }

  async componentDidMount() {
    let id = queryString.parse(this.props.location.search).id;

    if (id !== undefined) {
      await this.buscarPerfil(id);
    }
  }

  async buscarPerfil(id) {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/retaguarda/perfis/${id}`,
        this.props.parameters()
      )
      .then((response) => {
        const perfil = response.data.perfil;

        this.setState({
          _id: perfil._id,
          desc: perfil.desc,
          acessoApp: perfil.acessoApp,
          acessoAdm: perfil.acessoAdm,
          roteiroVisualizar: perfil.roteiroVisualizar,
          roteiroCadastrar: perfil.roteiroCadastrar,
          despesaVisualizar: perfil.despesaVisualizar,
          despesaCadastrar: perfil.despesaCadastrar,
          usuarioVisualizar: perfil.usuarioVisualizar,
          usuarioCadastrar: perfil.usuarioCadastrar,
          veiculoVisualizar: perfil.veiculoVisualizar,
          veiculoCadastrar: perfil.veiculoCadastrar,
          checklistVisualizar: perfil.checklistVisualizar,
          checklistCadastrar: perfil.checklistCadastrar,
          notificacaoVisualizar: perfil.notificacaoVisualizar,
          notificacaoCadastrar: perfil.notificacaoCadastrar,
          perfilVisualizar: perfil.perfilVisualizar,
          perfilCadastrar: perfil.perfilCadastrar,
          dashboardVisualizar: perfil.dashboardVisualizar,
        });
      });
  }

  notificar(type, message) {
    toast[type](message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  onChangeInput(tipo, value) {
    if (tipo === "celular") {
      value = phoneMask(value);
    }
    this.setState({ [tipo]: value });
  }

  onChangeSwitch(checked, tipo) {
    this.setState({ [tipo]: checked });
  }

  validarPerfil() {
    let errors = this.state.errors;
    let valido = true;

    if (this.state.desc === "") {
      valido = false;
      errors.desc = true;
    } else {
      errors.desc = false;
    }

    this.setState({ errors });
    return valido;
  }

  async cadastrarPerfil() {
    if (!this.validarPerfil()) {
      this.notificar("warning", "Favor preencher todos os dados!");
      return false;
    }

    let acaoPagina = this.state._id === null ? "CADASTRAR" : "EDITAR";

    let perfilNovo = {
      desc: this.state.desc,
      acessoApp: this.state.acessoApp,
      acessoAdm: this.state.acessoAdm,
      roteiroVisualizar: this.state.roteiroVisualizar,
      roteiroCadastrar: this.state.roteiroCadastrar,
      despesaVisualizar: this.state.despesaVisualizar,
      despesaCadastrar: this.state.despesaCadastrar,
      usuarioVisualizar: this.state.usuarioVisualizar,
      usuarioCadastrar: this.state.usuarioCadastrar,
      veiculoVisualizar: this.state.veiculoVisualizar,
      veiculoCadastrar: this.state.veiculoCadastrar,
      checklistVisualizar: this.state.checklistVisualizar,
      checklistCadastrar: this.state.checklistCadastrar,
      notificacaoVisualizar: this.state.notificacaoVisualizar,
      notificacaoCadastrar: this.state.notificacaoCadastrar,
      perfilVisualizar: this.state.perfilVisualizar,
      perfilCadastrar: this.state.perfilCadastrar,
      dashboardVisualizar: this.state.dashboardVisualizar,
    };

    let urlAtualizar = `${this.props.getBaseUrlApi()}/retaguarda/perfis`;

    if (acaoPagina === "EDITAR") {
      perfilNovo._id = this.state._id;

      urlAtualizar = `${this.props.getBaseUrlApi()}/retaguarda/perfis/${
        this.state._id
      }`;
    }

    await axios
      .post(urlAtualizar, { perfil: perfilNovo }, this.props.parameters())
      .then((response) => {
        this.setState(
          {
            desc: "",
            acessoApp: false,
            acessoAdm: false,
            _id: null,
            roteiroVisualizar: false,
            roteiroCadastrar: false,
            despesaVisualizar: false,
            despesaCadastrar: false,
            usuarioVisualizar: false,
            usuarioCadastrar: false,
            veiculoVisualizar: false,
            veiculoCadastrar: false,
            checklistVisualizar: false,
            checklistCadastrar: false,
            notificacaoVisualizar: false,
            notificacaoCadastrar: false,
            perfilVisualizar: false,
            perfilCadastrar: false,
            dashboardVisualizar: false,
          },
          () => {
            if (acaoPagina === "CADASTRAR") {
              this.notificar(
                "success",
                "Perfil de usuário cadastrado com sucesso!"
              );
            } else {
              this.notificar("success", "Perfil editado com sucesso!");
              setTimeout(() => {
                this.props.history.push("/perfil");
              }, 1000);
            }
          }
        );
      })
      .catch((error) => {
        this.notificar("warning", error.response.data.erro);
      });
  }

  render() {
    const InputRequired = () => {
      return <span className="required-icone">*</span>;
    };

    return (
      <React.Fragment>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
        <div className="container-scroller font-app">
          <NavBar title="Perfis / Novo perfil" />
          <div className="container-fluid page-body-wrapper">
            <SideBar itemActive={"perfis"} />
            <div className="main-panel">
              <div className="content-wrapper">
                {/* <TitleAndDescription
                  title="Usuários / Novo usuário"
                  description=""
                /> */}

                <div className="row">
                  <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body5 dashboard-tabs p-0">
                        <div className="tab-content py-0 px-0">
                          <div
                            className="tab-pane fade show active"
                            id="overview"
                            role="tabpanel"
                            aria-labelledby="overview-tab"
                          >
                            <div className="d-flex flex-wrap justify-content-xl-between">
                              <div
                                style={{
                                  padding: "10px",
                                  float: "left",
                                  width: "100%",
                                }}
                              >
                                {/* <div
                                  className="float-left width-100"
                                  style={{
                                    borderBottom: "1px solid #CCC",
                                    padding: "5px",
                                    marginBottom: "20px",
                                  }}
                                >
                                  DADOS DO PERFIL
                                </div> */}
                                <div className="row">
                                  <div className="col-md-4 pd-veiculo2">
                                    <div className="row">
                                      <div className="col-md-12">
                                        <div className="form-group form-group-chegoo">
                                          <label>
                                            Descrição
                                            <InputRequired />
                                          </label>
                                          <input
                                            type="text"
                                            value={this.state.desc}
                                            label="desc do perfil"
                                            className={`form-control form-control-chegoo font-size-14 ${
                                              this.state.errors.desc
                                                ? "input-error"
                                                : ""
                                            }`}
                                            onChange={(e) =>
                                              this.onChangeInput(
                                                "desc",
                                                e.target.value
                                              )
                                            }
                                            ref={(node) => {
                                              if (node) {
                                                if (this.state.errors.desc) {
                                                  node.style.setProperty(
                                                    "border-color",
                                                    "red",
                                                    "important"
                                                  );
                                                } else {
                                                  node.style.setProperty(
                                                    "border-color",
                                                    "#ced4da",
                                                    "important"
                                                  );
                                                }
                                              }
                                            }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-6 pd-veiculo">
                                    <div className="col-md-12">
                                      <div className="row">
                                        <div className="col-md-3">
                                          <div className="form-group form-group-chegoo">
                                            <label>Acesso App ?</label>
                                            <div className="item-switch">
                                              <Switch
                                                checked={this.state.acessoApp}
                                                onChange={(checked) =>
                                                  this.onChangeSwitch(
                                                    checked,
                                                    "acessoApp"
                                                  )
                                                }
                                                onColor="#f0e8fc"
                                                onHandleColor="#2da951"
                                                handleDiameter={22}
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                height={14}
                                                width={48}
                                                // height={16}
                                                // width={48}
                                              />
                                            </div>
                                          </div>
                                        </div>

                                        <div className="col-md-4">
                                          <div className="form-group form-group-chegoo">
                                            <label>Acesso Retaguarda ?</label>
                                            <div className="item-switch">
                                              <Switch
                                                checked={this.state.acessoAdm}
                                                onChange={(checked) =>
                                                  this.onChangeSwitch(
                                                    checked,
                                                    "acessoAdm"
                                                  )
                                                }
                                                onColor="#f0e8fc"
                                                onHandleColor="#2da951"
                                                handleDiameter={22}
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                height={14}
                                                width={48}
                                                // height={16}
                                                // width={48}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="row">
                                      <div className="col-md-6">
                                        <div className="form-group form-group-chegoo">
                                          <label className="font-size-16">
                                            Módulos Retaguarda
                                          </label>

                                          <div
                                            className="table-responsive"
                                            style={{ marginTop: "10px" }}
                                          >
                                            <table className="table">
                                              <thead className="thead-dark">
                                                <th>Acesso</th>
                                                <th>Visualizar</th>
                                                <th>Cadastrar/Alterar</th>
                                              </thead>
                                              <tbody>
                                                <tr>
                                                  <td>Roteiros</td>
                                                  <td>
                                                    {" "}
                                                    <div className="item-switch">
                                                      <Switch
                                                        checked={
                                                          this.state
                                                            .roteiroVisualizar
                                                        }
                                                        onChange={(checked) =>
                                                          this.onChangeSwitch(
                                                            checked,
                                                            "roteiroVisualizar"
                                                          )
                                                        }
                                                        onColor="#f0e8fc"
                                                        onHandleColor="#2da951"
                                                        handleDiameter={22}
                                                        uncheckedIcon={false}
                                                        checkedIcon={false}
                                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                        height={14}
                                                        width={48}
                                                        // height={16}
                                                        // width={48}
                                                        disabled={
                                                          this.state.acessoAdm
                                                            ? false
                                                            : true
                                                        }
                                                      />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    {" "}
                                                    <div className="item-switch">
                                                      <Switch
                                                        checked={
                                                          this.state
                                                            .roteiroCadastrar
                                                        }
                                                        onChange={(checked) =>
                                                          this.onChangeSwitch(
                                                            checked,
                                                            "roteiroCadastrar"
                                                          )
                                                        }
                                                        onColor="#f0e8fc"
                                                        onHandleColor="#2da951"
                                                        handleDiameter={22}
                                                        uncheckedIcon={false}
                                                        checkedIcon={false}
                                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                        height={14}
                                                        width={48}
                                                        // height={16}
                                                        // width={48}
                                                        disabled={
                                                          this.state.acessoAdm
                                                            ? false
                                                            : true
                                                        }
                                                      />
                                                    </div>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>Despesas</td>
                                                  <td>
                                                    {" "}
                                                    <div className="item-switch">
                                                      <Switch
                                                        checked={
                                                          this.state
                                                            .despesaVisualizar
                                                        }
                                                        onChange={(checked) =>
                                                          this.onChangeSwitch(
                                                            checked,
                                                            "despesaVisualizar"
                                                          )
                                                        }
                                                        onColor="#f0e8fc"
                                                        onHandleColor="#2da951"
                                                        handleDiameter={22}
                                                        uncheckedIcon={false}
                                                        checkedIcon={false}
                                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                        height={14}
                                                        width={48}
                                                        // height={16}
                                                        // width={48}
                                                        disabled={
                                                          this.state.acessoAdm
                                                            ? false
                                                            : true
                                                        }
                                                      />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    {" "}
                                                    <div className="item-switch">
                                                      <Switch
                                                        checked={
                                                          this.state
                                                            .despesaCadastrar
                                                        }
                                                        onChange={(checked) =>
                                                          this.onChangeSwitch(
                                                            checked,
                                                            "despesaCadastrar"
                                                          )
                                                        }
                                                        onColor="#f0e8fc"
                                                        onHandleColor="#2da951"
                                                        handleDiameter={22}
                                                        uncheckedIcon={false}
                                                        checkedIcon={false}
                                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                        height={14}
                                                        width={48}
                                                        // height={16}
                                                        // width={48}
                                                        disabled={
                                                          this.state.acessoAdm
                                                            ? false
                                                            : true
                                                        }
                                                      />
                                                    </div>
                                                  </td>
                                                </tr>

                                                <tr>
                                                  <td>Usuários</td>
                                                  <td>
                                                    {" "}
                                                    <div className="item-switch">
                                                      <Switch
                                                        checked={
                                                          this.state
                                                            .usuarioVisualizar
                                                        }
                                                        onChange={(checked) =>
                                                          this.onChangeSwitch(
                                                            checked,
                                                            "usuarioVisualizar"
                                                          )
                                                        }
                                                        onColor="#f0e8fc"
                                                        onHandleColor="#2da951"
                                                        handleDiameter={22}
                                                        uncheckedIcon={false}
                                                        checkedIcon={false}
                                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                        height={14}
                                                        width={48}
                                                        // height={16}
                                                        // width={48}
                                                        disabled={
                                                          this.state.acessoAdm
                                                            ? false
                                                            : true
                                                        }
                                                      />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    {" "}
                                                    <div className="item-switch">
                                                      <Switch
                                                        checked={
                                                          this.state
                                                            .usuarioCadastrar
                                                        }
                                                        onChange={(checked) =>
                                                          this.onChangeSwitch(
                                                            checked,
                                                            "usuarioCadastrar"
                                                          )
                                                        }
                                                        onColor="#f0e8fc"
                                                        onHandleColor="#2da951"
                                                        handleDiameter={22}
                                                        uncheckedIcon={false}
                                                        checkedIcon={false}
                                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                        height={14}
                                                        width={48}
                                                        // height={16}
                                                        // width={48}
                                                        disabled={
                                                          this.state.acessoAdm
                                                            ? false
                                                            : true
                                                        }
                                                      />
                                                    </div>
                                                  </td>
                                                </tr>

                                                <tr>
                                                  <td>Veículos</td>
                                                  <td>
                                                    {" "}
                                                    <div className="item-switch">
                                                      <Switch
                                                        checked={
                                                          this.state
                                                            .veiculoVisualizar
                                                        }
                                                        onChange={(checked) =>
                                                          this.onChangeSwitch(
                                                            checked,
                                                            "veiculoVisualizar"
                                                          )
                                                        }
                                                        onColor="#f0e8fc"
                                                        onHandleColor="#2da951"
                                                        handleDiameter={22}
                                                        uncheckedIcon={false}
                                                        checkedIcon={false}
                                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                        height={14}
                                                        width={48}
                                                        // height={16}
                                                        // width={48}
                                                        disabled={
                                                          this.state.acessoAdm
                                                            ? false
                                                            : true
                                                        }
                                                      />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    {" "}
                                                    <div className="item-switch">
                                                      <Switch
                                                        checked={
                                                          this.state
                                                            .veiculoCadastrar
                                                        }
                                                        onChange={(checked) =>
                                                          this.onChangeSwitch(
                                                            checked,
                                                            "veiculoCadastrar"
                                                          )
                                                        }
                                                        onColor="#f0e8fc"
                                                        onHandleColor="#2da951"
                                                        handleDiameter={22}
                                                        uncheckedIcon={false}
                                                        checkedIcon={false}
                                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                        height={14}
                                                        width={48}
                                                        // height={16}
                                                        // width={48}
                                                        disabled={
                                                          this.state.acessoAdm
                                                            ? false
                                                            : true
                                                        }
                                                      />
                                                    </div>
                                                  </td>
                                                </tr>
                                                <tr>
                                                  <td>Checklists</td>
                                                  <td>
                                                    {" "}
                                                    <div className="item-switch">
                                                      <Switch
                                                        checked={
                                                          this.state
                                                            .checklistVisualizar
                                                        }
                                                        onChange={(checked) =>
                                                          this.onChangeSwitch(
                                                            checked,
                                                            "checklistVisualizar"
                                                          )
                                                        }
                                                        onColor="#f0e8fc"
                                                        onHandleColor="#2da951"
                                                        handleDiameter={22}
                                                        uncheckedIcon={false}
                                                        checkedIcon={false}
                                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                        height={14}
                                                        width={48}
                                                        // height={16}
                                                        // width={48}
                                                        disabled={
                                                          this.state.acessoAdm
                                                            ? false
                                                            : true
                                                        }
                                                      />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    {" "}
                                                    <div className="item-switch">
                                                      <Switch
                                                        checked={
                                                          this.state
                                                            .checklistCadastrar
                                                        }
                                                        onChange={(checked) =>
                                                          this.onChangeSwitch(
                                                            checked,
                                                            "checklistCadastrar"
                                                          )
                                                        }
                                                        onColor="#f0e8fc"
                                                        onHandleColor="#2da951"
                                                        handleDiameter={22}
                                                        uncheckedIcon={false}
                                                        checkedIcon={false}
                                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                        height={14}
                                                        width={48}
                                                        // height={16}
                                                        // width={48}
                                                        disabled={
                                                          this.state.acessoAdm
                                                            ? false
                                                            : true
                                                        }
                                                      />
                                                    </div>
                                                  </td>
                                                </tr>

                                                <tr>
                                                  <td>Notificações</td>
                                                  <td>
                                                    {" "}
                                                    <div className="item-switch">
                                                      <Switch
                                                        checked={
                                                          this.state
                                                            .notificacaoVisualizar
                                                        }
                                                        onChange={(checked) =>
                                                          this.onChangeSwitch(
                                                            checked,
                                                            "notificacaoVisualizar"
                                                          )
                                                        }
                                                        onColor="#f0e8fc"
                                                        onHandleColor="#2da951"
                                                        handleDiameter={22}
                                                        uncheckedIcon={false}
                                                        checkedIcon={false}
                                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                        height={14}
                                                        width={48}
                                                        // height={16}
                                                        // width={48}
                                                        disabled={
                                                          this.state.acessoAdm
                                                            ? false
                                                            : true
                                                        }
                                                      />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    {" "}
                                                    <div className="item-switch">
                                                      <Switch
                                                        checked={
                                                          this.state
                                                            .notificacaoCadastrar
                                                        }
                                                        onChange={(checked) =>
                                                          this.onChangeSwitch(
                                                            checked,
                                                            "notificacaoCadastrar"
                                                          )
                                                        }
                                                        onColor="#f0e8fc"
                                                        onHandleColor="#2da951"
                                                        handleDiameter={22}
                                                        uncheckedIcon={false}
                                                        checkedIcon={false}
                                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                        height={14}
                                                        width={48}
                                                        // height={16}
                                                        // width={48}
                                                        disabled={
                                                          this.state.acessoAdm
                                                            ? false
                                                            : true
                                                        }
                                                      />
                                                    </div>
                                                  </td>
                                                </tr>

                                                <tr>
                                                  <td>Perfis</td>
                                                  <td>
                                                    {" "}
                                                    <div className="item-switch">
                                                      <Switch
                                                        checked={
                                                          this.state
                                                            .perfilVisualizar
                                                        }
                                                        onChange={(checked) =>
                                                          this.onChangeSwitch(
                                                            checked,
                                                            "perfilVisualizar"
                                                          )
                                                        }
                                                        onColor="#f0e8fc"
                                                        onHandleColor="#2da951"
                                                        handleDiameter={22}
                                                        uncheckedIcon={false}
                                                        checkedIcon={false}
                                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                        height={14}
                                                        width={48}
                                                        // height={16}
                                                        // width={48}
                                                        disabled={
                                                          this.state.acessoAdm
                                                            ? false
                                                            : true
                                                        }
                                                      />
                                                    </div>
                                                  </td>
                                                  <td>
                                                    {" "}
                                                    <div className="item-switch">
                                                      <Switch
                                                        checked={
                                                          this.state
                                                            .perfilCadastrar
                                                        }
                                                        onChange={(checked) =>
                                                          this.onChangeSwitch(
                                                            checked,
                                                            "perfilCadastrar"
                                                          )
                                                        }
                                                        onColor="#f0e8fc"
                                                        onHandleColor="#2da951"
                                                        handleDiameter={22}
                                                        uncheckedIcon={false}
                                                        checkedIcon={false}
                                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                        height={14}
                                                        width={48}
                                                        // height={16}
                                                        // width={48}
                                                        disabled={
                                                          this.state.acessoAdm
                                                            ? false
                                                            : true
                                                        }
                                                      />
                                                    </div>
                                                  </td>
                                                </tr>

                                                <tr>
                                                  <td>Dashboard</td>
                                                  <td>
                                                    {" "}
                                                    <div className="item-switch">
                                                      <Switch
                                                        checked={
                                                          this.state
                                                            .dashboardVisualizar
                                                        }
                                                        onChange={(checked) =>
                                                          this.onChangeSwitch(
                                                            checked,
                                                            "dashboardVisualizar"
                                                          )
                                                        }
                                                        onColor="#f0e8fc"
                                                        onHandleColor="#2da951"
                                                        handleDiameter={22}
                                                        uncheckedIcon={false}
                                                        checkedIcon={false}
                                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                        height={14}
                                                        width={48}
                                                        // height={16}
                                                        // width={48}
                                                        disabled={
                                                          this.state.acessoAdm
                                                            ? false
                                                            : true
                                                        }
                                                      />
                                                    </div>
                                                  </td>
                                                  <td></td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-md-2 pd-veiculo2">
                                    <div className="form-group form-group-chegoo2">
                                      <button
                                        className="btn btn-success btn-app-verde"
                                        onClick={this.cadastrarPerfil}
                                      >
                                        Salvar
                                      </button>
                                    </div>
                                  </div>
                                </div>

                                {/* felipe */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
