import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import { SideBar, NavBar, Footer } from "../../components";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import Select from "react-select";
import queryString from "query-string";

export default class UnidadeMedidaNova extends Component {
  constructor(props) {
    super(props);
    this.notificar = this.notificar.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
    this.cadastrarUnidadeMedida = this.cadastrarUnidadeMedida.bind(this);
    this.validarUnidadeMedida = this.validarUnidadeMedida.bind(this);
    this.buscarUnidadeMedida = this.buscarUnidadeMedida.bind(this);

    this.state = {
      _id: null,
      desc: "",
      errors: {
        desc: false,
      },
      action: "CADASTRAR",
      isSaving: false,
    };
  }

  async componentDidMount() {
    const userLogado = JSON.parse(localStorage.getItem("usuario"));
    if (userLogado.nivel === "4") {
      return this.props.history.push("/nao-conformidades");
    }
    if (userLogado.nivel === "5") {
      return this.props.history.push("/despesas");
    }
    let id = queryString.parse(this.props.location.search).id;

    if (id !== undefined) {
      await this.buscarUnidadeMedida(id);
    }
  }

  notificar(type, message) {
    toast[type](message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  onChangeInput(tipo, value) {
    this.setState({ [tipo]: value }, () => {});
  }

  validarUnidadeMedida() {
    let errors = this.state.errors;
    let valido = true;

    if (this.state.desc === "") {
      errors.desc = true;
      valido = false;
    } else {
      errors.desc = false;
    }

    this.setState({ errors });
    return valido;
  }

  async cadastrarUnidadeMedida() {
    if (this.state.isSaving) {
      return false;
    }

    this.setState({ isSaving: true }, async () => {
      if (!this.validarUnidadeMedida()) {
        this.notificar("warning", "Favor preencher todos os dados!");
        this.setState({ isSaving: false });
        return false;
      }

      let unidadeMedidaNova = {
        desc: this.state.desc,
      };

      let urlAtualizar = `${this.props.getBaseUrlApi()}/retaguarda/unidades-medida`;

      if (this.state.action === "EDITAR") {
        unidadeMedidaNova._id = this.state._id;
        urlAtualizar = `${this.props.getBaseUrlApi()}/retaguarda/unidades-medida/${
          this.state._id
        }`;
      }

      await axios
        .post(
          urlAtualizar,
          { unidadeMedida: unidadeMedidaNova },
          this.props.parameters()
        )
        .then((response) => {
          if (this.state.action === "CADASTRAR") {
            this.notificar(
              "success",
              "Unidade de Medida cadastrada com sucesso!"
            );
          } else {
            this.notificar("success", "Unidade de Medida editada com sucesso!");
          }

          setTimeout(() => {
            this.props.history.push("/unidades-medida");
          }, 1000);
        })
        .catch((error) => {
          this.notificar("warning", error.response.data.erro);
          this.setState({ isSaving: false });
        });
    });
  }

  async buscarUnidadeMedida(id) {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/retaguarda/unidades-medida/${id}`,
        this.props.parameters()
      )
      .then((response) => {
        const unidadeMedida = response.data.unidadeMedida;

        if (unidadeMedida === null) {
          this.props.history.push("/unidades-medida");
          return false;
        }

        this.setState({
          _id: unidadeMedida._id,
          desc: unidadeMedida.desc,
          action: "EDITAR",
        });
      });
  }

  render() {
    const InputRequired = () => {
      return <span className="required-icone">*</span>;
    };
    return (
      <React.Fragment>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
        <div className="container-scroller font-app">
          <NavBar
            title={`Unidades Medida / ${
              this.state.action === "CADASTRAR" ? "Nova" : "Editar"
            }`}
          />
          <div className="container-fluid page-body-wrapper">
            <SideBar itemActive={"unidades-medida"} />
            <div className="main-panel">
              <div className="content-wrapper">
                <div className="row">
                  <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body5 dashboard-tabs">
                        <div className="tab-content py-0 px-0">
                          <div
                            className="tab-pane fade show active"
                            id="overview"
                            role="tabpanel"
                            aria-labelledby="overview-tab"
                          >
                            <div className="d-flex flex-wrap justify-content-xl-between">
                              <div
                                style={{
                                  float: "left",
                                  width: "100%",
                                }}
                              >
                                <div className="row">
                                  <div className="col-md-3 pd-veiculo2">
                                    <div className="form-group form-group-chegoo">
                                      <label>
                                        Descrição&nbsp;
                                        <InputRequired />
                                      </label>
                                      <input
                                        type="text"
                                        value={this.state.desc}
                                        onChange={(e) => {
                                          let value =
                                            e.target.value.toUpperCase();

                                          this.onChangeInput("desc", value);
                                        }}
                                        className={`form-control font-size-14 form-control-chegoo required-chegoo`}
                                        ref={(node) => {
                                          if (node) {
                                            if (this.state.errors.desc) {
                                              node.style.setProperty(
                                                "border-color",
                                                "red",
                                                "important"
                                              );
                                            } else {
                                              node.style.setProperty(
                                                "border-color",
                                                "#ced4da",
                                                "important"
                                              );
                                            }
                                          }
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-md-2 pd-veiculo2">
                                    <div className="form-group form-group-chegoo2">
                                      <button
                                        className="btn btn-success btn-app-verde"
                                        onClick={() => {
                                          this.cadastrarUnidadeMedida();
                                        }}
                                      >
                                        {this.state.isSaving ? "Salvando..." : "Salvar"}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
