import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import { SideBar, NavBar, Footer } from "../../components";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import Select from "react-select";
import queryString from "query-string";

export default class ObraNova extends Component {
  constructor(props) {
    super(props);
    this.notificar = this.notificar.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
    this.cadastrarObra = this.cadastrarObra.bind(this);
    this.validarObra = this.validarObra.bind(this);
    this.buscarObra = this.buscarObra.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.loadUsuarios = this.loadUsuarios.bind(this);
    this.loadSequenciaObra = this.loadSequenciaObra.bind(this);

    this.state = {
      _id: null,
      cod: "",
      desc: "",
      situacao: "0", //ABERTA OU FINALIZADA
      usuario: null,
      usuarios: [],
      errors: {
        cod: false,
        desc: false,
        usuario: false,
      },
      action: "CADASTRAR",
      isSaving: false
    };
  }

  async componentDidMount() {
    const userLogado = JSON.parse(localStorage.getItem("usuario"));
    if (userLogado.nivel === "3") {
      return this.props.history.push("/pedidos-material");
    }
    if (userLogado.nivel === "4") {
      return this.props.history.push("/nao-conformidades");
    }
    if (userLogado.nivel === "5") {
      return this.props.history.push("/despesas");
    }
    let id = queryString.parse(this.props.location.search).id;
    let action = "CADASTRAR";

    if (id !== undefined) {
      await this.buscarObra(id);
      action = "EDITAR";
    }

    await this.loadUsuarios();

    if (action === "CADASTRAR") {
      // await this.loadSequenciaObra();
    }
  }

  notificar(type, message) {
    toast[type](message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  onChangeInput(tipo, value) {
    this.setState({ [tipo]: value }, () => {});
  }

  validarObra() {
    let errors = this.state.errors;
    let valido = true;

    if (this.state.cod === "") {
      errors.cod = true;
      valido = false;
    } else {
      let cod = parseInt(this.state.cod);
      if (cod === 0) {
        errors.cod = true;
        valido = false;
      } else {
        errors.cod = false;
      }
    }
    if (this.state.desc === "") {
      errors.desc = true;
      valido = false;
    } else {
      errors.desc = false;
    }

    this.setState({ errors });
    return valido;
  }

  async cadastrarObra() {
    if(this.state.isSaving){
      return false;
    }
   this.setState({isSaving: true}, async() => {
    if (!this.validarObra()) {
      this.notificar("warning", "Favor preencher todos os dados!");
      this.setState({isSaving: false})
      return false;
    }

    let obraNova = {
      cod: parseInt(this.state.cod),
      desc: this.state.desc,
      situacao: this.state.situacao,
      usuario: this.state.usuario,
    };

    let urlAtualizar = `${this.props.getBaseUrlApi()}/retaguarda/obras`;

    if (this.state.action === "EDITAR") {
      obraNova._id = this.state._id;
      urlAtualizar = `${this.props.getBaseUrlApi()}/retaguarda/obras/${
        this.state._id
      }`;
    }

    await axios
      .post(urlAtualizar, { obra: obraNova }, this.props.parameters())
      .then((response) => {
        if (this.state.action === "CADASTRAR") {
          this.notificar("success", "Obra cadastrada com sucesso!");
        } else {
          this.notificar("success", "Obra editada com sucesso!");
        }

        setTimeout(() => {
          this.props.history.push("/obras");
        }, 1000);
      })
      .catch((error) => {
        this.notificar("warning", error.response.data.erro);
        this.setState({isSaving: false})
      });
   })
    
  }

  async buscarObra(id) {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/retaguarda/obras/${id}`,
        this.props.parameters()
      )
      .then((response) => {
        const obra = response.data.obra;

        if (obra === null) {
          this.props.history.push("/obras");
          return false;
        }

        let usuario = null;
        if (obra.usuario !== null) {
          usuario = obra.usuario;
          usuario.label = usuario.desc;
          usuario.value = usuario._id;
        }

        this.setState({
          _id: obra._id,
          cod: obra.cod,
          desc: obra.desc,
          usuario,
          situacao: obra.situacao,
          action: "EDITAR",
        });
      });
  }

  handleFocus(e) {
    e.target.select();
  }

  async loadUsuarios() {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/retaguarda/usuarios`,
        this.props.parameters()
      )
      .then((response) => {
        let usuarios = response.data.usuarios;

        for (let item of usuarios) {
          item.label = item.desc;
          item.value = item._id;
        }

        this.setState({ usuarios });
      });
  }

  async loadSequenciaObra() {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/retaguarda/sequencia-obra`,
        this.props.parameters()
      )
      .then((response) => {
        let sequencia = response.data.sequencia;
        this.setState({ cod: sequencia });
      });
  }

  render() {
    const InputRequired = () => {
      return <span className="required-icone">*</span>;
    };
    return (
      <React.Fragment>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
        <div className="container-scroller font-app">
          <NavBar
            title={`Obras / ${
              this.state.action === "CADASTRAR" ? "Nova" : "Editar"
            } obra`}
          />
          <div className="container-fluid page-body-wrapper">
            <SideBar itemActive={"obras"} />
            <div className="main-panel">
              <div className="content-wrapper">
                <div className="row">
                  <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body5 dashboard-tabs">
                        <div className="tab-content py-0 px-0">
                          <div
                            className="tab-pane fade show active"
                            id="overview"
                            role="tabpanel"
                            aria-labelledby="overview-tab"
                          >
                            <div className="d-flex flex-wrap justify-content-xl-between">
                              <div
                                style={{
                                  float: "left",
                                  width: "100%",
                                }}
                              >
                                <div className="row">
                                  <div className="col-md-1 pd-veiculo2">
                                    <div className="form-group form-group-chegoo">
                                      <label>
                                        Código&nbsp;
                                        <InputRequired />
                                      </label>
                                      <input
                                        type="text"
                                        value={this.state.cod}
                                        onFocus={this.handleFocus}
                                        disabled={
                                          this.state.action === "EDITAR"
                                        }
                                        className={`form-control font-size-14 form-control-chegoo required-chegoo`}
                                        onChange={(e) =>
                                          this.onChangeInput(
                                            "cod",
                                            e.target.value
                                          )
                                        }
                                        ref={(node) => {
                                          if (node) {
                                            if (this.state.errors.cod) {
                                              node.style.setProperty(
                                                "border-color",
                                                "red",
                                                "important"
                                              );
                                            } else {
                                              node.style.setProperty(
                                                "border-color",
                                                "#ced4da",
                                                "important"
                                              );
                                            }
                                          }
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-5 pd-veiculo2">
                                    <div className="form-group form-group-chegoo">
                                      <label>
                                        Descrição&nbsp;
                                        <InputRequired />
                                      </label>
                                      <input
                                        type="text"
                                        value={this.state.desc}
                                        onChange={(e) =>
                                          this.onChangeInput(
                                            "desc",
                                            e.target.value
                                          )
                                        }
                                        className={`form-control font-size-14 form-control-chegoo required-chegoo`}
                                        ref={(node) => {
                                          if (node) {
                                            if (this.state.errors.desc) {
                                              node.style.setProperty(
                                                "border-color",
                                                "red",
                                                "important"
                                              );
                                            } else {
                                              node.style.setProperty(
                                                "border-color",
                                                "#ced4da",
                                                "important"
                                              );
                                            }
                                          }
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-5 pd-veiculo">
                                    <div
                                      className="form-group form-group-chegoo"
                                      style={{ position: "relative" }}
                                    >
                                      <label>
                                        Usuário&nbsp;
                                        <InputRequired />
                                      </label>
                                      <Select
                                        value={this.state.usuario}
                                        onChange={(item) =>
                                          this.onChangeInput("usuario", item)
                                        }
                                        options={this.state.usuarios}
                                        placeholder="Selecione o usuário"
                                        className={`${
                                          this.state.errors.usuario
                                            ? "select-error"
                                            : ""
                                        } required-chegoo`}
                                        isDisabled={this.state.situacao === "1"}
                                        styles={{
                                          control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            fontSize: "14px",
                                            height: "35px",
                                            minHeight: "35px",
                                            backgroundColor: "#fffef6",
                                          }),
                                          menu: (baseStyles, state) => ({
                                            ...baseStyles,
                                            fontSize: "14px",
                                          }),
                                        }}
                                      />
                                      {this.state.usuario !== null && (
                                        <i
                                          className="fa fa-times"
                                          style={{
                                            position: "absolute",
                                            right: "50px",
                                            top: "34px",
                                            color:
                                              this.state.situacao === "0"
                                                ? "#dd0e0e"
                                                : "#716666",
                                            fontSize: "18px",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            if (this.state.situacao === "0") {
                                              this.setState({ usuario: null });
                                            }
                                          }}
                                        ></i>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-md-2 pd-veiculo2">
                                    <div className="form-group form-group-chegoo">
                                      <label>
                                        Situação&nbsp;
                                        <InputRequired />
                                      </label>
                                      <select
                                        className="form-control"
                                        value={this.state.situacao}
                                        onChange={(e) => {
                                          this.onChangeInput(
                                            "situacao",
                                            e.target.value
                                          );
                                        }}
                                      >
                                        <option value="0">Aberta</option>
                                        <option value="1">Finalizada</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-md-2 pd-veiculo2">
                                    <div className="form-group form-group-chegoo2">
                                      <button
                                        className="btn btn-success btn-app-verde"
                                        onClick={() => {
                                          this.cadastrarObra();
                                        }}
                                      >
                                        {this.state.isSaving ? "Salvando..." : "Salvar"}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
