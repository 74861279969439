import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { UncontrolledTooltip } from "reactstrap";

export default function SideBar(props) {
  const [menuRelatoriosAberto, setMenuRelatoriosAberto] = useState(false);
  const [exibirRelatoriosAgrupados, setExibirRelatoriosAgrupados] =
    useState(true);
  const [usuario, setUsuario] = useState(
    JSON.parse(localStorage.getItem("usuario"))
  );

  useEffect(() => {
    if (localStorage.getItem("menuEncolhido") === "true") {
      setExibirRelatoriosAgrupados(false);
    } else {
      setExibirRelatoriosAgrupados(true);
    }
  }, []);

  return (
    <React.Fragment>
      <nav className="sidebar sidebar-offcanvas noprintfelipe" id="sidebar">
        <ul className="nav">
          {usuario.nivel !== "3" && usuario.nivel !== "4" && (
            <li
              className={`nav-item ${
                props.itemActive === "despesas" ? "active" : ""
              }`}
            >
              <Link className="nav-link" to="/despesas">
                <span style={{ minWidth: "30px", textAlign: "center" }}>
                  <img
                    src="/images/icons/despesas.svg"
                    id="item-despesas"
                    style={{ width: "22px", color: "red" }}
                  />
                </span>
                &nbsp;&nbsp;
                <span className="menu-title">Lançamentos</span>
                <UncontrolledTooltip placement="right" target={`item-despesas`}>
                  Lançamentos
                </UncontrolledTooltip>
              </Link>
            </li>
          )}

          {usuario.nivel !== "4" && usuario.nivel !== "5" && (
            <li
              className={`nav-item ${
                props.itemActive === "pedidosMaterial" ? "active" : ""
              }`}
            >
              <Link className="nav-link" to="/pedidos-material">
                <span style={{ minWidth: "30px", textAlign: "center" }}>
                  <img
                    src="/images/icons/usuarios.svg"
                    id="item-usuarios5"
                    style={{ width: "22px", color: "red" }}
                  />
                </span>
                &nbsp;&nbsp;
                <span className="menu-title">Pedidos de Material</span>
                <UncontrolledTooltip
                  placement="right"
                  target={`item-usuarios5`}
                >
                  Pedidos de Material
                </UncontrolledTooltip>
              </Link>
            </li>
          )}

          {usuario.nivel !== "3" && usuario.nivel !== "4" && usuario.nivel !== "5" && (
            <li
              className={`nav-item ${
                props.itemActive === "obras" ? "active" : ""
              }`}
            >
              <Link className="nav-link" to="/obras">
                <span style={{ minWidth: "30px", textAlign: "center" }}>
                  <img
                    src="/images/icons/usuarios.svg"
                    id="item-usuarios"
                    style={{ width: "22px", color: "red" }}
                  />
                </span>
                &nbsp;&nbsp;
                <span className="menu-title">Obras</span>
                <UncontrolledTooltip placement="right" target={`item-usuarios`}>
                  Obras
                </UncontrolledTooltip>
              </Link>
            </li>
          )}

          {usuario.nivel !== "3" && usuario.nivel !== "4" && usuario.nivel !== "5" && (
            <li
              className={`nav-item ${
                props.itemActive === "tipos-despesa" ? "active" : ""
              }`}
            >
              <Link className="nav-link" to="/tipos-despesa">
                <span style={{ minWidth: "30px", textAlign: "center" }}>
                  <img
                    src="/images/icons/despesas.svg"
                    id="item-despesas3"
                    style={{ width: "22px", color: "red" }}
                  />
                </span>
                &nbsp;&nbsp;
                <span className="menu-title">Tipos de Lançamento</span>
                <UncontrolledTooltip
                  placement="right"
                  target={`item-despesas3`}
                >
                  Tipos de Lançamento
                </UncontrolledTooltip>
              </Link>
            </li>
          )}

          {usuario.nivel !== "3" && usuario.nivel !== "4" && usuario.nivel !== "5" && (
            <li
              className={`nav-item ${
                props.itemActive === "usuarios" ? "active" : ""
              }`}
            >
              <Link className="nav-link" to="/usuarios">
                <span style={{ minWidth: "30px", textAlign: "center" }}>
                  <img
                    src="/images/icons/usuarios.svg"
                    id="item-usuarios"
                    style={{ width: "22px", color: "red" }}
                  />
                </span>
                &nbsp;&nbsp;
                <span className="menu-title">Usuários</span>
                <UncontrolledTooltip placement="right" target={`item-usuarios`}>
                  Usuários
                </UncontrolledTooltip>
              </Link>
            </li>
          )}

          {usuario.nivel !== "3" && usuario.nivel !== "5" && (
            <li
              className={`nav-item ${
                props.itemActive === "naoConformidades" ? "active" : ""
              }`}
            >
              <Link className="nav-link" to="/nao-conformidades">
                <span style={{ minWidth: "30px", textAlign: "center" }}>
                  <img
                    src="/images/icons/usuarios.svg"
                    id="item-nao-conformidades"
                    style={{ width: "22px", color: "red" }}
                  />
                </span>
                &nbsp;&nbsp;
                <span className="menu-title">Não Conformidades</span>
                <UncontrolledTooltip
                  placement="right"
                  target={`item-nao-conformidades`}
                >
                  Não Conformidades
                </UncontrolledTooltip>
              </Link>
            </li>
          )}

{usuario.nivel !== "4" && usuario.nivel !== "5" && (
          <li
            className={`nav-item ${
              props.itemActive === "unidades-medida" ? "active" : ""
            }`}
          >
            <Link className="nav-link" to="/unidades-medida">
              <span style={{ minWidth: "30px", textAlign: "center" }}>
                <img
                  src="/images/icons/usuarios.svg"
                  id="item-usuarios"
                  style={{ width: "22px", color: "red" }}
                />
              </span>
              &nbsp;&nbsp;
              <span className="menu-title">Unidades de Medida</span>
              <UncontrolledTooltip placement="right" target={`item-usuarios`}>
                Unidades de Medida
              </UncontrolledTooltip>
            </Link>
          </li>)}
          <li
            className={`nav-item ${
              props.itemActive === "usuarios" ? "active" : ""
            }`}
          >
            <Link className="nav-link" to="/logout">
              <span style={{ minWidth: "30px", textAlign: "center" }}>
                <img
                  src="/images/icons/usuarios.svg"
                  id="item-usuarios"
                  style={{ width: "22px", color: "red" }}
                />
              </span>
              &nbsp;&nbsp;
              <span className="menu-title">Sair</span>
              <UncontrolledTooltip placement="right" target={`item-usuarios`}>
                Sair
              </UncontrolledTooltip>
            </Link>
          </li>
        </ul>
      </nav>
    </React.Fragment>
  );
}
