import React, { Component } from "react";

class Impressao extends Component {
  constructor(props) {
    super(props);

    this.state = {
      html: "",
      titulo: ""
    };
  }

  async componentDidMount() {
    let html = localStorage.getItem("html");
    let titulo = localStorage.getItem('tituloPagina')

    if (html === null) {
      return this.props.history.push("/home");
    }

    this.setState({ html, titulo }, () => {
     
      document.title = titulo
    });

    const clearBody = () => {
      document.body.innerHTML = '';
    };

    const clearHeader = () => {
      document.head.innerHTML = '';
    };
    
  
    // Função para renderizar o conteúdo
    const renderContent = () => {
      // Limpa o corpo antes de renderizar
      clearBody();
      clearHeader();
  
      // Renderiza a variável de estado que contém o HTML
      document.body.innerHTML = `<div>${html}</div>`;

    }

    renderContent()

    window.print();


  }

  render() {
    return (
      <React.Fragment>
        {/* <div
          dangerouslySetInnerHTML={{
            __html: this.state.html,
          }}
        ></div> */}
      </React.Fragment>
    );
  }
}

export default Impressao;
