import React, { Component } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { SideBar, NavBar, Footer, TitleAndDescription } from "../../components";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import Select from "react-select";
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from "reactstrap";
import { UncontrolledTooltip } from "reactstrap";
      import FileDownload from "js-file-download";

const { formatarReal } = require("../../utils");

class Veiculo extends Component {
  constructor(props) {
    super(props);
    this.load = this.load.bind(this);
    this.loadVeiculos = this.loadVeiculos.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleClickDeletar = this.handleClickDeletar.bind(this);
    this.handleClickConfirmaDeletar =
      this.handleClickConfirmaDeletar.bind(this);
    this.gerarRelatorio =
      this.gerarRelatorio.bind(this);

    this.state = {
      veiculos: [],
      veiculoDeletar: null,
      modal: false,
    };
  }

  async componentDidMount() {
    await this.load();
  }

  async load() {
    await this.loadVeiculos();
  }

  async loadVeiculos() {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/retaguarda/veiculos`,
        this.props.parameters()
      )
      .then((response) => {
        // // ////console.clear();
        //console.log(response.data.veiculos);

        this.setState({ veiculos: response.data.veiculos });
      });
  }

  notificar(type, message) {
    toast[type](message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  handleClickDeletar(id) {
    this.setState({ veiculoDeletar: id, modal: true });
  }

  toggle() {
    this.setState({ modal: !this.state.modal });
  }

  async handleClickConfirmaDeletar() {
    await axios
      .delete(
        `${this.props.getBaseUrlApi()}/retaguarda/veiculos/${
          this.state.veiculoDeletar
        }`,
        this.props.parameters()
      )
      .then((response) => {
        this.setState({ veiculoDeletar: null, modal: false }, () => {
          this.loadVeiculos();
          this.notificar("success", "Veículo deletado com sucesso!");
        });
      })
      .catch((error) => {
        this.setState({ veiculoDeletar: null, modal: false }, () => {
          this.notificar("warning", error.response.data.erro);
        });
      });
  }

  async gerarRelatorio() {

    let listaFormatada = [];
    let lista = this.state.veiculos;

    for (let item of lista) {
      let motorista = ""

      if(item.motorista !== null){
        motorista = item.motorista.desc
      }

      listaFormatada.push({
        id: item._id,
        placa: item.placa,
        veiculo: item.desc,
        ano: item.anoFabricacao,
        tipoVeiculo: item.tipoVeiculo.desc,
        tipoCaracteristica: item.tipoCaracteristica.desc,
        tipoRodado: item.tipoRodado.desc,
        tipoCarroceria: item.tipoCarroceria.desc,
        tipoCombustivel: item.tipoCombustivel.desc,
        tipoPropriedade: item.tipoPropriedade.desc,
        capacidade: item.capacidade,
        tara: item.tara,
        cubos: item.capacidadeM3,
        tanque: item.tanque,
        status: item.status,
        motorista: motorista
      });
    }

    // let dataInicial = this.state.dataInicial;
    // let dataFinal = this.state.dataFinal;

    let nomeArquivo = `relatorio-veiculos`;
    // let nomeArquivo = `despesas-motorista-${moment(dataInicial)
    //   .startOf("day")
    //   .format("YYYY-MM-DD")}-${moment(dataFinal)
    //   .startOf("day")
    //   .format("YYYY-MM-DD")}.xlsx`;

    let motorista = "Todos";

    // if(this.state.motoristaSelecionado !== null){
    //   motorista = this.state.motoristaSelecionado.desc;
    // }

    let parametros = {
      veiculos: listaFormatada,
      motorista,
      // dataInicial,
      // dataFinal,
      // motorista,
    };


    await axios
    .post(`${this.props.getBaseUrlImpressao()}/veiculos-pdf`, { parametros })
    .then(async (response) => {
      // FileDownload(response.data, nomeArquivo);
      localStorage.setItem('html', response.data.html)
      localStorage.setItem('tituloPagina', nomeArquivo)
      window.open("/impressao", "_blank");
    })
    .catch((error) => {
      console.log(error);
      if (error.response !== undefined) {
        // this.notificar("warning", error.response.data.erro);
      }
    });
      // import FileDownload from "js-file-download";
  }
  async gerarRelatorio2() {

    let listaFormatada = [];
    let lista = this.state.veiculos;

    for (let item of lista) {
      let motorista = ""

      if(item.motorista !== null){
        motorista = item.motorista.desc
      }

      listaFormatada.push({
        ID: item._id,
        PLACA: item.placa,
        VEICULO: item.desc,
        ANO: item.anoFabricacao,
        TIPO_VEICULO: item.tipoVeiculo.desc,
        TIPO_CARACTERISTICA: item.tipoCaracteristica.desc,
        TIPO_RODADO: item.tipoRodado.desc,
        TIPO_CARROCERIA: item.tipoCarroceria.desc,
        TIPO_COMBUSTIVEL: item.tipoCombustivel.desc,
        TIPO_PROPRIEDADE: item.tipoPropriedade.desc,
        "CAPACIDADE(KG)": item.capacidade,
        "TARA(KG)": item.tara,
        "CUBOS(M³)": item.capacidadeM3,
        'TANQUE(L)': item.tanque,
        STATUS: item.status,
        MOTORISTA: motorista
      });
    }

    // let dataInicial = this.state.dataInicial;
    // let dataFinal = this.state.dataFinal;

    let nomeArquivo = `relatorio-veiculos.xlsx`;
    // let nomeArquivo = `despesas-motorista-${moment(dataInicial)
    //   .startOf("day")
    //   .format("YYYY-MM-DD")}-${moment(dataFinal)
    //   .startOf("day")
    //   .format("YYYY-MM-DD")}.xlsx`;

    let params = {};
    params.responseType = "blob";

    await axios
      .post(
        `${this.props.getBaseUrlApi()}/retaguarda/relatorios`,
        { lista: listaFormatada },
        params
      )
      .then(async (response) => {
        FileDownload(response.data, nomeArquivo);
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          // this.notificar("warning", error.response.data.erro);
        }
      });
      // import FileDownload from "js-file-download";
  }


  render() {
    return (
      <React.Fragment>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />

        <div className="container-scroller font-app">
          <NavBar title="Veículos" />
          <div className="container-fluid page-body-wrapper">
            <SideBar itemActive={"veiculos"} />
            <div className="main-panel">
              <div className="content-wrapper">
                {/* <TitleAndDescription title="Veículos" description="" /> */}





                <div
                  className="row"
                  style={{ marginBottom: "0.8rem", alignItems: "center" }}
                >
                  <div className="col-md-12 stretch-card">
                    {true && (
                      <div className="card">
                        <div
                          className="card-body5"
                          style={{ position: "relative" }}
                        >
                          <React.Fragment>
                            {/* <div
                              style={{
                                position: "absolute",
                                top: "11px",
                                right: "11px",
                              }}
                            >
                              <button
                                className="btn btn-success btn-app-verde-sm"
                                onClick={() => {
                                  this.props.history.push("/fornecedor-novo");
                                }}
                              >
                               CADASTRAR
                              </button>
                            </div> */}
                            <div className="row">
                              {/* <div className="col-md-2 pd-veiculo2">
                                <div className="form-group form-group-chegoo2">
                                  <label>Status</label>
                                  <select className="form-control form-control-chegoo font-size-14">
                                    <option>Ativo</option>
                                    <option>Inativo</option>
                                  </select>
                                </div>
                              </div> */}


<div className="col-md-2 col-6 pd-veiculo2">
                                <div className="form-group form-group-chegoo2">
                                  <label>Status</label>
                                  <select className="form-control form-control-chegoo font-size-14">
                                    <option>Ativo</option>
                                    <option>Inativo</option>
                                  </select>
                                </div>
                              </div>



                              <div className="col-md-2 col-6 pd-veiculo">
                                <div className="form-group-chegoo form-group-chegoo2 font-size-14">
                                  <label>Motorista</label>
                                  <Select
                                    value={this.state.motoristaSelecionado}
                                    onChange={(value) => {
                                      this.setState({
                                        motoristaSelecionado: value,
                                      });
                                    }}
                                    options={this.state.motoristas}
                                    placeholder="Selecione"
                                    styles={{
                                      control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        fontSize: "14px",
                                        height: "35px",
                                        minHeight: "35px",
                                      }),
                                      menu: (baseStyles, state) => ({
                                        ...baseStyles,
                                        fontSize: "14px",
                                      }),
                                    }}
                                  />
                                </div>
                              </div>

                              <div
                                className="col-md-2 pd-veiculo"
                                style={{ display: "flex", marginTop: "15px" }}
                              >
                                <div
                                  className="form-group-chegoo2"
                                  style={{ float: "left" }}
                                >
                                  <label></label>
                                  <button
                                    className="btn btn-secondary btn-sm"
                                    style={{
                                      padding: "0.275rem 0.55rem",
                                      margin: "7px 5px 0px",
                                    }}
                                    onClick={() => this.load()}
                                    id={`tooltip-filtro-atendimentos`}
                                  >
                                    <i
                                      className="fa fa-search"
                                      aria-hidden="true"
                                      style={{ fontSize: "px" }}
                                    ></i>
                                  </button>
                                  <UncontrolledTooltip
                                    placement="bottom"
                                    target={`tooltip-filtro-atendimentos`}
                                  >
                                    Filtrar
                                  </UncontrolledTooltip>
                                </div>
                                <div className="form-group-chegoo2">
                                  <label></label>
                                  <button
                                    className="btn btn-warning btn-sm"
                                    style={{
                                      padding: "0.275rem 0.55rem",
                                      margin: "7px 5px 0px",
                                      color:"#FFF"
                                    }}
                                    onClick={() => this.limparFiltros()}
                                    id={`tooltip-limpar-filtro-atendimentos`}
                                  >
                                    <i
                                      className="fa fa-eraser"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                  <UncontrolledTooltip
                                    placement="bottom"
                                    target={`tooltip-limpar-filtro-atendimentos`}
                                  >
                                    Limpar filtro
                                  </UncontrolledTooltip>
                                </div>
                                <div className="form-group-chegoo2">
                                  <label></label>
                                  <button
                                    className="btn btn-danger btn-sm"
                                    style={{
                                      padding: "0.275rem 0.55rem",
                                      margin: "7px 5px 0px",
                                    }}
                                    onClick={() => this.gerarRelatorio()}
                                    id={`tooltip-limpar-filtro-atendimentos2`}
                                  >
                                    <i
                                      className="fa fa-file-pdf-o"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                  <UncontrolledTooltip
                                    placement="bottom"
                                    target={`tooltip-limpar-filtro-atendimentos2`}
                                  >
                                    Gerar Relatório
                                  </UncontrolledTooltip>
                                </div>
                              </div>

                              <div
                                className="offset-4 col-md-2 col-6 pd-veiculo3"
                                style={{
                                  marginTop: "15px",
                                  marginTop: "15px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "end",
                                }}
                              >
                                <button
                                  className="btn btn-success btn-app-verde-sm"
                                  onClick={() => {
                                    this.props.history.push("/veiculos-novo");
                                  }}
                                >
                                  CADASTRAR
                                </button>
                              </div>
                            </div>
                          </React.Fragment>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

















                <div className="row">
                  <div className="col-md-12 stretch-card">
                    {this.state.veiculos.length > -1 && (
                      <div className="card">
                        <div className="card-body2">
                          <React.Fragment>
                            {/* <div
                              className="float-left width-100"
                              style={{
                                borderBottom: "1px solid #CCC",
                                padding: "5px",
                                marginBottom: "20px",
                                position: "relative",
                              }}
                            >
                              VEÍCULOS CADASTRADOS
                              <div>
                                <button
                                  className="btn btn-success btn-app-verde"
                                  onClick={() => {
                                    this.props.history.push("/veiculos-novo");
                                  }}
                                  style={{
                                    position: "absolute",
                                    right: 0,
                                    top: "-15px",
                                  }}
                                >
                                  Novo veículo
                                </button>
                              </div>
                            </div> */}
                            <div className="table-responsive">
                              <table
                                id="recent-purchases-listing"
                                className="table"
                              >
                                <thead className="thead-dark">
                                  <tr>
                                    <th># Placa</th>
                                    <th>Veículo</th>
                                    <th>Tipo</th>
                                    <th>Carroceria</th>
                                    <th className="text-center">
                                      Capacidade(KG)
                                    </th>
                                    <th className="text-center">Tara(KG)</th>
                                    <th className="text-center">Motorista</th>
                                    <th className="text-center">Ações</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.veiculos.map((veiculo, key) => (
                                    <tr key={key}>
                                      <td
                                        style={{
                                          fontWeight: "bold",
                                          color:
                                            veiculo.situacao === "entrega"
                                              ? "#009ba4"
                                              : veiculo.situacao === "defeito"
                                              ? "#d5433d"
                                              : "inherit",
                                        }}
                                      >
                                        {veiculo.placa}
                                      </td>
                                      <td>
                                        {veiculo.desc} - {veiculo.anoFabricacao}
                                      </td>
                                      <td>{veiculo.tipoVeiculo.desc}</td>
                                      <td>{veiculo.tipoCarroceria.desc}</td>
                                      <td className="text-center">
                                        {veiculo.capacidade}
                                      </td>
                                      <td className="text-center">
                                        {veiculo.tara}
                                      </td>
                                      <td className="text-center">
                                        {" "}
                                        {veiculo.motorista !== null
                                          ? veiculo.motorista.desc
                                          : "-"}
                                      </td>
                                      <td
                                        className="d-flex text-center"
                                        style={{ fontSize: "18px" }}
                                      >
                                        <div
                                          style={{
                                            margin: "2px",
                                            cursor: "pointer",
                                          }}
                                        >
                                          <i
                                            className="fa fa-edit"
                                            onClick={() => {
                                              this.props.history.push(
                                                `/veiculos-novo?id=${veiculo._id}`
                                              );
                                            }}
                                          ></i>
                                        </div>
                                        <div
                                          style={{
                                            margin: "2px",
                                            cursor: "pointer",
                                          }}
                                        >
                                          <i
                                            className="fa fa-trash"
                                            style={{ color: "red" }}
                                            onClick={() =>
                                              this.handleClickDeletar(
                                                veiculo._id
                                              )
                                            }
                                          ></i>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>

                            
                          </React.Fragment>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>

        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Deletar veículo</ModalHeader>
          <ModalBody>Tem certeza que deseja deletar este veículo?</ModalBody>
          <ModalFooter>
            <Button
              className="btn-modal-recuperar"
              color="secondary"
              onClick={this.toggle}
            >
              Não
            </Button>
            <Button
              className="btn-modal-recuperar"
              color="primary"
              onClick={this.handleClickConfirmaDeletar}
            >
              Sim
            </Button>{" "}
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default Veiculo;
