import React, { Component } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import "react-toastify/dist/ReactToastify.css";

class Login extends Component {
  constructor() {
    super();
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.notificar = this.notificar.bind(this);
    this.toggle = this.toggle.bind(this);
    this.recuperarSenha = this.recuperarSenha.bind(this);
    this.state = {
      login: "",
      password: "",
      error: false,
      modal: false,
      loginRecuperar: "",
      emailRecuperar: "",
    };
  }

  async componentDidMount() {

    //window.location.href= "http://127.0.0.1:5500/Oxyy%20-%20Login%20and%20Register%20Form%20Html%20Template.html"
   // return false;

    
    let ultimoLoginStr = localStorage.getItem("ultimoLogin");

    if (ultimoLoginStr !== null && ultimoLoginStr !== "") {
      let ultimoLogin = JSON.parse(ultimoLoginStr);
      this.setState({ login: ultimoLogin.login });
    }

    // localStorage.setItem(
    //   "token",
    //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7Il9pZCI6IjU2MTFlMWRkOWM4OGMzOWM5ZGI3YmFhZjA5MDEzMzFjIiwiX3JldiI6IjIwLTg0YWI5OTc1YWUxMmRiN2Q4MzEwMWFkOWZhYjNmMmUwIiwidHlwZSI6InVzdWFyaW8iLCJkZXNjIjoiRmVsaXBlIFIuIEtyYXVzZSIsImVtYWlsIjoibmFvc2VpQGhvdG1haWwuY29tIiwiY2VsdWxhciI6IjQ3OTg4NjE4Nzg1Iiwic3RhdHVzIjoiYSIsImFjZXNzb0FwcCI6dHJ1ZSwiYWNlc3NvQWRtIjp0cnVlLCJzaW5jQXV0byI6dHJ1ZSwibG9naW4iOiJmZWxpcGUiLCJzZW5oYSI6ImZlbGlwZTEyMyIsImlkTGljZW5jYSI6Ijk4NmU0MWYzLTRlYjMtNDlkMS1hZTZjLTZkMTM2ZGUzMmUzNSIsImNhcmdvIjp7Il9pZCI6IjU2MTFlMWRkOWM4OGMzOWM5ZGI3YmFhZjA5MDEwN2YxIiwiZGVzYyI6Ik1vdG9yYSBUb3BwZXIgZGFzIEdhbMOheGlhcyJ9LCJub3RpZmljYWNhbyI6eyJkZXNwZXNhc0FkaWFudGFtZW50b3MiOnRydWUsImRlc3Blc2FzTm92YXMiOnRydWV9fSwiaWF0IjoxNjMwNjcyNjc2LCJleHAiOjE2MzEyNzc0NzZ9.D3dhAUCkEk_eeoStbn1CC5J9WH4Y_eauck7kSFPGSVo"
    // );
    // localStorage.setItem("descricaoVendedor", "Felipe R. Krause");
    // localStorage.setItem(
    //   "cargoVendedor",
    //   JSON.stringify({
    //     _id: "5611e1dd9c88c39c9db7baaf090107f1",
    //     desc: "Motora Topper das Galáxias",
    //   })
    // );
    // localStorage.setItem("idIntegracaoVendedor", null);
    // //localStorage.setItem("imagem", "null");

    // setTimeout(() => {
    //   this.props.history.push("/home");
    // }, 1000);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal,
      loginRecuperar: this.state.login,
    });
  }

  handleChangeInput(e) {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    this.setState({
      [e.target.id]: value,
    });
  }

  async handleSubmit(e) {
    e.preventDefault();
    let localLogin = this.state.login;
    let toSend = {
      login: localLogin,
      password: this.state.password,
    };

    await axios
      .post(`${this.props.getBaseUrlApi()}/retaguarda/login`, toSend)
      .then(async (res) => {

        localStorage.setItem("token", res.data.token);
        localStorage.setItem("descricaoVendedor", res.data.descricaoVendedor);
        localStorage.setItem("cargoVendedor", res.data.cargoVendedor);

        localStorage.setItem(
          "idIntegracaoVendedor",
          1
        );

        let usuario = res.data.usuario;
        delete usuario.senha;

        localStorage.setItem(
          "usuario",
          JSON.stringify(usuario)
        );

        // localStorage.setItem("ultimoLogin", JSON.stringify(toSend).login);
        this.props.history.push("/home");

        
        
       
        // if (res.data.imagem !== null) {
        //   localStorage.setItem("imagem", res.data.imagem);
        // }
 
      })
      .catch((error) => {
        console.log(error);
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          this.notificar("warning", error.response.data.erro);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  }

  notificar(type, message) {
    toast[type](message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  recuperarSenha() {
    let toSend = {
      login: this.state.loginRecuperar,
      email: this.state.emailRecuperar,
    };

    if (toSend.login === "" || toSend.email === "") {
      this.notificar("warning", "Favor preencher os campos corretamente!");
      return false;
    }

    axios
      .post(`${this.props.getBaseUrlApi()}/retaguarda/recuperar-senha`, toSend)
      .then(async (res) => {
        //TODO: AXIOS
        console.log(res);
        this.notificar("success", "Email de recuperação de senha enviado.");
        this.toggle();
      })
      .catch((error) => {
        console.log(error);
        if (error.response) {
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
          this.notificar("warning", error.response.data.erro.erro);
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
        console.log(error.config);
      });
  }

  render() {
    return (
      <React.Fragment>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
        <div className="container-scroller">
          <div className="container-fluid page-body-wrapper full-page-wrapper">
            <div className="content-wrapper d-flex align-items-center auth px-0">
              <div className="row w-100 mx-0">
                <div className="col-lg-4 mx-auto">
                  <div
                    className="brand-logo text-center"
                    style={{
                      marginBottom: "0",
                      padding: "25px",

                      // background:
                      //   "linear-gradient(45deg, #039c9f 10%, rgba(42, 168, 87, 1) 100%)",
                    }}
                  >

                    {/* <span style={{fontSize:"30px"}}>LOGO</span> */}

                    <img
                          className="img-responsive"
                          src="../images/teste1.png"
                          // src={item.produto.imagemPrincipal}
                          // alt={item.produto.desc}
                          // style={{ width: "100%", cursor: "pointer" }}
                          // onClick={(e) =>
                          //   this.abrirDetalheProduto(
                          //     item.produto._id
                          //   )
                          // }
                          style={{width:"300px"}}
                        />


                    {/* <img src="../../images/logo-branca-nova.png" alt="logo" /> */}
                  </div>
                  <div className="auth-form-light text-left py-5 px-4 px-sm-5">
                    <form className="pt-3">
                      <div
                        className="form-group"
                        style={{ position: "relative" }}
                      >
                        <i
                          class="fa fa-user"
                          style={{
                            position: "absolute",
                            top: "9px",
                            left: "5px",
                            fontSize: "20px",
                          }}
                        ></i>
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          id="exampleInputEmail1"
                          placeholder="Usuário"
                          style={{ border: "1px solid #ccc" }}
                          id="login"
                          placeholder="LOGIN"
                          onChange={this.handleChangeInput}
                          value={this.state.login}
                          style={{ paddingLeft: "25px" }}
                        />
                      </div>
                      <div
                        className="form-group"
                        style={{ position: "relative" }}
                      >
                        <i
                          class="fa fa-lock"
                          style={{
                            position: "absolute",
                            top: "11px",
                            left: "5px",
                            fontSize: "20px",
                          }}
                        ></i>
                        <input
                          type="password"
                          className="form-control form-control-lg"
                          id="exampleInputPassword1"
                          placeholder="Senha"
                          style={{ border: "1px solid #ccc" }}
                          id="password"
                          placeholder="SENHA"
                          onChange={this.handleChangeInput}
                          value={this.state.password}
                          style={{ paddingLeft: "25px" }}
                        />
                      </div>
                      <div className="mt-3">
                        <button
                          className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn teste10"
                          // href="../../index.html"
                          onClick={this.handleSubmit}
                          type="button"
                          style={{backgroundColor: "#2da951"}}
                        >
                          ENTRAR
                        </button>
                      </div>
                      {/* <div className="my-2 d-flex justify-content-between align-items-center">
                        <a
                          href="#"
                          className="auth-link text-black"
                          style={{
                            width: "100%",
                            float: "left",
                            textAlign: "center",
                            paddingTop: "5px",
                            color: "#908787",
                            fontSize: "13px",
                          }}
                          //onClick={() => this.toggle()}
                        >
                          Esqueceu sua senha? clique aqui
                        </a>
                      </div> */}
                      <div className="my-2 d-flex justify-content-between align-items-center">
                        <span
                          className="width-100"
                          style={{ fontSize: "13px", textAlign: "center" }}
                        >
                          v1.0.0
                        </span>
                      </div>
                      <div
                        style={{
                          textAlign: "right",
                          width: "100%",
                          paddingTop: "20px",
                        }}
                      >
                        {/* <img
                          src="../../images/logo-suricatto-nova.png"
                          alt="logo"
                          style={{ width: "100px" }}
                        /> */}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Recuperar Senha</ModalHeader>
          <ModalBody>
            <div className="form-group">
              <label>Login</label>
              <input
                type="text"
                className="form-control"
                id="loginRecuperar"
                value={this.state.loginRecuperar}
                onChange={this.handleChangeInput}
              />
            </div>
            <div className="form-group">
              <label>Email</label>
              <input
                type="text"
                className="form-control"
                id="emailRecuperar"
                value={this.state.emailRecuperar}
                onChange={this.handleChangeInput}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              className="btn-modal-recuperar"
              color="secondary"
              onClick={this.toggle}
            >
              Cancelar
            </Button>
            <Button
              className="btn-modal-recuperar"
              color="primary"
              onClick={this.recuperarSenha}
            >
              Enviar
            </Button>{" "}
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default Login;
