import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import { SideBar, NavBar, TitleAndDescription, Footer } from "../../components";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import axios from "axios";
import { truncateWithEllipsis } from "@amcharts/amcharts4/.internal/core/utils/Utils";
import Select from "react-select";
import queryString from "query-string";
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from "reactstrap";
import { CurrencyInput } from "../../components";
import Switch from "react-switch";
const { v4: uuidv4 } = require("uuid");
const { phoneMask, validaCpfCnpj } = require("../../utils");
const { cpfMask, cnpjMask, placaVeicularMask } = require("../../mask");

export default class FornecedorNovo extends Component {
  constructor(props) {
    super(props);
    this.notificar = this.notificar.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
    this.cadastrarFornecedor = this.cadastrarFornecedor.bind(this);
    this.validarFornecedor = this.validarFornecedor.bind(this);
    this.buscarFornecedor = this.buscarFornecedor.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleClickDeletar = this.handleClickDeletar.bind(this);
    this.handleClickConfirmaDeletar =
      this.handleClickConfirmaDeletar.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.onChangeInputEndereco = this.onChangeInputEndereco.bind(this);
    this.onChangeCategoria = this.onChangeCategoria.bind(this);

    this.state = {
      _id: null,
      tipo: "fornecedor",
      cpfCnpj: "",
      razaoSocial: "",
      fantasia: "",
      celular: "",
      telefone: "",
      email: "",
      endereco: {
        rua: "",
        num: "",
        bairro: "",
        cidade: "",
        uf: "",
        cep: "",
      },
      status: "A",
      errors: {
        cpfCnpj: false,
        razaoSocial: false,
        fantasia: false,
        endereco: false,
        numero: false,
        bairro: false,
        cidade: false,
        uf: false,
        email: false,
        categoria: false,
      },
      fornecedorDeletar: null,
      modal: false,
      tipoPessoa: "PF",
      categoriasSelecionadas: null,
    };
  }

  async componentDidMount() {
    let id = queryString.parse(this.props.location.search).id;

    if (id !== undefined) {
      await this.buscarFornecedor(id);
    }
  }

  notificar(type, message) {
    toast[type](message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  onChangeInput(tipo, value) {
    let tipoPessoa = this.state.tipoPessoa;

    if (tipo === "cpfCnpj") {
      if (value.length > 14) {
        value = cnpjMask(value);
        tipoPessoa = "PJ";
      } else {
        value = cpfMask(value);
        tipoPessoa = "PF";
      }
    }

    this.setState({ [tipo]: value, tipoPessoa }, () => {});
  }

  onChangeInputEndereco(tipo, value) {
    let endereco = this.state.endereco;
    endereco[tipo] = value;

    this.setState({ endereco }, () => {});
  }

  validarFornecedor() {
    let errors = this.state.errors;
    let valido = true;

    if (this.state.cpfCnpj === "") {
      errors.cpfCnpj = true;
      valido = false;
    } else {
      errors.cpfCnpj = false;
    }

    if (this.state.razaoSocial === "") {
      errors.razaoSocial = true;
      valido = false;
    } else {
      errors.razaoSocial = false;
    }

    if (this.state.fantasia === "") {
      errors.fantasia = true;
      valido = false;
    } else {
      errors.fantasia = false;
    }

    if (this.state.endereco.rua === "") {
      errors.endereco = true;
      valido = false;
    } else {
      errors.endereco = false;
    }

    if (this.state.endereco.num === "") {
      errors.numero = true;
      valido = false;
    } else {
      errors.numero = false;
    }

    if (this.state.endereco.bairro === "") {
      errors.bairro = true;
      valido = false;
    } else {
      errors.bairro = false;
    }

    if (this.state.endereco.cidade === "") {
      errors.cidade = true;
      valido = false;
    } else {
      errors.cidade = false;
    }

    if (this.state.endereco.uf === "") {
      errors.uf = true;
      valido = false;
    } else {
      errors.uf = false;
    }

    if (this.state.email === "") {
      errors.email = true;
      valido = false;
    } else {
      errors.email = false;
    }

    if (this.state.categoriasSelecionadas === null) {
      errors.categoria = true;
      valido = false;
    } else {
      errors.categoria = false;
    }

    console.log(errors);
    console.log(this.state);

    this.setState({ errors });

    return valido;
  }

  async cadastrarFornecedor() {
    if (!this.validarFornecedor()) {
      this.notificar("warning", "Favor preencher todos os dados!");
      return false;
    }

    let acaoPagina = this.state._id === null ? "cadastrar" : "editar";

    let fornecedorNovo = {
      cpfCnpj: this.state.cpfCnpj,
      razaoSocial: this.state.razaoSocial,
      fantasia: this.state.fantasia,
      endereco: {
        rua: this.state.endereco.rua,
        num: this.state.endereco.num,
        bairro: this.state.endereco.bairro,
        cidade: this.state.endereco.cidade,
        uf: this.state.endereco.uf,
        cep: this.state.endereco.cep,
      },
      celular: this.state.celular,
      telefone: this.state.telefone,
      tipoPessoa: this.state.tipoPessoa,
      categorias: this.state.categoriasSelecionadas,
      email: this.state.email,
    };

    let urlAtualizar = `${this.props.getBaseUrlApi()}/retaguarda/fornecedores`;

    if (acaoPagina === "editar") {
      fornecedorNovo._id = this.state._id;
      urlAtualizar = `${this.props.getBaseUrlApi()}/retaguarda/fornecedores/${
        this.state._id
      }`;
    }

    await axios
      .post(urlAtualizar, fornecedorNovo, this.props.parameters())
      .then((response) => {
        if (acaoPagina === "cadastrar") {
          this.notificar("success", "Fornecedor cadastrado com sucesso!");
        } else {
          this.notificar("success", "Fornecedor editado com sucesso!");
        }

        setTimeout(() => {
          this.props.history.push("/fornecedores");
        }, 1000);
      })
      .catch((error) => {
        this.notificar("warning", error.response.data.erro);
      });
  }

  async buscarFornecedor(id) {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/retaguarda/fornecedores/${id}`,
        this.props.parameters()
      )
      .then((response) => {
        const fornecedor = response.data.fornecedor;

        if (fornecedor === null) {
          this.props.history.push("/fornecedores");
          return false;
        }

        ////console.clear();
        console.log(fornecedor);

        this.setState({
          _id: fornecedor._id,
          cpfCnpj: fornecedor.cpfCnpj,
          razaoSocial: fornecedor.razaoSocial,
          fantasia: fornecedor.fantasia,
          celular: fornecedor.celular,
          telefone: fornecedor.telefone,
          status: fornecedor.status,
          endereco: {
            rua: fornecedor.endereco.rua,
            num: fornecedor.endereco.num,
            bairro: fornecedor.endereco.bairro,
            cidade: fornecedor.endereco.cidade,
            uf: fornecedor.endereco.uf,
            cep: fornecedor.endereco.cep,
          },
          tipoPessoa:
            fornecedor.tipoPessoa === undefined ? "PJ" : fornecedor.tipoPessoa,
          categoriasSelecionadas: fornecedor.categorias,
          email: fornecedor.email,
        });
      });
  }

  handleClickDeletar(id) {
    this.setState({ fornecedorDeletar: id, modal: true });
  }

  toggle() {
    this.setState({ modal: !this.state.modal });
  }

  async handleClickConfirmaDeletar() {
    await axios
      .delete(
        `${this.props.getBaseUrlApi()}/retaguarda/fornecedores/${
          this.state.fornecedorDeletar
        }`,
        this.props.parameters()
      )
      .then((response) => {
        this.setState({ fornecedorDeletar: null, modal: false }, () => {
          this.notificar("success", "Fornecedor deletado com sucesso!");
          setTimeout(() => {
            this.props.history.push("/fornecedores");
          }, 1000);
        });
      })
      .catch((error) => {
        this.setState({ fornecedorDeletar: null, modal: false }, () => {
          this.notificar("warning", error.response.data.erro);
        });
      });
  }

  handleFocus(e) {
    e.target.select();
  }

  async buscaCnpj() {
    let tipoPessoa = this.state.tipoPessoa;

    if (tipoPessoa === "PF") {
      return null;
    }

    if (!navigator.onLine) {
      this.notificar(
        "warning",
        "Conecte a uma rede de dados para buscar informações do CNPJ!"
      );
      return false;
    }

    await axios
      .post(
        `${this.props.getBaseUrlApi()}/retaguarda/consulta-cnpj`,
        {
          cnpj: this.state.cpfCnpj,
        },
        {}
      )
      .then((response) => {
        let resposta = response.data.fornecedor;

        //  console.log(resposta);

        this.setState(
          {
            razaoSocial: resposta.razaoSocial,
            fantasia: resposta.fantasia,
            telefone: resposta.telefone,
            endereco: resposta.endereco,
          },
          () => {
            /// console.log(this.state);
          }
        );
      })
      .catch((error) => {
        console.log("responseErrpr", error);
        //   this.notificar("error", error.response.msg);
      });
  }

  onChangeCategoria(categoriasSelecionadas) {
    this.setState({ categoriasSelecionadas });
  }

  render() {
    const InputRequired = () => {
      return <span className="required-icone">*</span>;
    };
    return (
      <React.Fragment>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
        <div className="container-scroller font-app">
          <NavBar title="Fornecedores / Novo fornecedor" />
          <div className="container-fluid page-body-wrapper">
            <SideBar itemActive={"fornecedores"} />
            <div className="main-panel">
              <div className="content-wrapper">
                <div className="row">
                  <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body5 dashboard-tabs">
                        <div className="tab-content py-0 px-0">
                          <div
                            className="tab-pane fade show active"
                            id="overview"
                            role="tabpanel"
                            aria-labelledby="overview-tab"
                          >
                            <div className="d-flex flex-wrap justify-content-xl-between">
                              <div
                                style={{
                                  float: "left",
                                  width: "100%",
                                }}
                              >
                                {/* <div
                                  className="float-left width-100"
                                  style={{
                                    borderBottom: "1px solid #CCC",
                                    padding: "5px",
                                    marginBottom: "20px",
                                  }}
                                >
                                  DETALHAMENTO
                                </div> */}
                                <div className="row">
                                  <div className="col-md-3 pd-veiculo2">
                                    <div className="form-group form-group-chegoo">
                                      <label>
                                        CPF/CNPJ
                                        <InputRequired />
                                      </label>
                                      <input
                                        type="text"
                                        value={this.state.cpfCnpj}
                                        onChange={(e) =>
                                          this.onChangeInput(
                                            "cpfCnpj",
                                            e.target.value
                                          )
                                        }
                                        className={`form-control font-size-14 form-control-chegoo text-center required-chegoo`}
                                        onBlur={(e) => {
                                          if (
                                            !validaCpfCnpj(this.state.cpfCnpj)
                                          ) {
                                            if (
                                              this.state.tipoPessoa === "PJ"
                                            ) {
                                              this.notificar(
                                                "warning",
                                                "CNPJ inválido."
                                              );
                                            } else {
                                              this.notificar(
                                                "warning",
                                                "CPF inválido."
                                              );
                                            }
                                            // this.setState({
                                            //   errors: {
                                            //     ...this.state.errors,
                                            //     cnpjCpf: true,
                                            //   },
                                            // });
                                          } else {
                                            this.buscaCnpj();
                                          }
                                        }}
                                        ref={(node) => {
                                          if (node) {
                                            if (this.state.errors.cpfCnpj) {
                                              node.style.setProperty(
                                                "border-color",
                                                "red",
                                                "important"
                                              );
                                            } else {
                                              node.style.setProperty(
                                                "border-color",
                                                "#ced4da",
                                                "important"
                                              );
                                            }
                                          }
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-5 pd-veiculo">
                                    <div className="form-group form-group-chegoo">
                                      <label>
                                        Razão Social
                                        <InputRequired />
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control font-size-14 form-control-chegoo required-chegoo"
                                        value={this.state.razaoSocial}
                                        onChange={(e) =>
                                          this.onChangeInput(
                                            "razaoSocial",
                                            e.target.value
                                          )
                                        }
                                        ref={(node) => {
                                          if (node) {
                                            if (this.state.errors.razaoSocial) {
                                              node.style.setProperty(
                                                "border-color",
                                                "red",
                                                "important"
                                              );
                                            } else {
                                              node.style.setProperty(
                                                "border-color",
                                                "#ced4da",
                                                "important"
                                              );
                                            }
                                          }
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-4 pd-veiculo3">
                                    <div className="form-group form-group-chegoo">
                                      <label>
                                        Fantasia
                                        <InputRequired />
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control font-size-14 form-control-chegoo required-chegoo"
                                        value={this.state.fantasia}
                                        onChange={(e) =>
                                          this.onChangeInput(
                                            "fantasia",
                                            e.target.value
                                          )
                                        }
                                        ref={(node) => {
                                          if (node) {
                                            if (this.state.errors.fantasia) {
                                              node.style.setProperty(
                                                "border-color",
                                                "red",
                                                "important"
                                              );
                                            } else {
                                              node.style.setProperty(
                                                "border-color",
                                                "#ced4da",
                                                "important"
                                              );
                                            }
                                          }
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-md-5 pd-veiculo2">
                                    <div className="form-group form-group-chegoo">
                                      <label>
                                        Endereço
                                        <InputRequired />
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control font-size-14 form-control-chegoo required-chegoo"
                                        value={this.state.endereco.rua}
                                        onChange={(e) =>
                                          this.onChangeInputEndereco(
                                            "rua",
                                            e.target.value
                                          )
                                        }
                                        ref={(node) => {
                                          if (node) {
                                            if (this.state.errors.endereco) {
                                              node.style.setProperty(
                                                "border-color",
                                                "red",
                                                "important"
                                              );
                                            } else {
                                              node.style.setProperty(
                                                "border-color",
                                                "#ced4da",
                                                "important"
                                              );
                                            }
                                          }
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-1 pd-veiculo">
                                    <div className="form-group form-group-chegoo">
                                      <label>
                                        Número
                                        <InputRequired />
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control font-size-14 form-control-chegoo text-center required-chegoo"
                                        value={this.state.endereco.num}
                                        onChange={(e) =>
                                          this.onChangeInputEndereco(
                                            "num",
                                            e.target.value
                                          )
                                        }
                                        ref={(node) => {
                                          if (node) {
                                            if (this.state.errors.numero) {
                                              node.style.setProperty(
                                                "border-color",
                                                "red",
                                                "important"
                                              );
                                            } else {
                                              node.style.setProperty(
                                                "border-color",
                                                "#ced4da",
                                                "important"
                                              );
                                            }
                                          }
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-3 pd-veiculo">
                                    <div className="form-group form-group-chegoo">
                                      <label>
                                        Bairro
                                        <InputRequired />
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control font-size-14 form-control-chegoo required-chegoo"
                                        value={this.state.endereco.bairro}
                                        onChange={(e) =>
                                          this.onChangeInputEndereco(
                                            "bairro",
                                            e.target.value
                                          )
                                        }
                                        ref={(node) => {
                                          if (node) {
                                            if (this.state.errors.bairro) {
                                              node.style.setProperty(
                                                "border-color",
                                                "red",
                                                "important"
                                              );
                                            } else {
                                              node.style.setProperty(
                                                "border-color",
                                                "#ced4da",
                                                "important"
                                              );
                                            }
                                          }
                                        }}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-md-3 pd-veiculo3">
                                    <div className="form-group form-group-chegoo">
                                      <label>
                                        Cidade
                                        <InputRequired />
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control font-size-14 form-control-chegoo required-chegoo"
                                        value={this.state.endereco.cidade}
                                        onChange={(e) =>
                                          this.onChangeInputEndereco(
                                            "cidade",
                                            e.target.value
                                          )
                                        }
                                        ref={(node) => {
                                          if (node) {
                                            if (this.state.errors.cidade) {
                                              node.style.setProperty(
                                                "border-color",
                                                "red",
                                                "important"
                                              );
                                            } else {
                                              node.style.setProperty(
                                                "border-color",
                                                "#ced4da",
                                                "important"
                                              );
                                            }
                                          }
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-1 pd-veiculo2">
                                    <div className="form-group form-group-chegoo">
                                      <label>
                                        UF
                                        <InputRequired />
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control font-size-14 form-control-chegoo required-chegoo"
                                        value={this.state.endereco.uf}
                                        onChange={(e) =>
                                          this.onChangeInputEndereco(
                                            "uf",
                                            e.target.value
                                          )
                                        }
                                        ref={(node) => {
                                          if (node) {
                                            if (this.state.errors.uf) {
                                              node.style.setProperty(
                                                "border-color",
                                                "red",
                                                "important"
                                              );
                                            } else {
                                              node.style.setProperty(
                                                "border-color",
                                                "#ced4da",
                                                "important"
                                              );
                                            }
                                          }
                                        }}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-md-3 pd-veiculo">
                                    <div className="form-group form-group-chegoo">
                                      <label>
                                        Email
                                        <InputRequired />
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control font-size-14 form-control-chegoo required-chegoo"
                                        value={this.state.email}
                                        onChange={(e) =>
                                          this.onChangeInput(
                                            "email",
                                            e.target.value
                                          )
                                        }
                                        ref={(node) => {
                                          if (node) {
                                            if (this.state.errors.email) {
                                              node.style.setProperty(
                                                "border-color",
                                                "red",
                                                "important"
                                              );
                                            } else {
                                              node.style.setProperty(
                                                "border-color",
                                                "#ced4da",
                                                "important"
                                              );
                                            }
                                          }
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-2 pd-veiculo">
                                    <div className="form-group form-group-chegoo">
                                      <label>Telefone</label>
                                      <input
                                        type="text"
                                        className="form-control font-size-14 form-control-chegoo text-center"
                                        value={phoneMask(this.state.telefone)}
                                        onChange={(e) =>
                                          this.onChangeInput(
                                            "telefone",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-2 pd-veiculo">
                                    <div className="form-group form-group-chegoo">
                                      <label>Celular</label>
                                      <input
                                        type="text"
                                        className="form-control font-size-14 form-control-chegoo text-center"
                                        value={phoneMask(this.state.celular)}
                                        onChange={(e) =>
                                          this.onChangeInput(
                                            "celular",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-4 pd-veiculo3">
                                    <div className="form-group form-group-chegoo">
                                      <label>
                                        Categoria
                                        <InputRequired />
                                      </label>
                                      <Select
                                        value={
                                          this.state.categoriasSelecionadas
                                        }
                                        onChange={this.onChangeCategoria}
                                        options={[
                                          {
                                            value: "manutencao",
                                            label: "Manutenção",
                                          },
                                          {
                                            value: "combustivel",
                                            label: "Combustível",
                                          },
                                          {
                                            value: "autopecas",
                                            label: "Autopeças",
                                          },
                                          {
                                            value: "alimentacao",
                                            label: "Alimentação",
                                          },
                                        ]}
                                        placeholder="Selecione"
                                        className={`${
                                          this.state.errors.categoria
                                            ? "select-error"
                                            : ""
                                        }`}
                                        styles={{
                                          control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            fontSize: "14px",
                                            // height: "35px",
                                            // minHeight: "35px",
                                            backgroundColor: "#fffef6" 
                                          }),
                                          menu: (baseStyles, state) => ({
                                            ...baseStyles,
                                            fontSize: "14px",
                                          }),
                                        }}
                                        isMulti
                                      />
                                    </div>
                                  </div>

                                </div>

                                {/* <div className="row">
                                  <div className="col-md-5 pd-veiculo2">
                                    <div className="form-group form-group-chegoo">
                                      <label>
                                        Categoria
                                        <InputRequired />
                                      </label>
                                      <Select
                                        value={
                                          this.state.categoriasSelecionadas
                                        }
                                        onChange={this.onChangeCategoria}
                                        options={[
                                          {
                                            value: "manutencao",
                                            label: "Manutenção",
                                          },
                                          {
                                            value: "combustivel",
                                            label: "Combustível",
                                          },
                                          {
                                            value: "autopecas",
                                            label: "Autopeças",
                                          },
                                          {
                                            value: "alimentacao",
                                            label: "Alimentação",
                                          },
                                        ]}
                                        placeholder="Selecione"
                                        className={`${
                                          this.state.errors.categoria
                                            ? "select-error"
                                            : ""
                                        }`}
                                        styles={{
                                          control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            fontSize: "14px",
                                            height: "35px",
                                            minHeight: "35px",
                                            backgroundColor: "#fffef6" 
                                          }),
                                          menu: (baseStyles, state) => ({
                                            ...baseStyles,
                                            fontSize: "14px",
                                          }),
                                        }}
                                        isMulti
                                      />
                                    </div>
                                  </div>
                                </div> */}
                                <div className="row">
                                  {/* <div className="col-md-8"></div>
                                  <div className="col-md-2 no-padding text-right">
                                    <div className="form-group form-group-chegoo">
                                      <button
                                        className="btn btn-danger btn-app-vermelho"
                                        onClick={() => {
                                          this.handleClickDeletar(
                                            this.state._id
                                          );
                                        }}
                                        disabled={
                                          this.state._id === null
                                            ? true
                                            : this.state.status === "I"
                                            ? true
                                            : false
                                        }
                                      >
                                        Deletar
                                      </button>
                                    </div>
                                  </div>{" "} */}
                                  <div className="col-md-2 pd-veiculo2">
                                    <div className="form-group form-group-chegoo2">
                                      <button
                                        className="btn btn-success btn-app-verde"
                                        //onClick={this.cadastrarFornecedor}
                                        onClick={() => {
                                          this.cadastrarFornecedor();
                                        }}
                                      >
                                        Salvar
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>

        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Deletar Fornecedor</ModalHeader>
          <ModalBody>Tem certeza que deseja deletar este fornecedor?</ModalBody>
          <ModalFooter>
            <Button
              className="btn-modal-recuperar"
              color="secondary"
              onClick={this.toggle}
            >
              Não
            </Button>
            <Button
              className="btn-modal-recuperar"
              color="primary"
              onClick={this.handleClickConfirmaDeletar}
            >
              Sim
            </Button>{" "}
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}
