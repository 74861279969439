import React, { Component } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { SideBar, NavBar, Footer, TitleAndDescription } from "../../components";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import Select from "react-select";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  UncontrolledTooltip,
} from "reactstrap";
import Switch from "react-switch";
import queryString from "query-string";
import DropFileInput from "./DropFileInput";
import { formatarReal } from "../../utils";
import { cnpjMask } from "../../mask";
import DatePicker from "react-datepicker";
import { CurrencyInput } from "../../components";

class VeiculoManutencaoDetalhe extends Component {
  constructor(props) {
    super(props);
    this.loadChecklistProblema = this.loadChecklistProblema.bind(this);
    this.toggleModalFoto = this.toggleModalFoto.bind(this);
    this.toggleModalResolucao = this.toggleModalResolucao.bind(this);
    this.toggleModalResolucao2 = this.toggleModalResolucao2.bind(this);
    this.loadFornecedores = this.loadFornecedores.bind(this);
    this.handleChangeInputResolucao =
      this.handleChangeInputResolucao.bind(this);
    this.salvarResolucaoProblema = this.salvarResolucaoProblema.bind(this);
    this.solucionarEmLote = this.solucionarEmLote.bind(this);
    this.salvarResolucaoProblema2 = this.salvarResolucaoProblema2.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.onClickVisualizarDanfe = this.onClickVisualizarDanfe.bind(this);
    this.felipe = this.felipe.bind(this);
    this.handleClickDeletarXml = this.handleClickDeletarXml.bind(this);
    this.toggleModalXmlManual = this.toggleModalXmlManual.bind(this);
    this.handleChangeInput = this.handleChangeInput.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.handleClickAdicionarXmlManual =
      this.handleClickAdicionarXmlManual.bind(this);

    this.state = {
      fornecedores: [],
      fornecedorSelecionado: null,
      modalFoto: false,
      modalResolucao: false,
      modalResolucao2: false,
      foto: "",
      problemaSelecionado: null,
      solucionarVarias: false,

      checklistProblema: {
        _id: null,
        veiculo: {
          _id: "",
          placa: "",
          desc: "",
        },
        motorista: {
          _id: "",
          desc: "",
        },
        km: "",
        dtGravacao: new Date(),
        problemas: [],
        listaXml: [],
      },
      listaXml: [],
      modalXmlManual: false,
      xmlManual: {
        nro: "",
        data: new Date(),
        fornecedor: null,
        valor: "0,00",
      },
      errorsXmlManual: {
        fornecedor: false,
        valor: false,
      },
    };
  }

  async componentDidMount() {
    let id = queryString.parse(this.props.location.search).id;

    if (id === undefined) {
      this.props.history.push("/manutencao-veiculos");
      return false;
    }

    await this.loadChecklistProblema(id);
    await this.loadFornecedores();
  }

  async loadChecklistProblema(id) {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/retaguarda/manutencao-veiculos/${id}`,
        this.props.parameters()
      )
      .then((response) => {
        let checklistProblema = response.data.checklistProblema;

        for (let item of checklistProblema.problemas) {
          item.marcado = false;
        }

        this.setState({
          id,
          checklistProblema,
        });
      });
  }

  notificar(type, message) {
    toast[type](message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  toggleModalFoto() {
    this.setState({ modalFoto: !this.state.modalFoto });
  }
  toggleModalResolucao() {
    this.setState({ modalResolucao: !this.state.modalResolucao });
  }
  toggleModalResolucao2() {
    this.setState({ modalResolucao2: !this.state.modalResolucao2 });
  }

  async loadFornecedores() {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/retaguarda/fornecedores`,
        this.props.parameters()
      )
      .then((response) => {
        let fornecedores = response.data.fornecedores;
        fornecedores.map((forn) => {
          forn.label = forn.fantasia;
          forn.value = forn._id;
          return forn;
        });

        this.setState({ fornecedores });
      })
      .catch((error) => {
        // this.setState({ despesaDeletar: null, modal: false }, () => {
        //   this.notificar("warning", error.response.data.erro);
        // });
      });
  }

  handleChangeInputResolucao(value, tipo) {
    let problemaSelecionado = this.state.problemaSelecionado;

    problemaSelecionado.dadosResolucao[tipo] = value;

    this.setState({ problemaSelecionado });
  }

  async salvarResolucaoProblema() {
    let problema = this.state.problemaSelecionado;

    await axios
      .post(
        `${this.props.getBaseUrlApi()}/retaguarda/manutencao-veiculos/${
          this.state.id
        }`,
        { id: this.state.id, problema, listaXml: this.state.listaXml },
        this.props.parameters()
      )
      .then((response) => {
        this.toggleModalResolucao();
        this.loadChecklistProblema(this.state.id);
        this.notificar("success", "Problema solucionado com sucesso");
        this.setState({ modalXmlManual: false });
      })
      .catch((error) => {
        // this.setState({ despesaDeletar: null, modal: false }, () => {
        //   this.notificar("warning", error.response.data.erro);
        // });
      });
  }
  async salvarResolucaoProblema2() {
    let problema = this.state.problemaSelecionado;
    let checklistProblema = this.state.checklistProblema;

    for (let itemProblema of checklistProblema.problemas) {
      if (itemProblema.marcado) {
        problema.id = itemProblema.id;
        await axios
          .post(
            `${this.props.getBaseUrlApi()}/retaguarda/manutencao-veiculos/${
              this.state.id
            }`,
            { id: this.state.id, problema },
            this.props.parameters()
          )
          .then((response) => {})
          .catch((error) => {
            // this.setState({ despesaDeletar: null, modal: false }, () => {
            //   this.notificar("warning", error.response.data.erro);
            // });
          });
      }
    }

    this.toggleModalResolucao();
    this.loadChecklistProblema(this.state.id);
    this.notificar("success", "Problemas solucionados com sucesso");

    this.setState({ solucionarVarias: false });
  }

  solucionarEmLote() {
    let checklistProblema = this.state.checklistProblema;
    let problemasMarcados = checklistProblema.problemas.filter(
      (prob) => prob.marcado
    );

    if (problemasMarcados.length === 0) {
      this.notificar(
        "warning",
        "Selecione os problemas para solucionar em lote"
      );
      return false;
    }

    this.setState(
      { solucionarVarias: true, problemaSelecionado: problemasMarcados[0] },
      () => {
        this.toggleModalResolucao();
      }
    );
  }

  handleFileChange = (e) => {
    // const selectedFiles = Array.from(e.target.files);
    const selectedFiles = Array.from(e);

    const xmlFiles = selectedFiles.filter((file) => file.type === "text/xml");

    const readerPromises = xmlFiles.map((file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          const xmlString = event.target.result;
          resolve(xmlString);
        };
        reader.onerror = (error) => {
          reject(error);
        };
        reader.readAsText(file);
      });
    });

    Promise.all(readerPromises)
      .then((results) => {
        this.felipe(results);
      })
      .catch((error) => {
        console.error("Error reading file:", error);
      });
  };

  async onClickVisualizarDanfe(xml) {
    await axios
      .post(
        `${this.props.getBaseUrlRoteirizador()}/atualizar-danfe`,
        { xml },
        this.props.parameters()
      )
      .then((response) => {
        window.open(
          `${this.props.getBaseUrlRoteirizador()}/visualizador-danfe.html`,
          "_blank"
        );
      })
      .catch((error) => {
        this.notificar("warning", error.response.data.erro);
      });
  }

  async felipe(listaXmls) {
    await axios
      .post(
        `${this.props.getBaseUrlRoteirizador()}/converter-xml-json2`,
        { listaXmls },
        this.props.parameters()
      )
      .then(async (response) => {
        let listaXmlFormatada = response.data.lista;

        for (let item of listaXmlFormatada) {
          item.emitente.cnpjFormatado = cnpjMask(item.emitente.cnpj);
          item.id = new Date().getTime();
          item.tipoXml = "automatico";
        }

        this.setState({
          listaXml: this.state.listaXml.concat(listaXmlFormatada),
        });
      });

    //  return false;
  }

  handleClickDeletarXml(id) {
    let lista = this.state.listaXml;
    lista = lista.filter((it) => it.id !== id);
    this.setState({ listaXml: lista });
  }

  toggleModalXmlManual() {
    this.setState({ modalXmlManual: !this.state.modalXmlManual });
  }

  handleChangeInput(value, tipo) {
    const hasDot = tipo.includes(".");
    const stateUpdate = {};

    if (hasDot) {
      const [objectKey, propertyKey] = tipo.split(".");
      const objectInState = this.state[objectKey] || {};

      objectInState[propertyKey] = value;
      stateUpdate[objectKey] = objectInState;
    } else {
      stateUpdate[tipo] = value;
    }

    this.setState(stateUpdate);
  }

  handleFocus(e) {
    e.target.select();
  }

  handleClickAdicionarXmlManual() {
    let xmlManual = this.state.xmlManual;

    let valor = this.state.xmlManual.valor;
    if (valor === "") {
      valor = "0,00";
    }
    valor = valor.replace("R$", "");
    valor = valor.replace(",", ".");
    valor = parseFloat(valor);

    let errors = this.state.errorsXmlManual;
    let valido = true;

    if (xmlManual.fornecedor === null) {
      errors.fornecedor = true;
      valido = false;
    } else {
      errors.fornecedor = false;
    }

    if (valor === 0) {
      errors.valor = true;
      valido = false;
    } else {
      errors.valor = false;
    }

    this.setState({ errorsXmlManual: errors });

    if (!valido) {
      this.notificar("warning", "Favor preenche todos os dados corretamente!");
      return false;
    }

    let listaXml = this.state.listaXml;

    function removerCaracteresEspeciais(str) {
      return str.replace(/[^\w\s]/gi, "");
    }

    let itemNovo = {
      dadosNota: {
        dtEmissao: xmlManual.data,
        numeroNf: xmlManual.nro,
        serie: "",
        valorTotalNota: valor,
      },
      emitente: {
        cnpj: removerCaracteresEspeciais(xmlManual.fornecedor.cpfCnpj),
        cnpjFormatado: xmlManual.fornecedor.cpfCnpj,
        endereco: {
          bairro: xmlManual.fornecedor.endereco.bairro,
          cep: xmlManual.fornecedor.endereco.cep,
          cidade: xmlManual.fornecedor.endereco.cep,
          endereco: xmlManual.fornecedor.endereco.rua,
          numero: xmlManual.fornecedor.endereco.num,
          uf: xmlManual.fornecedor.endereco.uf,
        },
        fantasia: xmlManual.fornecedor.fantasia,
        razaoSocial: xmlManual.fornecedor.razaoSocial,
        telefone: xmlManual.fornecedor.telefone,
      },
      id: new Date().getTime(),
      tipoXml: "manual",
    };
    listaXml.push(itemNovo);
    this.setState({ listaXml }, () => {
      this.toggleModalXmlManual();
      this.setState({
        xmlManual: {
          nro: "",
          data: new Date(),
          fornecedor: null,
          valor: "0,00",
        },
      });
    });
  }

  render() {
    const InputRequired = () => {
      return <span className="required-icone">*</span>;
    };

    return (
      <React.Fragment>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />

        <div className="container-scroller font-app">
          <NavBar title="Manutenção de Veículos" />
          <div className="container-fluid page-body-wrapper">
            <SideBar itemActive={"manutencaoVeiculos"} />
            <div className="main-panel">
              <div className="content-wrapper">
                <div className="row">
                  <div className="col-md-12 stretch-card">
                    <div className="card">
                      <div className="card-body3">
                        <React.Fragment>
                          {/* <div
                            className="float-left width-100"
                            style={{
                              borderBottom: "1px solid #CCC",
                              padding: "5px",
                              marginBottom: "20px",
                              position: "relative",
                            }}
                          >
                            VEÍCULO COM PROBLEMA
                          </div> */}

                          <div className="row font-size-14">
                            <div className="col-2 pd-veiculo2">
                              <b>Placa:</b>{" "}
                              {this.state.checklistProblema.veiculo.placa}
                            </div>
                            <div className="col-2 pd-veiculo">
                              <b>Veículo:</b>{" "}
                              {this.state.checklistProblema.veiculo.desc}
                            </div>
                            <div className="col-2 pd-veiculo">
                              <b>Data:</b>{" "}
                              {moment(
                                this.state.checklistProblema.dtGravacao
                              ).format("DD/MM/YYYY HH:mm")}
                            </div>

                            <div className="col-2 pd-veiculo">
                              <b>Tipo:</b>{" "}
                              {this.state.checklistProblema.tipo === "saida"
                                ? "Saída"
                                : "Chegada"}
                            </div>

                            <div className="col-2 pd-veiculo">
                              <b>Motorista:</b>{" "}
                              {this.state.checklistProblema.motorista.desc}
                            </div>

                            <div className="col-2 pd-veiculo3">
                              <b>KM:</b> {this.state.checklistProblema.km}
                            </div>
                          </div>

                          <div className="row font-size-14"></div>

                          <div className="row">
                            <div
                              className="col-md-6"
                              style={{ fontSize: "16px" }}
                            >
                              <b>ITENS DO CHECKLIST</b>
                            </div>
                          </div>

                          <div
                            className="table-itens-container"
                            style={{ padding: "0 0px" }}
                          >
                            <table
                              className="table"
                              style={{ marginBottom: "30px" }}
                            >
                              <thead className="thead-dark">
                                <th>Categoria</th>
                                <th>Itens</th>
                                <th className="text-center">Fotos</th>
                                <th className="text-center">Data Resolução</th>
                                <th className="text-center">Resolvido</th>
                                <th className="text-center">Ações</th>
                              </thead>
                              <tbody>
                                {this.state.checklistProblema.problemas.map(
                                  (item, key) => (
                                    <tr
                                      key={key}
                                      onClick={() => {
                                        let checklistProblema =
                                          this.state.checklistProblema;

                                        for (let check of checklistProblema.problemas) {
                                          if (check.resolvido === false) {
                                            if (check.id === item.id) {
                                              check.marcado = !check.marcado;
                                            }
                                          }
                                        }

                                        this.setState({ checklistProblema });
                                      }}
                                      style={{
                                        backgroundColor: `${
                                          item.marcado ? "#ded9d9" : "inherit"
                                        }`,
                                        cursor: "pointer",
                                      }}
                                    >
                                      <td>{item.checklist.desc}</td>
                                      <td>{item.desc}</td>
                                      <td
                                        className="text-center d-flex flexWrap"
                                        style={{ justifyContent: "center" }}
                                      >
                                        {item.fotos.map((foto) => (
                                          <div
                                            style={{
                                              cursor: "pointer",
                                              fontSize: "20px",
                                              margin: "3px",
                                            }}
                                          >
                                            <i
                                              className="fa fa-image"
                                              onClick={() => {
                                                this.setState(
                                                  { foto: foto.base64 },
                                                  () => {
                                                    this.toggleModalFoto();
                                                  }
                                                );
                                              }}
                                            />
                                          </div>
                                        ))}
                                      </td>
                                      <td className="text-center">
                                        {item.resolvido
                                          ? moment(
                                              item.dadosResolucao.dataResolucao
                                            ).format("DD/MM/YYYY HH:mm")
                                          : "-"}
                                      </td>
                                      <td
                                        className="text-center"
                                        style={{
                                          color: "red",
                                          fontSize: "20px",
                                          cursor: "pointer",
                                        }}
                                      >
                                        <Switch
                                          checked={item.resolvido}
                                          onChange={() => {
                                            this.setState(
                                              { problemaSelecionado: item },
                                              () => {
                                                this.toggleModalResolucao();
                                              }
                                            );
                                          }}
                                          onColor="#f0e8fc"
                                          onHandleColor="#2da951"
                                          handleDiameter={22}
                                          uncheckedIcon={false}
                                          checkedIcon={false}
                                          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                          height={14}
                                          width={48}
                                          // height={16}
                                          // width={48}
                                          disabled={item.resolvido}
                                        />
                                      </td>
                                      <td className="text-center">
                                        <div
                                          className="d-flex"
                                          style={{
                                            justifyContent: "center",
                                            alignItems: "center",
                                          }}
                                        >
                                          {" "}
                                          {item.resolvido && (
                                            <div style={{ margin: "2px" }}>
                                              <i
                                                className="fa fa-eye"
                                                onClick={() => {
                                                  this.setState(
                                                    {
                                                      problemaSelecionado: item,
                                                    },
                                                    () => {
                                                      this.toggleModalResolucao2();
                                                    }
                                                  );
                                                }}
                                              />
                                            </div>
                                          )}
                                          {/* {item.resolvido && (
                                            <React.Fragment>
                                              <div style={{ margin: "2px" }}>
                                                <i
                                                  id={`danfe-app-${item._id}`}
                                                  className="fa fa-file-pdf-o"
                                                  onClick={() => {
                                                    this.onClickVisualizarDanfe(
                                                      item.dadosResolucao
                                                        .listaXml[0]
                                                    );
                                                  }}
                                                  style={{
                                                    fontSize: "22px",
                                                    color: "blue",
                                                  }}
                                                  // onClick={() => {
                                                  //   this.props.history.push(
                                                  //     `/despesas-motorista?id=${despesa.idUsuario}`
                                                  //   );
                                                  // }}
                                                ></i>

                                                <UncontrolledTooltip
                                                  placement="right"
                                                  target={`danfe-app-${item._id}`}
                                                >
                                                  Visualizar Danfe
                                                </UncontrolledTooltip>
                                              </div>
                                            </React.Fragment>
                                          )} */}
                                        </div>
                                      </td>
                                    </tr>
                                  )
                                )}
                              </tbody>
                            </table>
                            {/* <div className="float-left w-100">
                              <button
                                className="btn btn-info font-size-14"
                                onClick={() => {
                                  this.solucionarEmLote();
                                }}
                              >
                                Solucionar em lote
                              </button>
                            </div> */}
                          </div>
                        </React.Fragment>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>

        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Deletar veículo</ModalHeader>
          <ModalBody>Tem certeza que deseja deletar este veículo?</ModalBody>
          <ModalFooter>
            <Button
              className="btn-modal-recuperar"
              color="secondary"
              onClick={this.toggle}
            >
              Não
            </Button>
            <Button
              className="btn-modal-recuperar"
              color="primary"
              onClick={this.handleClickConfirmaDeletar}
            >
              Sim
            </Button>{" "}
          </ModalFooter>
        </Modal>

        <Modal isOpen={this.state.modalFoto} toggle={this.toggleModalFoto}>
          <ModalBody>
            <div className="text-center float-left w-100">
              <img src={this.state.foto} alt="canhoto" width="100%" />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              className="btn-modal-recuperar"
              color="secondary"
              onClick={this.toggleModalFoto}
            >
              Fechar
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.modalResolucao}
          toggle={this.toggleModalResolucao}
          style={{ minWidth: "800px" }}
        >
          <ModalHeader>Informações sobre a Resolução</ModalHeader>
          <ModalBody>
            {this.state.problemaSelecionado !== null && (
              <div className="float-left w-100">
                <div
                  className="form-group form-group-chegoo"
                  style={{ position: "relative", zIndex: "9999" }}
                >
                  <label>Tipo resolução</label>
                  <select
                    className="form-control form-control-chegoo font-size-14"
                    value={this.state.problemaSelecionado.dadosResolucao.tipo}
                    onChange={(e) => {
                      this.handleChangeInputResolucao(e.target.value, "tipo");
                    }}
                  >
                    <option value="interna">Interna</option>
                    <option value="externa">Externa</option>
                  </select>
                </div>
                <div
                  className="form-group form-group-chegoo2"
                  style={{ position: "relative", zIndex: "9999" }}
                >
                  <label>Descrição</label>
                  <textarea
                    className="form-control form-control-chegoo font-size-14"
                    value={this.state.problemaSelecionado.dadosResolucao.obs}
                    onChange={(e) => {
                      this.handleChangeInputResolucao(e.target.value, "obs");
                    }}
                  ></textarea>
                </div>

                {/* <div
                      className="form-group form-group-chegoo"
                      style={{ position: "relative", zIndex: "9999" }}
                    >
                      <label>Fornecedor</label>
                      <Select
                        value={
                          this.state.problemaSelecionado.dadosResolucao
                            .fornecedor
                        }
                        onChange={(value) =>
                          this.handleChangeInputResolucao(value, "fornecedor")
                        }
                        options={this.state.fornecedores}
                        placeholder="Selecione o Fornecedor"
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            fontSize: "14px",
                            // height: "35px",
                            // minHeight: "35px"
                          }),
                          menu: (baseStyles, state) => ({
                            ...baseStyles,
                            fontSize: "14px",
                          }),
                        }}
                        isMulti
                      />
                    </div> */}
                <div
                  className="form-group form-group-chegoo2 d-flex"
                  style={{ justifyContent: "center" }}
                >
                  {/* <label>Nota Fiscal</label> */}

                  <div
                    style={{
                      float: "left",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <DropFileInput
                      onFileChange={(files) => this.handleFileChange(files)}
                    />
                  </div>
                </div>

                <div style={{ position: "relative", zIndex: "9999" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginBottom: "5px",
                    }}
                  >
                    <span
                      style={{ cursor: "pointer", textDecoration: "underline" }}
                      onClick={this.toggleModalXmlManual}
                    >
                      Cadastrar manualmente
                    </span>
                  </div>
                  <table className="table">
                    <thead className="thead-dark">
                      <tr>
                        <th className="text-right">Nro</th>
                        <th className="text-right">Data</th>
                        <th className="text-center">Fornecedor</th>
                        <th className="text-right">Valor</th>
                        <th className="text-center">Açoes</th>
                      </tr>
                    </thead>

                    <tbody>
                      {this.state.listaXml.map((xml) => (
                        <tr>
                          <td className="text-right">
                            <div
                              style={{
                                display: "flex",
                                alignContent: "center",
                              }}
                            >
                              {xml.dadosNota.numeroNf}
                            </div>
                          </td>
                          <td className="text-right">
                            {moment(xml.dadosNota.dtEmissao).format(
                              "DD/MM/YYYY"
                            )}
                          </td>
                          <td className="text-center">
                            {xml.emitente.fantasia}
                          </td>
                          <td className="text-right">
                            R$ {formatarReal(xml.dadosNota.valorTotalNota)}
                          </td>
                          <td className="text-center">
                            <div className="d-flex">
                              {xml.tipoXml === "automatico" && (
                                <div style={{ margin: "0 3px" }}>
                                  <i
                                    id={`danfe-app-22`}
                                    className="fa fa-file-pdf-o"
                                    onClick={() => {
                                      this.onClickVisualizarDanfe(xml.xml);
                                    }}
                                    style={{
                                      fontSize: "22px",
                                      color: "blue",
                                      cursor: "pointer",
                                    }}
                                  ></i>
                                </div>
                              )}
                              <div style={{ margin: "3px" }}>
                                <i
                                  id={`danfe-app-223`}
                                  className="fa fa-times"
                                  onClick={() => {
                                    this.handleClickDeletarXml(xml.id);
                                  }}
                                  style={{
                                    fontSize: "22px",
                                    color: "red",
                                    cursor: "pointer",
                                  }}
                                ></i>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                {/* <div className="form-group form-group-chegoo">
                      <label>Nr da Nota</label>
                      <input
                        type="text"
                        className="form-control form-control-chegoo font-size-14"
                        value={
                          this.state.problemaSelecionado.dadosResolucao.nrNota
                        }
                        onChange={(e) =>
                          this.handleChangeInputResolucao(
                            e.target.value,
                            "nrNota"
                          )
                        }
                      />
                    </div> */}
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              className="btn-modal-recuperar"
              color="secondary"
              //  onClick={this.toggleModalResolucao}
              onClick={() => {
                if (this.state.solucionarVarias) {
                  this.salvarResolucaoProblema2();
                } else {
                  this.salvarResolucaoProblema();
                }
              }}
            >
              Salvar
            </Button>
          </ModalFooter>
        </Modal>

        {/* nro, data, fornecedor, valor */}
        {/* kkk */}

        <Modal
          isOpen={this.state.modalXmlManual}
          toggle={this.toggleModalXmlManual}
        >
          <ModalHeader>Adicionar Nota manual</ModalHeader>
          <ModalBody>
            <div className="float-left w-100">
              <div className="form-group form-group-chegoo2">
                <label>Nro</label>
                <input
                  className="form-control form-control-chegoo font-size-14"
                  onChange={(e) => {
                    this.handleChangeInput(e.target.value, "xmlManual.nro");
                  }}
                />
              </div>

              <div
                className="form-group form-group-chegoo2"
                style={{ position: "relative" }}
              >
                <label>
                  Data
                  <InputRequired />
                </label>
                <DatePicker
                  selected={this.state.xmlManual.data}
                  customInput={
                    <input className="form-control form-control-chegoo font-size-14 text-center required-chegoo" />
                  }
                  dateFormat="dd/MM/yyyy"
                  onChange={(date) => {
                    this.handleChangeInput(date, "xmlManual.data");
                  }}
                  locale="pt-BR"
                />
              </div>

              <div
                className="form-group form-group-chegoo2"
                style={{ position: "relative" }}
              >
                <label>
                  Fornecedor
                  <InputRequired />
                </label>
                <Select
                  value={this.state.xmlManual.fornecedor}
                  onChange={(value) =>
                    this.handleChangeInput(value, "xmlManual.fornecedor")
                  }
                  className={`${
                    this.state.errorsXmlManual.fornecedor ? "select-error" : ""
                  } required-chegoo`}
                  options={this.state.fornecedores}
                  placeholder="Selecione"
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      fontSize: "14px",
                      height: "35px",
                      minHeight: "35px",
                      backgroundColor: "#fffef6",
                    }),
                    menu: (baseStyles, state) => ({
                      ...baseStyles,
                      fontSize: "14px",
                    }),
                  }}
                />
              </div>

              <div
                className="form-group form-group-chegoo2"
                style={{ position: "relative" }}
              >
                <label>
                  Valor
                  <InputRequired />
                </label>

                <CurrencyInput
                  placeholder="R$0,00"
                  type="text"
                  value={this.state.xmlManual.valor}
                  onChange={(e) => {
                    this.handleChangeInput(e.target.value, "xmlManual.valor");
                  }}
                  onFocus={this.handleFocus}
                  className={`font-size-14 form-control-chegoo text-center required-chegoo ${
                    false ? "input-error" : ""
                  }`}
                  onBlur={(e) => {
                    let valor = this.state.xmlManual.valor;
                    if (valor !== "") {
                      valor = valor.replace("R$", "");
                      valor = valor.replace(",", ".");
                      valor = parseFloat(valor);

                      valor = valor.toFixed(2).replace(".", ",");
                      let xmlManual = this.state.xmlManual;

                      xmlManual.valor = valor;
                      this.setState({
                        xmlManual,
                      });
                    }
                  }}
                  ref={(node) => {
                    if (node) {
                      if (this.state.errorsXmlManual.valor) {
                        node.style.setProperty(
                          "border-color",
                          "red",
                          "important"
                        );
                      } else {
                        node.style.setProperty(
                          "border-color",
                          "#ced4da",
                          "important"
                        );
                      }
                    }
                  }}
                />
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              className="btn-modal-recuperar"
              color="secondary"
              onClick={() => {
                this.handleClickAdicionarXmlManual();
              }}
            >
              Salvar
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.modalResolucao2}
          toggle={this.toggleModalResolucao2}
          style={{ minWidth: "800px" }}
        >
          {/* {kk2} */}
          <ModalHeader>Informações sobre a Resolução</ModalHeader>
          <ModalBody>
            {this.state.problemaSelecionado !== null && (
              <div className="float-left w-100">
                <div className="form-group form-group-chegoo">
                  <label>Tipo resolução</label>
                  <select
                    className="form-control form-control-chegoo font-size-14"
                    value={this.state.problemaSelecionado.dadosResolucao.tipo}
                    disabled={true}
                  >
                    <option value="interna">Interna</option>
                    <option value="externa">Externa</option>
                  </select>
                </div>
                <div className="form-group form-group-chegoo">
                  <label>Descrição</label>
                  <textarea
                    className="form-control form-control font-size-14"
                    value={this.state.problemaSelecionado.dadosResolucao.obs}
                    disabled={true}
                  ></textarea>
                </div>

                <React.Fragment>
                  {/* <div className="form-group form-group-chegoo">
                      <label>Fornecedor</label>
                      <Select
                        value={
                          this.state.problemaSelecionado.dadosResolucao
                            .fornecedor
                        }
                        onChange={(value) =>
                          this.handleChangeInputResolucao(value, "fornecedor")
                        }
                        options={this.state.fornecedores}
                        placeholder="Selecione o Fornecedor"
                        isDisabled={true}
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            fontSize: "14px",
                            height: "35px",
                            minHeight: "35px",
                          }),
                          menu: (baseStyles, state) => ({
                            ...baseStyles,
                            fontSize: "14px",
                          }),
                        }}
                      />
                    </div> */}
                  <div className="form-group form-group-chegoo2">
                    <label>Notas Fiscais</label>
                    {/* <input
                        type="text"
                        className="form-control form-control-chegoo font-size-14"
                        value={
                          this.state.problemaSelecionado.dadosResolucao.nrNota
                        }
                        disabled={true}
                      /> */}
                  </div>

                  <div>
                    <table className="table">
                      <thead className="thead-dark">
                        <tr>
                          <th className="text-right">Nro</th>
                          <th className="text-right">Data</th>
                          <th className="text-center">Fornecedor</th>
                          <th className="text-right">Valor</th>
                          <th className="text-center">Açoes</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.problemaSelecionado.dadosResolucao
                          .listaXml !== undefined &&
                          this.state.problemaSelecionado.dadosResolucao.listaXml.map(
                            (xml) => (
                              <tr>
                                <td className="text-right">
                                  {xml.dadosNota.numeroNf}
                                </td>
                                <td className="text-right">
                                  {moment(xml.dadosNota.dtEmissao).format(
                                    "DD/MM/YYYY"
                                  )}
                                </td>
                                <td className="text-center">
                                  {xml.emitente.fantasia}
                                </td>
                                <td className="text-right">
                                  R${" "}
                                  {formatarReal(xml.dadosNota.valorTotalNota)}
                                </td>
                                <td className="text-center">
                                  {xml.tipoXml === "automatico" && (
                                    <i
                                      id={`danfe-app-22`}
                                      className="fa fa-file-pdf-o"
                                      onClick={() => {
                                        this.onClickVisualizarDanfe(xml.xml);
                                      }}
                                      style={{
                                        fontSize: "22px",
                                        color: "blue",
                                        cursor: "pointer",
                                      }}
                                      // onClick={() => {
                                      //   this.props.history.push(
                                      //     `/despesas-motorista?id=${despesa.idUsuario}`
                                      //   );
                                      // }}
                                    ></i>
                                  )}
                                </td>
                              </tr>
                            )
                          )}
                      </tbody>
                    </table>
                  </div>
                </React.Fragment>
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <Button
              className="btn-modal-recuperar"
              color="secondary"
              //  onClick={this.toggleModalResolucao}
              onClick={() => {
                this.toggleModalResolucao2();
              }}
            >
              Fechar
            </Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default VeiculoManutencaoDetalhe;
