import React, { Component } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { SideBar, NavBar, Footer, TitleAndDescription } from "../../components";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import Select from "react-select";
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from "reactstrap";
const { formatarReal } = require("../../utils");

class Atividade extends Component {
  constructor(props) {
    super(props);
    this.load = this.load.bind(this);
    this.loadAtividades = this.loadAtividades.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleClickDeletar = this.handleClickDeletar.bind(this);
    this.handleClickConfirmaDeletar =
      this.handleClickConfirmaDeletar.bind(this);

    this.state = {
      usuarios: [],
      usuarioDeletar: null,
      modal: false,
      atividades: [],
    };
  }

  async componentDidMount() {
    await this.load();
  }

  async load() {
    await this.loadAtividades();
  }

  async loadAtividades() {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/retaguarda/atividades-lista`,
        this.props.parameters()
      )
      .then((response) => {
        // // ////console.clear();
       // console.log(response.data.atividades);

        let atividades = response.data.atividades;

        for (let atividade of atividades) {
          let dtGravacao = moment(atividade.dtGravacao);
          let diferenca = moment.duration(moment().diff(dtGravacao)).asDays();

          diferenca = parseInt(diferenca);

          let descHora = "";

          //OCORRENCIA NO DIA ATUAL
          if (diferenca === 0) {
            if (moment().format("DD") === dtGravacao.format("DD")) {
              descHora = `Hoje às ${dtGravacao.format("HH:mm")}`;
            } else {
              descHora = `Ontem às ${dtGravacao.format("HH:mm")}`;
            }
          } else {
            descHora = `${dtGravacao.format(
              "DD/MM/YYYY"
            )} às ${dtGravacao.format("HH:ss")}`;
          }

          atividade.descHora = descHora;
        }

        this.setState({ atividades });
      });
  }

  notificar(type, message) {
    toast[type](message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  handleClickDeletar(id) {
    this.setState({ usuarioDeletar: id, modal: true });
  }

  toggle() {
    this.setState({ modal: !this.state.modal });
  }

  async handleClickConfirmaDeletar() {
    await axios
      .delete(
        `${this.props.getBaseUrlApi()}/retaguarda/usuarios/${
          this.state.usuarioDeletar
        }`,
        this.props.parameters()
      )
      .then((response) => {
        this.setState({ usuarioDeletar: null, modal: false }, () => {
          this.loadAtividades();
          this.notificar("success", "Usuário deletado com sucesso!");
        });
      })
      .catch((error) => {
        this.setState({ usuarioDeletar: null, modal: false }, () => {
          this.notificar("warning", error.response.data.erro);
        });
      });
  }

  render() {
    return (
      <React.Fragment>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />

        <div className="container-scroller font-app">
          <NavBar title="Atividades" />
          <div className="container-fluid page-body-wrapper">
            <SideBar itemActive={"usuarios"} />
            <div className="main-panel">
              <div className="content-wrapper">
                {/* <TitleAndDescription title="Atividades" description="" /> */}

                <div className="row">
                  <div className="col-md-12 stretch-card">
                    {this.state.usuarios.length > -1 && (
                      <div className="card">
                        <div className="card-body">
                          <React.Fragment>
                            <div
                              className="float-left width-100"
                              style={{
                                borderBottom: "1px solid #CCC",
                                padding: "5px",
                                marginBottom: "20px",
                                position: "relative",
                              }}
                            >
                              LISTA DE ATIVIDADES
                            </div>
                            <div className="table-responsive">
                              <table
                                id="recent-purchases-listing"
                                className="table"
                              >
                                <thead>
                                  <tr>
                                    <th
                                      style={{
                                        width: "280px",
                                        maxWidth: "280px",
                                      }}
                                    >
                                      Titulo
                                    </th>
                                    <th>Cliente</th>
                                    <th>Motorista</th>
                                    {/* <th>Data</th> */}
                                    <th className="text-center">Ações</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.atividades.map(
                                    (atividade, key) => (
                                      <tr key={key}>
                                        <td
                                          className="d-flex td-atividade"
                                          style={{
                                            justifyContent: "center",
                                            alignItems: "center",
                                            maxWidth: "280px",
                                            width: "280px",
                                            cursor: "pointer",
                                          }}
                                          onClick={(e) => {
                                            this.props.history.push(
                                              `/roteiros-detalhe-visita?id=${atividade.idRoteiro}&seq=${atividade.seq}`
                                            );
                                          }}
                                        >
                                          {" "}
                                          <div className="col-2 text-center">
                                            {atividade.icon === "warning" && (
                                              <i
                                                className="fa fa-exclamation"
                                                style={{
                                                  fontSize: "22px",
                                                  color: "#FCAB10",
                                                }}
                                              ></i>
                                            )}
                                            {atividade.icon === "danger" && (
                                              <i
                                                className="fa fa-times"
                                                style={{
                                                  fontSize: "22px",
                                                  color: "#F2542D",
                                                }}
                                              ></i>
                                            )}
                                            {atividade.icon === "success" && (
                                              <i
                                                className="fa fa-check"
                                                style={{
                                                  fontSize: "22px",
                                                  color: "#2da951",
                                                }}
                                              ></i>
                                            )}{" "}
                                          </div>
                                          <div className="col-10">
                                            {atividade.titulo}
                                            <div
                                              style={{
                                                float: "left",
                                                width: "100%",
                                                paddingTop: "5px",
                                              }}
                                            >
                                              {atividade.descHora}
                                            </div>
                                          </div>
                                        </td>
                                        <td> {atividade.desc}</td>
                                        <td> {atividade.motorista.desc}</td>
                                        {/* <td> {atividade.descHora}</td> */}
                                        <td className="text-center">
                                          {" "}
                                          <i
                                            className="fa fa-search"
                                            onClick={(e) => {
                                              this.props.history.push(
                                                `/roteiros-detalhe-visita?id=${atividade.idRoteiro}&seq=${atividade.seq}`
                                              );
                                            }}
                                            style={{ cursor: "pointer" }}
                                          />
                                        </td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </table>
                            </div>
                            {/* 
                            <div className="paginacao">
                              <Pagination
                                prevPageText={
                                  <i className="fa fa-angle-left" />
                                }
                                nextPageText={
                                  <i className="fa fa-angle-right" />
                                }
                                firstPageText={
                                  <i className="fa fa-angle-double-left" />
                                }
                                lastPageText={
                                  <i className="fa fa-angle-double-right" />
                                }
                                activePage={this.state.paginacao.pagina}
                                itemsCountPerPage={this.state.paginacao.limite}
                                totalItemsCount={this.state.totalcomissoes}
                                onChange={(pagina) => this.paginar(pagina)}
                                itemClass="page-item"
                                linkClass="page-link"
                              />
                            </div> */}
                          </React.Fragment>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>

        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Deletar usuário</ModalHeader>
          <ModalBody>Tem certeza que deseja deletar este usuário?</ModalBody>
          <ModalFooter>
            <Button
              className="btn-modal-recuperar"
              color="secondary"
              onClick={this.toggle}
            >
              Não
            </Button>
            <Button
              className="btn-modal-recuperar"
              color="primary"
              onClick={this.handleClickConfirmaDeletar}
            >
              Sim
            </Button>{" "}
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default Atividade;
