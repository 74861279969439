import React, { Component } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { SideBar, NavBar, Footer } from "../../components";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import Select from "react-select";
import { UncontrolledTooltip } from "reactstrap";
import DatePicker from "react-datepicker";
import FileDownload from "js-file-download";

class RoteiroConsulta extends Component {
  constructor(props) {
    super(props);
    this.load = this.load.bind(this);
    this.loadVisitas = this.loadVisitas.bind(this);
    this.loadMotoristas = this.loadMotoristas.bind(this);
    this.loadVeiculos = this.loadVeiculos.bind(this);
    this.limparFiltros = this.limparFiltros.bind(this);
    this.loadClientes = this.loadClientes.bind(this);
    this.gerarRelatorio = this.gerarRelatorio.bind(this);

    this.state = {
      visitas: [],
      veiculos: [],
      veiculoSelecionado: null,
      motoristas: [],
      motoristaSelecionado: null,
      dataInicial: moment().startOf("month").toDate(),
      dataFinal: moment().endOf("month").toDate(),
      clientes: [],
      clienteSelecionado: null,
    };
  }

  async componentDidMount() {
    this.load();
    this.loadVeiculos();
    this.loadMotoristas();
    this.loadClientes();
  }

  async load() {
    await this.loadVisitas();
  }

  async loadVisitas() {
    let veiculo = this.state.veiculoSelecionado;
    let motorista = this.state.motoristaSelecionado;
    let dataInicial = this.state.dataInicial;
    let dataFinal = this.state.dataFinal;
    let cliente = this.state.clienteSelecionado;

    if (motorista !== null) {
      motorista = motorista._id;
    }

    if (veiculo !== null) {
      veiculo = veiculo._id;
    }

    if (cliente !== null) {
      cliente = cliente._id;
    }

    let url = `${this.props.getBaseUrlApi()}/retaguarda/consulta-roteiros`;

    await axios
      .post(
        url,
        { dataInicial, dataFinal, motorista, veiculo, cliente },
        this.props.parameters()
      )
      .then((response) => {
        let visitas = response.data.visitas;

        for (let visita of visitas) {
          visita.statusClass = visita.status;

          if (visita.status === "naoRealizada") {
            visita.status = "Não Realizada";
          }
        }

        this.setState({ visitas });
      });
  }

  notificar(type, message) {
    toast[type](message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  async loadVeiculos() {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/retaguarda/veiculos`,
        this.props.parameters()
      )
      .then((response) => {
        let veiculos = response.data.veiculos;

        veiculos.map((veic) => {
          veic.label = veic.desc;
          veic.value = veic._id;
          return veic;
        });

        this.setState({ veiculos });
      })
      .catch((error) => {
        // this.setState({ despesaDeletar: null, modal: false }, () => {
        //   this.notificar("warning", error.response.data.erro);
        // });
      });
  }

  limparFiltros() {
    this.setState(
      {
        veiculoSelecionado: null,
        motoristaSelecionado: null,
        dataInicial: moment().startOf("month").toDate(),
        dataFinal: moment().endOf("month").toDate(),
        clienteSelecionado: null,
      },
      () => {
        this.load();
      }
    );
  }

  async loadMotoristas() {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/retaguarda/usuarios`,
        this.props.parameters()
      )
      .then((response) => {
        const data = response.data;
        let motoristas = data.usuarios;

        for (let motorista of motoristas) {
          motorista.label = motorista.desc;
          motorista.value = motorista._id;
        }

        this.setState({ motoristas });
      });
  }

  async loadClientes() {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/retaguarda/clientes`,
        this.props.parameters()
      )
      .then(async (response) => {
        let clientes = response.data.clientes;

        clientes = clientes.map((cliente) => {
          cliente.label = cliente.desc;
          cliente.value = cliente._id;
          return cliente;
        });

        this.setState({ clientes });
      });
  }

  async gerarRelatorio() {
    let listaFormatada = [];
    let lista = this.state.visitas;

    for (let item of lista) {
      let dataInicial = "";
      let dataFinal = "";

      if (item.dtInicial !== null) {
        dataInicial = moment(item.dtInicial).format("DD/MM/YYYY HH:mm");
      }

      if (item.dtFinal !== null) {
        dataFinal = moment(item.dtFinal).format("DD/MM/YYYY HH:mm");
      }

      listaFormatada.push({
        situacao: item.status.slice(0, 1).toUpperCase() + item.status.slice(1),
        roteiro: item.descRoteiro,
        motorista: item.motorista.desc,
        cliente: item.cliente.desc,
        endereco: `${item.endereco.end}, N° ${item.endereco.num}, ${item.endereco.bairro}, ${item.endereco.cidade} - ${item.endereco.uf}`,
        cep: item.endereco.cep,
        dataInicial: dataInicial,
        dataFinal: dataFinal,
        documento: item.documentos[0].nro,
      });
    }

    let dataInicial = this.state.dataInicial;
    let dataFinal = this.state.dataFinal;
    let veiculo = "Todos";
    let motorista = "Todos";
    let cliente = "Todos";

    if (this.state.veiculoSelecionado !== null) {
      veiculo = this.state.veiculoSelecionado.desc;
    }

    if (this.state.motoristaSelecionado !== null) {
      motorista = this.state.motoristaSelecionado.desc;
    }

    if (this.state.clienteSelecionado !== null) {
      cliente = this.state.clienteSelecionado.desc;
    }

    let parametros = {
      roteiros: listaFormatada,
      dataInicial,
      dataFinal,
      veiculo,
      motorista,
      cliente,
    };

    // let nomeArquivo = `ordens-visita.xlsx`;
    let nomeArquivo = `ordens-visita-${moment(dataInicial)
      .startOf("day")
      .format("YYYY-MM-DD")}-${moment(dataFinal)
      .startOf("day")
      .format("YYYY-MM-DD")}`;

    await axios
      .post(
        `${this.props.getBaseUrlImpressao()}/roteiros-pdf`,
        { parametros }
      )
      .then(async (response) => {
        // FileDownload(response.data, nomeArquivo);
        localStorage.setItem('html', response.data.html)
        localStorage.setItem('tituloPagina', nomeArquivo)
        window.open("/impressao", "_blank");
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          // this.notificar("warning", error.response.data.erro);
        }
      });
    // import FileDownload from "js-file-download";
  }

  async gerarRelatorio2() {
    ////console.clear()
    console.log(JSON.stringify(this.state))
    console.log(this.state)
// return false;

    let listaFormatada = [];
    let lista = this.state.visitas;

    for (let item of lista) {
      let dataInicial = "";
      let dataFinal = "";

      if(item.dtInicial !== null){
        dataInicial = moment(item.dtInicial).format('DD/MM/YYYY HH:mm');
      }

      if(item.dtFinal !== null){
        dataFinal = moment(item.dtFinal).format('DD/MM/YYYY HH:mm');
      }


      listaFormatada.push({
        SITUACAO: item.status.slice(0, 1).toUpperCase() + item.status.slice(1),
        ROTEIRO: item.descRoteiro,
        MOTORISTA: item.motorista.desc,
        CLIENTE: item.cliente.desc,
        ENDERECO: item.endereco.end,
        NUMERO: item.endereco.num,
        BAIRRO: item.endereco.bairro,
        CIDADE: item.endereco.cidade,
        UF: item.endereco.uf,
        CEP: item.endereco.cep,
        DATA_INICIAL: dataInicial,
        DATA_FINAL: dataFinal,
        DOCUMENTO: item.documentos[0].nro
      });
    }

    let dataInicial = this.state.dataInicial;
    let dataFinal = this.state.dataFinal;

    // let nomeArquivo = `ordens-visita.xlsx`;
    let nomeArquivo = `ordens-visita-${moment(dataInicial)
      .startOf("day")
      .format("YYYY-MM-DD")}-${moment(dataFinal)
      .startOf("day")
      .format("YYYY-MM-DD")}.xlsx`;

    let params = {};
    params.responseType = "blob";

    await axios
      .post(
        `${this.props.getBaseUrlApi()}/retaguarda/relatorios`,
        { lista: listaFormatada },
        params
      )
      .then(async (response) => {
        FileDownload(response.data, nomeArquivo);
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          // this.notificar("warning", error.response.data.erro);
        }
      });
      // import FileDownload from "js-file-download";
  }

  render() {
    return (
      <React.Fragment>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />

        <div className="container-scroller font-app">
          <NavBar title="Consulta de ordens de visita" />
          <div className="container-fluid page-body-wrapper">
            <SideBar itemActive={"consulta-roteiros"} />
            <div className="main-panel">
              <div className="content-wrapper">
              <div
                  className="row"
                  style={{ marginBottom: "0.8rem", alignItems: "center" }}
                >
                  <div className="col-md-12 stretch-card">
                    {true && (
                      <div className="card">
                        <div
                          className="card-body5"
                          style={{ position: "relative" }}
                        >
                          <React.Fragment>
                            {/* <div
                              style={{
                                position: "absolute",
                                top: "11px",
                                right: "11px",
                              }}
                            >
                              <button
                                className="btn btn-success btn-app-verde-sm"
                                onClick={() => {
                                  this.props.history.push("/fornecedor-novo");
                                }}
                              >
                               CADASTRAR
                              </button>
                            </div> */}
                            <div className="row">
                              <div className="col-md-2 pd-veiculo2">
                              <div className="form-group form-group-chegoo2">
                                  <label>Data Inicial</label>
                                  <DatePicker
                                  selected={this.state.dataInicial}
                                  customInput={
                                    <input className="form-control font-size-14 form-control-chegoo text-center" style={{width:"100%"}} />
                                  }
                                  dateFormat="dd/MM/yyyy"
                                  onChange={(date) => {
                                    this.setState({ dataInicial: date });
                                  }}
                                  locale="pt-BR"
                                />
                                </div>
                              </div>
                              <div className="col-md-2 pd-veiculo">
                              <div className="form-group form-group-chegoo2">
                                  <label>Data Final</label>
                                  <DatePicker
                                  selected={this.state.dataFinal}
                                  customInput={
                                    <input className="form-control font-size-14 form-control-chegoo text-center" />
                                  }
                                  dateFormat="dd/MM/yyyy"
                                  onChange={(date) => {
                                    this.setState({ dataFinal: date });
                                  }}
                                  locale="pt-BR"
                                />
                                </div>
                              </div>
                              <div className="col-md-2 col-6 pd-veiculo">
                                <div className="form-group-chegoo font-size-14">
                                  <label>Veículo</label>
                                  <Select
                                    value={this.state.veiculoSelecionado}
                                    onChange={(value) => {
                                      this.setState({
                                        veiculoSelecionado: value,
                                      });
                                    }}
                                    options={this.state.veiculos}
                                    placeholder="Selecione"
                                    styles={{
                                      control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        fontSize: "14px",
                                        height: "35px",
                                        minHeight: "35px"
                                      }),
                                      menu: (baseStyles, state) => ({
                                        ...baseStyles,
                                        fontSize: "14px",
                                        marginTop: "30px"
                                      }),
                                    }}
                                    className="custom-select2" // Adicione uma classe personalizada
                                  />
                                </div>
                              </div>

                              {/* <div className="col-md-2 pd-veiculo">
                                <div className="form-group form-group-chegoo2">
                                  <label>Status</label>
                                  <select className="form-control form-control-chegoo font-size-14">
                                    <option>Ativo</option>
                                    <option>Inativo</option>
                                  </select>
                                </div>
                              </div> */}

<div className="col-md-2 col-6 pd-veiculo">
                                <div className="form-group-chegoo form-group-chegoo2 font-size-14">
                                  <label>Motorista</label>
                                  <Select
                                    value={this.state.motoristaSelecionado}
                                    onChange={(value) => {
                                      this.setState({
                                        motoristaSelecionado: value,
                                      });
                                    }}
                                    options={this.state.motoristas}
                                    placeholder="Selecione"
                                    styles={{
                                      control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        fontSize: "14px",
                                        height: "35px",
                                        minHeight: "35px"
                                      }),
                                      menu: (baseStyles, state) => ({
                                        ...baseStyles,
                                        fontSize: "14px"
                                      }),
                                    }}
                                  />
                                </div>
                              </div>

                              <div className="col-md-2 col-6 pd-veiculo">
                                <div className="form-group-chegoo form-group-chegoo2 font-size-14">
                                  <label>Cliente</label>
                                  <Select
                                    value={this.state.clienteSelecionado}
                                    onChange={(value) => {
                                      this.setState({
                                        clienteSelecionado: value,
                                      });
                                    }}
                                    options={this.state.clientes}
                                    placeholder="Selecione"
                                    styles={{
                                      control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        fontSize: "14px",
                                        height: "35px",
                                        minHeight: "35px"
                                      }),
                                      menu: (baseStyles, state) => ({
                                        ...baseStyles,
                                        fontSize: "14px"
                                      }),
                                    }}
                                  />
                                </div>
                              </div>
                              


                              <div
                                className="col-md-2 pd-veiculo"
                                style={{ display: "flex", marginTop: "15px" }}
                              >
                                <div
                                  className="form-group-chegoo2"
                                  style={{ float: "left" }}
                                >
                                  <label></label>
                                  <button
                                    className="btn btn-secondary btn-sm"
                                    style={{
                                      padding: "0.275rem 0.55rem",
                                      margin: "7px 5px 0px",
                                    }}
                                    onClick={() => this.load()}
                                    id={`tooltip-filtro-atendimentos`}
                                  >
                                    <i
                                      className="fa fa-search"
                                      aria-hidden="true"
                                      style={{ fontSize: "px" }}
                                    ></i>
                                  </button>
                                  <UncontrolledTooltip
                                    placement="bottom"
                                    target={`tooltip-filtro-atendimentos`}
                                  >
                                    Filtrar
                                  </UncontrolledTooltip>
                                </div>
                                <div className="form-group-chegoo2">
                                  <label></label>
                                  <button
                                    className="btn btn-warning btn-sm"
                                    style={{
                                      padding: "0.275rem 0.55rem",
                                      margin: "7px 5px 0px",
                                      color: "#FFF"
                                    }}
                                    onClick={() => this.limparFiltros()}
                                    id={`tooltip-limpar-filtro-atendimentos`}
                                  >
                                    <i
                                      className="fa fa-eraser"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                  <UncontrolledTooltip
                                    placement="bottom"
                                    target={`tooltip-limpar-filtro-atendimentos`}
                                  >
                                    Limpar filtro
                                  </UncontrolledTooltip>
                                </div>
                                <div className="form-group-chegoo2">
                                  <label></label>
                                  <button
                                    className="btn btn-danger btn-sm"
                                    style={{
                                      padding: "0.275rem 0.55rem",
                                      margin: "7px 5px 0px",
                                    }}
                                    onClick={() => this.gerarRelatorio()}
                                    id={`tooltip-limpar-filtro-atendimentos`}
                                  >
                                    <i
                                      className="fa fa-file-pdf-o"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                  <UncontrolledTooltip
                                    placement="bottom"
                                    target={`tooltip-limpar-filtro-atendimentos`}
                                  >
                                    Gerar relatório
                                  </UncontrolledTooltip>
                                </div>
                              </div>

                             


                              {/* <div
                                className="col-md-2"
                                style={{
                                  marginTop: "15px",
                                  marginTop: "15px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "end",
                                }}
                              >
                                <button
                                  className="btn btn-success btn-app-verde-sm"
                                  onClick={() => {
                                    this.props.history.push("/fornecedor-novo");
                                  }}
                                >
                                  CADASTRAR
                                </button>
                              </div> */}
                            </div>
                          </React.Fragment>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12 stretch-card">
                    {this.state.visitas.length > -1 && (
                      <div className="card">
                        <div className="card-body2">
                          <React.Fragment>
                         
                            
                            <div className="table-responsive">
                              <table
                                id="recent-purchases-listing"
                                className="table"
                              >
                                <thead className="thead-dark">
                                  <tr>
                                    <th>Situação</th>
                                    <th>Roteiro</th>

                                    <th>Motorista</th>
                                    <th>Cliente</th>
                                    <th className="text-right">Data Inicial</th>
                                    <th className="text-right">Data Final</th>
                                    <th className="text-center">Documento</th>
                                    <th>Ações</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.visitas.map((visita, key) => (
                                    <tr key={key}>
                                      <td
                                        className={`color-${visita.statusClass}`}
                                        style={{
                                          textTransform: "capitalize",
                                          width: "130px",
                                          minWidth: "130px",
                                        }}
                                      >
                                        {" "}
                                        <i className="fa fa-circle"> </i>{" "}
                                        {visita.status}
                                      </td>
                                      <td>
                                        <span
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          {visita.descRoteiro}
                                        </span>{" "}
                                      </td>

                                      <td> {visita.motorista.desc}</td>
                                      <td> {visita.cliente.desc}</td>
                                      <td className="text-right">
                                        {visita.dtInicial !== null
                                          ? moment(visita.dtInicial).format(
                                              "DD/MM HH:mm"
                                            )
                                          : ""}
                                      </td>
                                      <td className="text-right">
                                        {visita.dtFinal !== null
                                          ? moment(visita.dtFinal).format(
                                              "DD/MM HH:mm"
                                            )
                                          : ""}
                                      </td>
                                      {/* <td
                                        className={`text-center`}
                                        style={{
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {visita.status === "realizada" ? (
                                          <i
                                            className="fa fa-search"
                                            style={{ cursor: "pointer" }}
                                            // onClick={(e) => {
                                            //   this.handleClickVisualizarFoto(
                                            //     visita.canhoto.foto,
                                            //     "canhoto"
                                            //   );
                                            // }}
                                          ></i>
                                        ) : (
                                          <span className="text-center">
                                            {" "}
                                            -{" "}
                                          </span>
                                        )}
                                      </td>
                                      <td
                                        className={`text-center`}
                                        style={{
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {visita.status === "realizada" ? (
                                          <i
                                            className="fa fa-search"
                                            style={{ cursor: "pointer" }}
                                            // onClick={(e) => {
                                            //   this.handleClickVisualizarFoto(
                                            //     visita.recebedor.assinatura,
                                            //     "assinatura"
                                            //   );
                                            // }}
                                          ></i>
                                        ) : (
                                          <span className="text-center">-</span>
                                        )}
                                      </td> */}
                                      <td
                                        className={`text-center`}
                                        style={{
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {visita.documentos[0].tipo}{" "}
                                        {visita.documentos[0].nro}
                                      </td>

                                      <td
                                        className="d-flex"
                                        style={{ fontSize: "18px" }}
                                      >
                                        <div
                                          style={{
                                            margin: "2px",
                                            cursor: "pointer",
                                          }}
                                        >
                                          <i
                                            className="fa fa-search"
                                            onClick={() => {
                                              this.props.history.push(
                                                `/roteiros-detalhe-visita?id=${visita.idRoteiro}&seq=${visita.seq}`
                                              );
                                            }}
                                          ></i>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </React.Fragment>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default RoteiroConsulta;
