import React, { Component } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { SideBar, NavBar, Footer, TitleAndDescription } from "../../components";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import Select from "react-select";
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from "reactstrap";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_dataviz from "@amcharts/amcharts4/themes/dataviz";
import { UncontrolledTooltip } from "reactstrap";
import DatePicker from "react-datepicker";
import FileDownload from "js-file-download";

const { formatarReal } = require("../../utils");
am4core.useTheme(am4themes_dataviz);
am4core.useTheme(am4themes_animated);

const SITUACAO_PENDENTE = "0";
const SITUACAO_ANALISE = "1";
const SITUACAO_APROVADO = "2";
const SITUACAO_REVISAO = "3";
const SITUACAO_REPROVADO = "4";
const SITUACAO_CANCELADO = "5";

const MODAL_IMPRESSAO = "modalImpressao";
const MODAL_DELETAR = "modalDeletar";

class Despesa extends Component {
  constructor(props) {
    super(props);
    this.load = this.load.bind(this);
    this.loadDespesas = this.loadDespesas.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleClickDeletar = this.handleClickDeletar.bind(this);
    this.handleClickConfirmaDeletar =
      this.handleClickConfirmaDeletar.bind(this);
    this.iniciaGraficoSaldo = this.iniciaGraficoSaldo.bind(this);
    this.loadUsuarios = this.loadUsuarios.bind(this);
    this.gerarRelatorio = this.gerarRelatorio.bind(this);
    this.onChangeInputFiltro = this.onChangeInputFiltro.bind(this);
    this.limparFiltros = this.limparFiltros.bind(this);
    this.loadObras = this.loadObras.bind(this);
    this.loadTiposDespesa = this.loadTiposDespesa.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
    this.handleConfirmMarcarRevisao =
      this.handleConfirmMarcarRevisao.bind(this);
    this.handleConfirmAprovarDespesa =
      this.handleConfirmAprovarDespesa.bind(this);
    this.handleConfirmReprovarDespesa =
      this.handleConfirmReprovarDespesa.bind(this);
    this.abrirPaginaEditarDespesa = this.abrirPaginaEditarDespesa.bind(this);
    this.abrirDespesasUsuario = this.abrirDespesasUsuario.bind(this);
    this.gerarRelatorioExcel = this.gerarRelatorioExcel.bind(this);
    this.loadFiltro = this.loadFiltro.bind(this);
    this.buscarImagem = this.buscarImagem.bind(this);
    this.abrirImagemBase64EmNovaAba =
      this.abrirImagemBase64EmNovaAba.bind(this);

    this.state = {
      despesas: [],
      despesaDeletar: null,
      // modal: false,
      totalizadores: {
        saldoMensal: 0,
        pagos: 0,
        pendentes: 0,
      },
      dataInicial: moment().startOf("month").toDate(),
      dataFinal: moment().endOf("month").toDate(),
      totalizadorNovo: {
        valorTotal: 0,
        categorias: [],
      },
      motoristas: [],
      motoristaSelecionado: null,
      filtro: {
        dtInicial: moment().startOf("month").toDate(),
        dtFinal: moment().endOf("month").toDate(),
        usuario: {
          _id: "99",
          value: "99",
          label: "Todos",
          desc: "Todos",
        },
        situacao: "99",
        obra: {
          _id: "99",
          value: "99",
          label: "Todas",
          desc: "Todas",
        },
        tipoDespesa: {
          _id: "99",
          value: "99",
          label: "Todos",
          desc: "Todos",
        },
      },
      usuarios: [],
      tiposDespesa: [],
      modal: {
        revisarDespesa: false,
        aprovarDespesa: false,
        reprovarDespesa: false,
        [MODAL_IMPRESSAO]: false,
        [MODAL_DELETAR]: false,
      },
      obsRevisao: "",
      idDespesaRevisao: null,
      somenteVisualizarObsRevisao: false,
      idDespesaAprovar: null,
      obsReprovacao: "",
      isDeleting: false,
      niveUsuarioLogado: "5",
    };
  }

  async componentDidMount() {
    const userLogado = JSON.parse(localStorage.getItem("usuario"));
    if (userLogado.nivel === "3") {
      return this.props.history.push("/pedidos-material");
    }
    if (userLogado.nivel === "4") {
      return this.props.history.push("/nao-conformidades");
    }
    await this.load();
    await this.loadUsuarios();
    await this.loadObras();
    this.setState({ niveUsuarioLogado: userLogado.nivel });
    await this.loadTiposDespesa();
  }

  async load() {
    this.loadFiltro();
  }

  async loadDespesas(isSalvarFiltro = false) {
    let filtro = this.state.filtro;

    let filtroEnvio = {};

    if (filtro.usuario._id !== "99") {
      filtroEnvio["idUsuario"] = filtro.usuario._id;
    }

    if (filtro.obra._id !== "99") {
      filtroEnvio["obra"] = filtro.obra._id;
    }

    if (filtro.situacao !== "99") {
      filtroEnvio["situacao"] = filtro.situacao;
    }

    if (filtro.tipoDespesa !== null && filtro.tipoDespesa._id !== "99") {
      filtroEnvio["tipoDespesa"] = filtro.tipoDespesa._id;
    }

    let options = {
      ...filtroEnvio,
      dataInicial: this.state.filtro.dtInicial,
      dataFinal: this.state.filtro.dtFinal,
    };

    await axios
      .post(
        `${this.props.getBaseUrlApi()}/retaguarda/lista-despesas-felipe`,
        {
          options,
        },
        this.props.parameters()
      )
      .then((response) => {
        let despesas = response.data.despesas;

        for (let item of despesas) {
          if (item.situacao === "0") {
            item.situacaoDesc = "Pendente";
            item.situacaoStyle = "pendente";
          }
          if (item.situacao === "1") {
            item.situacaoDesc = "Em análise";
            item.situacaoStyle = "analise";
          }
          if (item.situacao === "2") {
            item.situacaoDesc = "Aprovado";
            item.situacaoStyle = "aprovado";
          }
          if (item.situacao === "3") {
            item.situacaoDesc = "Revisão";
            item.situacaoStyle = "revisao";
          }
          if (item.situacao === "4") {
            item.situacaoDesc = "Reprovado";
            item.situacaoStyle = "reprovado";
          }
          if (item.situacao === "5") {
            item.situacaoDesc = "Cancelado";
            item.situacaoStyle = "cancelado";
          }
        }

        this.setState({ despesas }, () => {
          if (isSalvarFiltro) {
            localStorage.setItem(
              "filtroLancamentos",
              JSON.stringify({ ...filtro, version: "1.0.0" })
            );
          }
        });

        // this.setState(
        //   {
        //     despesas: response.data.usuarios,
        //     totalizadores: {
        //       saldoMensal: totalizadores.saldo,
        //       pagos: totalizadores.pagos,
        //       pendentes: totalizadores.pendentes,
        //     },
        //     totalizadorNovo: response.data.totalizadorNovo,
        //   },
        //   async () => {
        //     await this.iniciaGraficoSaldo(
        //       response.data.totalizadorNovo.categorias
        //     );
        //   }
        // );
      });
  }

  notificar(type, message) {
    toast[type](message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  handleClickDeletar(id) {
    this.setState({ despesaDeletar: id }, () => {
      this.toggle(MODAL_DELETAR);
    });
  }

  toggle(type) {
    let modal = this.state.modal;
    modal[type] = !modal[type];
    this.setState({ modal });
  }

  async handleClickConfirmaDeletar() {
    if (this.state.isDeleting) {
      return false;
    }

    this.setState({ isDeleting: true }, async () => {
      await axios
        .post(
          `${this.props.getBaseUrlApi()}/retaguarda/despesas-deletar`,
          { idDespesa: this.state.despesaDeletar },
          this.props.parameters()
        )
        .then((response) => {
          this.setState({ despesaDeletar: null }, () => {
            this.loadDespesas();
            this.toggle(MODAL_DELETAR);
            this.notificar("success", "Despesa deletada com sucesso!");
            this.setState({ isDeleting: false });
          });
        })
        .catch((error) => {
          this.setState({ despesaDeletar: null }, () => {
            this.toggle(MODAL_DELETAR);
            this.notificar("warning", error.response.data.erro);
            this.setState({ isDeleting: false });
          });
        });
    });
  }

  iniciaGraficoSaldo(categorias) {
    var chart = am4core.create("chartdiv", am4charts.PieChart);
    chart.hiddenState.properties.opacity = 0;

    chart.data = [];

    for (let categoria of categorias) {
      chart.data.push({
        country: categoria.desc,
        litres: categoria.valor,
      });
    }

    var series = chart.series.push(new am4charts.PieSeries());
    series.dataFields.value = "litres";
    series.dataFields.category = "country";
    series.labels.template.disabled = true;

    const legend = new am4charts.Legend();
    legend.parent = chart.chartContainer;
    legend.position = "right";
    chart.legend = legend;

    this.chart = chart;
  }

  iniciaGraficoSaldo2(value1, value2) {
    var chart = am4core.create("chartdiv", am4charts.PieChart);
    chart.hiddenState.properties.opacity = 0;

    chart.data = [
      {
        tipo: "Pagos",
        value: value1,
        color: am4core.color("#2da951"),
      },
      {
        tipo: "Saldo",
        value: value2,
        color: am4core.color("gainsboro"),
      },
    ];
    chart.radius = am4core.percent(60);
    chart.innerRadius = am4core.percent(40);
    chart.startAngle = 180;
    chart.endAngle = 360;
    var series = chart.series.push(new am4charts.PieSeries());
    series.dataFields.value = "value";
    series.dataFields.category = "tipo";
    series.slices.template.cornerRadius = 10;
    series.slices.template.innerCornerRadius = 7;
    series.slices.template.draggable = false;
    series.slices.template.inert = true;
    series.slices.template.propertyFields.fill = "color";
    series.alignLabels = false;
    series.hiddenState.properties.startAngle = 90;
    series.hiddenState.properties.endAngle = 90;
    series.labels.template.disabled = true;
    this.chart = chart;
  }

  async loadUsuarios() {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/retaguarda/usuarios`,
        this.props.parameters()
      )
      .then((response) => {
        const data = response.data;
        let usuarios = data.usuarios;

        for (let item of usuarios) {
          item.label = item.desc;
          item.value = item._id;
        }

        usuarios.unshift({
          _id: "99",
          value: "99",
          label: "Todos",
          desc: "Todos",
        });

        this.setState({ usuarios });
      });
  }
  async loadObras() {
    await axios
      .post(
        `${this.props.getBaseUrlApi()}/retaguarda/lista-obras`,
        {},
        this.props.parameters()
      )
      .then((response) => {
        const obras = response.data.obras;

        for (let item of obras) {
          // item.label = item.desc;
          item.label = `${item.cod.toString().padStart(4, "0")} - ${item.desc}`;
          item.value = item._id;
        }

        obras.unshift({
          _id: "99",
          value: "99",
          label: "Todas",
          desc: "Todas",
        });

        this.setState({ obras });
      });
  }

  async loadTiposDespesa() {
    await axios
      .post(
        `${this.props.getBaseUrlApi()}/retaguarda/lista-tipos-despesa`,
        {},
        this.props.parameters()
      )
      .then((response) => {
        const tiposDespesa = response.data.tiposDespesa;

        for (let item of tiposDespesa) {
          item.label = item.desc;
          item.value = item._id;
        }

        tiposDespesa.unshift({
          _id: "99",
          value: "99",
          label: "Todos",
          desc: "Todos",
        });

        this.setState({ tiposDespesa });
      });
  }

  async gerarRelatorio2() {
    let listaFormatada = [];
    let lista = this.state.despesas;

    for (let item of lista) {
      listaFormatada.push({
        ID_MOTORISTA: item.idUsuario,
        MOTORISTA: item.descUsuario,
        "VALOR_ADIANTAMENTO(R$)": item.adiantamento
          .toFixed(2)
          .replace(".", ","),
        "VALOR_DESPESAS(R$)": item.despesas.toFixed(2).replace(".", ","),
        "SALDO(R$)": item.saldo.toFixed(2).replace(".", ","),
      });
    }

    let dataInicial = this.state.dataInicial;
    let dataFinal = this.state.dataFinal;

    // let nomeArquivo = `relatorio-fornecedores.xlsx`;
    let nomeArquivo = `despesas-motorista-${moment(dataInicial)
      .startOf("day")
      .format("YYYY-MM-DD")}-${moment(dataFinal)
      .startOf("day")
      .format("YYYY-MM-DD")}.xlsx`;

    let params = {};
    params.responseType = "blob";

    await axios
      .post(
        `${this.props.getBaseUrlApi()}/retaguarda/relatorios`,
        { lista: listaFormatada },
        params
      )
      .then(async (response) => {
        FileDownload(response.data, nomeArquivo);
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          // this.notificar("warning", error.response.data.erro);
        }
      });
    // import FileDownload from "js-file-download";
  }

  onChangeInputFiltro(type, value) {
    let filtro = this.state.filtro;
    filtro[type] = value;
    this.setState({ filtro });
  }

  limparFiltros() {
    let filtro = {
      dtInicial: moment().startOf("month").toDate(),
      dtFinal: moment().endOf("month").toDate(),
      situacao: "99",
      usuario: {
        _id: "99",
        value: "99",
        label: "Todos",
        desc: "Todos",
      },
      obra: {
        _id: "99",
        value: "99",
        label: "Todas",
        desc: "Todas",
      },
      tipoDespesa: {
        _id: "99",
        value: "99",
        label: "Todos",
        desc: "Todos",
      },
    };

    this.setState({ filtro }, () => {
      this.loadDespesas(true);
    });
  }

  onChangeInput(type, value) {
    this.setState({ [type]: value });
  }

  async handleConfirmMarcarRevisao() {
    let obsRevisao = this.state.obsRevisao;
    let idDespesaRevisao = this.state.idDespesaRevisao;
    if (obsRevisao === "") {
      this.notificar("warning", "Favor informar a OBS");
      return false;
    }

    await axios
      .post(
        `${this.props.getBaseUrlApi()}/retaguarda/extrato-revisar-despesa`,
        { obsRevisao, idDespesa: idDespesaRevisao },
        this.props.parameters()
      )
      .then(async (response) => {
        this.notificar("success", "Despesa marcada para revisão!");
        this.toggle("revisarDespesa");

        this.loadDespesas();
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          // this.notificar("warning", error.response.data.erro);
        }
      });
  }
  async handleConfirmAprovarDespesa() {
    let idDespesaAprovar = this.state.idDespesaAprovar;

    await axios
      .post(
        `${this.props.getBaseUrlApi()}/retaguarda/extrato-aprovar-despesa`,
        { idDespesa: idDespesaAprovar },
        this.props.parameters()
      )
      .then(async (response) => {
        this.notificar("success", "Despesa aprovada com sucesso!");
        this.toggle("aprovarDespesa");

        this.loadDespesas();
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          // this.notificar("warning", error.response.data.erro);
        }
      });
  }
  async handleConfirmReprovarDespesa() {
    let idDespesaReprovar = this.state.idDespesaReprovar;
    let obsReprovacao = this.state.obsReprovacao;

    if (obsReprovacao === "") {
      this.notificar("warning", "Favor informar a OBS");
      return false;
    }

    await axios
      .post(
        `${this.props.getBaseUrlApi()}/retaguarda/extrato-reprovar-despesa`,
        { idDespesa: idDespesaReprovar, obsReprovacao },
        this.props.parameters()
      )
      .then(async (response) => {
        this.notificar("success", "Despesa reprovada com sucesso!");
        this.toggle("reprovarDespesa");

        this.loadDespesas();
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          this.notificar("warning", error.response.data.erro);
        }
      });
  }

  abrirPaginaEditarDespesa(despesa) {
    return this.props.history.push(`/despesas-nova?id=${despesa._id}`);
  }

  abrirDespesasUsuario(despesa) {
    return this.props.history.push(
      `/despesas-usuario?id=${despesa.usuario._id}`
    );
  }

  async gerarRelatorioExcel() {
    let itensFormatados = [];
    let itens = this.state.despesas;

    for (let item of itens) {
      let desc = "";
      let tipoDespesa = "";
      let obra = "";

      if (item.tipo === "C") {
        desc = item.tipoDespesa.desc;
      } else {
        desc = item.desc;
        tipoDespesa = item.tipoDespesa.desc;
      }

      if (item.obra !== null) {
        obra = `${item.obra.cod} - ${item.obra.desc}`;
      }

      itensFormatados.push({
        DATA: moment(item.dtDespesa).format("DD/MM/YYYY"),
        ["USUÁRIO"]: item.usuario.desc,
        ["DESC"]: desc,
        ["VALOR (R$)"]: item.valor,
        ["TIPO DESPESA"]: tipoDespesa,
        ["OBRA"]: obra,
        ["SITUAÇÃO"]: item.situacaoDesc,
      });
    }

    let dataInicial = this.state.filtro.dtInicial;
    let dataFinal = this.state.filtro.dtFinal;

    let nomeArquivo = `lancamentos-${moment(dataInicial)
      .startOf("day")
      .format("YYYY-MM-DD")}-${moment(dataFinal)
      .startOf("day")
      .format("YYYY-MM-DD")}.xlsx`;

    let params = {};
    params.responseType = "blob";

    await axios
      .post(
        `${this.props.getBaseUrlApi()}/retaguarda/relatorios`,
        { lista: itensFormatados },
        params
      )
      .then(async (response) => {
        this.toggle(MODAL_IMPRESSAO);
        FileDownload(response.data, nomeArquivo);
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          this.notificar("warning", error.response.data.erro);
        }
      });
  }

  async gerarRelatorio() {
    let listaFormatada = [];
    let lista = this.state.despesas;

    for (let item of lista) {
      listaFormatada.push({
        data: moment(item.dtDespesa).format("DD/MM/YYYY"),
        usuario: item.usuario.desc,
        desc: item.tipo === "D" ? item.desc : item.tipoDespesa.desc,
        valor: `R$ ${formatarReal(item.valor)}`,
        situacao: item.situacaoDesc,
        tipoDespesa: item.tipo === "D" ? item.tipoDespesa.desc : "",
      });
    }

    let dataInicial = moment(this.state.filtro.dtInicial).format("DD/MM/YYYY");
    let dataFinal = moment(this.state.filtro.dtFinal).format("DD/MM/YYYY");
    let usuario = this.state.filtro.usuario;
    let obra = this.state.filtro.obra;
    let situacao = this.state.filtro.situacao;

    if (usuario._id === "99") {
      usuario = "Todos";
    } else {
      usuario = usuario.desc;
    }
    if (obra._id === "99") {
      obra = "Todas";
    } else {
      obra = obra.desc;
    }
    if (situacao === "99") {
      situacao = "Todas";
    } else {
      if (situacao === "0") {
        situacao = "Pedente";
      } else if (situacao === "2") {
        situacao = "Aprovado";
      } else if (situacao === "3") {
        situacao = "Revisão";
      } else if (situacao === "4") {
        situacao = "Reprovado";
      } else if (situacao === "5") {
        situacao = "Cancelado";
      }
    }

    let parametros = {
      lancamentos: listaFormatada,
      dataInicial,
      dataFinal,
      usuario,
      obra,
      situacao,
    };

    let nomeArquivo = `relatorio-lancamentos`;

    await axios
      .post(`${this.props.getBaseUrlImpressao()}/lancamentos-pdf`, {
        parametros,
      })
      .then(async (response) => {
        // FileDownload(response.data, nomeArquivo);
        localStorage.setItem("html", response.data.html);
        localStorage.setItem("tituloPagina", nomeArquivo);
        this.toggle(MODAL_IMPRESSAO);
        window.open("/impressao", "_blank");
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          // this.notificar("warning", error.response.data.erro);
        }
      });
  }

  async loadFiltro() {
    let filtroStorage = localStorage.getItem("filtroLancamentos");
    if (filtroStorage !== null) {
      filtroStorage = JSON.parse(filtroStorage);
      if (filtroStorage.version !== "1.0.0") {
        localStorage.removeItem("filtroLancamentos");
        await this.loadDespesas();
      } else {
        this.setState(
          {
            filtro: {
              dtInicial: moment(filtroStorage.dtInicial).toDate(),
              dtFinal: moment(filtroStorage.dtFinal).toDate(),
              usuario: filtroStorage.usuario,
              obra: filtroStorage.obra,
              tipoDespesa: filtroStorage.tipoDespesa,
              situacao: filtroStorage.situacao,
            },
          },
          async () => {
            await this.loadDespesas();
          }
        );
      }
    } else {
      await this.loadDespesas();
    }
  }

  async buscarImagem(caminhoDaImagem) {
    await axios
      .post(
        `${this.props.getBaseUrlApi()}/retaguarda/buscar-comprovante`,
        { caminhoDaImagem },
        this.props.parameters()
      )
      .then((response) => {
        const base64 = response.data.base64;
        if (base64 !== null) {
          this.abrirImagemBase64EmNovaAba(base64);
        }
      });
  }

  abrirImagemBase64EmNovaAba(base64Image) {
    var htmlContent =
      '<!DOCTYPE html><html><head><title>Imagem</title></head><body style="margin: 0; display: flex; justify-content: center; align-items: center;"><img src="' +
      base64Image +
      '" style="max-width: 500px; max-height: 100%;"></body></html>';

    // Cria uma URL para o documento HTML
    var blob = new Blob([htmlContent], { type: "text/html" });
    var url = URL.createObjectURL(blob);

    // Abre a URL em uma nova aba
    window.open(url, "_blank");

    // Libera a URL após a abertura da aba
    URL.revokeObjectURL(url);
  }

  render() {
    return (
      <React.Fragment>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />

        <div className="container-scroller font-app">
          <NavBar title="Lançamentos" />
          <div className="container-fluid page-body-wrapper">
            <SideBar itemActive={"despesas"} />
            <div className="main-panel">
              <div className="content-wrapper">
                {/* <TitleAndDescription title="Despesas" description="" /> */}

                <div
                  className="row"
                  style={{ marginBottom: "0.8rem", alignItems: "center" }}
                >
                  <div className="col-md-12 stretch-card">
                    {true && (
                      <div className="card">
                        <div
                          className="card-body5"
                          style={{ position: "relative" }}
                        >
                          <React.Fragment>
                            {/* <div
                              style={{
                                position: "absolute",
                                top: "11px",
                                right: "11px",
                              }}
                            >
                              <button
                                className="btn btn-success btn-app-verde-sm"
                                onClick={() => {
                                  this.props.history.push("/fornecedor-novo");
                                }}
                              >
                               CADASTRAR
                              </button>
                            </div> */}
                            <div className="row">
                              {/* <div className="col-md-2 pd-veiculo2">
                                <div className="form-group form-group-chegoo2">
                                  <label>Status</label>
                                  <select className="form-control form-control-chegoo font-size-14">
                                    <option>Ativo</option>
                                    <option>Inativo</option>
                                  </select>
                                </div>
                              </div> */}

                              <div className="col-md-2 pd-veiculo2">
                                <div className="form-group form-group-chegoo2">
                                  <label>Data Inicial</label>
                                  <DatePicker
                                    selected={this.state.filtro.dtInicial}
                                    customInput={
                                      <input
                                        className="form-control font-size-14 form-control-chegoo text-center"
                                        style={{ width: "100%" }}
                                      />
                                    }
                                    dateFormat="dd/MM/yyyy"
                                    onChange={(date) => {
                                      this.onChangeInputFiltro(
                                        "dtInicial",
                                        date
                                      );
                                    }}
                                    locale="pt-BR"
                                  />
                                </div>
                              </div>
                              <div className="col-md-2 pd-veiculo">
                                <div className="form-group form-group-chegoo2">
                                  <label>Data Final</label>
                                  <DatePicker
                                    selected={this.state.filtro.dtFinal}
                                    customInput={
                                      <input className="form-control font-size-14 form-control-chegoo text-center" />
                                    }
                                    dateFormat="dd/MM/yyyy"
                                    onChange={(date) => {
                                      this.onChangeInputFiltro("dtFinal", date);
                                    }}
                                    locale="pt-BR"
                                  />
                                </div>
                              </div>

                              <div className="col-md-2 col-6 pd-veiculo">
                                <div className="form-group-chegoo form-group-chegoo2 font-size-14">
                                  <label>Usuário</label>
                                  <Select
                                    value={this.state.filtro.usuario}
                                    onChange={(value) =>
                                      this.onChangeInputFiltro("usuario", value)
                                    }
                                    options={this.state.usuarios}
                                    placeholder="Selecione"
                                    styles={{
                                      control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        fontSize: "14px",
                                        height: "35px",
                                        minHeight: "35px",
                                      }),
                                      menu: (baseStyles, state) => ({
                                        ...baseStyles,
                                        fontSize: "14px",
                                      }),
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-2 col-6 pd-veiculo">
                                <div className="form-group-chegoo form-group-chegoo2 font-size-14">
                                  <label>Obra</label>
                                  <Select
                                    value={this.state.filtro.obra}
                                    onChange={(value) =>
                                      this.onChangeInputFiltro("obra", value)
                                    }
                                    options={this.state.obras}
                                    placeholder="Selecione"
                                    styles={{
                                      control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        fontSize: "14px",
                                        height: "35px",
                                        minHeight: "35px",
                                      }),
                                      menu: (baseStyles, state) => ({
                                        ...baseStyles,
                                        fontSize: "14px",
                                      }),
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-2 col-6 pd-veiculo">
                                <div className="form-group-chegoo form-group-chegoo2 font-size-14">
                                  <label>Tipo de Despesa</label>
                                  <Select
                                    value={this.state.filtro.tipoDespesa}
                                    onChange={(value) =>
                                      this.onChangeInputFiltro(
                                        "tipoDespesa",
                                        value
                                      )
                                    }
                                    options={this.state.tiposDespesa}
                                    placeholder="Selecione"
                                    styles={{
                                      control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        fontSize: "14px",
                                        height: "35px",
                                        minHeight: "35px",
                                      }),
                                      menu: (baseStyles, state) => ({
                                        ...baseStyles,
                                        fontSize: "14px",
                                      }),
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-2 col-6 pd-veiculo3">
                                <div className="form-group-chegoo form-group-chegoo2 font-size-14">
                                  <label>Situação</label>
                                  <select
                                    className="form-control form-control-chegoo font-size-14"
                                    onChange={(e) =>
                                      this.onChangeInputFiltro(
                                        "situacao",
                                        e.target.value
                                      )
                                    }
                                    value={this.state.filtro.situacao}
                                  >
                                    <option value="99">Todos</option>
                                    <option value="0">Pendente</option>
                                    <option value="2">Aprovado</option>
                                    <option value="3">Revisão</option>
                                    <option value="4">Reprovado</option>
                                    <option value="5">Cancelado</option>
                                  </select>
                                </div>
                              </div>

                              <div
                                className="col-md-2 pd-veiculo"
                                style={{ display: "flex", marginTop: "3px" }}
                              >
                                <div
                                  className="form-group-chegoo2"
                                  style={{ float: "left" }}
                                >
                                  <label></label>
                                  <button
                                    className="btn btn-secondary btn-sm"
                                    style={{
                                      padding: "0.275rem 0.55rem",
                                      margin: "7px 5px 0px",
                                    }}
                                    onClick={() => this.loadDespesas(true)}
                                    id={`tooltip-filtro-atendimentos`}
                                  >
                                    <i
                                      className="fa fa-search"
                                      aria-hidden="true"
                                      style={{ fontSize: "px" }}
                                    ></i>
                                  </button>
                                  <UncontrolledTooltip
                                    placement="bottom"
                                    target={`tooltip-filtro-atendimentos`}
                                  >
                                    Filtrar
                                  </UncontrolledTooltip>
                                </div>
                                {/* <div className="form-group-chegoo2">
                                  <label></label>
                                  <button
                                    className="btn btn-warning btn-sm"
                                    style={{
                                      padding: "0.275rem 0.55rem",
                                      margin: "7px 5px 0px",
                                      color: "#FFF",
                                    }}
                                    onClick={() => this.limparFiltros()}
                                    id={`tooltip-limpar-filtro-atendimentos`}
                                  >
                                    <i
                                      className="fa fa-eraser"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                  <UncontrolledTooltip
                                    placement="bottom"
                                    target={`tooltip-limpar-filtro-atendimentos`}
                                  >
                                    Limpar filtro
                                  </UncontrolledTooltip>
                                </div> */}

                                <div className="form-group-chegoo2">
                                  <label></label>
                                  <button
                                    className="btn btn-secondary btn-sm"
                                    style={{
                                      padding: "0.275rem 0.55rem",
                                      margin: "7px 5px 0px",
                                      color: "#FFF",
                                    }}
                                    onClick={() => this.toggle(MODAL_IMPRESSAO)}
                                    id={`tooltip-limpar-filtro-atendimentos3`}
                                  >
                                    <i
                                      className="fa fa-print"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                  <UncontrolledTooltip
                                    placement="bottom"
                                    target={`tooltip-limpar-filtro-atendimentos3`}
                                  >
                                    Imprimir
                                  </UncontrolledTooltip>
                                </div>
                                <div className="form-group-chegoo2">
                                  <label></label>
                                  <button
                                    className="btn btn-success btn-sm"
                                    style={{
                                      padding: "0.275rem 0.55rem",
                                      margin: "7px 5px 0px",
                                      color: "#FFF",
                                    }}
                                    onClick={() => {
                                      this.props.history.push("/despesas-nova");
                                    }}
                                    id={`tooltip-limpar-filtro-atendimentos`}
                                  >
                                    <i
                                      className="fa fa-dollar"
                                      aria-hidden="true"
                                      id="btn-cadastrar-lancamento"
                                    ></i>
                                  </button>
                                  <UncontrolledTooltip
                                    placement="bottom"
                                    target={`btn-cadastrar-lancamento`}
                                  >
                                    Cadastrar
                                  </UncontrolledTooltip>
                                </div>

                                <div className="form-group-chegoo2">
                                  <label></label>
                                  <button
                                    className="btn btn-warning btn-sm"
                                    style={{
                                      padding: "0.275rem 0.55rem",
                                      margin: "7px 5px 0px",
                                      color: "#FFF",
                                    }}
                                    onClick={() => this.limparFiltros()}
                                    id={`tooltip-limpar-filtro-lancamentos`}
                                  >
                                    <i
                                      className="fa fa-eraser"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                  <UncontrolledTooltip
                                    placement="bottom"
                                    target={`tooltip-limpar-filtro-lancamentos`}
                                  >
                                    Limpar filtro
                                  </UncontrolledTooltip>
                                </div>

                                {/* <div className="form-group-chegoo2">
                                  <label></label>
                                  <button
                                    className="btn btn-danger btn-sm"
                                    style={{
                                      padding: "0.275rem 0.55rem",
                                      margin: "7px 5px 0px",
                                    }}
                                    onClick={() => this.gerarRelatorio()}
                                    id={`tooltip-limpar-filtro-atendimentos55`}
                                  >
                                    <i
                                      className="fa fa-file-pdf-o"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                  <UncontrolledTooltip
                                    placement="bottom"
                                    target={`tooltip-limpar-filtro-atendimentos55`}
                                  >
                                    Gerar relatório
                                  </UncontrolledTooltip>
                                </div> */}
                                {/* 
                                <div className="form-group-chegoo2">
                                  <label></label>
                                  <button
                                    className="btn btn-success btn-sm"
                                    style={{
                                      padding: "0.275rem 0.55rem",
                                      margin: "10px 5px 0px",
                                    }}
                                    onClick={() => this.gerarRelatorio()}
                                    id={`tooltip-limpar-filtro-atendimentos`}
                                  >
                                    <i
                                      className="fa fa-file-excel-o"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                  <UncontrolledTooltip
                                    placement="bottom"
                                    target={`tooltip-limpar-filtro-atendimentos`}
                                  >
                                    Limpar filtro
                                  </UncontrolledTooltip>
                                </div> */}
                              </div>
                            </div>

                            {/* <div className="row">
                              <div
                                className="col-md-2 pd-veiculo"
                                style={{ display: "flex", marginTop: "15px" }}
                              >
                                <div
                                  className="form-group-chegoo2"
                                  style={{
                                    marginTop: "7px",
                                    marginLeft: "5px",
                                  }}
                                >
                                  <button
                                    className="btn btn-success btn-app-verde-sm"
                                    onClick={() => {
                                      this.props.history.push("/despesas-nova");
                                    }}
                                  >
                                    CADASTRAR
                                  </button>
                                </div>
                              
                              </div>

                             
                            </div> */}
                          </React.Fragment>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12 stretch-card">
                    {this.state.despesas.length > -1 && (
                      <div className="card">
                        <div className="card-body2">
                          <React.Fragment>
                            {/* <div
                              className="float-left width-100"
                              style={{
                                borderBottom: "1px solid #CCC",
                                padding: "5px",
                                marginBottom: "20px",
                                position: "relative",
                              }}
                            >
                              RESUMO DE DESPESAS
                              <div>
                                <button
                                  className="btn btn-success btn-app-verde"
                                  onClick={() => {
                                    this.props.history.push("/despesas-nova");
                                  }}
                                  style={{
                                    position: "absolute",
                                    right: 0,
                                    top: "-15px",
                                  }}
                                >
                                  Nova despesa
                                </button>
                              </div>
                            </div> */}
                            <div className="table-responsive">
                              <table
                                id="recent-purchases-listing"
                                className="table"
                              >
                                <thead className="thead-dark">
                                  <tr>
                                    {/* <th className="text-right">id</th> */}
                                    <th className="text-right">Data</th>
                                    <th className="">Usuário</th>
                                    <th className="">Descrição</th>
                                    <th className="text-right">Valor</th>
                                    <th className="text-center">Situação</th>
                                    <th className="text-center">Ações</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.despesas.map((despesa, key) => (
                                    <tr key={key}>
                                      {/* <td>{despesa._id}</td> */}
                                      <td className="text-right">
                                        <span
                                          onClick={() =>
                                            this.abrirPaginaEditarDespesa(
                                              despesa
                                            )
                                          }
                                          style={{ cursor: "pointer" }}
                                        >
                                          {" "}
                                          {moment(despesa.dtDespesa).format(
                                            "DD/MM"
                                          )}
                                        </span>
                                      </td>
                                      <td>
                                        <span
                                          onClick={() =>
                                            this.abrirDespesasUsuario(despesa)
                                          }
                                          style={{
                                            cursor: "pointer",
                                            textDecoration: "underline",
                                          }}
                                          className={`${
                                            despesa.situacao ===
                                              SITUACAO_REPROVADO ||
                                            despesa.situacao ===
                                              SITUACAO_CANCELADO
                                              ? "linha-cancelado"
                                              : ""
                                          }`}
                                        >
                                          {despesa.usuario.desc}
                                        </span>
                                      </td>
                                      <td>
                                        <span
                                          onClick={() =>
                                            this.abrirPaginaEditarDespesa(
                                              despesa
                                            )
                                          }
                                          style={{ cursor: "pointer" }}
                                          className={`${
                                            despesa.situacao ===
                                              SITUACAO_REPROVADO ||
                                            despesa.situacao ===
                                              SITUACAO_CANCELADO
                                              ? "linha-cancelado"
                                              : ""
                                          }`}
                                        >
                                          {despesa.tipo === "D"
                                            ? despesa.desc
                                            : despesa.tipoDespesa.desc}
                                        </span>
                                      </td>
                                      <td
                                        className="text-right"
                                        style={{
                                          color:
                                            despesa.tipo === "D"
                                              ? "#f2542d "
                                              : "#2da951",
                                          fontWeight: "bold",
                                        }}
                                      >
                                        <span
                                          onClick={() =>
                                            this.abrirPaginaEditarDespesa(
                                              despesa
                                            )
                                          }
                                          style={{ cursor: "pointer" }}
                                          className={`${
                                            despesa.situacao ===
                                              SITUACAO_REPROVADO ||
                                            despesa.situacao ===
                                              SITUACAO_CANCELADO
                                              ? "linha-cancelado"
                                              : ""
                                          }`}
                                        >
                                          {" "}
                                          R$ {formatarReal(despesa.valor)}
                                        </span>
                                      </td>
                                      <td className="text-center">
                                        <div
                                          className="d-flex"
                                          style={{
                                            alignItems: "center",
                                            justifyContent: "center",
                                          }}
                                        >
                                          <span
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                              this.abrirPaginaEditarDespesa(
                                                despesa
                                              )
                                            }
                                            className={`tag-despesa ${despesa.situacaoStyle}`}
                                          >
                                            {despesa.situacaoDesc}
                                          </span>
                                        </div>
                                      </td>

                                      <td
                                        className="d-flex"
                                        style={{
                                          fontSize: "18px",
                                          justifyContent: "center",
                                        }}
                                      >
                                        <div
                                          style={{
                                            margin: "2px",
                                            cursor: "pointer",
                                          }}
                                        >
                                          <i
                                            className="fa fa-search"
                                            onClick={() => {
                                              this.props.history.push(
                                                `/despesas-nova?id=${despesa._id}`
                                              );
                                            }}
                                            id={`btn-visualizar-${despesa._id}`}
                                          ></i>

                                          <UncontrolledTooltip
                                            placement="bottom"
                                            target={`btn-visualizar-${despesa._id}`}
                                          >
                                            Visualizar
                                          </UncontrolledTooltip>
                                        </div>
                                        <div
                                          style={{
                                            margin: "2px",
                                            cursor: "pointer",
                                            color:
                                              despesa.tipo === "D" &&
                                              (despesa.situacao ===
                                                SITUACAO_PENDENTE ||
                                                despesa.situacao ===
                                                  SITUACAO_REVISAO)
                                                ? "orange"
                                                : "grey",
                                          }}
                                        >
                                          <i
                                            className="fa fa-warning"
                                            id={`btn-revisar-${despesa._id}`}
                                            onClick={() => {
                                              if (
                                                despesa.situacao ===
                                                SITUACAO_PENDENTE
                                              ) {
                                                this.setState(
                                                  {
                                                    idDespesaRevisao:
                                                      despesa._id,
                                                    somenteVisualizarObsRevisao: false,
                                                  },
                                                  () => {
                                                    this.toggle(
                                                      "revisarDespesa"
                                                    );
                                                  }
                                                );
                                              } else {
                                                if (
                                                  despesa.situacao ===
                                                  SITUACAO_REVISAO
                                                ) {
                                                  this.setState(
                                                    {
                                                      obsRevisao:
                                                        despesa.obsRevisao,
                                                      somenteVisualizarObsRevisao: true,
                                                    },
                                                    () => {
                                                      this.toggle(
                                                        "revisarDespesa"
                                                      );
                                                    }
                                                  );
                                                }
                                              }
                                            }}
                                          ></i>

                                          <UncontrolledTooltip
                                            placement="bottom"
                                            target={`btn-revisar-${despesa._id}`}
                                          >
                                            Solicitar Revisão
                                          </UncontrolledTooltip>
                                        </div>
                                        <div
                                          style={{
                                            margin: "2px",
                                            cursor: "pointer",
                                            color:
                                              despesa.tipo === "D" &&
                                              despesa.situacao ===
                                                SITUACAO_PENDENTE
                                                ? "green"
                                                : "grey",
                                          }}
                                        >
                                          <i
                                            className="fa fa-check"
                                            onClick={() => {
                                              if (
                                                despesa.situacao ===
                                                SITUACAO_PENDENTE
                                              ) {
                                                this.setState(
                                                  {
                                                    idDespesaAprovar:
                                                      despesa._id,
                                                  },
                                                  () => {
                                                    this.toggle(
                                                      "aprovarDespesa"
                                                    );
                                                  }
                                                );
                                              }
                                            }}
                                            id={`btn-aprovar-${despesa._id}`}
                                          ></i>

                                          <UncontrolledTooltip
                                            placement="bottom"
                                            target={`btn-aprovar-${despesa._id}`}
                                          >
                                            Aprovar
                                          </UncontrolledTooltip>
                                        </div>
                                        <div
                                          style={{
                                            margin: "2px",
                                            cursor: "pointer",
                                            color:
                                              despesa.tipo === "D" &&
                                              despesa.situacao ===
                                                SITUACAO_PENDENTE
                                                ? "red"
                                                : "grey",
                                          }}
                                        >
                                          <i
                                            className="fa fa-times"
                                            onClick={() => {
                                              if (
                                                despesa.situacao ===
                                                SITUACAO_PENDENTE
                                              ) {
                                                this.setState(
                                                  {
                                                    idDespesaReprovar:
                                                      despesa._id,
                                                  },
                                                  () => {
                                                    this.toggle(
                                                      "reprovarDespesa"
                                                    );
                                                  }
                                                );
                                              }
                                            }}
                                            id={`btn-reprovar-${despesa._id}`}
                                          ></i>

                                          <UncontrolledTooltip
                                            placement="bottom"
                                            target={`btn-reprovar-${despesa._id}`}
                                          >
                                            Reprovar
                                          </UncontrolledTooltip>
                                        </div>
                                        <div
                                          style={{
                                            margin: "2px",
                                            cursor: "pointer",
                                            color:
                                              despesa.identificador !==
                                                undefined &&
                                              despesa.extensaoImagem !==
                                                undefined
                                                ? "#1342ad"
                                                : "grey",
                                          }}
                                        >
                                          <i
                                            className="fa fa-photo"
                                            onClick={() => {
                                              if (
                                                despesa.identificador !==
                                                  undefined &&
                                                despesa.extensaoImagem !==
                                                  undefined
                                              ) {
                                                this.buscarImagem(
                                                  `${despesa.identificador}-orig${despesa.extensaoImagem}`
                                                );
                                              }
                                            }}
                                            id={`btn-comprovante-${despesa._id}`}
                                          ></i>

                                          <UncontrolledTooltip
                                            placement="bottom"
                                            target={`btn-comprovante-${despesa._id}`}
                                          >
                                            Visualizar Comprovante
                                          </UncontrolledTooltip>
                                        </div>
                                        {this.state.niveUsuarioLogado ===
                                          "0" && (
                                          <div
                                            style={{
                                              margin: "2px",
                                              cursor: "pointer",
                                              color: "red",
                                            }}
                                          >
                                            <i
                                              className="fa fa-trash"
                                              onClick={() => {
                                                this.handleClickDeletar(
                                                  despesa._id
                                                );
                                              }}
                                              id={`btn-deletar-${despesa._id}`}
                                            ></i>

                                            <UncontrolledTooltip
                                              placement="bottom"
                                              target={`btn-deletar-${despesa._id}`}
                                            >
                                              Deletar
                                            </UncontrolledTooltip>
                                          </div>
                                        )}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>

                            {/* <div style={{padding: "5px 5px 15px 5px"}}>
                            <button
                                  className="btn btn-success btn-app-verde"
                                  onClick={() => {
                                    this.props.history.push("/despesas-nova");
                                  }}
                                  style={{
                                    // position: "absolute",
                                    // right: 0,
                                    // top: "-15px",
                                  }}
                                >
                                  Nova despesa
                                </button>
                            </div> */}
                            {/* 
                            <div className="paginacao">
                              <Pagination
                                prevPageText={
                                  <i className="fa fa-angle-left" />
                                }
                                nextPageText={
                                  <i className="fa fa-angle-right" />
                                }
                                firstPageText={
                                  <i className="fa fa-angle-double-left" />
                                }
                                lastPageText={
                                  <i className="fa fa-angle-double-right" />
                                }
                                activePage={this.state.paginacao.pagina}
                                itemsCountPerPage={this.state.paginacao.limite}
                                totalItemsCount={this.state.totalcomissoes}
                                onChange={(pagina) => this.paginar(pagina)}
                                itemClass="page-item"
                                linkClass="page-link"
                              />
                            </div> */}
                          </React.Fragment>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>

        <Modal
          isOpen={this.state.modal.revisarDespesa}
          toggle={() => this.toggle("revisarDespesa")}
        >
          <ModalHeader toggle={() => this.toggle("revisarDespesa")}>
            Solicitar Revisão
          </ModalHeader>
          <ModalBody>
            Tem certeza que deseja solicitar revisão desta despesa ?
            <div
              className="form-group-chegoo form-group-chegoo2 font-size-14"
              style={{ marginTop: "10px" }}
            >
              <label>Obs Revisão</label>

              <textarea
                type="text"
                className="form-control"
                value={this.state.obsRevisao}
                onChange={(e) =>
                  this.onChangeInput("obsRevisao", e.target.value)
                }
                disabled={this.state.somenteVisualizarObsRevisao}
                rows={4}
              ></textarea>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              className="btn-modal-recuperar"
              color="secondary"
              onClick={() => this.toggle("revisarDespesa")}
            >
              Cancelar
            </Button>
            <Button
              className="btn-modal-recuperar"
              color="primary"
              onClick={this.handleConfirmMarcarRevisao}
              disabled={this.state.somenteVisualizarObsRevisao}
            >
              Sim
            </Button>{" "}
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.modal.aprovarDespesa}
          toggle={() => this.toggle("aprovarDespesa")}
        >
          <ModalHeader toggle={() => this.toggle("aprovarDespesa")}>
            Aprovar Despesa
          </ModalHeader>
          <ModalBody>Tem certeza que deseja aprovar esta despesa?</ModalBody>
          <ModalFooter>
            <Button
              className="btn-modal-recuperar"
              color="secondary"
              onClick={() => this.toggle("aprovarDespesa")}
            >
              Cancelar
            </Button>
            <Button
              className="btn-modal-recuperar"
              color="primary"
              onClick={this.handleConfirmAprovarDespesa}
            >
              Sim
            </Button>{" "}
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.modal.reprovarDespesa}
          toggle={() => this.toggle("reprovarDespesa")}
        >
          <ModalHeader toggle={() => this.toggle("reprovarDespesa")}>
            Reprovar Despesa
          </ModalHeader>
          <ModalBody>
            Tem certeza que deseja reprovar esta despesa ?
            <div
              className="form-group-chegoo form-group-chegoo2 font-size-14"
              style={{ marginTop: "10px" }}
            >
              <label>Obs Reprovação</label>

              <input
                type="text"
                className="form-control"
                value={this.state.obsReprovacao}
                onChange={(e) =>
                  this.onChangeInput("obsReprovacao", e.target.value)
                }
                // disabled={this.state.somenteVisualizarObsRevisao}
              ></input>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              className="btn-modal-recuperar"
              color="secondary"
              onClick={() => this.toggle("reprovarDespesa")}
            >
              Cancelar
            </Button>
            <Button
              className="btn-modal-recuperar"
              color="primary"
              onClick={this.handleConfirmReprovarDespesa}
              // disabled={this.state.somenteVisualizarObsRevisao}
            >
              Sim
            </Button>{" "}
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.modal[MODAL_IMPRESSAO]}
          toggle={() => this.toggle(MODAL_IMPRESSAO)}
        >
          <ModalHeader toggle={() => this.toggle(MODAL_IMPRESSAO)}>
            Impressão
          </ModalHeader>
          <ModalBody>Escolha abaixo as opções de impressão</ModalBody>
          <ModalFooter>
            <Button
              className="btn-modal-recuperar2"
              color="success"
              onClick={() => this.gerarRelatorioExcel()}
            >
              <i className="fa fa-file-excel-o"></i>&nbsp;&nbsp;EXCEL
            </Button>
            <Button
              className="btn-modal-recuperar2"
              color="danger"
              onClick={this.gerarRelatorio}
            >
              <i className="fa fa-file-pdf-o"></i>&nbsp;&nbsp;PDF
            </Button>{" "}
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.modal[MODAL_DELETAR]}
          toggle={() => this.toggle(MODAL_DELETAR)}
        >
          <ModalHeader toggle={() => this.toggle(MODAL_DELETAR)}>
            Deletar Lançamento?
          </ModalHeader>
          <ModalBody>
            Tem certeza que deseja deletar este lançamento? essa ação é
            irreversível.
          </ModalBody>
          <ModalFooter>
            <Button
              className="btn-modal-recuperar"
              color="secondary"
              onClick={() => this.toggle(MODAL_DELETAR)}
            >
              Cancelar
            </Button>
            <Button
              className="btn-modal-recuperar2"
              color="primary"
              onClick={this.handleClickConfirmaDeletar}
            >
              {this.state.isDeleting ? "Deletando..." : "Sim"}
            </Button>{" "}
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default Despesa;
