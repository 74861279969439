import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import { SideBar, NavBar, Footer } from "../../components";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import Select from "react-select";
import queryString from "query-string";

export default class PedidoMaterialNovo extends Component {
  constructor(props) {
    super(props);
    this.notificar = this.notificar.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
    this.cadastrarPedidoMaterial = this.cadastrarPedidoMaterial.bind(this);
    this.validarPedidoMaterial = this.validarPedidoMaterial.bind(this);
    this.buscarPedidoMaterial = this.buscarPedidoMaterial.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.loadUsuarios = this.loadUsuarios.bind(this);
    this.loadObras = this.loadObras.bind(this);
    this.loadUnidadesMedida = this.loadUnidadesMedida.bind(this);

    this.state = {
      _id: null,
      desc: "",
      quantidade: "0",
      um: null,
      obra: null,
      usuario: null,
      usuarios: [],
      obras: [],
      errors: {
        desc: false,
        quantidade: false,
        obra: false,
        usuario: false,
        obs: false,
      },
      situacao: "0", //SOMENTE NA EDICAO
      obs: "",
      situacaoAtual: "0",
      unidadesMedida: [],
      isSaving: false,
    };
  }

  async componentDidMount() {
    const userLogado = JSON.parse(localStorage.getItem("usuario"));
    if (userLogado.nivel === "4") {
      return this.props.history.push("/nao-conformidades");
    }
    if (userLogado.nivel === "5") {
      return this.props.history.push("/despesas");
    }
    let id = queryString.parse(this.props.location.search).id;

    if (id !== undefined) {
      await this.buscarPedidoMaterial(id);
    }

    await this.loadUsuarios();
    await this.loadObras();
    await this.loadUnidadesMedida();
  }

  notificar(type, message) {
    toast[type](message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  onChangeInput(tipo, value) {
    this.setState({ [tipo]: value }, () => {});
  }

  validarPedidoMaterial() {
    let errors = this.state.errors;
    let valido = true;

    if (this.state.desc === "") {
      errors.desc = true;
      valido = false;
    } else {
      errors.desc = false;
    }
    if (this.state.quantidade === "" || this.state.quantidade === "0") {
      errors.quantidade = true;
      valido = false;
    } else {
      errors.quantidade = false;
    }

    if (this.state.obra === null) {
      errors.obra = true;
      valido = false;
    } else {
      errors.obra = false;
    }

    if (this.state.usuario === null) {
      errors.usuario = true;
      valido = false;
    } else {
      errors.usuario = false;
    }

    if (this.state.situacao === "1") {
      if (this.state.obs === "") {
        errors.obs = true;
        valido = false;
      } else {
        errors.obs = false;
      }
    }

    this.setState({ errors });
    return valido;
  }

  async cadastrarPedidoMaterial() {
    if (this.state.isSaving) {
      return false;
    }

    this.setState({ isSaving: true }, async () => {
      if (!this.validarPedidoMaterial()) {
        this.notificar("warning", "Favor preencher todos os dados!");
        this.setState({ isSaving: false });
        return false;
      }

      let acaoPagina = this.state._id === null ? "cadastrar" : "editar";

      let pedidoMaterialNovo = {
        desc: this.state.desc,
        quantidade: this.state.quantidade,
        um: this.state.um,
        obra: this.state.obra,
        usuario: this.state.usuario,
        situacao: this.state.situacao,
        obs: this.state.obs,
      };

      let urlAtualizar = `${this.props.getBaseUrlApi()}/retaguarda/pedidos-material`;

      if (acaoPagina === "editar") {
        pedidoMaterialNovo._id = this.state._id;
        urlAtualizar = `${this.props.getBaseUrlApi()}/retaguarda/pedidos-material/${
          this.state._id
        }`;
      }

      await axios
        .post(
          urlAtualizar,
          { pedidoMaterial: pedidoMaterialNovo },
          this.props.parameters()
        )
        .then((response) => {
          if (acaoPagina === "cadastrar") {
            this.notificar(
              "success",
              "Pedido de material cadastrado com sucesso!"
            );
          } else {
            this.notificar(
              "success",
              "Pedido de material editado com sucesso!"
            );
          }

          setTimeout(() => {
            this.props.history.push("/pedidos-material");
          }, 1000);
        })
        .catch((error) => {
          this.notificar("warning", error.response.data.erro);
          this.setState({ isSaving: false });
        });
    });
  }

  async buscarPedidoMaterial(id) {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/retaguarda/pedidos-material/${id}`,
        this.props.parameters()
      )
      .then((response) => {
        const pedidoMaterial = response.data.pedidoMaterial;

        if (pedidoMaterial === null) {
          this.props.history.push("/pedidos-material");
          return false;
        }

        this.setState({
          _id: pedidoMaterial._id,
          desc: pedidoMaterial.desc,
          quantidade: pedidoMaterial.quantidade,
          um: pedidoMaterial.um,
          obra: pedidoMaterial.obra,
          usuario: pedidoMaterial.usuario,
          situacao: pedidoMaterial.situacao,
          obs: pedidoMaterial.obs,
          situacaoAtual: pedidoMaterial.situacao,
        });
      });
  }

  handleFocus(e) {
    e.target.select();
  }

  async loadUsuarios() {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/retaguarda/usuarios`,
        this.props.parameters()
      )
      .then((response) => {
        let usuarios = response.data.usuarios;

        for (let item of usuarios) {
          item.label = item.desc;
          item.value = item._id;
        }

        let usuario = null;

        if (this.state.usuario !== null) {
          let usuarioFind = usuarios.find(
            (it) => it._id === this.state.usuario._id
          );
          if (usuarioFind !== undefined) {
            usuario = usuarioFind;
          }
        }

        this.setState({ usuarios, usuario });
      });
  }

  async loadObras() {
    await axios
      .post(
        `${this.props.getBaseUrlApi()}/retaguarda/lista-obras`,
        {},
        this.props.parameters()
      )
      .then((response) => {
        let obras = response.data.obras;

        let obra = null;

        for (let item of obras) {
          // item.label = item.desc;
          item.label = `${item.cod.toString().padStart(4, "0")} - ${item.desc}`;
          item.value = item._id;
        }

        if (this.state.obra !== null) {
          let obraFind = obras.find((it) => it._id === this.state.obra._id);
          if (obraFind !== undefined) {
            obra = obraFind;
          }
        }

        this.setState({ obras, obra });
      });
  }

  async loadUnidadesMedida() {
    await axios
      .post(
        `${this.props.getBaseUrlApi()}/retaguarda/lista-unidades-medida`,
        { filtro: { status: "A" } },
        this.props.parameters()
      )
      .then((response) => {
        let unidadesMedida = response.data.unidadesMedida;

        for (let item of unidadesMedida) {
          item.label = item.desc;
          item.value = item._id;
        }

        this.setState({ unidadesMedida });
      });
  }

  render() {
    const InputRequired = () => {
      return <span className="required-icone">*</span>;
    };
    return (
      <React.Fragment>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
        <div className="container-scroller font-app">
          <NavBar
            title={`Pedido de Material / ${
              this.state.action === "CADASTRAR" ? "Novo" : "Editar"
            } Pedido`}
          />
          <div className="container-fluid page-body-wrapper">
            <SideBar itemActive={"pedidosMaterial"} />
            <div className="main-panel">
              <div className="content-wrapper">
                <div className="row">
                  <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body5 dashboard-tabs">
                        <div className="tab-content py-0 px-0">
                          <div
                            className="tab-pane fade show active"
                            id="overview"
                            role="tabpanel"
                            aria-labelledby="overview-tab"
                          >
                            <div className="d-flex flex-wrap justify-content-xl-between">
                              <div
                                style={{
                                  float: "left",
                                  width: "100%",
                                }}
                              >
                                <div className="row">
                                  <div className="col-md-3 pd-veiculo2">
                                    <div
                                      className="form-group form-group-chegoo"
                                      style={{ position: "relative" }}
                                    >
                                      <label>
                                        Usuário&nbsp;
                                        <InputRequired />
                                      </label>
                                      <Select
                                        value={this.state.usuario}
                                        onChange={(item) =>
                                          this.onChangeInput("usuario", item)
                                        }
                                        options={this.state.usuarios}
                                        placeholder="Selecione o usuário"
                                        className={`${
                                          this.state.errors.usuario
                                            ? "select-error"
                                            : ""
                                        } required-chegoo`}
                                        isDisabled={
                                          this.state.situacao === "1" ||
                                          this.state.situacao === "2"
                                        }
                                        styles={{
                                          control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            fontSize: "14px",
                                            height: "35px",
                                            minHeight: "35px",
                                            backgroundColor: "#fffef6",
                                          }),
                                          menu: (baseStyles, state) => ({
                                            ...baseStyles,
                                            fontSize: "14px",
                                          }),
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-3 pd-veiculo">
                                    <div
                                      className="form-group form-group-chegoo"
                                      style={{ position: "relative" }}
                                    >
                                      <label>
                                        Obra&nbsp;
                                        <InputRequired />
                                      </label>
                                      <Select
                                        value={this.state.obra}
                                        onChange={(item) =>
                                          this.onChangeInput("obra", item)
                                        }
                                        options={this.state.obras}
                                        placeholder="Selecione a obra"
                                        className={`${
                                          this.state.errors.obra
                                            ? "select-error"
                                            : ""
                                        } required-chegoo`}
                                        // isDisabled={this.state.situacao === "1"}
                                        styles={{
                                          control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            fontSize: "14px",
                                            height: "35px",
                                            minHeight: "35px",
                                            backgroundColor: "#fffef6",
                                          }),
                                          menu: (baseStyles, state) => ({
                                            ...baseStyles,
                                            fontSize: "14px",
                                          }),
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-4 pd-veiculo3">
                                    <div className="form-group form-group-chegoo">
                                      <label>U.M</label>
                                      <Select
                                        value={this.state.um}
                                        onChange={(item) =>
                                          this.onChangeInput("um", item)
                                        }
                                        options={this.state.unidadesMedida}
                                        placeholder="Selecione a U.M"
                                        // className={`${
                                        //   this.state.errors.obra
                                        //     ? "select-error"
                                        //     : ""
                                        // } required-chegoo`}
                                        // isDisabled={this.state.situacao === "1"}
                                        styles={{
                                          control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            fontSize: "14px",
                                            height: "35px",
                                            minHeight: "35px",
                                            backgroundColor: "#fffef6",
                                          }),
                                          menu: (baseStyles, state) => ({
                                            ...baseStyles,
                                            fontSize: "14px",
                                          }),
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-md-6 pd-veiculo2">
                                    <div className="form-group form-group-chegoo">
                                      <label>
                                        Item&nbsp;
                                        <InputRequired />
                                      </label>
                                      <textarea
                                        className="form-control font-size-14 form-control-chegoo required-chegoo text-area-form"
                                        rows={8}
                                        value={this.state.desc}
                                        onChange={(e) =>
                                          this.onChangeInput(
                                            "desc",
                                            e.target.value
                                          )
                                        }
                                        ref={(node) => {
                                          if (node) {
                                            if (this.state.errors.desc) {
                                              node.style.setProperty(
                                                "border-color",
                                                "red",
                                                "important"
                                              );
                                            } else {
                                              node.style.setProperty(
                                                "border-color",
                                                "#ced4da",
                                                "important"
                                              );
                                            }
                                          }
                                        }}
                                      ></textarea>
                                    </div>
                                  </div>

                                  <div className="col-md-2 pd-veiculo3">
                                    <div className="form-group form-group-chegoo">
                                      <label>
                                        Quantidade&nbsp;
                                        <InputRequired />
                                      </label>
                                      <input
                                        type="number"
                                        className="form-control"
                                        value={this.state.quantidade}
                                        onChange={(e) =>
                                          this.onChangeInput(
                                            "quantidade",
                                            e.target.value
                                          )
                                        }
                                        onFocus={this.handleFocus}
                                        ref={(node) => {
                                          if (node) {
                                            if (this.state.errors.quantidade) {
                                              node.style.setProperty(
                                                "border-color",
                                                "red",
                                                "important"
                                              );
                                            } else {
                                              node.style.setProperty(
                                                "border-color",
                                                "#ced4da",
                                                "important"
                                              );
                                            }
                                          }
                                        }}
                                      />
                                    </div>
                                  </div>

                                  {this.state._id !== null && (
                                    <div className="col-md-3 pd-veiculo3">
                                      <div className="form-group form-group-chegoo">
                                        <label>
                                          Situação&nbsp;
                                          <InputRequired />
                                        </label>

                                        <select
                                          className="form-control form-control-chegoo font-size-14"
                                          value={this.state.situacao}
                                          onChange={(e) =>
                                            this.onChangeInput(
                                              "situacao",
                                              e.target.value
                                            )
                                          }
                                        >
                                          <option value="0">Pendente</option>
                                          <option value="1">Finalizado</option>
                                          <option value="2">Cancelado</option>
                                        </select>
                                      </div>
                                    </div>
                                  )}
                                </div>
                                {this.state.situacao === "1" && (
                                  <div className="row">
                                    <div className="col-md-6 pd-veiculo2">
                                      <div className="form-group form-group-chegoo">
                                        <label>
                                          Obs Resolução&nbsp;
                                          <InputRequired />
                                        </label>
                                        <textarea
                                          type="text"
                                          className="form-control form-control-chegoo font-size-14 textarea-registroo"
                                          value={this.state.obs}
                                          onChange={(e) =>
                                            this.onChangeInput(
                                              "obs",
                                              e.target.value
                                            )
                                          }
                                          //  onFocus={this.handleFocus}
                                          ref={(node) => {
                                            if (node) {
                                              if (this.state.errors.obs) {
                                                node.style.setProperty(
                                                  "border-color",
                                                  "red",
                                                  "important"
                                                );
                                              } else {
                                                node.style.setProperty(
                                                  "border-color",
                                                  "#ced4da",
                                                  "important"
                                                );
                                              }
                                            }
                                          }}
                                          rows="4"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )}

                                <div className="row">
                                  <div className="col-md-2 pd-veiculo2">
                                    <div className="form-group form-group-chegoo2">
                                      <button
                                        className="btn btn-success btn-app-verde"
                                        onClick={() => {
                                          this.cadastrarPedidoMaterial();
                                        }}
                                        disabled={
                                          this.state.situacaoAtual === "2"
                                        }
                                      >
                                        {this.state.isSaving
                                          ? "Salvando..."
                                          : "Salvar"}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
