import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import { SideBar, NavBar, TitleAndDescription, Footer } from "../../components";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import axios from "axios";
import { truncateWithEllipsis } from "@amcharts/amcharts4/.internal/core/utils/Utils";
import Select from "react-select";
import queryString from "query-string";
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from "reactstrap";
import { CurrencyInput } from "../../components";
import Switch from "react-switch";
const { v4: uuidv4 } = require("uuid");
const { phoneMask } = require("../../utils");

export default class ChecklistNovo extends Component {
  constructor(props) {
    super(props);
    this.notificar = this.notificar.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
    this.cadastrarChecklist = this.cadastrarChecklist.bind(this);
    this.buscarChecklist = this.buscarChecklist.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleClickDeletar = this.handleClickDeletar.bind(this);
    this.handleClickConfirmaDeletar =
      this.handleClickConfirmaDeletar.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.removerItemLista = this.removerItemLista.bind(this);
    this.addItem = this.addItem.bind(this);

    this.state = {
      _id: null,
      checklistDeletar: null,
      modal: false,
      itens: [],
      descItem: "",
      desc: "",
      errors: {
        desc: false,
      },
    };
  }

  async componentDidMount() {
    let id = queryString.parse(this.props.location.search).id;

    if (id !== undefined) {
      await this.buscarChecklist(id);
    }
  }

  notificar(type, message) {
    toast[type](message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  onChangeInput(tipo, value) {
    this.setState({ [tipo]: value });
  }

  async cadastrarChecklist() {
    let itens = this.state.itens;
    let desc = this.state.desc;
    let errors = this.state.errors;

    if (desc === "") {
      this.notificar("warning", "Favor preencher a descrição!");
      errors.desc = true;
      this.setState({ errors });
      return false;
    } else {
      errors.desc = false;
      this.setState({ errors });
    }

    if (itens.length === 0) {
      this.notificar("warning", "Favor adicionar itens do checklist!");
      return false;
    }

    let acaoPagina = this.state._id === null ? "cadastrar" : "editar";

    let checklistNovo = {
      desc,
      itens,
    };

    checklistNovo.itens = checklistNovo.itens.map((item) => item.desc);

    if (acaoPagina === "editar") {
      checklistNovo._id = this.state._id;
    }

    let urlAtualizar = `${this.props.getBaseUrlApi()}/retaguarda/checklists`;

    if (this.state._id !== null) {
      urlAtualizar = `${this.props.getBaseUrlApi()}/retaguarda/checklists/${
        this.state._id
      }`;
    }

    await axios
      .post(urlAtualizar, checklistNovo, this.props.parameters())
      .then((response) => {
        this.setState(
          {
            desc: "",
            itens: [],
          },
          () => {
            if (acaoPagina === "cadastrar") {
              this.notificar("success", "Checklist cadastrado com sucesso!");
            } else {
              this.notificar("success", "Checklist editado com sucesso!");
              setTimeout(() => {
                this.props.history.push("/checklists");
              }, 1000);
            }
          }
        );
      })
      .catch((error) => {
        this.notificar("warning", error.response.data.erro);
      });
  }

  async buscarChecklist(id) {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/retaguarda/checklists/${id}`,
        this.props.parameters()
      )
      .then((response) => {
        const checklist = response.data.checklist;

        if (checklist === null) {
          this.props.history.push("/checklists");
          return false;
        }

        this.setState({
          _id: checklist._id,
          desc: checklist.desc,
          itens: checklist.itens.map((item) => {
            return {
              codigo: Math.random(),
              desc: item,
            };
          }),
        });
      });
  }

  handleClickDeletar(id) {
    this.setState({ checklistDeletar: id, modal: true });
  }

  toggle() {
    this.setState({ modal: !this.state.modal });
  }

  async handleClickConfirmaDeletar() {
    await axios
      .delete(
        `${this.props.getBaseUrlApi()}/retaguarda/checklists/${
          this.state.checklistDeletar
        }`,
        this.props.parameters()
      )
      .then((response) => {
        this.setState({ checklistDeletar: null, modal: false }, () => {
          this.notificar("success", "Checklist deletado com sucesso!");
          setTimeout(() => {
            this.props.history.push("/checklists");
          }, 1000);
        });
      })
      .catch((error) => {
        this.setState({ checklistDeletar: null, modal: false }, () => {
          this.notificar("warning", error.response.data.erro);
        });
      });
  }

  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      this.addItem();
    }
  };

  removerItemLista(item) {
    let itens = this.state.itens;

    itens = itens.filter((i) => i.codigo !== item.codigo);

    this.setState({ itens }, () => {
      this.notificar("success", "item removido com sucesso");
    });
  }

  addItem() {
    let desc = this.state.descItem;

    if (desc === "") {
      this.notificar("warning", "Favor digitar uma descrição");
      return false;
    }

    let itens = this.state.itens;
    itens.push({
      codigo: Math.random(),
      desc,
    });

    this.setState({ itens, descItem: "" });
  }

  render() {
    const InputRequired = () => {
      return <span className="required-icone">*</span>;
    };

    return (
      <React.Fragment>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
        <div className="container-scroller font-app">
          <NavBar title="Checklist / Novo checklist" />
          <div className="container-fluid page-body-wrapper">
            <SideBar itemActive={"checklist"} />
            <div className="main-panel">
              <div className="content-wrapper">
                {/* <TitleAndDescription
                  title="Despesas / Nova despesa"
                  description=""
                /> */}

                <div className="row">
                  <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body dashboard-tabs p-0">
                        <div className="tab-content py-0 px-0">
                          <div
                            className="tab-pane fade show active"
                            id="overview"
                            role="tabpanel"
                            aria-labelledby="overview-tab"
                          >
                            <div className="d-flex flex-wrap justify-content-xl-between">
                              <div
                                style={{
                                  padding: "15px",
                                  float: "left",
                                  width: "100%",
                                }}
                              >
                                {/* <div
                                  className="float-left width-100"
                                  style={{
                                    borderBottom: "1px solid #CCC",
                                    padding: "5px",
                                    marginBottom: "20px",
                                  }}
                                >
                                  DETALHAMENTO
                                </div> */}

                                <div className="row">
                                  <div className="col-md-5">
                                    <div className="form-group form-group-chegoo">
                                      <label>
                                        Descrição <InputRequired />
                                      </label>
                                      <input
                                        type="text"
                                        value={this.state.desc}
                                        label="email@email.com.br"
                                        className={`form-control form-control-chegoo font-size-14 required-chegoo`}
                                        onChange={(e) =>
                                          this.onChangeInput(
                                            "desc",
                                            e.target.value
                                          )
                                        }
                                        ref={(node) => {
                                          if (node) {
                                            if (this.state.errors.desc) {
                                              node.style.setProperty(
                                                "border-color",
                                                "red",
                                                "important"
                                              );
                                            } else {
                                              node.style.setProperty(
                                                "border-color",
                                                "#ced4da",
                                                "important"
                                              );
                                            }
                                          }
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-5">
                                    <div className="form-group form-group-chegoo">
                                      <label>Item</label>
                                      <div style={{ position: "relative" }}>
                                        <input
                                          type="text"
                                          value={this.state.descItem}
                                          className={`form-control form-control-chegoo font-size-14`}
                                          onChange={(e) =>
                                            this.onChangeInput(
                                              "descItem",
                                              e.target.value
                                            )
                                          }
                                          onKeyDown={this.handleKeyPress}
                                        />
                                        <div className="iten-add">
                                          <i
                                            className="fa fa-plus"
                                            onClick={() => this.addItem()}
                                          ></i>
                                        </div>
                                      </div>
                                      <small className="">
                                        Pressione enter para adicionar a lista
                                      </small>
                                    </div>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-10">
                                    <div
                                      className="font-size-14"
                                      style={{
                                        padding: "0px 0px 10px 0px",
                                      }}
                                    >
                                      ITENS DO CHECKLIST
                                    </div>
                                    <div
                                      className="table-itens-container"
                                      style={{ padding: "0 0px" }}
                                    >
                                      <table
                                        className="table"
                                        style={{ marginBottom: "30px" }}
                                      >
                                        <thead className="thead-dark">
                                          <th>Item</th>
                                          <th className="text-center">Ações</th>
                                        </thead>
                                        <tbody>
                                          {this.state.itens.map((item) => (
                                            <tr key={item.codigo}>
                                              <td>{item.desc}</td>
                                              <td
                                                className="text-center"
                                                style={{
                                                  color: "red",
                                                  fontSize: "20px",
                                                  cursor: "pointer",
                                                }}
                                              >
                                                <i
                                                  className="fa fa-trash"
                                                  onClick={() =>
                                                    this.removerItemLista(item)
                                                  }
                                                ></i>
                                              </td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </div>

                                {/* <div className="row">
                                  <div className="col-md-3">
                                    <div className="form-group">
                                      <label>Marcar como pago</label>
                                      <div>
                                        <Switch
                                          checked={this.state.despesaPaga}
                                          onChange={(checked) => {
                                            this.setState({
                                              despesaPaga: checked,
                                            });
                                          }}
                                          onColor="#f0e8fc"
                                          onHandleColor="#2da951"
                                          handleDiameter={25}
                                          uncheckedIcon={false}
                                          checkedIcon={false}
                                          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                          height={16}
                                          width={48}
                                          disabled={
                                            this.state.status !== "pendente"
                                              ? true
                                              : false
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div> */}

                                <div className="row">
                                  {/* <div className="col-md-8"></div> */}
                                  {/* <div className="col-md-2 no-padding text-right">
                                    <div className="form-group">
                                      <button
                                        className="btn btn-danger btn-app-vermelho"
                                        onClick={() => {
                                          this.handleClickDeletar(
                                            this.state._id
                                          );
                                        }}
                                        disabled={
                                          this.state._id === null ? true : false
                                        }
                                      >
                                        Deletar
                                      </button>
                                    </div>
                                  </div>{" "} */}
                                  <div
                                    className="col-md-2"
                                    style={{
                                      marginTop:
                                        this.state.itens.length === 0
                                          ? "15px"
                                          : "0px",
                                    }}
                                  >
                                    <div className="form-group form-group-chegoo2">
                                      <button
                                        className="btn btn-success btn-app-verde"
                                        //onClick={this.cadastrarChecklist}
                                        onClick={() => {
                                          this.cadastrarChecklist();
                                        }}
                                      >
                                        Salvar
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>

        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Deletar Checklist</ModalHeader>
          <ModalBody>Tem certeza que deseja deletar este checklist?</ModalBody>
          <ModalFooter>
            <Button
              className="btn-modal-recuperar"
              color="secondary"
              onClick={this.toggle}
            >
              Não
            </Button>
            <Button
              className="btn-modal-recuperar"
              color="primary"
              onClick={this.handleClickConfirmaDeletar}
            >
              Sim
            </Button>{" "}
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}
