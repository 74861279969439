import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import { SideBar, NavBar, TitleAndDescription, Footer } from "../../components";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import axios from "axios";
import { truncateWithEllipsis } from "@amcharts/amcharts4/.internal/core/utils/Utils";
import Select from "react-select";
import queryString from "query-string";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  UncontrolledTooltip,
} from "reactstrap";
import { CurrencyInput } from "../../components";
import Switch from "react-switch";
const { v4: uuidv4 } = require("uuid");
const { phoneMask, validaCpfCnpj } = require("../../utils");
const { cpfMask, cnpjMask } = require("../../mask");

export default class OrdemAbastecimentoNova extends Component {
  constructor(props) {
    super(props);
    this.notificar = this.notificar.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
    this.cadastrarOrdemAbastecimento =
      this.cadastrarOrdemAbastecimento.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.loadFornecedores = this.loadFornecedores.bind(this);
    this.loadVeiculos = this.loadVeiculos.bind(this);
    this.validarOrdemAbastecimento = this.validarOrdemAbastecimento.bind(this);

    this.state = {
      errors: {
        fornecedor: false,
        veiculo: false,
        combustivel: false,
      },

      modal: false,

      fornecedorSelecionado: null,
      veiculoSelecionado: null,
      combustivelSelecionado: null,
      obs: "",
      status: "pendente",

      fornecedores: [],
      veiculos: [],
      tiposCombustivel: [
        {
          value: 0,
          label: "Diesel",
        },
        {
          value: 1,
          label: "Gasolina",
        },
        {
          value: 2,
          label: "Álcool",
        },
        {
          value: 4,
          label: "Gás Natural",
        },
      ],
    };
  }

  async componentDidMount() {
    this.loadFornecedores();
    this.loadVeiculos();
  }

  notificar(type, message) {
    toast[type](message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  onChangeInput(tipo, value) {
    this.setState({ [tipo]: value });
  }

  validarOrdemAbastecimento() {
    let valido = true;
    let errors = this.state.errors;

    if (this.state.fornecedorSelecionado === null) {
      valido = false;
      errors.fornecedor = true;
    } else {
      errors.fornecedor = false;
    }

    if (this.state.veiculoSelecionado === null) {
      valido = false;
      errors.veiculo = true;
    } else {
      errors.veiculo = false;
    }

    if (this.state.combustivelSelecionado === null) {
      valido = false;
      errors.combustivel = true;
    } else {
      errors.combustivel = false;
    }

    this.setState({ errors });

    return valido;
  }

  async cadastrarOrdemAbastecimento() {
    // // ////console.clear();

    // console.log(this.state);

    if (!this.validarOrdemAbastecimento()) {
      this.notificar("warning", "Favor preencher todos os dados!");
      return false;
    }

    let fornecedorSelecionado = this.state.fornecedorSelecionado;
    let veiculoSelecionado = this.state.veiculoSelecionado;
    let combustivelSelecionado = this.state.combustivelSelecionado;
    let obs = this.state.obs;
    let status = this.state.status;

    let ordemAbastecimentoNova = {
      descCliente: fornecedorSelecionado.fantasia,
      combustivel: combustivelSelecionado.label,
      veiculo: veiculoSelecionado.desc,
      placa: veiculoSelecionado.placa,
      obs,
      status,
    };

    let urlAtualizar = `${this.props.getBaseUrlApi()}/retaguarda/ordens-abastecimento-nova`;

    await axios
      .post(urlAtualizar, ordemAbastecimentoNova, this.props.parameters())
      .then((response) => {
        this.notificar(
          "success",
          "Ordem de abastecimento cadastrada com sucesso!"
        );

        setTimeout(() => {
          this.props.history.push("/ordens-abastecimento");
        }, 1000);
      })
      .catch((error) => {
        this.notificar("warning", error.response.data.erro);
      });
  }

  handleFocus(e) {
    e.target.select();
  }

  async loadFornecedores() {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/retaguarda/fornecedores`,
        this.props.parameters()
      )
      .then((response) => {
        let fornecedores1 = response.data.fornecedores;
        let fornecedores = []

        for(let item of fornecedores1){
          let valido = false;
          for(let categoria of item.categorias){
            // alert(categoria.label)
            if(categoria.value === 'combustivel'){
              valido = true
            }
          }
          if(valido){
            fornecedores.push(item)
          }
        }



        fornecedores.map((forn) => {
          forn.label = forn.fantasia;
          forn.value = forn._id;
          return forn;
        });

        // // ////console.clear();
        //console.log(fornecedores);

        this.setState({ fornecedores });
      })
      .catch((error) => {
        // this.setState({ despesaDeletar: null, modal: false }, () => {
        //   this.notificar("warning", error.response.data.erro);
        // });
      });
  }
  async loadVeiculos() {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/retaguarda/veiculos`,
        this.props.parameters()
      )
      .then((response) => {
        let veiculos = response.data.veiculos;
        veiculos.map((veic) => {
          veic.label = veic.desc;
          veic.value = veic._id;
          return veic;
        });

        // console.log(veiculos);

        this.setState({ veiculos });
      })
      .catch((error) => {
        // this.setState({ despesaDeletar: null, modal: false }, () => {
        //   this.notificar("warning", error.response.data.erro);
        // });
      });
  }

  render() {

    const InputRequired = () => {
      return <span className="required-icone">*</span>;
    };


    return (
      <React.Fragment>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
        <div className="container-scroller font-app">
          <NavBar title="Ordens de Abastecimento / Nova" />
          <div className="container-fluid page-body-wrapper">
            <SideBar itemActive={"ordensAbastecimento"} />
            <div className="main-panel">
              <div className="content-wrapper">
                <div className="row">
                  <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body dashboard-tabs p-0">
                        <div className="tab-content py-0 px-0">
                          <div
                            className="tab-pane fade show active"
                            id="overview"
                            role="tabpanel"
                            aria-labelledby="overview-tab"
                          >
                            <div className="d-flex flex-wrap justify-content-xl-between">
                              <div
                                style={{
                                  padding: "10px",
                                  float: "left",
                                  width: "100%",
                                }}
                              >
                                {/* <div
                                  className="float-left width-100"
                                  style={{
                                    borderBottom: "1px solid #CCC",
                                    padding: "5px",
                                    marginBottom: "20px",
                                  }}
                                >
                                  DETALHAMENTO DA ORDEM
                                </div> */}
                                <div className="row">
                                  <div className="col-md-4 pd-veiculo2">
                                    <div className="form-group form-group-chegoo">
                                      <label>Fornecedor <InputRequired/></label>
                                      <Select
                                        value={this.state.fornecedorSelecionado}
                                        onChange={(value) =>
                                          this.setState({
                                            fornecedorSelecionado: value,
                                          })
                                        }
                                        className={`${
                                          this.state.errors.fornecedor
                                            ? "select-error"
                                            : ""
                                        }`}
                                        options={this.state.fornecedores}
                                        placeholder="Selecione o Fornecedor"
                                        styles={{
                                          control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            fontSize: "14px",
                                            height: "35px",
                                            minHeight: "35px",
                                            backgroundColor: "#fffef6" 
                                          }),
                                          menu: (baseStyles, state) => ({
                                            ...baseStyles,
                                            fontSize: "14px",
                                          }),
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-4 pd-veiculo">
                                    <div className="form-group form-group-chegoo">
                                      <label>Veículo <InputRequired/></label>
                                      <Select
                                        value={this.state.veiculoSelecionado}
                                        onChange={(value) => {
                                          // // ////console.clear();
                                          // console.log("veic", value);

                                          let combustivelSelecionado = null;

                                          if (
                                            value.tipoCombustivel.desc !==
                                              "Flex" &&
                                            value.tipoCombustivel.desc !==
                                              "Elétrico"
                                          ) {
                                            combustivelSelecionado = {
                                              label: value.tipoCombustivel.desc,
                                              value:
                                                value.tipoCombustivel.codigo,
                                            };
                                          }
                                          this.setState({
                                            veiculoSelecionado: value,
                                            combustivelSelecionado,
                                          });
                                        }}
                                        className={`${
                                          this.state.errors.veiculo
                                            ? "select-error"
                                            : ""
                                        }`}
                                        options={this.state.veiculos}
                                        placeholder="Selecione o Veículo"
                                        styles={{
                                          control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            fontSize: "14px",
                                            height: "35px",
                                            minHeight: "35px",
                                            backgroundColor: "#fffef6" 
                                          }),
                                          menu: (baseStyles, state) => ({
                                            ...baseStyles,
                                            fontSize: "14px",
                                          }),
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-4 pd-veiculo3">
                                    <div className="form-group form-group-chegoo">
                                      <label>Combustível <InputRequired/></label>
                                      <Select
                                        value={
                                          this.state.combustivelSelecionado
                                        }
                                        onChange={(value) =>
                                          this.setState({
                                            combustivelSelecionado: value,
                                          })
                                        }
                                        className={`${
                                          this.state.errors.combustivel
                                            ? "select-error"
                                            : ""
                                        }`}
                                        options={this.state.tiposCombustivel}
                                        placeholder="Selecione o Combustível"
                                        styles={{
                                          control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            fontSize: "14px",
                                            height: "35px",
                                        minHeight: "35px",
                                        backgroundColor: "#fffef6" 
                                          }),
                                          menu: (baseStyles, state) => ({
                                            ...baseStyles,
                                            fontSize: "14px",
                                          }),
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-md-10 pd-veiculo2">
                                    <div className="form-group form-group-chegoo">
                                      <label>OBS</label>
                                      <input
                                      type="text"
                                        // rows={5}
                                        className="form-control form-control-chegoo font-size-14"
                                        value={this.state.obs}
                                        onChange={(e) =>
                                          this.onChangeInput(
                                            "obs",
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-2 pd-veiculo3">
                                    <div className="form-group form-group-chegoo">
                                      <label>Status<InputRequired/></label>
                                      <select
                                        className="form-control form-control-chegoo font-size-14 required-chegoo"
                                        value={this.state.status}
                                        onChange={(e) =>
                                          this.onChangeInput(
                                            "status",
                                            e.target.value
                                          )
                                        }
                                      >
                                        <option value="pendente">
                                          Pendente
                                        </option>
                                        <option value="realizada">
                                          Aprovada
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                </div>

                                <div className="row">
                                  {/* <div className="col-md-8"></div>
                                  <div className="col-md-2 no-padding text-right"></div>{" "} */}
                                  <div className="col-md-2 pd-veiculo2">
                                    <div className="form-group form-group-chegoo2">
                                      <button
                                        className="btn btn-success btn-app-verde"
                                        //onClick={this.cadastrarOrdemAbastecimento}
                                        onClick={() => {
                                          this.cadastrarOrdemAbastecimento();
                                        }}
                                      >
                                        Salvar
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>

        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Deletar veículo</ModalHeader>
          <ModalBody>Tem certeza que deseja deletar esta despesa?</ModalBody>
          <ModalFooter>
            <Button
              className="btn-modal-recuperar"
              color="secondary"
              onClick={this.toggle}
            >
              Não
            </Button>
            <Button
              className="btn-modal-recuperar"
              color="primary"
              onClick={this.handleClickConfirmaDeletar}
            >
              Sim
            </Button>{" "}
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}
