import React, { Component } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { SideBar, NavBar, Footer, TitleAndDescription } from "../../components";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import Select from "react-select";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  UncontrolledTooltip,
} from "reactstrap";
const { formatarReal } = require("../../utils");

const MODAL_EXCLUIR_USUARIO = "excluirUsuario";
const MODAL_DESLOGAR_USUARIO = "deslogarUsuario";

class Usuario extends Component {
  constructor(props) {
    super(props);
    this.load = this.load.bind(this);
    this.loadUsuarios = this.loadUsuarios.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleClickDeletar = this.handleClickDeletar.bind(this);
    this.handleClickConfirmaDeletar =
      this.handleClickConfirmaDeletar.bind(this);
    this.handleClickDeslogarUsuario =
      this.handleClickDeslogarUsuario.bind(this);
    this.abrirPaginaSaldoDetalhado = this.abrirPaginaSaldoDetalhado.bind(this);
    this.onChangeInputFiltro = this.onChangeInputFiltro.bind(this);

    this.state = {
      usuarios: [],
      usuarioDeletar: null,
      usuarioDeslogar: null,
      modal: {
        [MODAL_EXCLUIR_USUARIO]: false,
        [MODAL_DESLOGAR_USUARIO]: false,
      },
      filtro: {
        status: "A",
      },
    };
  }

  async componentDidMount() {
    const userLogado = JSON.parse(localStorage.getItem('usuario'));
    if(userLogado.nivel === "3"){
      return this.props.history.push('/pedidos-material')
    }
    if(userLogado.nivel === "4"){
      return this.props.history.push('/nao-conformidades')
    }
    if(userLogado.nivel === "5"){
      return this.props.history.push('/despesas')
    }

    await this.load();
  }

  async load() {
    await this.loadUsuarios();
  }

  async loadUsuarios() {
    let options = {
      nivel: JSON.parse(localStorage.getItem('usuario')).nivel
    };

    if (this.state.filtro.status !== "99") {
      options.status = this.state.filtro.status;
    }

    await axios
      .post(
        `${this.props.getBaseUrlApi()}/retaguarda/lista-usuarios`,
        options,
        this.props.parameters()
      )
      .then((response) => {
        let usuarios = response.data.usuarios;
        for (let usuario of usuarios) {
          usuario.funcao =
            usuario.funcao === "administrativo"
              ? "Administrativo"
              : "Mestre de Obras";

          if(usuario.nivel === '0'){
            usuario.nivel = 'Admin';
          }
          else if(usuario.nivel === '1'){
            usuario.nivel = 'App';
          }
          else if(usuario.nivel === '2'){
            usuario.nivel = 'Retaguarda';
          }
          else if(usuario.nivel === '3'){
            usuario.nivel = 'Logística';
          }
          else if(usuario.nivel === '4'){
            usuario.nivel = 'Qualidade';
          }
          else if(usuario.nivel === '5'){
            usuario.nivel = 'Financeiro';
          }

        }
        this.setState({ usuarios: response.data.usuarios });
      });
  }

  notificar(type, message) {
    toast[type](message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  handleClickDeletar(id) {
    this.setState({ usuarioDeletar: id }, () => {
      this.toggle(MODAL_EXCLUIR_USUARIO);
    });
  }

  toggle(type) {
    let modal = this.state.modal;
    modal[type] = !modal[type];
    this.setState({ modal });
  }

  async handleClickConfirmaDeletar() {
    await axios
      .delete(
        `${this.props.getBaseUrlApi()}/retaguarda/usuarios/${
          this.state.usuarioDeletar
        }`,
        this.props.parameters()
      )
      .then((response) => {
        this.setState({ usuarioDeletar: null }, () => {
          this.toggle(MODAL_EXCLUIR_USUARIO);
          this.loadUsuarios();
          this.notificar("success", "Usuário deletado com sucesso!");
        });
      })
      .catch((error) => {
        this.setState({ usuarioDeletar: null }, () => {
          this.toggle(MODAL_EXCLUIR_USUARIO);
          this.notificar("warning", error.response.data.erro);
        });
      });
  }

  async handleClickDeslogarUsuario() {
    let usuario = this.state.usuarioDeslogar;
    await axios
      .post(
        `${this.props.getBaseUrlApi()}/retaguarda/deslogar-usuario`,
        { idUsuario: usuario._id },
        this.props.parameters()
      )
      .then((response) => {
        this.toggle(MODAL_DESLOGAR_USUARIO);
        this.notificar("success", "Usuário deslogado com sucesso !");
        this.load();
      });
  }

  abrirPaginaSaldoDetalhado(idUsuario) {
    return this.props.history.push(`/despesas-usuario?id=${idUsuario}`);
  }

  onChangeInputFiltro(type, value) {
    let filtro = this.state.filtro;
    filtro[type] = value;
    this.setState({ filtro });
  }

  render() {
    return (
      <React.Fragment>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />

        <div className="container-scroller font-app">
          <NavBar title="Usuários" />
          <div className="container-fluid page-body-wrapper">
            <SideBar itemActive={"usuarios"} />
            <div className="main-panel">
              <div className="content-wrapper">
                <div
                  className="row"
                  style={{ marginBottom: "0.8rem", alignItems: "center" }}
                >
                  <div className="col-md-12 stretch-card">
                    {true && (
                      <div className="card">
                        <div
                          className="card-body5"
                          style={{ position: "relative" }}
                        >
                          <React.Fragment>
                            <div className="row">
                              {/* <div className="col-md-2 pd-veiculo2">
                                <div className="form-group form-group-chegoo2">
                                  <label>Usuário</label>
                                  <input
                                    type="text"
                                    className="form-control form-control-chegoo font-size-14 text-center"
                                  />
                                </div>
                              </div> */}

                              <div className="col-md-2 pd-veiculo2">
                                <div className="form-group form-group-chegoo2">
                                  <label>Status</label>
                                  <select
                                    className="form-control form-control-chegoo font-size-14"
                                    value={this.state.filtro.status}
                                    onChange={(e) =>
                                      this.onChangeInputFiltro(
                                        "status",
                                        e.target.value
                                      )
                                    }
                                  >
                                    <option value="99">Todos</option>
                                    <option value="A">Ativo</option>
                                    <option value="I">Inativo</option>
                                  </select>
                                </div>
                              </div>
                              <div
                                className="col-md-2 pd-veiculo"
                                style={{ display: "flex", marginTop: "15px" }}
                              >
                                <div
                                  className="form-group-chegoo2"
                                  style={{ float: "left" }}
                                >
                                  <label></label>
                                  <button
                                    className="btn btn-secondary btn-sm"
                                    style={{
                                      padding: "0.275rem 0.55rem",
                                      margin: "10px 5px 0px",
                                    }}
                                    onClick={() => this.load()}
                                    id={`tooltip-filtro-atendimentos`}
                                  >
                                    <i
                                      className="fa fa-search"
                                      aria-hidden="true"
                                      style={{ fontSize: "px" }}
                                    ></i>
                                  </button>
                                  <UncontrolledTooltip
                                    placement="bottom"
                                    target={`tooltip-filtro-atendimentos`}
                                  >
                                    Filtrar
                                  </UncontrolledTooltip>
                                </div>
                                <div className="form-group-chegoo2">
                                  <label></label>
                                  <button
                                    className="btn btn-warning btn-sm"
                                    style={{
                                      padding: "0.275rem 0.55rem",
                                      margin: "10px 5px 0px",
                                      color: "#FFF",
                                    }}
                                    onClick={() => this.limparFiltros()}
                                    id={`tooltip-limpar-filtro-atendimentos`}
                                  >
                                    <i
                                      className="fa fa-eraser"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                  <UncontrolledTooltip
                                    placement="bottom"
                                    target={`tooltip-limpar-filtro-atendimentos`}
                                  >
                                    Limpar filtro
                                  </UncontrolledTooltip>
                                </div>
                              </div>
                              <div
                                className="offset-md-6 col-md-2 pd-veiculo3"
                                style={{
                                  marginTop: "15px",
                                  marginTop: "15px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "end",
                                }}
                              >
                                <button
                                  className="btn btn-success btn-app-verde-sm"
                                  onClick={() => {
                                    this.props.history.push("/usuarios-novo");
                                  }}
                                >
                                  CADASTRAR
                                </button>
                              </div>
                            </div>
                          </React.Fragment>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12 stretch-card">
                    {this.state.usuarios.length > -1 && (
                      <div className="card">
                        <div className="card-body2">
                          <React.Fragment>
                            <div className="table-responsive">
                              <table
                                id="recent-purchases-listing"
                                className="table"
                              >
                                <thead className="thead-dark">
                                  <tr>
                                    <th># Login</th>
                                    <th>Usuário</th>
                                    <th>Função</th>
                                    <th className="text-right">Celular</th>
                                    <th>Nível</th>
                                    <th className="text-center">Data Login</th>
                                    <th className="text-center">Status</th>
                                    <th className="text-right">Saldo</th>

                                    <th>Ações</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.usuarios.map((usuario, key) => (
                                    <tr key={key}>
                                      <td>{usuario.login}</td>
                                      <td> {usuario.desc}</td>
                                      <td> {usuario.funcao}</td>
                                      <td className="text-right">
                                        {" "}
                                        {usuario.celular}
                                      </td>
                                      <td> {usuario.nivel} </td>
                                      <td className="text-center">
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                          }}
                                        >
                                          {" "}
                                          {usuario.dtLogin !== null
                                            ? moment(usuario.dtLogin).format(
                                                "DD/MM/YY HH:mm"
                                              )
                                            : ""}
                                          &nbsp;&nbsp;
                                          {usuario.dtLogin !== null && (
                                            <React.Fragment>
                                              <i
                                                id={`btn-deslogar-${usuario._id}`}
                                                className="fa fa-power-off"
                                                style={{
                                                  fontSize: "18px",
                                                  cursor: "pointer",
                                                }}
                                                onClick={() => {
                                                  this.setState(
                                                    {
                                                      usuarioDeslogar: usuario,
                                                    },
                                                    () => {
                                                      this.toggle(
                                                        MODAL_DESLOGAR_USUARIO
                                                      );
                                                    }
                                                  );
                                                }}
                                              ></i>

                                              <UncontrolledTooltip
                                                placement="bottom"
                                                target={`btn-deslogar-${usuario._id}`}
                                              >
                                                Deslogar
                                              </UncontrolledTooltip>
                                            </React.Fragment>
                                          )}
                                        </div>
                                      </td>
                                      <td className="text-center">
                                        {usuario.status === "A"
                                          ? "Ativo"
                                          : "Inativo"}
                                      </td>
                                      <td className="text-right">
                                        R$ {formatarReal(usuario.saldo)}
                                      </td>

                                      <td
                                        className="d-flex"
                                        style={{ fontSize: "18px" }}
                                      >
                                        <div
                                          style={{
                                            margin: "3px",
                                            cursor: "pointer",
                                          }}
                                        >
                                          <i
                                            className="fa fa-edit"
                                            onClick={() => {
                                              this.props.history.push(
                                                `/usuarios-novo?id=${usuario._id}`
                                              );
                                            }}
                                            id={`btn-editar-${usuario._id}`}
                                          ></i>

                                          <UncontrolledTooltip
                                            placement="bottom"
                                            target={`btn-editar-${usuario._id}`}
                                          >
                                            Editar
                                          </UncontrolledTooltip>
                                        </div>
                                        <div
                                          style={{
                                            margin: "3px",
                                            cursor: "pointer",
                                          }}
                                        >
                                          <i
                                            className="fa fa-trash"
                                            style={{ color: "red" }}
                                            onClick={() => {
                                              this.handleClickDeletar(
                                                usuario._id
                                              );
                                            }}
                                            id={`btn-excluir-${usuario._id}`}
                                          ></i>

                                          <UncontrolledTooltip
                                            placement="bottom"
                                            target={`btn-excluir-${usuario._id}`}
                                          >
                                            Excluir
                                          </UncontrolledTooltip>
                                        </div>
                                        <div
                                          style={{
                                            margin: "3px",
                                            cursor: "pointer",
                                          }}
                                        >
                                          <i
                                            className="fa fa-dollar"
                                            style={{ color: "green" }}
                                            onClick={() => {
                                              this.abrirPaginaSaldoDetalhado(
                                                usuario._id
                                              );
                                            }}
                                            id={`btn-saldo-${usuario._id}`}
                                          ></i>

                                          <UncontrolledTooltip
                                            placement="bottom"
                                            target={`btn-saldo-${usuario._id}`}
                                          >
                                            Saldo detalhado
                                          </UncontrolledTooltip>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </React.Fragment>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>

        <Modal
          isOpen={this.state.modal[MODAL_EXCLUIR_USUARIO]}
          toggle={() => this.toggle(MODAL_EXCLUIR_USUARIO)}
        >
          <ModalHeader toggle={() => this.toggle(MODAL_EXCLUIR_USUARIO)}>
            Deletar usuário
          </ModalHeader>
          <ModalBody>Tem certeza que deseja deletar este usuário?</ModalBody>
          <ModalFooter>
            <Button
              className="btn-modal-recuperar"
              color="secondary"
              onClick={() => this.toggle(MODAL_EXCLUIR_USUARIO)}
            >
              Não
            </Button>
            <Button
              className="btn-modal-recuperar"
              color="primary"
              onClick={this.handleClickConfirmaDeletar}
            >
              Sim
            </Button>{" "}
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.modal[MODAL_DESLOGAR_USUARIO]}
          toggle={() => this.toggle(MODAL_DESLOGAR_USUARIO)}
        >
          <ModalHeader toggle={() => this.toggle(MODAL_DESLOGAR_USUARIO)}>
            Deslogar usuário
          </ModalHeader>
          <ModalBody>Tem certeza que deseja deslogar este usuário?</ModalBody>
          <ModalFooter>
            <Button
              className="btn-modal-recuperar"
              color="secondary"
              onClick={() => this.toggle(MODAL_DESLOGAR_USUARIO)}
            >
              Não
            </Button>
            <Button
              className="btn-modal-recuperar"
              color="primary"
              onClick={this.handleClickDeslogarUsuario}
            >
              Sim
            </Button>{" "}
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default Usuario;
