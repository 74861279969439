import React from "react";

const Footer = (props) => {
  return (
    <footer className="footer">
      <div className="d-sm-flex justify-content-center justify-content-sm-between">
        <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">
          {" "}
        </span>

        <span className="float-none float-sm-right d-block mt-1 mt-sm-0 text-center">
          <img
            src="../../images/logo-suricatto-nova.png"
            alt="logo"
            style={{ width: "100px" }}
          />
        </span>
      </div>
    </footer>
  );
};

export default Footer;
