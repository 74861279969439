import React, { Component } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { SideBar, NavBar, Footer, TitleAndDescription } from "../../components";
import "react-toastify/dist/ReactToastify.css";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  UncontrolledTooltip,
} from "reactstrap";
import queryString from "query-string";
const { formatarReal, getUrlGoogleMaps } = require("../../utils");

class RoteiroDetalheVisita extends Component {
  constructor(props) {
    super(props);
    this.loadRoteiros = this.loadRoteiros.bind(this);
    this.toggleModalObs = this.toggleModalObs.bind(this);
    this.toggleModalNaoConformidade =
      this.toggleModalNaoConformidade.bind(this);
    this.handleClickVisualizarNaoConformidade =
      this.handleClickVisualizarNaoConformidade.bind(this);

    this.state = {
      visita: null,
      totalizadores: {
        totalVisitas: 0,
        pendentes: 0,
        realizados: 0,
        divergencias: 0,
        ocorrencias: 0,
        canceladas: 0,
      },
      totalVisitas: 0,
      ocorrencias: [],
      totalizadorVolumes: {
        volumes: 0,
        bipados: 0,
        pendentes: 0,
      },
      tipoRoteiro: "sequencial",
      modalObs: false,
      textoModalObs: "",
      modalNaoConformidade: false,
      fotoNaoConformidade: null,
    };
  }

  async componentDidMount() {
    let id = queryString.parse(this.props.location.search).id;
    let seq = queryString.parse(this.props.location.search).seq;

    if (id !== undefined && seq !== undefined) {
      await this.loadRoteiros(id, seq);
    } else {
      this.props.history.push("/roteiros");
      return false;
    }
  }

  async loadRoteiros(id, seq) {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/retaguarda/roteiros-visita/${id}?seq=${seq}`,
        this.props.parameters()
      )
      .then((response) => {
        let visita = response.data.visita;
        let ocorrencias = response.data.ocorrencias;
        const tipoRoteiro = response.data.tipoRoteiro;

        visita.statusClass = visita.status;

        if (visita.status === "naoRealizada") {
          visita.status = "Não Realizada";
        }

        let totalizadorVolumes = this.state.totalizadorVolumes;

        for (let volume of visita.volumes) {
          if (volume.status === "naoConferido") {
            totalizadorVolumes.pendentes++;
          } else if (volume.status === "conferido") {
            totalizadorVolumes.bipados++;
          }
        }

        totalizadorVolumes.volumes = visita.volumes.length;

        for (let naoConformidade of visita.naoConformidades) {
          naoConformidade.idTemp = Math.random().toString().replace(".", "");
          naoConformidade.obsOriginal = naoConformidade.obs;
          naoConformidade.visualizarObs = false;

          if (naoConformidade.obs.length > 20) {
            naoConformidade.obs = naoConformidade.obs.substr(0, 20);
            naoConformidade.obs += "...";
            naoConformidade.visualizarObs = true;
          }
        }

        visita.urlMaps = getUrlGoogleMaps(visita.endereco);

        this.setState({
          visita,
          totalVisitas: response.data.totalVisitas,
          ocorrencias,
          totalizadorVolumes,
          tipoRoteiro,
        });
      });
  }

  notificar(type, message) {
    toast[type](message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  toggleModalObs() {
    this.setState({ modalObs: !this.state.modalObs });
  }

  toggleModalNaoConformidade() {
    this.setState({ modalNaoConformidade: !this.state.modalNaoConformidade });
  }

  handleClickVisualizarNaoConformidade(naoConformidade) {
    this.setState({ fotoNaoConformidade: naoConformidade.foto }, () =>
      this.toggleModalNaoConformidade()
    );
  }

  render() {
    return (
      <React.Fragment>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />

        <div className="container-scroller font-app">
          <NavBar title="Ordens de visita / Detalhe / Visita" />
          <div className="container-fluid page-body-wrapper">
            <SideBar itemActive={"roteiros"} />
            <div className="main-panel">
              <div className="content-wrapper">
                {/* <TitleAndDescription
                  title="Ordens de visita / Detalhe / Visita"
                  description=""
                /> */}

                <div className="row" style={{ paddingBottom: "40px" }}>
                  {this.state.visita !== null && (
                    <div
                      className={`${
                        this.state.visita.status === "realizada"
                          ? "col-md-9"
                          : "col-md-12"
                      } stretch-card`}
                    >
                      <div className="card">
                        <div className="card-body3">
                          <React.Fragment>
                            <div
                              className="float-left width-100"
                              style={{
                                padding: "5px",
                                position: "relative",
                              }}
                              className="font-size-14"
                            >
                              <div className="float-left font-size-16">
                                DETALHAMENTO DA VISITA&nbsp;&nbsp;&nbsp;&nbsp;
                                <span
                                  className={`color-${this.state.visita.statusClass}`}
                                  style={{
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {" "}
                                  <i className="fa fa-circle"> </i>{" "}
                                  {this.state.visita.status}
                                </span>
                              </div>

                              {this.state.visita !== null && (
                                <div className={` float-right`}>
                                  {this.state.visita.seq} /{" "}
                                  {this.state.totalVisitas}
                                </div>
                              )}

                              <div
                                className="float-left w-100"
                                style={{ padding: "10px 0" }}
                              >
                                <span
                                  style={{
                                    textTransform: "capitalize",
                                    fontWeight: "bold",
                                  }}
                                  className="font-size-16"
                                >
                                  {this.state.visita.operacao}
                                </span>
                                <div className="font-size-14">
                                  {this.state.visita.cliente.desc}
                                </div>
                              </div>
                            </div>

                            <div
                              className="float-left w-100 d-flex flex-wrap"
                              style={{ padding: "5px 0" }}
                            >
                              <div className="col-6 no-padding">
                                <div
                                  style={{ fontWeight: "bold" }}
                                  className="font-size-16"
                                >
                                  Endereço de Entrega
                                </div>
                                <div className="row">
                                  <div className="col-2">
                                    <div style={{ fontSize: "50px" }}>
                                      <i
                                        className="fa fa-map-marker"
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          window.open(
                                            this.state.visita.urlMaps,
                                            "_blank"
                                          )
                                        }
                                      ></i>
                                    </div>{" "}
                                  </div>

                                  <div className="col-10 font-size-14">
                                    <div>
                                      {this.state.visita.endereco.end},{" "}
                                      {this.state.visita.endereco.num}{" "}
                                    </div>
                                    <div>
                                      {" "}
                                      {this.state.visita.endereco.compl}
                                    </div>
                                    <div>
                                      {this.state.visita.endereco.bairro} -{" "}
                                      {this.state.visita.endereco.cidade} -{" "}
                                      {this.state.visita.endereco.uf}
                                    </div>
                                    <div>
                                      {" "}
                                      CEP {this.state.visita.endereco.cep}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {this.state.tipoRoteiro === "sequencial" && (
                                <div className="col-6">
                                  <div className="row">
                                    <div className="col-6">
                                      <div
                                        style={{ fontWeight: "bold" }}
                                        className="font-size-16"
                                      >
                                        Documento
                                      </div>
                                      <div className="font-size-14">
                                        <span
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          {this.state.visita.documentos[0].tipo}
                                          &nbsp;
                                        </span>
                                        {this.state.visita.documentos[0].nro}
                                      </div>
                                      <div className="font-size-14">
                                        {
                                          this.state.visita.documentos[0]
                                            .volumes
                                        }{" "}
                                        volume(s)
                                      </div>
                                    </div>
                                    <div className="col-6">
                                      <div
                                        style={{ fontWeight: "bold" }}
                                        className="font-size-16"
                                      >
                                        Valor frete
                                      </div>
                                      <div className="font-size-14">
                                        R$ 0,00
                                      </div>
                                      {this.state.visita.documentos[0].danfe !==
                                        "" && (
                                        <div
                                          className="font-size-14"
                                          style={{
                                            textDecoration: "underline",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            window.open(
                                              this.state.visita.documentos[0]
                                                .danfe,
                                              "_blank"
                                            );
                                          }}
                                        >
                                          Ver DANFE
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                  <div className="float-left w-100 font-size-14">
                                    Obs: {this.state.visita.documentos[0].obs}
                                  </div>
                                </div>
                              )}
                            </div>

                            <div className="float-left w-100 d-flex flex-wrap container-tabelas">
                              <div className="col-6 padding-tabela">
                                <div
                                  className="float-left w-100 font-size-16"
                                  // style={{ paddingLeft: "12px" }}
                                >
                                  {" "}
                                  ATIVIDADES{" "}
                                </div>

                                <div className="table-responsive font-size-14">
                                  <table
                                    id="recent-purchases-listing"
                                    className="table"
                                  >
                                    <thead className="thead-dark">
                                      <tr>
                                        <th>Atividade</th>
                                        <th className="text-right">
                                          Data / Hora
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {this.state.ocorrencias.length > 0 && (
                                        <React.Fragment>
                                          {this.state.ocorrencias.map(
                                            (ocorrencia, key) => (
                                              <tr key={key}>
                                                <td>
                                                  <i
                                                    className={`fa fa-circle legenda-${ocorrencia.tipo}`}
                                                  >
                                                    {" "}
                                                  </i>
                                                  &nbsp;{ocorrencia.desc}
                                                </td>
                                                <td className="text-right">
                                                  {ocorrencia.descHora}
                                                </td>
                                              </tr>
                                            )
                                          )}
                                          {/* <tr>
                                            <td
                                              colSpan="2"
                                              className="text-right"
                                              style={{ fontWeight: "bold" }}
                                            >
                                              Tempo Total: 02:00
                                            </td>
                                          </tr> */}
                                        </React.Fragment>
                                      )}

                                      {this.state.ocorrencias.length === 0 && (
                                        <React.Fragment>
                                          <tr>
                                            <td
                                              colSpan="2"
                                              className="text-center font-size-14"
                                            >
                                              Não possuí ocorrências
                                            </td>
                                          </tr>
                                        </React.Fragment>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>

                              <div className="col-6">
                                <div
                                  className="float-left w-100 font-size-16"
                                  // style={{ paddingLeft: "12px" }}
                                >
                                  {" "}
                                  {/* VOLUMES CONFERIDOS{" "} */}
                                  ITENS
                                </div>

                                <div className="table-responsive font-size-14">
                                  <table
                                    id="recent-purchases-listing"
                                    className="table"
                                  >
                                    <thead className="thead-dark">
                                      <tr>
                                        <th>Cod</th>
                                        <th>Item</th>
                                        <th className="text-right">Qtd</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {this.state.visita.volumes.map(
                                        (volume, key) => (
                                          <tr key={key}>
                                            <td>&nbsp;{volume.cod}</td>
                                            <td>{volume.desc}</td>
                                            <td className="text-right">
                                              {/* {volume.seq} /{" "} */}
                                              {volume.qtd.toString().replace('.', ',')}
                                            </td>
                                            {/* <td className="text-right">
                                              {volume.seq} /{" "}
                                              {this.state.visita.volumes.length}
                                            </td> */}
                                          </tr>
                                        )
                                      )}
                                      <tr style={{ fontWeight: "bold" }}>
                                        
                                        <td colSpan={3}>
                                          <div className="d-flex" style={{marginTop:"15px"}}>


                                          <div className="col-4 no-padding text-center">
                                          Volumes:{" "}
                                          {
                                            this.state.totalizadorVolumes
                                              .volumes
                                          }
                                        </div>
                                        <div className="col-4 no-padding text-center">
                                          Bipados:{" "}
                                          {
                                            this.state.totalizadorVolumes
                                              .bipados
                                          }
                                        </div>
                                        <div className="col-4 no-padding text-center">
                                          Pendentes:{" "}
                                          {
                                            this.state.totalizadorVolumes
                                              .pendentes
                                          }
                                        </div>

                                          </div>
                                          </td>
                                          
                                          
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>

                            <div className="float-left w-100 d-flex flex-wrap container-tabelas">
                              <div className="col-7 padding-tabela">
                                <div
                                  className="float-left w-100 font-size-16"
                                  // style={{ paddingLeft: "12px" }}
                                >
                                  {" "}
                                  NÃO CONFORMIDADES{" "}
                                </div>

                                <div className="table-responsive font-size-14">
                                  <table
                                    id="recent-purchases-listing"
                                    className="table"
                                  >
                                    <thead className="thead-dark">
                                      <tr>
                                        <th>#</th>
                                        <th>Descrição</th>
                                        <th>Detalhe</th>
                                        <th className="text-center">Foto</th>
                                      </tr>
                                    </thead>
                                    {this.state.visita.naoConformidades.length >
                                      0 && (
                                      <tbody>
                                        {this.state.visita.naoConformidades.map(
                                          (naoConformidade, key) => (
                                            <tr key={key}>
                                              <td
                                                style={{ fontWeight: "bold" }}
                                              >
                                                &nbsp;{naoConformidade.cod}
                                              </td>
                                              <td>{naoConformidade.desc}</td>
                                              <td>
                                                {naoConformidade.obs}

                                                {naoConformidade.visualizarObs && (
                                                  <React.Fragment>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                                    <i
                                                      className="fa fa-search"
                                                      id={`item-naoConformidade-${naoConformidade.idTemp}`}
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                      onClick={() => {
                                                        this.setState(
                                                          {
                                                            textoModalObs:
                                                              naoConformidade.obsOriginal,
                                                          },
                                                          () => {
                                                            this.toggleModalObs();
                                                          }
                                                        );
                                                      }}
                                                    />
                                                    <UncontrolledTooltip
                                                      placement="right"
                                                      target={`item-naoConformidade-${naoConformidade.idTemp}`}
                                                    >
                                                      Visualizar Obs completa
                                                    </UncontrolledTooltip>
                                                  </React.Fragment>
                                                )}
                                              </td>
                                              <td className="text-center">
                                                {naoConformidade.foto !==
                                                  "" ? (
                                                  <i
                                                    className="fa fa-search"
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={() => {
                                                      this.handleClickVisualizarNaoConformidade(
                                                        naoConformidade
                                                      );
                                                    }}
                                                  ></i>
                                                ) : (
                                                  <span>-</span>
                                                )}
                                              </td>
                                            </tr>
                                          )
                                        )}
                                      </tbody>
                                    )}
                                    {this.state.visita.naoConformidades
                                      .length === 0 && (
                                      <tbody>
                                        <tr>
                                          <td
                                            colSpan="3"
                                            className="text-center font-size-14"
                                          >
                                            <div>
                                              Não possuí não-conformidades
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    )}
                                  </table>
                                </div>
                              </div>

                              <div className="col-5">
                                <div
                                  className="float-left w-100 font-size-16"
                                  // style={{ paddingLeft: "12px" }}
                                >
                                  {" "}
                                  COBRANÇA{" "}
                                </div>

                                <div className="table-responsive font-size-14">
                                  <table
                                    id="recent-purchases-listing"
                                    className="table"
                                  >
                                    <thead className="thead-dark">
                                      <tr>
                                        <th>Forma</th>
                                        <th className="text-right">Valor</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td>Dinheiro</td>
                                        <td className="text-right">
                                          R${" "}
                                          {formatarReal(
                                            this.state.visita.cobranca
                                              .recebidoDin
                                          )}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Cheque</td>
                                        <td className="text-right">
                                          R${" "}
                                          {formatarReal(
                                            this.state.visita.cobranca
                                              .recebidoChe
                                          )}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Crédito / Débito</td>
                                        <td className="text-right">
                                          R${" "}
                                          {formatarReal(
                                            this.state.visita.cobranca
                                              .recebidoCar
                                          )}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td
                                          colSpan="2"
                                          className="text-right"
                                          style={{ fontWeight: "bold" }}
                                        >
                                          Total: R${" "}
                                          {formatarReal(
                                            this.state.visita.cobranca
                                              .valorCobrar
                                          )}
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </React.Fragment>
                        </div>
                      </div>
                    </div>
                  )}

                  {this.state.visita !== null &&
                    this.state.visita.status === "realizada" && (
                      <div className="col-md-3 stretch-card">
                        <div className="card">
                          <div className="card-body3">
                            <React.Fragment>
                              <div
                                className="float-left width-100 text-center font-size-16"
                                style={{
                                  color: "",
                                  padding: "10px 0",
                                  fontWeight: "bold",
                                }}
                              >
                                Canhoto
                              </div>

                              <div className="text-center float-left w-100">
                                {/* <img
                                  src="images/canhoto.png"
                                  alt="canhoto"
                                  width="100%"
                                /> */}

                                <img
                                  src={this.state.visita.canhoto.foto}
                                  alt="canhoto"
                                  width="100%"
                                />
                              </div>

                              <div
                                className="float-left w-100"
                                style={{ marginTop: "20px" }}
                              >
                                <div
                                  className="float-left width-100 text-center font-size-16"
                                  style={{
                                    color: "",
                                    padding: "10px 0",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Assinatura
                                </div>

                                <div className="text-center float-left w-100">
                                  <img
                                    src={this.state.visita.recebedor.assinatura}
                                    alt="canhoto"
                                    width="100%"
                                  />
                                </div>

                                <div
                                  className="text-center float-left w-100"
                                  style={{ paddingTop: "20px" }}
                                >
                                  <div style={{ fontWeight: "bold" }}>
                                    {" "}
                                    Recebedor
                                  </div>
                                  <div style={{ fontSize: "14px" }}>
                                    {" "}
                                    {this.state.visita.recebedor.nome}
                                  </div>
                                  <div style={{ fontSize: "14px" }}>
                                    {" "}
                                    {this.state.visita.recebedor.cpf}
                                  </div>
                                </div>
                              </div>
                            </React.Fragment>
                          </div>
                        </div>
                      </div>
                    )}
                </div>

                <div className="row">
                  <div className="col-md-9 stretch-card">
                    {false && (
                      <div className="card">
                        <div className="card-body">
                          <React.Fragment>
                            <div
                              className="float-left w-100"
                              style={{
                                padding: "5px",
                                marginBottom: "20px",
                                position: "relative",
                              }}
                            >
                              DETALHAMENTO DA ORDEM
                              <div>
                                <button
                                  className="btn btn-success btn-app-blue"
                                  onClick={() => {
                                    this.props.history.push("/despesas-nova");
                                  }}
                                  style={{
                                    position: "absolute",
                                    right: 0,
                                    top: 0,
                                  }}
                                >
                                  Ver no mapa
                                </button>
                              </div>
                            </div>
                            <div className="table-responsive">
                              <table
                                id="recent-purchases-listing"
                                className="table"
                              >
                                <thead>
                                  <tr>
                                    <th>Seq</th>
                                    <th>Operação</th>
                                    <th>Situação</th>
                                    <th>Cliente</th>
                                    <th className="text-center">Canhoto</th>
                                    <th className="text-center">Assinatura</th>
                                    <th className="text-center">Documento</th>
                                    <th>Ações</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.roteiro.visitas.map(
                                    (visita, key) => (
                                      <tr key={key}>
                                        <td
                                          style={{
                                            fontWeight: "bold",
                                            width: "30px",
                                            maxWidth: "30px",
                                          }}
                                        >
                                          {visita.seq}
                                        </td>
                                        <td>
                                          <span
                                            style={{
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            {visita.operacao}
                                          </span>{" "}
                                        </td>
                                        <td
                                          className={`color-${visita.statusClass}`}
                                          style={{
                                            textTransform: "capitalize",
                                            width: "130px",
                                            minWidth: "130px",
                                          }}
                                        >
                                          {" "}
                                          <i className="fa fa-circle"> </i>{" "}
                                          {visita.status}
                                        </td>

                                        <td> {visita.cliente.desc}</td>
                                        <td
                                          className={`text-center`}
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          <i className="fa fa-search"></i>
                                        </td>
                                        <td
                                          className={`text-center`}
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          <i className="fa fa-search"></i>
                                        </td>
                                        <td
                                          className={`text-center`}
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          {visita.documentos[0].tipo}{" "}
                                          {visita.documentos[0].nro}
                                        </td>

                                        <td
                                          className="d-flex"
                                          style={{ fontSize: "18px" }}
                                        >
                                          <div
                                            style={{
                                              margin: "2px",
                                              cursor: "pointer",
                                            }}
                                          >
                                            <i
                                              className="fa fa-search"
                                              // onClick={() => {
                                              //   this.props.history.push(
                                              //     `/veiculos-novo?id=${veiculo._id}`
                                              //   );
                                              // }}
                                            ></i>
                                          </div>
                                        </td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </table>
                            </div>

                            <div className="width-100 float-left">
                              <button
                                className="btn btn-success btn-app-verde"
                                onClick={() => {
                                  this.props.history.push("/roteiros-novo");
                                }}
                                style={{
                                  float: "right",
                                  // position: "absolute",
                                  // right: 0,
                                  // top: "-15px",
                                }}
                              >
                                Novo roteiro
                              </button>
                            </div>
                            {/* 
                            <div className="paginacao">
                              <Pagination
                                prevPageText={
                                  <i className="fa fa-angle-left" />
                                }
                                nextPageText={
                                  <i className="fa fa-angle-right" />
                                }
                                firstPageText={
                                  <i className="fa fa-angle-double-left" />
                                }
                                lastPageText={
                                  <i className="fa fa-angle-double-right" />
                                }
                                activePage={this.state.paginacao.pagina}
                                itemsCountPerPage={this.state.paginacao.limite}
                                totalItemsCount={this.state.totalcomissoes}
                                onChange={(pagina) => this.paginar(pagina)}
                                itemClass="page-item"
                                linkClass="page-link"
                              />
                            </div> */}
                          </React.Fragment>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>

        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Deletar veículo</ModalHeader>
          <ModalBody>Tem certeza que deseja deletar este veículo?</ModalBody>
          <ModalFooter>
            <Button
              className="btn-modal-recuperar"
              color="secondary"
              onClick={this.toggle}
            >
              Não
            </Button>
            <Button
              className="btn-modal-recuperar"
              color="primary"
              onClick={this.handleClickConfirmaDeletar}
            >
              Sim
            </Button>{" "}
          </ModalFooter>
        </Modal>

        <Modal isOpen={this.state.modalObs} toggle={this.toggleModalObs}>
          <ModalBody>{this.state.textoModalObs}</ModalBody>
          <ModalFooter>
            <Button
              className="btn-modal-recuperar"
              color="secondary"
              onClick={this.toggleModalObs}
            >
              Fechar
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.modalNaoConformidade}
          toggle={this.toggleModalNaoConformidade}
        >
          <ModalBody>
            <div className="text-center float-left w-100">
              {/* <img
                                  src="images/canhoto.png"
                                  alt="canhoto"
                                  width="100%"
                                /> */}

              {this.state.visita !== null && (
                <img
                  src={this.state.fotoNaoConformidade}
                  alt="canhoto"
                  width="100%"
                />
              )}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              className="btn-modal-recuperar"
              color="secondary"
              onClick={this.toggleModalNaoConformidade}
            >
              Fechar
            </Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default RoteiroDetalheVisita;
