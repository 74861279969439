import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import { SideBar, NavBar, TitleAndDescription, Footer } from "../../components";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Switch from "react-switch";
import axios from "axios";
import { truncateWithEllipsis } from "@amcharts/amcharts4/.internal/core/utils/Utils";
import Select from "react-select";
import queryString from "query-string";
const { v4: uuidv4 } = require("uuid");
const { phoneMask } = require("../../utils");

export default class NotificacaoNova extends Component {
  constructor(props) {
    super(props);
    this.notificar = this.notificar.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
    this.loadUsuarios = this.loadUsuarios.bind(this);
    this.cadastrarNotificacao = this.cadastrarNotificacao.bind(this);
    this.validarNotificacao = this.validarNotificacao.bind(this);
    this.buscarNotificacao = this.buscarNotificacao.bind(this);

    this.state = {
      _id: null,
      titulo: "",
      desc: "",
      usuariosSelecionados: null,
      usuarios: [],
      errors: {
        titulo: false,
        desc: false,
        usuarios: false,
      },
    };
  }

  componentDidMount() {
    this.loadUsuarios();
    let id = queryString.parse(this.props.location.search).id;

    if(id !== undefined){
      this.buscarNotificacao(id);
    }

  }

  async loadUsuarios() {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/retaguarda/usuarios`,
        this.props.parameters()
      )
      .then((response) => {
        const data = response.data;

        let usuarios = data.usuarios;

        for (let usuario of usuarios) {
          usuario.label = usuario.desc;
          usuario.value = usuario._id;
        }

        this.setState({ usuarios });
      });
  }

  async buscarNotificacao(_id) {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/retaguarda/notificacoes/${_id}`,
        this.props.parameters()
      )
      .then((response) => {
        const notificacao = response.data.notificacao;

        for (let usuario of notificacao.usuarios) {
          usuario.label = usuario.desc;
          usuario.value = usuario._id;
        }

        this.setState({
          _id: notificacao._id,
          titulo: notificacao.titulo,
          desc: notificacao.desc,
          usuariosSelecionados: notificacao.usuarios
        })
      });
  }

  notificar(type, message) {
    toast[type](message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  onChangeInput(tipo, value) {
    this.setState({ [tipo]: value });
  }

  validarNotificacao() {
    let errors = this.state.errors;
    let valido = true;

    if (this.state.titulo === "") {
      valido = false;
      errors.titulo = true;
    } else {
      errors.titulo = false;
    }

    if (this.state.desc === "") {
      valido = false;
      errors.desc = true;
    } else {
      errors.desc = false;
    }

    if (this.state.usuariosSelecionados === null) {
      valido = false;
      errors.usuarios = true;
    } else {
      errors.usuarios = false;
    }

    this.setState({ errors });
    return valido;
  }

  async cadastrarNotificacao() {
    if (!this.validarNotificacao()) {
      this.notificar("warning", "Favor preencher todos os dados!");
      return false;
    }

    // Criar uma cópia profunda dos objetos dentro do array
    let usuarios = this.state.usuariosSelecionados.map((it) => {
      return { ...it };
    });

    usuarios.forEach((it) => {
      delete it.label;
      delete it.value;
    });

    let notificacao = {
      titulo: this.state.titulo,
      desc: this.state.desc,
      usuarios,
    };

    await axios
      .post(
        `${this.props.getBaseUrlApi()}/retaguarda/notificacoes`,
        {notificacao},
        this.props.parameters()
      )
      .then((response) => {
        this.notificar("success", "Notificação cadastrada com sucesso!");
        setTimeout(() => {
          return this.props.history.push('/notificacoes');
        }, 1500);
        
      });
  }

  render() {
    const InputRequired = () => {
      return <span className="required-icone">*</span>;
    };
    return (
      <React.Fragment>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
        <div className="container-scroller font-app">
          <NavBar title="Notificações / Nova" />
          <div className="container-fluid page-body-wrapper">
            <SideBar itemActive={"notificacoes"} />
            <div className="main-panel">
              <div className="content-wrapper">
                <div className="row">
                  <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body5 dashboard-tabs p-0">
                        <div className="tab-content py-0 px-0">
                          <div
                            className="tab-pane fade show active"
                            id="overview"
                            role="tabpanel"
                            aria-labelledby="overview-tab"
                          >
                            <div className="d-flex flex-wrap justify-content-xl-between">
                              <div
                                style={{
                                  padding: "10px",
                                  float: "left",
                                  width: "100%",
                                }}
                              >
                                <div className="row">
                                  <div className="col-md-2 pd-veiculo2">
                                    <div className="form-group form-group-chegoo">
                                      <label>
                                        Título
                                        <InputRequired />
                                      </label>
                                      <input
                                        type="text"
                                        value={this.state.titulo}
                                        label="Nome do usuário"
                                        className={`form-control font-size-14 form-control-chegoo required-chegoo`}
                                        onChange={(e) =>
                                          this.onChangeInput(
                                            "titulo",
                                            e.target.value
                                          )
                                        }
                                        ref={(node) => {
                                          if (node) {
                                            if (this.state.errors.titulo) {
                                              node.style.setProperty(
                                                "border-color",
                                                "red",
                                                "important"
                                              );
                                            } else {
                                              node.style.setProperty(
                                                "border-color",
                                                "#ced4da",
                                                "important"
                                              );
                                            }
                                          }
                                        }}
                                        disabled={this.state._id !== null}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-md-5 pd-veiculo">
                                    <div className="form-group form-group-chegoo">
                                      <label>
                                        Descrição
                                        <InputRequired />
                                      </label>
                                      <input
                                        type="text"
                                        value={this.state.desc}
                                        label="email@email.com.br"
                                        className={`form-control font-size-14 form-control-chegoo required-chegoo`}
                                        onChange={(e) =>
                                          this.onChangeInput(
                                            "desc",
                                            e.target.value
                                          )
                                        }
                                        ref={(node) => {
                                          if (node) {
                                            if (this.state.errors.desc) {
                                              node.style.setProperty(
                                                "border-color",
                                                "red",
                                                "important"
                                              );
                                            } else {
                                              node.style.setProperty(
                                                "border-color",
                                                "#ced4da",
                                                "important"
                                              );
                                            }
                                          }
                                        }}
                                        disabled={this.state._id !== null}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-md-4 pd-veiculo3">
                                    <div className="form-group form-group-chegoo">
                                      <label>
                                        Usuários
                                        <InputRequired />
                                      </label>
                                      <Select
                                        value={this.state.usuariosSelecionados}
                                        onChange={(value) =>
                                          this.onChangeInput(
                                            "usuariosSelecionados",
                                            value
                                          )
                                        }
                                        options={this.state.usuarios}
                                        placeholder="Selecione"
                                        className={`${
                                          this.state.errors.usuarios
                                            ? "select-error"
                                            : ""
                                        }`}
                                        styles={{
                                          control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            fontSize: "14px",
                                            // height: "35px",
                                            // minHeight: "35px",
                                            backgroundColor: "#fffef6",
                                          }),
                                          menu: (baseStyles, state) => ({
                                            ...baseStyles,
                                            fontSize: "14px",
                                          }),
                                        }}
                                        isMulti
                                        isDisabled={this.state._id !== null}
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-md-2 pd-veiculo2">
                                    <div className="form-group form-group-chegoo2">
                                      <button
                                        className="btn btn-success btn-app-verde"
                                        onClick={this.cadastrarNotificacao}
                                        disabled={this.state._id !== null}
                                      >
                                        Salvar
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
