import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import { SideBar, NavBar, TitleAndDescription, Footer } from "../../components";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Switch from "react-switch";
import axios from "axios";
import { truncateWithEllipsis } from "@amcharts/amcharts4/.internal/core/utils/Utils";
import queryString from "query-string";
import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from "reactstrap";
import Select from "react-select";
import { CurrencyInput } from "../../components";
const { v4: uuidv4 } = require("uuid");
const { phoneMask } = require("../../utils");

export default class RoteiroNovo extends Component {
  constructor(props) {
    super(props);
    this.notificar = this.notificar.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
    this.onChangeSwitch = this.onChangeSwitch.bind(this);
    this.load = this.load.bind(this);
    this.onChangeCargo = this.onChangeCargo.bind(this);
    this.cadastrarUsuario = this.cadastrarUsuario.bind(this);
    this.validarUsuario = this.validarUsuario.bind(this);
    this.buscarUsuario = this.buscarUsuario.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleClickDeletar = this.handleClickDeletar.bind(this);
    this.handleClickConfirmaDeletar =
      this.handleClickConfirmaDeletar.bind(this);
    this.getDefaultRoteiro = this.getDefaultRoteiro.bind(this);
    this.onChangeInputTerceiro = this.onChangeInputTerceiro.bind(this);

    this.state = {
      roteiro: this.getDefaultRoteiro(),
      motoristas: [],
      terceiros: [],
      terceiro: null,

      errors: {
        cod: false,
        desc: false,
        motorista: false,
        terceiro: false,

        usuario: false,
        email: false,
        celular: false,
        cargo: false,
        login: false,
        senha: false,
        senhaRepetir: false,
      },

      _id: null,
      desc: "",
      email: "",
      celular: "",
      cargo: null,
      acessoApp: false,
      acessoAdm: false,
      login: "",
      senha: "",
      senhaRepetir: "",
      cargos: [],

      usuarioDeletar: null,
      modal: false,
    };
  }

  async componentDidMount() {
    let id = queryString.parse(this.props.location.search).id;

    if (id !== undefined) {
      await this.buscarUsuario(id);
    }

    await this.load();
  }

  async load() {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/retaguarda/usuarios`,
        this.props.parameters()
      )
      .then((response) => {
        let motoristas = response.data.usuarios;

        motoristas = motoristas.map((motorista) => {
          motorista.label = motorista.desc;
          motorista.value = motorista._id;
          return motorista;
        });

        this.setState({ motoristas });
      });
  }

  getDefaultRoteiro() {
    return {
      tipo: "sequencial",
      cod: "",
      desc: "",
      status: "pendente",
      visitas: [],
      motorista: null,
      dtPrevisao: new Date(),
      visitas: [
        {
          seq: 1,
          operacao: "entregar",
          status: "realizada",
          dtEntrega: "2021-12-20T10:00:00.000Z",
          obsEntrega: "",
          duracao: "",
          cliente: {
            _id: "1052",
            desc: "Mercado Bertoli",
            cnpj: "45.997.418/0001-53",
            obs: "",
          },
          endereco: {
            end: "Antônio Machado",
            num: "60",
            compl: "Ao lado da academia TFit",
            bairro: "Nereu Ramos",
            cidade: "Jaraguá do Sul",
            uf: "SC",
            cep: "89265-560",
          },
          documentos: [
            {
              nro: "123",
              tipo: "nfe",
              valor: 1500,
              dtEmissao: "2021-07-06T00:00:00.000Z",
              volumes: 8,
              obs: "entregar pref. a tardde. 4 caixas e 2 volumes únicos.",
              danfe: "http://www.google.com",
            },
          ],
          cobranca: {
            cobrar: false,
            valorCobrar: 100,
            recebidoDin: 100,
            recebidoChe: 0,
            recebidoCar: 0,
          },
          recebedor: {
            nome: "",
            cpf: "",
            assinatura:"",
            coordenadas: "",
          },
          canhoto: { foto: "null", valido: false },
          ocorrencias: [],
          volumes: [],
          naoConformidades: [],
        },
      ],
    };
  }

  onChangeInputTerceiro(value) {
    this.setState({ terceiro: value });
  }

  async buscarUsuario(id) {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/retaguarda/usuarios/${id}`,
        this.props.parameters()
      )
      .then((response) => {
        const usuario = response.data.usuario;

        let cargos = this.state.cargos;

        this.setState({
          _id: usuario._id,
          desc: usuario.desc,
          email: usuario.email,
          celular: usuario.celular,
          cargo: usuario.cargo._id,
          acessoApp: usuario.acessoApp,
          acessoAdm: usuario.acessoAdm,
          login: usuario.login,
        });

        ////this.setState({ cargos: data.cargos });
      });
  }

  notificar(type, message) {
    toast[type](message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  onChangeInput(tipo, value) {
    let roteiro = this.state.roteiro;
    roteiro[tipo] = value;

    this.setState({ roteiro });
  }

  onChangeSwitch(checked, seq) {

    let roteiro = this.state.roteiro;

    for(let visita of roteiro.visitas){
      if(visita.seq === seq){
        visita.cobranca.cobrar = checked;
      }
    }

    this.setState({ roteiro });
  }

  onChangeCargo(value) {
    this.setState({ cargo: value });
  }

  validarUsuario() {
    let errors = this.state.errors;
    let valido = true;

    if (this.state.desc === "") {
      valido = false;
      errors.desc = true;
    } else {
      errors.desc = false;
    }

    if (this.state.email === "") {
      valido = false;
      errors.email = true;
    } else {
      errors.email = false;
    }

    if (this.state.celular === "") {
      valido = false;
      errors.celular = true;
    } else {
      errors.celular = false;
    }

    if (this.state.cargo === null || this.state.cargo === 0) {
      valido = false;
      errors.cargo = true;
    } else {
      errors.cargo = false;
    }

    if (this.state.login === "") {
      valido = false;
      errors.login = true;
    } else {
      errors.login = false;
    }

    if (this.state.senha === "") {
      valido = false;
      errors.senha = true;
    } else {
      errors.senha = false;
    }

    if (this.state.senhaRepetir === "") {
      valido = false;
      errors.senhaRepetir = true;
    } else {
      errors.senhaRepetir = false;
    }

    this.setState({ errors });
    return valido;
  }

  async cadastrarUsuario() {
    if (!this.validarUsuario()) {
      this.notificar("warning", "Favor preencher todos os dados!");
      return false;
    }

    if (this.state.senha !== this.state.senhaRepetir) {
      this.notificar("warning", "As senhas não conferem");
      return false;
    }

    let acaoPagina = this.state._id === null ? "cadastrar" : "editar";

    let usuarioNovo = {
      desc: this.state.desc,
      email: this.state.email,
      celular: this.state.celular,
      cargo: this.state.cargo,
      acessoApp: this.state.acessoApp,
      acessoAdm: this.state.acessoAdm,
      login: this.state.login,
      senha: this.state.senha,
    };

    if (acaoPagina === "editar") {
      usuarioNovo._id = this.state._id;
    }

    let cargos = this.state.cargos;
    let cargoFind = cargos.find((cargo) => cargo._id === usuarioNovo.cargo);

    usuarioNovo.cargo = {
      _id: cargoFind._id,
      desc: cargoFind.desc,
    };

    let urlAtualizar = `${this.props.getBaseUrlApi()}/retaguarda/usuarios`;

    if (this.state._id !== null) {
      urlAtualizar = `${this.props.getBaseUrlApi()}/retaguarda/usuarios/${
        this.state._id
      }`;
    }

    await axios
      .post(urlAtualizar, usuarioNovo, this.props.parameters())
      .then((response) => {
        this.setState(
          {
            desc: "",
            email: "",
            celular: "",
            cargo: 0,
            acessoApp: false,
            acessoAdm: false,
            login: "",
            senha: "",
            senhaRepetir: "",
            _id: null,
          },
          () => {
            if (acaoPagina === "cadastrar") {
              this.notificar("success", "Usuário cadastrado com sucesso!");
            } else {
              this.notificar("success", "Usuário editado com sucesso!");
              setTimeout(() => {
                this.props.history.push("/usuarios");
              }, 1000);
            }
          }
        );
      })
      .catch((error) => {
        this.notificar("warning", error.response.data.erro);
      });
  }

  handleClickDeletar(id) {
    this.setState({ usuarioDeletar: id, modal: true });
  }

  toggle() {
    this.setState({ modal: !this.state.modal });
  }

  async handleClickConfirmaDeletar() {
    await axios
      .delete(
        `${this.props.getBaseUrlApi()}/retaguarda/usuarios/${
          this.state.usuarioDeletar
        }`,
        this.props.parameters()
      )
      .then((response) => {
        this.setState({ usuarioDeletar: null, modal: false }, () => {
          this.notificar("success", "Usuário deletado com sucesso!");
          setTimeout(() => {
            this.props.history.push("/usuarios");
          }, 1000);
        });
      })
      .catch((error) => {
        this.setState({ usuarioDeletar: null, modal: false }, () => {
          this.notificar("warning", error.response.data.erro);
        });
      });
  }

  render() {
    return (
      <React.Fragment>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
        <div className="container-scroller font-app">
          <NavBar title="Ordens de visita / Novo" />
          <div className="container-fluid page-body-wrapper">
            <SideBar itemActive={"roteiros"} />
            <div className="main-panel">
              <div className="content-wrapper">
                {/* <TitleAndDescription
                  title="Ordens de visita / Novo"
                  description=""
                /> */}

                <div className="row">
                  <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body dashboard-tabs p-0">
                        <div className="tab-content py-0 px-0">
                          <div
                            className="tab-pane fade show active"
                            id="overview"
                            role="tabpanel"
                            aria-labelledby="overview-tab"
                          >
                            <div className="d-flex flex-wrap justify-content-xl-between">
                              <div
                                style={{
                                  padding: "15px",
                                  float: "left",
                                  width: "100%",
                                }}
                              >
                                <div
                                  className="float-left width-100"
                                  style={{
                                    borderBottom: "1px solid #CCC",
                                    padding: "5px",
                                    marginBottom: "20px",
                                  }}
                                >
                                  NOVO ROTEIRO
                                </div>
                                <div className="row">
                                  <div className="col-md-2">
                                    <div className="form-group">
                                      <label>Código</label>
                                      <input
                                        type="text"
                                        value={this.state.roteiro.cod}
                                        label="EX: A001"
                                        className={`form-control ${
                                          this.state.errors.cod
                                            ? "input-error"
                                            : ""
                                        }`}
                                        onChange={(e) =>
                                          this.onChangeInput(
                                            "cod",
                                            e.target.value
                                          )
                                        }
                                      />
                                      {this.state.errors.cod && (
                                        <small className="small-error">
                                          Favor preencher o código.
                                        </small>
                                      )}
                                    </div>
                                  </div>

                                  <div className="col-md-4">
                                    <div className="form-group">
                                      <label>Título</label>
                                      <input
                                        type="text"
                                        value={this.state.roteiro.desc}
                                        label="Ex: Roteiro sul do estado"
                                        className={`form-control ${
                                          this.state.errors.desc
                                            ? "input-error"
                                            : ""
                                        }`}
                                        onChange={(e) =>
                                          this.onChangeInput(
                                            "desc",
                                            e.target.value
                                          )
                                        }
                                      />
                                      {this.state.errors.desc && (
                                        <small className="small-error">
                                          Favor preencher o título.
                                        </small>
                                      )}
                                    </div>
                                  </div>

                                  <div className="col-md-3">
                                    <div className="form-group">
                                      <label>Motorista</label>
                                      <Select
                                        value={this.state.roteiro.motorista}
                                        onChange={(value) =>
                                          this.onChangeInput("motorista", value)
                                        }
                                        options={this.state.motoristas}
                                        placeholder="Selecione o motorista"
                                        className={`${
                                          this.state.errors.motorista
                                            ? "select-error"
                                            : ""
                                        }`}
                                      />
                                      {this.state.errors.motorista && (
                                        <small className="small-error">
                                          Favor selecionar o motorista.
                                        </small>
                                      )}
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="form-group">
                                      <label>Entrega em</label>
                                      <DatePicker
                                        selected={this.state.roteiro.dtPrevisao}
                                        customInput={
                                          <input className="form-control" />
                                        }
                                        dateFormat="dd/MM/yyyy"
                                        onChange={(date) => {
                                          this.onChangeInput(
                                            "dtPrevisao",
                                            date
                                          );
                                        }}
                                        locale="pt-BR"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-2">
                                    <div className="form-group">
                                      <label>Entrega completa ?</label>
                                      <select
                                        className={`form-control`}
                                        onChange={(e) =>
                                          this.onChangeInput(
                                            "tipo",
                                            e.target.value
                                          )
                                        }
                                        value={this.state.roteiro.tipo}
                                      >
                                        <option value={"sequencial"}>
                                          Não
                                        </option>
                                        <option value={"completa"}>Sim</option>
                                      </select>
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="form-group">
                                      <label>
                                        Terceiro que irá receber a entrega
                                        completa
                                      </label>
                                      <Select
                                        value={this.state.terceiro}
                                        onChange={(value) =>
                                          this.onChangeInputTerceiro(value)
                                        }
                                        options={this.state.terceiros}
                                        placeholder="Selecione o terceiro"
                                        className={`${
                                          this.state.errors.terceiro
                                            ? "select-error"
                                            : ""
                                        }`}
                                        isDisabled={
                                          this.state.roteiro.tipo ===
                                          "sequencial"
                                            ? true
                                            : false
                                        }
                                      />
                                    </div>
                                  </div>

                                  <div
                                    className="col-md-6 d-flex"
                                    style={{ alignItems: "center" }}
                                  >
                                    <div className="form-group">
                                      <button
                                        className="btn btn-success btn-app-azul"
                                        onClick={this.cadastrarUsuario}
                                      >
                                        Importar XMLs
                                      </button>

                                      {/* <div className="item-switch"> */}
                                      {/* <Switch
                                              checked={this.state.acessoApp}
                                              onChange={(checked) =>
                                                this.onChangeSwitch(
                                                  checked,
                                                  "acessoApp"
                                                )
                                              }
                                              onColor="#f0e8fc"
                                              onHandleColor="#2da951"
                                              handleDiameter={25}
                                              uncheckedIcon={false}
                                              checkedIcon={false}
                                              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                              height={16}
                                              width={48}
                                            /> */}
                                      {/* </div> */}
                                    </div>
                                  </div>
                                </div>

                                <div className="table-responsive">
                                  <table
                                    id="recent-purchases-listing"
                                    className="table"
                                  >
                                    <thead>
                                      <tr>
                                        <th>Seq.</th>
                                        <th>Operação</th>
                                        <th>Cliente</th>
                                        <th>Documento</th>
                                        <th className="text-center">Volumes</th>
                                        <th className="text-right">Valor cobrança</th>
                                        <th>Cobrar ?</th>
                                        <th className="text-center">DANFE</th>
                                        <th>Ações</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {this.state.roteiro.visitas.map(
                                        (visita, key) => (
                                          <tr key={key}>
                                            <td>{visita.seq}</td>
                                            <td
                                              style={{
                                                textTransform: "capitalize",
                                              }}
                                            >
                                              {" "}
                                              {visita.operacao}
                                            </td>
                                            <td> {visita.cliente.desc}</td>
                                            <td>
                                              {" "}
                                              <span
                                                style={{
                                                  textTransform: "capitalize",
                                                }}
                                              >
                                                {visita.documentos[0].tipo}
                                              </span>{" "}
                                              {visita.documentos[0].nro}
                                            </td>
                                            <td className="text-center">
                                              {" "}
                                              {visita.documentos[0].volumes}
                                            </td>
                                            <td className="text-right">
                                            <CurrencyInput
                      placeholder="R$0,00"
                      type="text"
                      value={this.state.valor}
                      onChange={(e) => {
                        this.handleInputChange(e, "valor");
                      }}
                      onFocus={this.handleFocus}
                      disabled={!visita.cobranca.cobrar}
                    />
                                            </td>
                                            <td>
                                              {" "}
                                              <Switch
                                                checked={visita.cobranca.cobrar}
                                                onChange={(checked) =>
                                                  this.onChangeSwitch(
                                                    checked,
                                                    visita.seq
                                                  )
                                                }
                                                onColor="#f0e8fc"
                                                onHandleColor="#2da951"
                                                handleDiameter={22}
                                                uncheckedIcon={false}
                                                checkedIcon={false}
                                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                                height={14}
                                              width={48}
                                              // height={16}
                                              // width={48}
                                              />{" "}
                                            </td>
                                            <td className="text-center">
                                              {" "}
                                              <i className="fa fa-search" />{" "}
                                            </td>
                                            <td style={{ fontSize: "20px" }}>
                                              <i class="fa fa-chevron-up"></i>
                                              <i class="fa fa-chevron-down"></i>
                                              <i class="fa fa-trash"></i>
                                            </td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </table>
                                </div>

                                {/* <div className="row">
                                  <div className="col-md-8"></div>
                                  <div className="col-md-2 no-padding text-right">
                                    <div className="form-group">
                                      <button
                                        className="btn btn-danger btn-app-vermelho"
                                        onClick={() => {
                                          this.handleClickDeletar(
                                            this.state._id
                                          );
                                        }}
                                        disabled={
                                          this.state._id === null ? true : false
                                        }
                                      >
                                        Deletar
                                      </button>
                                    </div>
                                  </div>{" "}
                                  <div className="col-md-2 no-padding text-right">
                                    <div className="form-group">
                                      <button
                                        className="btn btn-success btn-app-verde"
                                        onClick={this.cadastrarUsuario}
                                      >
                                        Salvar
                                      </button>
                                    </div>
                                  </div>
                                </div> */}

                                {/* felipe */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Deletar usuário</ModalHeader>
          <ModalBody>Tem certeza que deseja deletar este usuário?</ModalBody>
          <ModalFooter>
            <Button
              className="btn-modal-recuperar"
              color="secondary"
              onClick={this.toggle}
            >
              Não
            </Button>
            <Button
              className="btn-modal-recuperar"
              color="primary"
              onClick={this.handleClickConfirmaDeletar}
            >
              Sim
            </Button>{" "}
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}
