import React, { Component } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { SideBar, NavBar, Footer, TitleAndDescription } from "../../components";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import Select from "react-select";
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from "reactstrap";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_dataviz from "@amcharts/amcharts4/themes/dataviz";
import queryString from "query-string";
import { UncontrolledTooltip } from "reactstrap";
const { formatarReal } = require("../../utils");
am4core.useTheme(am4themes_dataviz);
am4core.useTheme(am4themes_animated);

class Roteiro extends Component {
  constructor(props) {
    super(props);
    this.load = this.load.bind(this);
    this.loadRoteiros = this.loadRoteiros.bind(this);
    this.iniciaGraficoSaldo = this.iniciaGraficoSaldo.bind(this);
    this.finalizarRoteiro = this.finalizarRoteiro.bind(this);

    this.state = {
      roteiros: [],
      totalizadores: {
        totalVisitas: 0,
        pendentes: 0,
        realizados: 0,
        divergencias: 0,
        ocorrencias: 0,
        canceladas: 0,
      },
    };
  }

  async componentDidMount() {
    await this.load();
  }

  async load() {
    await this.loadRoteiros();
  }

  async loadRoteiros() {
    let cliente = queryString.parse(this.props.location.search).cliente;
    let motorista = queryString.parse(this.props.location.search).motorista;

    let url = `${this.props.getBaseUrlApi()}/retaguarda/roteiros`;

    if (cliente !== undefined) {
      url += `?cliente=${cliente}`;
    }

    if (motorista !== undefined) {
      url += `?motorista=${motorista}`;
    }

    await axios.get(url, this.props.parameters()).then((response) => {
      let roteiros = response.data.roteiros;

      this.setState(
        { roteiros, totalizadores: response.data.totalizadores },
        async () => {
          await this.iniciaGraficoSaldo(
            response.data.totalizadores.realizados,
            response.data.totalizadores.pendentes
          );
        }
      );
    });
  }

  notificar(type, message) {
    toast[type](message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  iniciaGraficoSaldo(value1 = 10, value2 = 90) {
    var chart = am4core.create("chartdiv", am4charts.PieChart);
    chart.hiddenState.properties.opacity = 0;

    chart.data = [
      {
        tipo: "Realizadas",
        value: value1,
        color: am4core.color("#2da951"),
      },
      {
        tipo: "Pendentes",
        value: value2,
        color: am4core.color("gainsboro"),
      },
    ];
    chart.radius = am4core.percent(60);
    chart.innerRadius = am4core.percent(40);
    chart.startAngle = 180;
    chart.endAngle = 360;
    var series = chart.series.push(new am4charts.PieSeries());
    series.dataFields.value = "value";
    series.dataFields.category = "tipo";
    series.slices.template.cornerRadius = 10;
    series.slices.template.innerCornerRadius = 7;
    series.slices.template.draggable = false;
    series.slices.template.inert = true;
    series.slices.template.propertyFields.fill = "color";
    series.alignLabels = false;
    series.hiddenState.properties.startAngle = 90;
    series.hiddenState.properties.endAngle = 90;
    series.labels.template.disabled = true;
    this.chart = chart;
  }

  async finalizarRoteiro(idRoteiro) {
    await axios
      .post(
        `${this.props.getBaseUrlApi()}/retaguarda/roteiros-finalizar/${idRoteiro}`,
        {},
        this.props.parameters()
      )
      .then((response) => {
        this.notificar("success", "Roteiro finalizado com sucesso!");
        this.load();
      });
  }

  render() {
    return (
      <React.Fragment>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />

        <div className="container-scroller font-app">
          <NavBar title="Ordens de visita" />
          <div className="container-fluid page-body-wrapper">
            <SideBar itemActive={"roteiros"} />
            <div className="main-panel">
              <div className="content-wrapper">
                {/* <TitleAndDescription title="Ordens de visita" description="" /> */}

                <div className="row" style={{ paddingBottom: "0.8rem" }}>
                  <div className="col-md-12 stretch-card">
                    {true && (
                      <div className="card">
                        <div className="card-body4" style={{position:"relative"}}>
                          <React.Fragment>
                            <div
                              className="float-left"
                              style={{
                                padding: "5px",
                                position: "absolute",
                                top: "-17px"
                              }}
                            >
                              RESUMO DIÁRIO
                            </div>

                            <div
                              className="row"
                              style={{ alignItems: "center" }}
                            >
                              <div className="col-5">
                                <div className="row text-center">
                                  <div
                                    className="col-4 no-padding"
                                    style={{ borderRight: "1px solid #CCC" }}
                                  >
                                    Visitas{" "}
                                    <div
                                      style={{
                                        fontSize: "20px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {this.state.totalizadores.totalVisitas}
                                    </div>
                                  </div>{" "}
                                  <div
                                    className="col-4 no-padding"
                                    style={{ borderRight: "1px solid #CCC" }}
                                  >
                                    Pendentes{" "}
                                    <div
                                      style={{
                                        fontSize: "20px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {this.state.totalizadores.pendentes}
                                    </div>
                                  </div>{" "}
                                  <div
                                    className="col-4 no-padding"
                                    style={{ borderRight: "1px solid #CCC" }}
                                  >
                                    Realizadas{" "}
                                    <div
                                      style={{
                                        fontSize: "20px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {this.state.totalizadores.realizados}
                                    </div>
                                  </div>{" "}
                                </div>
                              </div>

                              <div className="col-2">
                                <div
                                  className="d-flex"
                                  style={{ height: "90px" }}
                                >
                                  <div
                                    id="chartdiv"
                                    style={{ width: "100%", display: "flex" }}
                                  ></div>
                                </div>
                              </div>

                              <div className="col-5">
                                <div className="row text-center">
                                  <div
                                    className="col-4 no-padding"
                                    style={{ borderLeft: "1px solid #CCC" }}
                                  >
                                    Canceladas
                                    <div
                                      style={{
                                        fontSize: "20px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {this.state.totalizadores.canceladas}
                                    </div>
                                  </div>{" "}
                                  <div
                                    className="col-4 no-padding"
                                    style={{ borderLeft: "1px solid #CCC" }}
                                  >
                                    Ocorrências
                                    <div
                                      style={{
                                        fontSize: "20px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {this.state.totalizadores.ocorrencias}
                                    </div>
                                  </div>{" "}
                                  <div
                                    className="col-4 no-padding"
                                    style={{ borderLeft: "1px solid #CCC" }}
                                  >
                                    Divergências
                                    <div
                                      style={{
                                        fontSize: "20px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {this.state.totalizadores.divergencias}
                                    </div>
                                  </div>{" "}
                                </div>
                              </div>

                              {/* 
                              <div className="col-12">
                                <div className="row text-center">
                                 
                                </div>
                              </div> */}
                            </div>
                          </React.Fragment>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12 stretch-card">
                    {this.state.roteiros.length > -1 && (
                      <div className="card">
                        <div className="card-body2">
                          <React.Fragment>
                            {/* <div
                              className="float-left width-100"
                              style={{
                                padding: "5px",
                                marginBottom: "20px",
                                position: "relative",
                              }}
                            >
                              ORDENS
                            </div> */}
                            <div className="table-responsive">
                              <table
                                id="recent-purchases-listing"
                                className="table"
                              >
                                <thead className="thead-dark">
                                  <tr>
                                    <th># Ordem</th>
                                    <th>Descrição</th>
                                    <th>Motorista</th>
                                    <th>Situação</th>
                                    <th className="text-center">
                                      Data Inicial
                                    </th>
                                    <th className="text-center">Data Final</th>
                                    {/* <th>Totalizadores</th> */}
                                    <th className="text-center">Pendentes</th>
                                    <th className="text-center">Realizados</th>
                                    {/*
                                    <th className="text-center">
                                      Divergências
                                    </th>
                                    <th className="text-center">Ocorrências</th> */}
                                    <th>Ações</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.roteiros.map((roteiro, key) => (
                                    <tr key={key}>
                                      <td style={{ fontWeight: "bold" }}>
                                        {roteiro.cod}
                                        <div
                                          style={{
                                            fontSize: "12px",
                                            color: "#808080",
                                          }}
                                        >
                                          {roteiro.dataDescritivo}
                                        </div>
                                      </td>
                                      <td>
                                        {roteiro.desc}
                                        {roteiro.tipo === "completa" && (
                                          <div
                                            style={{
                                              fontSize: "12px",
                                              color: "#808080",
                                            }}
                                          >
                                            Entrega completa
                                          </div>
                                        )}
                                      </td>
                                      <td> {roteiro.motorista.desc}</td>
                                      <td
                                        className={`color-${roteiro.status}`}
                                        style={{ textTransform: "capitalize" }}
                                      >
                                        {" "}
                                        <i className="fa fa-circle"> </i>{" "}
                                        {roteiro.status}{" "}
                                        {roteiro.status === "financeiro" && (
                                          <React.Fragment>
                                            {" "}
                                            <i
                                              className="fa fa-check"
                                              id={`roteiro-status-${roteiro._id}`}
                                              style={{ cursor: "pointer" }}
                                              onClick={() =>
                                                this.finalizarRoteiro(
                                                  roteiro._id
                                                )
                                              }
                                            />
                                            <UncontrolledTooltip
                                              placement="bottom"
                                              target={`roteiro-status-${roteiro._id}`}
                                              id={`roteiro-status-${roteiro._id}`}
                                            >
                                              Marcar como finalizado
                                            </UncontrolledTooltip>
                                          </React.Fragment>
                                        )}
                                      </td>
                                      <td className="text-center">
                                        {roteiro.dtInicio !== undefined ? (
                                          <span>
                                            {moment(roteiro.dtInicio).format(
                                              "DD/MM HH:mm"
                                            )}
                                          </span>
                                        ) : (
                                          <span> - </span>
                                        )}
                                      </td>
                                      <td className="text-center">
                                        {roteiro.dtFim !== undefined ? (
                                          <span>
                                            {moment(roteiro.dtFim).format(
                                              "DD/MM HH:mm"
                                            )}
                                          </span>
                                        ) : (
                                          <span> - </span>
                                        )}
                                      </td>
                                      {/* <td>
                                            Pendentes: {roteiro.totalizador.pendentes} <br/>
                                            Realizados: {roteiro.totalizador.realizados} <br/>
                                            Divergências: {roteiro.totalizador.divergencias} <br/>
                                            Ocorrências: {roteiro.totalizador.ocorrencias} <br/>
                                      </td> */}
                                      <td className="text-center">
                                        {" "}
                                        {roteiro.totalizador.pendentes}
                                      </td>
                                      <td className="text-center">
                                        {" "}
                                        {roteiro.totalizador.realizados}
                                      </td>
                                      {/*
                                      </td>
                                      <td className="text-center">
                                        {" "}
                                        {roteiro.totalizador.divergencias}
                                      </td>
                                      <td className="text-center">
                                        {" "}
                                        {roteiro.totalizador.ocorrencias}
                                      </td> */}
                                      <td
                                        className="d-flex"
                                        style={{ fontSize: "18px" }}
                                      >
                                        <div
                                          style={{
                                            margin: "2px",
                                            cursor: "pointer",
                                          }}
                                        >
                                          <i
                                            className="fa fa-search"
                                            onClick={() => {
                                              this.props.history.push(
                                                `/roteiros-detalhe?id=${roteiro._id}`
                                              );
                                            }}
                                          ></i>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>

                            {/* <div className="width-100" style={{padding: "5px 5px 15px 5px"}}>
                              <button
                                className="btn btn-success btn-app-verde"
                                onClick={() => {
                                  //this.props.history.push("/roteiros-novo");
                                }}
                                style={{
                                  // position: "absolute",
                                  // right: 0,
                                  // top: "-15px",
                                }}
                              >
                                Novo roteiro
                              </button>
                            </div> */}
                            {/* 
                            <div className="paginacao">
                              <Pagination
                                prevPageText={
                                  <i className="fa fa-angle-left" />
                                }
                                nextPageText={
                                  <i className="fa fa-angle-right" />
                                }
                                firstPageText={
                                  <i className="fa fa-angle-double-left" />
                                }
                                lastPageText={
                                  <i className="fa fa-angle-double-right" />
                                }
                                activePage={this.state.paginacao.pagina}
                                itemsCountPerPage={this.state.paginacao.limite}
                                totalItemsCount={this.state.totalcomissoes}
                                onChange={(pagina) => this.paginar(pagina)}
                                itemClass="page-item"
                                linkClass="page-link"
                              />
                            </div> */}
                          </React.Fragment>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>

        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Deletar veículo</ModalHeader>
          <ModalBody>Tem certeza que deseja deletar este veículo?</ModalBody>
          <ModalFooter>
            <Button
              className="btn-modal-recuperar"
              color="secondary"
              onClick={this.toggle}
            >
              Não
            </Button>
            <Button
              className="btn-modal-recuperar"
              color="primary"
              onClick={this.handleClickConfirmaDeletar}
            >
              Sim
            </Button>{" "}
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default Roteiro;
