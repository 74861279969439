import React, { Component } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { SideBar, NavBar, Footer, TitleAndDescription } from "../../components";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import Select from "react-select";
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from "reactstrap";
import { UncontrolledTooltip } from "reactstrap";
import DatePicker from "react-datepicker";
const { formatarReal } = require("../../utils");

class Notificacao extends Component {
  constructor(props) {
    super(props);
    this.load = this.load.bind(this);
    this.loadNotificacoes = this.loadNotificacoes.bind(this);
    this.handleChangeInputFiltro = this.handleChangeInputFiltro.bind(this);
    this.limparFiltros = this.limparFiltros.bind(this);

    this.state = {
      notificacoes: [],
      filtro: {
        dataInicial: moment().startOf("month").toDate(),
        dataFinal: moment().endOf("month").toDate(),
      },
    };
  }

  async componentDidMount() {
    await this.load();
  }

  async load() {
    await this.loadNotificacoes();
  }

  async loadNotificacoes() {
    await axios
      .post(
        `${this.props.getBaseUrlApi()}/retaguarda/buscar-notificacoes`,
        {
          dataInicial: this.state.filtro.dataInicial,
          dataFinal: this.state.filtro.dataFinal,
        },
        this.props.parameters()
      )
      .then((response) => {
        this.setState({ notificacoes: response.data.notificacoes });
      });
  }

  notificar(type, message) {
    toast[type](message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  handleChangeInputFiltro(value, tipo) {
    let filtro = this.state.filtro;
    filtro[tipo] = value;
    this.setState({ filtro });
  }

  limparFiltros(){
    let filtro = {
      dataInicial: moment().startOf('month').toDate(),
      dataFinal: moment().endOf('month').toDate(),
    }
    this.setState({filtro}, () => {
      this.loadNotificacoes()
    })
  }

  render() {
    return (
      <React.Fragment>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />

        <div className="container-scroller font-app">
          <NavBar title="Notificações" />
          <div className="container-fluid page-body-wrapper">
            <SideBar itemActive={"notificacoes"} />
            <div className="main-panel">
              <div className="content-wrapper">
                {/* <TitleAndDescription title="Veículos" description="" /> */}

                <div
                  className="row"
                  style={{ marginBottom: "0.8rem", alignItems: "center" }}
                >
                  <div className="col-md-12 stretch-card">
                    {true && (
                      <div className="card">
                        <div
                          className="card-body5"
                          style={{ position: "relative" }}
                        >
                          <React.Fragment>
                            <div className="row">
                              <div className="col-md-2 pd-veiculo2">
                                <div className="form-group form-group-chegoo2">
                                  <label>Data Inicial</label>
                                  <DatePicker
                                    selected={this.state.filtro.dataInicial}
                                    customInput={
                                      <input
                                        className="form-control font-size-14 form-control-chegoo text-center"
                                        style={{ width: "100%" }}
                                      />
                                    }
                                    dateFormat="dd/MM/yyyy"
                                    onChange={(date) => {
                                      this.handleChangeInputFiltro(
                                        date,
                                        "dataInicial"
                                      );
                                    }}
                                    locale="pt-BR"
                                  />
                                </div>
                              </div>
                              <div className="col-md-2 pd-veiculo">
                                <div className="form-group form-group-chegoo2">
                                  <label>Data Final</label>
                                  <DatePicker
                                    selected={this.state.filtro.dataFinal}
                                    customInput={
                                      <input className="form-control font-size-14 form-control-chegoo text-center" />
                                    }
                                    dateFormat="dd/MM/yyyy"
                                    onChange={(date) => {
                                      this.handleChangeInputFiltro(
                                        date,
                                        "dataFinal"
                                      );
                                    }}
                                    locale="pt-BR"
                                  />
                                </div>
                              </div>

                              <div
                                className="col-md-2 pd-veiculo"
                                style={{ display: "flex", marginTop: "15px" }}
                              >
                                <div
                                  className="form-group-chegoo2"
                                  style={{ float: "left" }}
                                >
                                  <label></label>
                                  <button
                                    className="btn btn-secondary btn-sm"
                                    style={{
                                      padding: "0.275rem 0.55rem",
                                      margin: "7px 5px 0px",
                                    }}
                                    onClick={() => this.loadNotificacoes()}
                                    id={`tooltip-filtro-atendimentos`}
                                  >
                                    <i
                                      className="fa fa-search"
                                      aria-hidden="true"
                                      style={{ fontSize: "px" }}
                                    ></i>
                                  </button>
                                  <UncontrolledTooltip
                                    placement="bottom"
                                    target={`tooltip-filtro-atendimentos`}
                                  >
                                    Filtrar
                                  </UncontrolledTooltip>
                                </div>
                                <div className="form-group-chegoo2">
                                  <label></label>
                                  <button
                                    className="btn btn-warning btn-sm"
                                    style={{
                                      padding: "0.275rem 0.55rem",
                                      margin: "7px 5px 0px",
                                      color:"#FFF"
                                    }}
                                    onClick={() => this.limparFiltros()}
                                    id={`tooltip-limpar-filtro-atendimentos`}
                                  >
                                    <i
                                      className="fa fa-eraser"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                  <UncontrolledTooltip
                                    placement="bottom"
                                    target={`tooltip-limpar-filtro-atendimentos`}
                                  >
                                    Limpar filtro
                                  </UncontrolledTooltip>
                                </div>
                              </div>

                              <div
                                className="offset-4 col-md-2 col-6 pd-veiculo3"
                                style={{
                                  marginTop: "15px",
                                  marginTop: "15px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "end",
                                }}
                              >
                                <button
                                  className="btn btn-success btn-app-verde-sm"
                                  onClick={() => {
                                    this.props.history.push(
                                      "/notificacoes-nova"
                                    );
                                  }}
                                >
                                  CADASTRAR
                                </button>
                              </div>
                            </div>
                          </React.Fragment>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12 stretch-card">
                    {true && (
                      <div className="card">
                        <div className="card-body2">
                          <React.Fragment>
                            <div className="table-responsive">
                              <table
                                id="recent-purchases-listing"
                                className="table"
                              >
                                <thead className="thead-dark">
                                  <tr>
                                    <th className="text-center">Data</th>
                                    <th>Título</th>
                                    <th>Desc</th>
                                    <th className="text-center">Ações</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.notificacoes.map(
                                    (notificacao, key) => (
                                      <tr key={key}>
                                        <td className="text-center">
                                          {moment(
                                            notificacao.dtGravacao
                                          ).format("DD/MM/YYYY")}
                                        </td>
                                        <td>{notificacao.titulo}</td>
                                        <td>{notificacao.desc}</td>
                                        <td
                                          className="text-center"
                                          style={{ fontSize: "18px" }}
                                        >
                                          {/* <div
                                            style={{
                                              margin: "2px",
                                              cursor: "pointer",
                                            }}
                                          > */}
                                            <i
                                              className="fa fa-eye"
                                              onClick={() => {
                                                this.props.history.push(
                                                  `/notificacoes-nova?id=${notificacao._id}`
                                                );
                                              }}
                                              style={{cursor:"pointer"}}
                                            ></i>
                                          {/* </div> */}
                                        </td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </React.Fragment>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Notificacao;
