import React, { Component } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { SideBar, NavBar, Footer, TitleAndDescription } from "../../components";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import Select from "react-select";
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from "reactstrap";

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_dataviz from "@amcharts/amcharts4/themes/dataviz";
import queryString from "query-string";
const { formatarReal } = require("../../utils");
am4core.useTheme(am4themes_dataviz);
am4core.useTheme(am4themes_animated);

class RoteiroDetalhe extends Component {
  constructor(props) {
    super(props);
    this.loadRoteiros = this.loadRoteiros.bind(this);
    this.iniciaGraficoSaldo = this.iniciaGraficoSaldo.bind(this);
    this.toggleModalCanhoto = this.toggleModalCanhoto.bind(this);
    this.toggleModalAssinatura = this.toggleModalAssinatura.bind(this);
    this.handleClickVisualizarFoto = this.handleClickVisualizarFoto.bind(this);

    this.state = {
      roteiro: null,
      totalizadores: {
        totalVisitas: 0,
        pendentes: 0,
        realizados: 0,
        divergencias: 0,
        ocorrencias: 0,
        canceladas: 0,
      },
      fotoCanhoto: null,
      fotoAssinatura: null,
      modalCanhoto: false,
      modalAssinatura: false,
    };
  }

  async componentDidMount() {
    let id = queryString.parse(this.props.location.search).id;

    if (id !== undefined) {
      await this.loadRoteiros(id);
    } else {
      this.props.history.push("/roteiros");
      return false;
    }
  }

  async loadRoteiros(id) {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/retaguarda/roteiros/${id}`,
        this.props.parameters()
      )
      .then((response) => {
        let roteiro = response.data.roteiro;

        for (let visita of roteiro.visitas) {
          visita.statusClass = visita.status;

          if (visita.status === "naoRealizada") {
            visita.status = "Não Realizada";
          }
        }

        this.setState(
          { roteiro, totalizadores: roteiro.totalizador },
          async () => {
            await this.iniciaGraficoSaldo(
              roteiro.totalizador.realizados,
              roteiro.totalizador.pendentes
            );
          }
        );
      });
  }

  notificar(type, message) {
    toast[type](message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  iniciaGraficoSaldo(value1 = 10, value2 = 90) {
    var chart = am4core.create("chartdiv", am4charts.PieChart);
    chart.hiddenState.properties.opacity = 0;

    chart.data = [
      {
        tipo: "Realizadas",
        value: value1,
        color: am4core.color("#2da951"),
      },
      {
        tipo: "Pendentes",
        value: value2,
        color: am4core.color("gainsboro"),
      },
    ];
    chart.radius = am4core.percent(60);
    chart.innerRadius = am4core.percent(40);
    chart.startAngle = 180;
    chart.endAngle = 360;
    var series = chart.series.push(new am4charts.PieSeries());
    series.dataFields.value = "value";
    series.dataFields.category = "tipo";
    series.slices.template.cornerRadius = 10;
    series.slices.template.innerCornerRadius = 7;
    series.slices.template.draggable = false;
    series.slices.template.inert = true;
    series.slices.template.propertyFields.fill = "color";
    series.alignLabels = false;
    series.hiddenState.properties.startAngle = 90;
    series.hiddenState.properties.endAngle = 90;
    series.labels.template.disabled = true;
    this.chart = chart;
  }

  toggleModalCanhoto() {
    this.setState({ modalCanhoto: !this.state.modalCanhoto });
  }

  toggleModalAssinatura() {
    this.setState({ modalAssinatura: !this.state.modalAssinatura });
  }

  handleClickVisualizarFoto(base64, tipo) {
    if (tipo === "canhoto") {
      this.setState({ fotoCanhoto: base64 }, () => {
        this.toggleModalCanhoto();
      });
    } else if (tipo === "assinatura") {
      this.setState({ fotoAssinatura: base64 }, () => {
        this.toggleModalAssinatura();
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />

        <div className="container-scroller font-app">
          <NavBar title={`Ordens de visita / Detalhe`} />
          <div className="container-fluid page-body-wrapper">
            <SideBar itemActive={"roteiros"} />
            <div className="main-panel">
              <div className="content-wrapper">
                {/* <TitleAndDescription
                  title="Ordens de visita / Detalhe"
                  description=""
                /> */}

                <div className="row" style={{ paddingBottom: "0.8rem" }}>
                  <div className="col-md-12 stretch-card">
                    {true && (
                      <div className="card">
                        <div
                          className="card-body4"
                          style={{ position: "relative" }}
                        >
                          <React.Fragment>
                            <div
                              className="float-left width-100"
                              style={{
                                padding: "5px",
                                position: "absolute",
                                top: "-17px",
                              }}
                            >
                              <div className="float-left">
                                RESUMO DO ROTEIRO
                              </div>

                              {/* {this.state.roteiro !== null && (
                                <div
                                  className={`color-${this.state.roteiro.status} float-right`}
                                  style={{
                                    textTransform: "capitalize",
                                  }}
                                >
                                  <i className="fa fa-circle"> </i>{" "}
                                  {this.state.roteiro.status}
                                </div>
                              )} */}
                            </div>

                            <div
                              className="row"
                              style={{ alignItems: "center" }}
                            >
                              <div className="col-5">
                                <div className="row text-center">
                                  <div
                                    className="col-4 no-padding"
                                    style={{ borderRight: "1px solid #CCC" }}
                                  >
                                    Visitas{" "}
                                    <div
                                      style={{
                                        fontSize: "20px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {this.state.totalizadores.totalVisitas}
                                    </div>
                                  </div>{" "}
                                  <div
                                    className="col-4 no-padding"
                                    style={{ borderRight: "1px solid #CCC" }}
                                  >
                                    Pendentes{" "}
                                    <div
                                      style={{
                                        fontSize: "20px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {this.state.totalizadores.pendentes}
                                    </div>
                                  </div>{" "}
                                  <div
                                    className="col-4 no-padding"
                                    style={{ borderRight: "1px solid #CCC" }}
                                  >
                                    Realizadas{" "}
                                    <div
                                      style={{
                                        fontSize: "20px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {this.state.totalizadores.realizados}
                                    </div>
                                  </div>{" "}
                                </div>
                              </div>

                              <div className="col-2">
                                <div
                                  className="d-flex"
                                  style={{ height: "90px" }}
                                >
                                  <div
                                    id="chartdiv"
                                    style={{ width: "100%", display: "flex" }}
                                  ></div>
                                </div>
                              </div>

                              <div className="col-5">
                                <div className="row text-center">
                                  <div
                                    className="col-4 no-padding"
                                    style={{ borderLeft: "1px solid #CCC" }}
                                  >
                                    Canceladas
                                    <div
                                      style={{
                                        fontSize: "20px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {this.state.totalizadores.canceladas}
                                    </div>
                                  </div>{" "}
                                  <div
                                    className="col-4 no-padding"
                                    style={{ borderLeft: "1px solid #CCC" }}
                                  >
                                    Ocorrências
                                    <div
                                      style={{
                                        fontSize: "20px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {this.state.totalizadores.ocorrencias}
                                    </div>
                                  </div>{" "}
                                  <div
                                    className="col-4 no-padding"
                                    style={{ borderLeft: "1px solid #CCC" }}
                                  >
                                    Divergências
                                    <div
                                      style={{
                                        fontSize: "20px",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {this.state.totalizadores.divergencias}
                                    </div>
                                  </div>{" "}
                                </div>
                              </div>

                              {/* 
                              <div className="col-12">
                                <div className="row text-center">
                                 
                                </div>
                              </div> */}
                            </div>
                          </React.Fragment>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12 stretch-card">
                    {this.state.roteiro !== null && (
                      <div className="card">
                        <div className="card-body2">
                          <React.Fragment>
                            {/* <div
                              className="float-left w-100"
                              style={{
                                padding: "5px",
                                marginBottom: "20px",
                                position: "relative",
                              }}
                            >
                              DETALHAMENTO DA ORDEM
                              <div className="font-size-15">
                                {this.state.roteiro.desc}
                              </div>
                              <div className="font-size-15">
                                {this.state.roteiro.dataDescritivo}{" "}
                                {this.state.roteiro.tipo === "completa" && (
                                  <span> - Entrega completa</span>
                                )}
                              </div>
                              <div>
                                <button
                                  className="btn btn-success btn-app-blue"
                                  onClick={() => {
                                    let roteiro = this.state.roteiro;
                                    let locais = "";

                                    for (let visita of roteiro.visitas) {
                                      if (
                                        visita.endereco.coordenadas !== null &&
                                        visita.endereco.coordenadas !==
                                          undefined
                                      ) {
                                        let coordenadas =
                                          visita.endereco.coordenadas;
                                        if (locais !== "") {
                                          locais += "_";
                                        }

                                        let color = "orange";

                                        if (visita.status === "realizada") {
                                          color = "green";
                                        } else if (
                                          visita.status === "naoRealizada"
                                        ) {
                                          color = "red";
                                        }

                                        locais += `${coordenadas.lat},${coordenadas.long},${encodeURIComponent(visita.cliente.desc)},${color}`;
                                      }
                                    }

                                    localStorage.setItem("locais", locais);
                                    this.props.history.push(
                                      "/roteiros-detalhe-mapa"
                                    );
                                  }}
                                  style={{
                                    position: "absolute",
                                    right: 0,
                                    top: 0,
                                  }}
                                >
                                  Ver no mapa
                                </button>
                              </div>
                            </div> */}
                            <div className="table-responsive1 font-size-15">
                              <table
                                id="recent-purchases-listing"
                                className="table"
                              >
                                <thead className="thead-dark">
                                  <tr>
                                    <th>Seq</th>
                                    <th>Operação</th>
                                    <th>Situação</th>
                                    <th>Cliente</th>
                                    <th className="text-center">Canhoto</th>
                                    <th className="text-center">Assinatura</th>
                                    <th className="text-center">Documento</th>
                                    <th>Ações</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.roteiro.visitas.map(
                                    (visita, key) => (
                                      <tr key={key}>
                                        <td
                                          style={{
                                            fontWeight: "bold",
                                            width: "30px",
                                            maxWidth: "30px",
                                          }}
                                        >
                                          {visita.seq
                                            .toString()
                                            .padStart(2, "0")}
                                        </td>
                                        <td>
                                          <span
                                            style={{
                                              textTransform: "capitalize",
                                            }}
                                          >
                                            {visita.operacao}
                                          </span>{" "}
                                        </td>
                                        <td
                                          className={`color-${visita.statusClass}`}
                                          style={{
                                            textTransform: "capitalize",
                                            width: "130px",
                                            minWidth: "130px",
                                          }}
                                        >
                                          {" "}
                                          <i className="fa fa-circle"> </i>{" "}
                                          {visita.status}
                                        </td>

                                        <td> {visita.cliente.desc}</td>
                                        <td
                                          className={`text-center`}
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          {visita.status === "realizada" ? (
                                            <i
                                              className="fa fa-search"
                                              style={{ cursor: "pointer" }}
                                              onClick={(e) => {
                                                this.handleClickVisualizarFoto(
                                                  visita.canhoto.foto,
                                                  "canhoto"
                                                );
                                              }}
                                            ></i>
                                          ) : (
                                            <span className="text-center">
                                              {" "}
                                              -{" "}
                                            </span>
                                          )}
                                        </td>
                                        <td
                                          className={`text-center`}
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          {visita.status === "realizada" ? (
                                            <i
                                              className="fa fa-search"
                                              style={{ cursor: "pointer" }}
                                              onClick={(e) => {
                                                this.handleClickVisualizarFoto(
                                                  visita.recebedor.assinatura,
                                                  "assinatura"
                                                );
                                              }}
                                            ></i>
                                          ) : (
                                            <span className="text-center">
                                              -
                                            </span>
                                          )}
                                        </td>
                                        <td
                                          className={`text-center`}
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          {visita.documentos[0].tipo}{" "}
                                          {visita.documentos[0].nro}
                                        </td>

                                        <td
                                          className="d-flex"
                                          style={{ fontSize: "18px" }}
                                        >
                                          <div
                                            style={{
                                              margin: "2px",
                                              cursor: "pointer",
                                            }}
                                          >
                                            <i
                                              className="fa fa-search"
                                              onClick={() => {
                                                this.props.history.push(
                                                  `/roteiros-detalhe-visita?id=${this.state.roteiro._id}&seq=${visita.seq}`
                                                );
                                              }}
                                            ></i>
                                          </div>
                                        </td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </table>
                            </div>

                            {/* <div className="width-100">
                              <button
                                className="btn btn-success btn-app-verde"
                                onClick={() => {
                                  this.props.history.push("/roteiros-novo");
                                }}
                                style={{
                                  float: "right",
                                  // position: "absolute",
                                  // right: 0,
                                  // top: "-15px",
                                }}
                              >
                                Novo roteiro
                              </button>
                            </div> */}

                            <div style={{ padding: "5px 5px 15px 5px" }}>
                              <button
                                className="btn btn-success btn-app-blue"
                                onClick={() => {
                                  let roteiro = this.state.roteiro;
                                  let locais = "";

                                  for (let visita of roteiro.visitas) {
                                    if (
                                      visita.endereco.coordenadas !== null &&
                                      visita.endereco.coordenadas !== undefined
                                    ) {
                                      let coordenadas =
                                        visita.endereco.coordenadas;
                                      if (locais !== "") {
                                        locais += "_";
                                      }

                                      let color = "orange";

                                      if (visita.status === "realizada") {
                                        color = "green";
                                      } else if (
                                        visita.status === "naoRealizada"
                                      ) {
                                        color = "red";
                                      }

                                      locais += `${coordenadas.lat},${
                                        coordenadas.long
                                      },${encodeURIComponent(
                                        visita.cliente.desc
                                      )},${color}`;
                                    }
                                  }

                                  localStorage.setItem("locais", locais);
                                  this.props.history.push(
                                    "/roteiros-detalhe-mapa"
                                  );
                                }}
                              >
                                Ver no mapa
                              </button>
                            </div>

                            {/* 
                            <div className="paginacao">
                              <Pagination
                                prevPageText={
                                  <i className="fa fa-angle-left" />
                                }
                                nextPageText={
                                  <i className="fa fa-angle-right" />
                                }
                                firstPageText={
                                  <i className="fa fa-angle-double-left" />
                                }
                                lastPageText={
                                  <i className="fa fa-angle-double-right" />
                                }
                                activePage={this.state.paginacao.pagina}
                                itemsCountPerPage={this.state.paginacao.limite}
                                totalItemsCount={this.state.totalcomissoes}
                                onChange={(pagina) => this.paginar(pagina)}
                                itemClass="page-item"
                                linkClass="page-link"
                              />
                            </div> */}
                          </React.Fragment>
                        </div>
                      </div>
                    )}
                  </div>

                  {/* <div className="col-md-3 stretch-card">
                    {this.state.roteiro !== null && (
                      <div className="card">
                        <div className="card-body">
                          <React.Fragment>
                            <div className="text-center float-left w-100">
                              <img
                                src="images/timeline.png"
                                alt="timeline"
                                width="110%"
                              />
                            </div>
                          </React.Fragment>
                        </div>
                      </div>
                    )}
                  </div> */}
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>

        <Modal
          isOpen={this.state.modalCanhoto}
          toggle={this.toggleModalCanhoto}
        >
          <ModalBody>
            <div className="text-center float-left w-100">
              {this.state.roteiro !== null && (
                <img src={this.state.fotoCanhoto} alt="canhoto" width="100%" />
              )}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              className="btn-modal-recuperar"
              color="secondary"
              onClick={this.toggleModalCanhoto}
            >
              Fechar
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.modalAssinatura}
          toggle={this.toggleModalAssinatura}
        >
          <ModalBody>
            <div className="text-center float-left w-100">
              {this.state.roteiro !== null && (
                <img
                  src={this.state.fotoAssinatura}
                  alt="assinatura"
                  width="100%"
                />
              )}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              className="btn-modal-recuperar"
              color="secondary"
              onClick={this.toggleModalAssinatura}
            >
              Fechar
            </Button>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default RoteiroDetalhe;
