import React, { Component } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { SideBar, NavBar, Footer, TitleAndDescription } from "../../components";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from "reactstrap";

class Template extends Component {
  constructor(props) {
    super(props);
    this.load = this.load.bind(this);
    this.loadTemplates = this.loadTemplates.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleClickDeletar = this.handleClickDeletar.bind(this);
    this.handleClickConfirmaDeletar =
      this.handleClickConfirmaDeletar.bind(this);

    this.state = {
      templates: [],
      templateDeletar: null,
      modal: false,
    };
  }

  async componentDidMount() {
    await this.load();
  }

  async load() {
    await this.loadTemplates();
  }

  async loadTemplates() {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/retaguarda/templates-roteiros`,
        this.props.parameters()
      )
      .then((response) => {
        this.setState({ templates: response.data.templates });
      });
  }

  notificar(type, message) {
    toast[type](message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  handleClickDeletar(id) {
    this.setState({ templateDeletar: id, modal: true });
  }

  toggle() {
    this.setState({ modal: !this.state.modal });
  }

  async handleClickConfirmaDeletar() {
    await axios
      .delete(
        `${this.props.getBaseUrlApi()}/retaguarda/templates/${
          this.state.templateDeletar
        }`,
        this.props.parameters()
      )
      .then((response) => {
        this.setState({ templateDeletar: null, modal: false }, () => {
          this.loadTemplates();
          this.notificar("success", "Template deletado com sucesso!");
        });
      })
      .catch((error) => {
        this.setState({ templateDeletar: null, modal: false }, () => {
          this.notificar("warning", error.response.data.erro);
        });
      });
  }

  render() {
    return (
      <React.Fragment>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />

        <div className="container-scroller font-app">
          <NavBar title="Templates de Roteiro" />
          <div className="container-fluid page-body-wrapper">
            <SideBar itemActive={"roteirizador2"} />
            <div className="main-panel">
              <div className="content-wrapper">
                {/* <TitleAndDescription title="Usuários" description="" /> */}

                <div className="row">
                  <div className="col-md-12 stretch-card">
                    {this.state.templates.length > -1 && (
                      <div className="card">
                        <div className="card-body2">
                          <React.Fragment>
                            {/* <div
                              className="float-left width-100"
                              style={{
                                borderBottom: "1px solid #CCC",
                                padding: "5px",
                                marginBottom: "20px",
                                position: "relative",
                              }}
                            >
                              TEMPLATES
                              <div>
                                <button
                                  className="btn btn-success btn-app-verde"
                                  onClick={() => {
                                    this.props.history.push("/roteirizador");
                                  }}
                                  style={{
                                    position: "absolute",
                                    right: 0,
                                    top: "-15px",
                                  }}
                                >
                                  Novo Template
                                </button>
                              </div>
                            </div> */}
                            <div className="table-responsive">
                              <table
                                id="recent-purchases-listing"
                                className="table"
                              >
                                <thead className="thead-dark">
                                  <tr>
                                    <th>ID</th>
                                    <th style={{textAlign:"center"}}>Desc</th>
                                    <th style={{ textAlign: "center" }}>
                                      Tipo
                                    </th>
                                    <th style={{ textAlign: "right" }}>
                                      Total
                                    </th>
                                    <th style={{ textAlign: "center" }}>
                                      Data
                                    </th>
                                    <th>Ações</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.templates.map((item, key) => (
                                    <tr key={key}>
                                      <td>{item._id}</td>
                                      <td style={{textAlign:"center", textTransform:"uppercase"}}>{item.desc}</td>
                                      <td
                                        style={{
                                          textTransform: "uppercase",
                                          textAlign: "center",
                                        }}
                                      >
                                        {" "}
                                        {item.tipo}
                                      </td>
                                      <td style={{ textAlign: "right" }}>
                                        {" "}
                                        {item.clientes.length}
                                      </td>
                                      <td style={{ textAlign: "center" }}>
                                        {" "}
                                        {moment(item.dtGravacao).format(
                                          "DD/MM/YY HH:mm"
                                        )}
                                      </td>

                                      <td
                                        className="d-flex"
                                        style={{ fontSize: "18px" }}
                                      >
                                        <div
                                          style={{
                                            margin: "2px",
                                            cursor: "pointer",
                                          }}
                                        >
                                          <i
                                            className="fa fa-play"
                                            style={{color:"#2da951"}}
                                            onClick={() => {
                                              this.props.history.push(
                                                `/roteirizador?idtemplate=${item._id}`
                                              );
                                            }}
                                          ></i>
                                        </div>
                                        <div
                                          style={{
                                            margin: "2px",
                                            cursor: "pointer",
                                          }}
                                        >
                                          <i
                                            className="fa fa-trash"
                                            style={{ color: "red" }}
                                            onClick={() => {
                                              this.handleClickDeletar(
                                                item._id
                                              );
                                            }}
                                          ></i>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>

                            <div style={{padding: "5px 5px 15px 5px"}}>
                            <button
                                  className="btn btn-success btn-app-verde"
                                  onClick={() => {
                                    this.props.history.push("/roteirizador");
                                  }}
                                  style={{
                                    // position: "absolute",
                                    // right: 0,
                                    // top: "-15px",
                                    // padding: "5px"
                                  }}
                                >
                                  Novo template
                                </button>
                            </div>


                            {/* 
                            <div className="paginacao">
                              <Pagination
                                prevPageText={
                                  <i className="fa fa-angle-left" />
                                }
                                nextPageText={
                                  <i className="fa fa-angle-right" />
                                }
                                firstPageText={
                                  <i className="fa fa-angle-double-left" />
                                }
                                lastPageText={
                                  <i className="fa fa-angle-double-right" />
                                }
                                activePage={this.state.paginacao.pagina}
                                itemsCountPerPage={this.state.paginacao.limite}
                                totalItemsCount={this.state.totalcomissoes}
                                onChange={(pagina) => this.paginar(pagina)}
                                itemClass="page-item"
                                linkClass="page-link"
                              />
                            </div> */}
                          </React.Fragment>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>

        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Deletar Template</ModalHeader>
          <ModalBody>Tem certeza que deseja deletar este template de roteiro?</ModalBody>
          <ModalFooter>
            <Button
              className="btn-modal-recuperar"
              color="secondary"
              onClick={this.toggle}
            >
              Não
            </Button>
            <Button
              className="btn-modal-recuperar"
              color="primary"
              onClick={this.handleClickConfirmaDeletar}
            >
              Sim
            </Button>{" "}
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default Template;
