import React, { Component } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { SideBar, NavBar, Footer, TitleAndDescription } from "../../components";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  UncontrolledTooltip,
} from "reactstrap";
import Select from "react-select";
import FileDownload from "js-file-download";
const { formatarReal } = require("../../utils");

class Template2 extends Component {
  constructor(props) {
    super(props);
    this.load = this.load.bind(this);
    this.loadTemplates = this.loadTemplates.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleClickDeletar = this.handleClickDeletar.bind(this);
    this.handleClickConfirmaDeletar =
      this.handleClickConfirmaDeletar.bind(this);
    this.loadXmlsBanco = this.loadXmlsBanco.bind(this);
    this.onClickVisualizarDanfe = this.onClickVisualizarDanfe.bind(this);
    this.selecionarItem = this.selecionarItem.bind(this);
    this.gerarRoteiros = this.gerarRoteiros.bind(this);
    this.toggleModalRoteiro = this.toggleModalRoteiro.bind(this);
    this.handleChangeRoteiroModal = this.handleChangeRoteiroModal.bind(this);
    this.handleClickSelecionarRoteiro =
      this.handleClickSelecionarRoteiro.bind(this);
    this.handleClickSalvarRoteiroEntrega =
      this.handleClickSalvarRoteiroEntrega.bind(this);
    this.filtrarEntregas = this.filtrarEntregas.bind(this);
    this.gerarRelatorio = this.gerarRelatorio.bind(this);

    this.state = {
      templates: [],
      templateDeletar: null,
      modal: false,
      xmlsBanco: [],
      modalRoteiro: false,
      roteiroSelecionado: null,
      idRoteiroSelecinado: null,
      entregaSelecionada: null,
      cidades: [],
      cidadeSelecionada: null,
      bairroSelecionado: null,
      bairros: [],
    };
  }

  async componentDidMount() {
    await this.load();
    await this.loadXmlsBanco();
  }

  async load() {
    await this.loadTemplates();
  }

  async loadTemplates() {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/retaguarda/templates-roteiros`,
        this.props.parameters()
      )
      .then((response) => {
        let templates = response.data.templates;
        //alert(JSON.stringify(templates))
        this.setState({ templates });
      });
  }

  notificar(type, message) {
    toast[type](message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  handleClickDeletar(id) {
    this.setState({ templateDeletar: id, modal: true });
  }

  toggle() {
    this.setState({ modal: !this.state.modal });
  }

  async handleClickConfirmaDeletar() {
    await axios
      .delete(
        `${this.props.getBaseUrlApi()}/retaguarda/templates/${
          this.state.templateDeletar
        }`,
        this.props.parameters()
      )
      .then((response) => {
        this.setState({ templateDeletar: null, modal: false }, () => {
          this.loadTemplates();
          this.notificar("success", "Template deletado com sucesso!");
        });
      })
      .catch((error) => {
        this.setState({ templateDeletar: null, modal: false }, () => {
          this.notificar("warning", error.response.data.erro);
        });
      });
  }

  async loadXmlsBanco() {
    let lista = [];
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/retaguarda/xmls-banco`,
        this.props.parameters()
      )
      .then((response) => {
        let lista = response.data.xmls;
        //////console.clear();
        console.log("aaaaa", lista);

        for (let item of lista) {
          if (item.status === undefined) {
            item.status = "A";
          }
        }

        lista = lista.filter((it) => it.status !== "B");

        lista = lista.map((it) => {
          it.selecionado = false;
          it.exibir = true;
          it.dtGravacaoObject = new Date(it.dtEmissao);
          return it;
        });

        lista = lista.sort(function (a, b) {
          return a.dtGravacaoObject.getTime() - b.dtGravacaoObject.getTime();
        });

        let templates = this.state.templates;
        let cidades = [];

        for (let item of lista) {
          // item.xml = "";
          item.selecionado = false;
          item.roteiro = null;

          let cidadeFind = cidades.find(
            (it) => it.desc === item.endereco.cidade
          );

          if (cidadeFind === undefined) {
            const _id = Math.random();
            const idBairro = Math.random();
            cidades.push({
              _id,
              value: _id,
              desc: item.endereco.cidade,
              label: item.endereco.cidade,
              bairros: [
                {
                  _id: idBairro,
                  value: idBairro,
                  desc: item.endereco.bairro,
                  label: item.endereco.bairro,
                },
              ],
            });
          } else {
            let bairroFind = cidadeFind.bairros.find(
              (it) => it.desc === item.endereco.bairro
            );
            if (bairroFind === undefined) {
              const _id = Math.random();
              cidadeFind.bairros.push({
                _id,
                value: _id,
                desc: item.endereco.bairro,
                label: item.endereco.bairro,
              });
            }
          }

          for (let template of templates) {
            for (let cliente of template.clientes) {
              if (cliente._id === item.cliente._id) {
                item.roteiro = {
                  _id: template._id,
                  cod: template.cod,
                  desc: template.desc,
                };
                continue;
              }
            }
          }
        }

        // ////console.clear()
        console.log("bbbb -> ", JSON.stringify(lista));

        this.setState({ xmlsBanco: lista, cidades });
      });
  }

  async onClickVisualizarDanfe(xml) {
    await axios
      .post(
        `${this.props.getBaseUrlRoteirizador()}/atualizar-danfe`,
        { xml },
        this.props.parameters()
      )
      .then((response) => {
        window.open(
          `${this.props.getBaseUrlRoteirizador()}/visualizador-danfe.html`,
          "_blank"
        );
      })
      .catch((error) => {
        this.notificar("warning", error.response.data.erro);
      });
  }

  selecionarItem(id, value) {
    let lista = this.state.xmlsBanco;

    for (let item of lista) {
      if (item._id === id) {
        item.selecionado = value;
      }
    }

    this.setState({ xmlsBanco: lista });
  }

  gerarRoteiros() {
    let lista = this.state.xmlsBanco;
    lista = lista.filter((it) => it.selecionado);

    if (lista.length === 0) {
      this.notificar("warning", "Confirme algumas entregas para prosseguir!");
      return false;
    }

    let itemFind = lista.find((it) => it.roteiro === null);

    if (itemFind !== undefined) {
      this.notificar(
        "warning",
        "Todos as entregas selecionadas devem estar com o roteiro configurado."
      );
      return false;
    }

    let novaLista = [];

    for (let item of lista) {
      let itemFind = novaLista.find((it) => it._id === item.roteiro._id);
      if (itemFind === undefined) {
        novaLista.push({
          _id: item.roteiro._id,
          seq: new Date().getTime(),
          roteiro: `${item.roteiro.cod} - ${item.roteiro.desc}`,
          motorista: null,
          veiculo: null,
          qtdEntregas: 1,
          itens: [{ ...item }],
        });
      } else {
        itemFind.qtdEntregas = itemFind.qtdEntregas + 1;
        itemFind.itens.push(item);
      }
    }

    ////console.clear();
    console.log("aaaa", JSON.stringify(novaLista));

    localStorage.setItem("roteirosAgrupados", JSON.stringify(novaLista));

    return this.props.history.push("/roteiro2");
  }

  toggleModalRoteiro() {
    this.setState({ modalRoteiro: !this.state.modalRoteiro });
  }

  handleChangeRoteiroModal(idRoteiro) {
    let roteiros = this.state.templates;

    let roteiroFind = roteiros.find((it) => it._id === idRoteiro);

    this.setState(
      {
        roteiroSelecionado: roteiroFind,
        idRoteiroSelecinado: idRoteiro,
      },
      () => {
        console.log("FFFFFF", this.state);
      }
    );
  }

  handleClickSelecionarRoteiro(entrega) {
    this.setState(
      {
        roteiroSelecionado: null,
        idRoteiroSelecinado: null,
        entregaSelecionada: entrega,
      },
      () => {
        this.toggleModalRoteiro();
      }
    );
  }

  async handleClickSalvarRoteiroEntrega() {
    let lista = this.state.xmlsBanco;
    let entregaSelecionada = this.state.entregaSelecionada;
    let roteiroSelecionado = this.state.roteiroSelecionado;

    for (let item of lista) {
      if (item._id === entregaSelecionada._id) {
        item.roteiro = {
          _id: roteiroSelecionado._id,
          cod: roteiroSelecionado.cod,
          desc: roteiroSelecionado.desc,
        };
      }
    }

    const cliente = {
      _id: entregaSelecionada.cliente._id,
      desc: entregaSelecionada.cliente.desc,
      cnpj: entregaSelecionada.cliente.cnpj,
    };

    const idRoteiro = roteiroSelecionado._id;

    await axios
      .post(
        `${this.props.getBaseUrlApi()}/retaguarda/atualizar-roteiro-cliente`,
        { cliente, idRoteiro },
        this.props.parameters()
      )
      .then((response) => {
        this.setState({ xmlsBanco: lista, entregaSelecionada: null }, () => {
          this.notificar("success", "Roteiro selecionado para esta entrega!");
          this.toggleModalRoteiro();
        });
      })
      .catch((error) => {
        this.notificar("warning", error.response.data.erro);
      });
  }

  filtrarEntregas() {
    let cidadadeSelecionada = this.state.cidadeSelecionada;
    let bairroSelecionado = this.state.bairroSelecionado;
    let lista = this.state.xmlsBanco;

    if (cidadadeSelecionada === null || cidadadeSelecionada.length === 0) {
      for (let item of lista) {
        item.exibir = true;
      }
    } else {
      for (let item of lista) {
        item.exibir = false;
      }

      for (let cidade of cidadadeSelecionada) {
        for (let item of lista) {
          if (item.endereco.cidade === cidade.desc) {
            if (bairroSelecionado !== null && bairroSelecionado.length > 0) {
              let encontrou = false;

              for (let bairro of bairroSelecionado) {
                if (bairro.desc === item.endereco.bairro) {
                  encontrou = true;
                }
              }

              if (encontrou) {
                item.exibir = true;
              }
            } else {
              item.exibir = true;
            }
          }
        }
      }
    }

    this.setState({ xmlsBanco: lista });
  }

  async gerarRelatorio() {
    let listaFormatada = [];
    let lista = this.state.xmlsBanco;

    for (let item of lista) {
      let cdRoteiro = "";
      let descRoteiro = "";

      if (item.roteiro !== null) {
        cdRoteiro = item.roteiro.cod;
        descRoteiro = item.roteiro.desc;
      }

      listaFormatada.push({
        cliente: item.cliente.desc,
        cdRoteiro: cdRoteiro,
        roteiro: descRoteiro,
        cidade: item.endereco.cidade,
        uf: item.endereco.estado,
        endereco: `${item.endereco.rua}, ${item.endereco.numero}, ${item.endereco.bairro}`,
        nfe: item.numeroNota,
        data: moment(item.dtEmissao).format("DD/MM/YYYY"),
        volumes: item.qtdVolumes,
        obs: "",
      });
    }

    // let dataInicial = this.state.dataInicial;
    // let dataFinal = this.state.dataFinal;

    let nomeArquivo = `relatorio-roteirizador`;
    // let nomeArquivo = `despesas-motorista-${moment(dataInicial)
    //   .startOf("day")
    //   .format("YYYY-MM-DD")}-${moment(dataFinal)
    //   .startOf("day")
    //   .format("YYYY-MM-DD")}.xlsx`;

    let cidade = "Todas";
    let bairro = "Todos";

    let cidadeSelecionada = this.state.cidadeSelecionada;
    let bairroSelecionado = this.state.bairroSelecionado;

    if(cidadeSelecionada !== null){
      cidade = ""
      for (let i = 0; i < cidadeSelecionada.length; i++) {
        cidade = `${cidade} ${cidadeSelecionada[i].desc}`;
        if (i < cidadeSelecionada.length - 1) {
          cidade = cidade + ",";
        }
      }
    }
    if(bairroSelecionado !== null){
      bairro = "";
      for (let i = 0; i < bairroSelecionado.length; i++) {
        bairro = `${bairro} ${bairroSelecionado[i].desc}`;
        if (i < bairroSelecionado.length - 1) {
          bairro = bairro + ",";
        }
      }
    }

    let parametros = {
      roteiros: listaFormatada,
      cidade,
      bairro
    };

    await axios
      .post(`${this.props.getBaseUrlImpressao()}/roteirizador-pdf`, { parametros })
      .then(async (response) => {
        // FileDownload(response.data, nomeArquivo);
        localStorage.setItem('html', response.data.html)
        localStorage.setItem('tituloPagina', nomeArquivo)
      window.open("/impressao", "_blank");
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          // this.notificar("warning", error.response.data.erro);
        }
      });
  }
  async gerarRelatorio2() {
    let listaFormatada = [];
    let lista = this.state.xmlsBanco;

    for (let item of lista) {
      let cdRoteiro = "";
      let descRoteiro = "";

      if (item.roteiro !== null) {
        cdRoteiro = item.roteiro.cod;
        descRoteiro = item.roteiro.desc;
      }

      listaFormatada.push({
        CLIENTE: item.cliente.desc,
        CD_ROTEIRO: cdRoteiro,
        ROTEIRO: descRoteiro,
        CIDADE: item.endereco.cidade,
        UF: item.endereco.estado,
        ENDERECO: `${item.endereco.rua}, ${item.endereco.numero}, ${item.endereco.bairro}`,
        NFE: item.numeroNota,
        DATA: moment(item.dtEmissao).format("DD/MM/YYYY"),
        VOLUMES: item.qtdVolumes,
        OBS: "",
      });
    }

    // let dataInicial = this.state.dataInicial;
    // let dataFinal = this.state.dataFinal;

    let nomeArquivo = `relatorio-roteirizador.xlsx`;
    // let nomeArquivo = `despesas-motorista-${moment(dataInicial)
    //   .startOf("day")
    //   .format("YYYY-MM-DD")}-${moment(dataFinal)
    //   .startOf("day")
    //   .format("YYYY-MM-DD")}.xlsx`;

    let params = {};
    params.responseType = "blob";

    await axios
      .post(
        `${this.props.getBaseUrlApi()}/retaguarda/relatorios`,
        { lista: listaFormatada },
        params
      )
      .then(async (response) => {
        FileDownload(response.data, nomeArquivo);
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          // this.notificar("warning", error.response.data.erro);
        }
      });
    // import FileDownload from "js-file-download";
  }

  render() {
    return (
      <React.Fragment>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />

        <div className="container-scroller font-app">
          <NavBar title="Roteirizador" />

          <div className="container-fluid page-body-wrapper">
            <SideBar itemActive={"roteirizador"} />
            <div className="main-panel">
              <div className="content-wrapper">
                {/* <TitleAndDescription title="Usuários" description="" /> */}

                <div className="row">
                  <div className="col-md-12 stretch-card">
                    {this.state.templates.length > -1 && (
                      <div className="card">
                        <div className="card-body">
                          <React.Fragment>
                            {/* <div
                              className="float-left width-100"
                              style={{
                                borderBottom: "1px solid #CCC",
                                padding: "5px",
                                position: "relative",
                              }}
                            >
                              Entregas
                            </div> */}

                            <div className="d-flex">
                              <div className="col-6">
                                <div
                                  className="sc-tracking-status-timeline2"
                                  style={{
                                    display: "flex",
                                    width: "100%",
                                    paddingBottom: "35px",
                                    marginLeft: "30px",
                                  }}
                                >
                                  <div className="sc-tracking-status-timeline__box2">
                                    <div
                                      data-desc="Selecionar Entregas"
                                      className="sc-tracking-status-timeline__step sc-tracking-status-timeline__animation-1 active"
                                    >
                                      <i className="sc-tracking-status-timeline__number animated sc-tracking-status-timeline__number-1">
                                        1
                                      </i>
                                      <i className="sc-tracking-status-timeline__icon animated fa fa-file sc-tracking-status-timeline__icon-1 zoomInUp"></i>
                                    </div>
                                    <div
                                      data-desc="Gerar Roteiros"
                                      className="sc-tracking-status-timeline__step sc-tracking-status-timeline__animation-2"
                                    >
                                      <i className="sc-tracking-status-timeline__number animated sc-tracking-status-timeline__number-2">
                                        2
                                      </i>
                                      <i className="sc-tracking-status-timeline__icon animated fa fa-check sc-tracking-status-timeline__icon-2 zoomInDown"></i>
                                    </div>
                                    <div
                                      data-desc="Rotas Geradas"
                                      className="sc-tracking-status-timeline__step sc-tracking-status-timeline__animation-3"
                                    >
                                      <i className="sc-tracking-status-timeline__number animated sc-tracking-status-timeline__number-3">
                                        3
                                      </i>
                                      <i className="sc-tracking-status-timeline__icon animated fa fa-truck sc-tracking-status-timeline__icon-3 zoomInUp"></i>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-6 d-flex">
                                <div className="col-5">
                                  <label className="font-size-14">Cidade</label>
                                  <Select
                                    value={this.state.cidadeSelecionada}
                                    onChange={(cidadeSelecionada) =>
                                      this.setState(
                                        { cidadeSelecionada },
                                        () => {
                                          if (
                                            cidadeSelecionada !== null &&
                                            cidadeSelecionada.length > 0
                                          ) {
                                            let bairros = [];

                                            for (let item of cidadeSelecionada) {
                                              bairros = bairros.concat(
                                                item.bairros
                                              );
                                            }

                                            this.setState({ bairros });
                                          } else {
                                            this.setState({
                                              bairros: [],
                                              bairroSelecionado: null,
                                            });
                                          }
                                        }
                                      )
                                    }
                                    options={this.state.cidades}
                                    placeholder="Selecione"
                                    //   style={{fontSize:"10px"}}
                                    styles={{
                                      control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        fontSize: "14px",
                                      }),
                                      menu: (baseStyles, state) => ({
                                        ...baseStyles,
                                        fontSize: "14px",
                                      }),
                                    }}
                                    isMulti
                                  />
                                </div>
                                <div className="col-5">
                                  <label className="font-size-14">Bairro</label>
                                  <Select
                                    value={this.state.bairroSelecionado}
                                    onChange={(bairroSelecionado) =>
                                      this.setState({ bairroSelecionado })
                                    }
                                    options={this.state.bairros}
                                    placeholder="Selecione"
                                    //   style={{fontSize:"10px"}}
                                    styles={{
                                      control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        fontSize: "14px",
                                      }),
                                      menu: (baseStyles, state) => ({
                                        ...baseStyles,
                                        fontSize: "14px",
                                      }),
                                    }}
                                    isMulti
                                  />
                                </div>
                                <div className="col-2 d-flex">
                                  <div style={{margin:"2px"}}>
                                    <label></label>
                                    <button
                                      className="btn btn-secondary btn-sm"
                                      style={{
                                        marginTop: "24px",
                                        padding: "8px 12px 8px 12px",
                                      }}
                                      onClick={() => {
                                        this.filtrarEntregas();
                                      }}
                                    >
                                      <i
                                        className="fa fa-search"
                                        style={{
                                          fontSize: "16px",
                                        }}
                                      ></i>{" "}
                                      {/* Filtrar */}
                                    </button>
                                  </div>

                                  <div style={{margin:"2px"}}>
                                    <label></label>
                                    <button
                                      className="btn btn-danger btn-sm"
                                      style={{
                                        marginTop: "24px",
                                        padding: "8px 12px 8px 12px",
                                      }}
                                      onClick={() => {
                                        this.gerarRelatorio();
                                      }}
                                    >
                                      <i
                                        className="fa fa-file-pdf-o"
                                        style={{
                                          fontSize: "16px",
                                        }}
                                      ></i>{" "}
                                      {/* Filtrar */}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="table-responsive">
                              <table
                                id="recent-purchases-listing"
                                className="table table-striped"
                              >
                                <thead className="thead-dark">
                                  <tr>
                                    <th style={{ minWidth: "90px" }}>
                                      Confirma
                                    </th>
                                    <th>Cliente</th>
                                    <th style={{ minWidth: "110px" }}>
                                      CD Roteiro
                                    </th>
                                    <th
                                      style={{
                                        minWidth: "110px",
                                        textAlign: "center",
                                      }}
                                    >
                                      Roteiro
                                    </th>

                                    <th>Cidade</th>
                                    <th>Endereço</th>
                                    {/* <th
                                      style={{
                                        minWidth: "120px",
                                        textAlign: "right",
                                      }}
                                    >
                                      Valor Nfe
                                    </th> */}

                                    <th style={{ minWidth: "90px" }}>Nfe</th>
                                    <th className="text-center">Data</th>

                                    <th style={{ textAlign: "right" }}>
                                      Volumes
                                    </th>
                                    <th style={{ textAlign: "right" }}>Obs</th>
                                    {/* <th style={{ textAlign: "right" }}>
                                      Roteiro
                                    </th> */}
                                    {/* <th className="text-center">Seleciona?</th> */}

                                    {/* <th>Ações</th> */}
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.xmlsBanco.length > 0 &&
                                    this.state.xmlsBanco
                                      .filter((it) => it.exibir)
                                      .map((item, key) => (
                                        <tr
                                          key={key}
                                          style={{
                                            backgroundColor:
                                              item.roteiro !== null
                                                ? "inherit"
                                                : "#f5d9d9",
                                          }}
                                        >
                                          <td>
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "center",
                                              }}
                                            >
                                              <input
                                                type="checkbox"
                                                checked={item.selecionado}
                                                onChange={(e) =>
                                                  this.selecionarItem(
                                                    item._id,
                                                    e.target.checked
                                                  )
                                                }
                                              />
                                            </div>
                                          </td>
                                          <td>
                                            <span
                                              onClick={() => {
                                                this.selecionarItem(
                                                  item._id,
                                                  !item.selecionado
                                                );
                                              }}
                                              style={{ cursor: "pointer" }}
                                            >
                                              {item.cliente.desc}{" "}
                                            </span>
                                          </td>
                                          <td
                                            style={{
                                              minWidth: "110px",
                                              textAlign: "right",
                                            }}
                                          >
                                            {item.roteiro !== null ? (
                                              <span
                                                onClick={() => {
                                                  this.selecionarItem(
                                                    item._id,
                                                    !item.selecionado
                                                  );
                                                }}
                                                style={{ cursor: "pointer" }}
                                              >
                                                {item.roteiro.cod}
                                              </span>
                                            ) : (
                                              ""
                                            )}
                                          </td>
                                          <td
                                            style={{
                                              minWidth: "110px",
                                              textAlign: "center",
                                            }}
                                          >
                                            {item.roteiro !== null ? (
                                              <span
                                                onClick={() => {
                                                  this.selecionarItem(
                                                    item._id,
                                                    !item.selecionado
                                                  );
                                                }}
                                                style={{ cursor: "pointer" }}
                                              >
                                                {item.roteiro.desc}
                                              </span>
                                            ) : (
                                              <span
                                                style={{
                                                  textDecoration: "underline",
                                                  fontWeight: "bold",
                                                  cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                  this.handleClickSelecionarRoteiro(
                                                    item
                                                  )
                                                }
                                              >
                                                INFORMAR
                                              </span>
                                            )}
                                          </td>

                                          <td>
                                            {" "}
                                            <span
                                              onClick={() => {
                                                this.selecionarItem(
                                                  item._id,
                                                  !item.selecionado
                                                );
                                              }}
                                              style={{ cursor: "pointer" }}
                                            >
                                              {item.endereco.cidade} -{" "}
                                              {item.endereco.estado}{" "}
                                            </span>
                                          </td>
                                          <td>
                                            {" "}
                                            <span
                                              onClick={() => {
                                                this.selecionarItem(
                                                  item._id,
                                                  !item.selecionado
                                                );
                                              }}
                                              style={{ cursor: "pointer" }}
                                            >
                                              {item.endereco.rua},{" "}
                                              {item.endereco.numero}, <br />
                                              {item.endereco.bairro}{" "}
                                            </span>
                                          </td>

                                          <td style={{ minWidth: "90px" }}>
                                            {/* <i
                                            style={{
                                              color: item.valido
                                                ? "#28a745"
                                                : "#f72c2c",
                                            }}
                                            className="fa fa-circle"
                                          ></i>
                                          &nbsp; */}
                                            <span
                                              style={{
                                                textDecoration: "underline",
                                                cursor: "pointer",
                                              }}
                                              onClick={() => {
                                                if (item.xml !== null) {
                                                  this.onClickVisualizarDanfe(
                                                    item.xml
                                                  );
                                                }
                                              }}
                                            >
                                              {item.numeroNota}
                                            </span>
                                          </td>
                                          <td className="text-center">
                                            {" "}
                                            <span
                                              onClick={() => {
                                                this.selecionarItem(
                                                  item._id,
                                                  !item.selecionado
                                                );
                                              }}
                                              style={{ cursor: "pointer" }}
                                            >
                                              {moment(item.dtEmissao).format(
                                                "DD/MM/YY"
                                              )}{" "}
                                            </span>
                                          </td>

                                          {/* <td
                                          style={{
                                            minWidth: "120px",
                                            textAlign: "right",
                                          }}
                                        >
                                          {" "}
                                          R$ {formatarReal(
                                            item.valorTotalNota
                                          )}{" "}
                                        </td> */}
                                          <td style={{ textAlign: "right" }}>
                                            <span
                                              onClick={() => {
                                                this.selecionarItem(
                                                  item._id,
                                                  !item.selecionado
                                                );
                                              }}
                                              style={{ cursor: "pointer" }}
                                            >
                                              {item.qtdVolumes}
                                            </span>
                                          </td>
                                          <td
                                            style={{ textAlign: "right" }}
                                          ></td>
                                          {/* <td style={{ textAlign: "right" }}>
                                          Roteiro Litoral
                                        </td> */}
                                          {/* <td>
                                          <React.Fragment>
                                            <div
                                              style={{
                                                margin: "3px",
                                                cursor: "pointer",
                                              }}
                                            >
                                              <i
                                                id={`danfe-app-${item._id}`}
                                                className="fa fa-file-pdf-o"
                                                onClick={() => {
                                                  if (item.xml !== null) {
                                                    this.onClickVisualizarDanfe(
                                                      item.xml
                                                    );
                                                  }
                                                }}
                                                style={{
                                                  fontSize: "22px",
                                                  color:
                                                    item.xml === null
                                                      ? "inherit"
                                                      : "blue",
                                                }}
                                              ></i>

                                              <UncontrolledTooltip
                                                placement="right"
                                                target={`danfe-app-${item._id}`}
                                              >
                                                Visualizar Danfe
                                              </UncontrolledTooltip>
                                            </div>
                                          </React.Fragment>
                                        </td> */}
                                        </tr>
                                      ))}
                                </tbody>
                              </table>
                            </div>

                            <div className="row">
                              <div
                                className="col-6 d-flex"
                                style={{ justifyContent: "left" }}
                              >
                                {this.state.xmlsBanco.length > 0 && (
                                  <button
                                    className="btn btn-success btn-app-verde btn-sm btn-sm2"
                                    onClick={() => {
                                      console.log("bbb", this.state.xmlsBanco);

                                      this.gerarRoteiros();

                                      // this.setState(
                                      //   {
                                      //     xmlsBanco: [],
                                      //   },
                                      //   () => {
                                      //     this.loadXmlsBanco();
                                      //   }
                                      // );
                                    }}
                                  >
                                    <i
                                      className="fa fa-chevron-right"
                                      style={{
                                        fontSize: "16px",
                                      }}
                                    ></i>{" "}
                                    Próximo
                                  </button>
                                )}
                              </div>

                              <div
                                className="col-6 d-flex"
                                style={{ justifyContent: "right" }}
                              >
                                <button
                                  className="btn btn-secondary btn-sm"
                                  onClick={() => {
                                    this.setState(
                                      {
                                        xmlsBanco: [],
                                      },
                                      () => {
                                        this.loadXmlsBanco();
                                      }
                                    );
                                  }}
                                >
                                  <i
                                    className="fa fa-refresh"
                                    style={{
                                      fontSize: "16px",
                                    }}
                                  ></i>{" "}
                                  ATUALIZAR
                                </button>
                              </div>
                            </div>

                            {/* <div
                              className="row"
                              style={{ alignItems: "center" }}
                            >
                              <div
                                className="col-12"
                                style={{
                                  fontWeight: "bold",
                                  fontSizee: "18px",
                                  marginTop: "20px",
                                }}
                              >
                                Filtros
                              </div>

                              <div className="col-3">
                                <label>Cidade</label>
                                <Select
                                  value={this.state.cidadeSelecionada}
                                  onChange={(cidadeSelecionada) =>
                                    this.setState({ cidadeSelecionada }, () => {
                                      if (
                                        cidadeSelecionada !== null &&
                                        cidadeSelecionada.length > 0
                                      ) {
                                        let bairros = [];

                                        for (let item of cidadeSelecionada) {
                                          bairros = bairros.concat(
                                            item.bairros
                                          );
                                        }

                                        this.setState({ bairros });
                                      } else {
                                        this.setState({
                                          bairros: [],
                                          bairroSelecionado: null,
                                        });
                                      }
                                    })
                                  }
                                  options={this.state.cidades}
                                  placeholder="Selecione a cidade"
                                  //   style={{fontSize:"10px"}}
                                  styles={{
                                    control: (baseStyles, state) => ({
                                      ...baseStyles,
                                      fontSize: "12px",
                                    }),
                                    menu: (baseStyles, state) => ({
                                      ...baseStyles,
                                      fontSize: "12px",
                                    }),
                                  }}
                                  isMulti
                                />
                              </div>
                              <div className="col-3">
                                <label>Bairro</label>
                                <Select
                                  value={this.state.bairroSelecionado}
                                  onChange={(bairroSelecionado) =>
                                    this.setState({ bairroSelecionado })
                                  }
                                  options={this.state.bairros}
                                  placeholder="Selecione o bairro"
                                  //   style={{fontSize:"10px"}}
                                  styles={{
                                    control: (baseStyles, state) => ({
                                      ...baseStyles,
                                      fontSize: "12px",
                                    }),
                                    menu: (baseStyles, state) => ({
                                      ...baseStyles,
                                      fontSize: "12px",
                                    }),
                                  }}
                                  isMulti
                                />
                              </div>
                              <div className="col-2">
                                <label></label>
                                <button
                                  className="btn btn-secondary btn-sm"
                                  style={{ marginTop: "25px" }}
                                  onClick={() => {
                                    this.filtrarEntregas();
                                  }}
                                >
                                  <i
                                    className="fa fa-search"
                                    style={{
                                      fontSize: "16px",
                                    }}
                                  ></i>{" "}
                                  Filtrar
                                </button>
                              </div>
                            </div> */}
                          </React.Fragment>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>

        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Deletar Template</ModalHeader>
          <ModalBody>
            Tem certeza que deseja deletar este template de roteiro?
          </ModalBody>
          <ModalFooter>
            <Button
              className="btn-modal-recuperar"
              color="secondary"
              onClick={this.toggle}
            >
              Não
            </Button>
            <Button
              className="btn-modal-recuperar"
              color="primary"
              onClick={this.handleClickConfirmaDeletar}
            >
              Sim
            </Button>{" "}
          </ModalFooter>
        </Modal>
        <Modal
          isOpen={this.state.modalRoteiro}
          toggle={this.toggleModalRoteiro}
        >
          <ModalHeader toggle={this.toggleModalRoteiro}>
            Selecionar Roteiro
          </ModalHeader>
          <ModalBody>
            {/* Tem certeza que deseja deletar este template de roteiro? */}
            <div className="form-group form-group-chegoo">
              <label>Roteiro Sugerido</label>
              <input
                type="text"
                className="form-control form-control-chegoo font-size-14"
                value="Joinville e Região"
                disabled={true}
              />
            </div>
            <div className="form-group form-group-chegoo">
              <label>Roteiros</label>
              {/* <input
                type="text"
                className="form-control"
                value="Roteiro Litoral PR"
                disabled={true}
              /> */}
              {this.state.templates.length > 0 && (
                <select
                  className="form-control form-control-chegoo font-size-14"
                  value={this.state.idRoteiroSelecinado}
                  onChange={(e) =>
                    this.handleChangeRoteiroModal(e.target.value)
                  }
                >
                  <option value="null">Selecione</option>
                  {this.state.templates.map((it, key) => (
                    <option key={key} value={it._id}>
                      {it.desc}
                    </option>
                  ))}
                </select>
              )}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              className="btn-modal-recuperar"
              color="secondary"
              onClick={this.toggleModalRoteiro}
            >
              Cancelar
            </Button>
            <Button
              className="btn-modal-recuperar"
              color="primary"
              onClick={this.handleClickSalvarRoteiroEntrega}
            >
              Salvar
            </Button>{" "}
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default Template2;
