import React, { Component } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { SideBar, NavBar, Footer } from "../../components";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import Select from "react-select";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  UncontrolledTooltip,
} from "reactstrap";
import FileDownload from "js-file-download";

const MODAL_IMPRESSAO = "modalImpressao";

class PedidoMaterial extends Component {
  constructor(props) {
    super(props);
    this.load = this.load.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleClickDeletar = this.handleClickDeletar.bind(this);
    this.handleClickConfirmaDeletar =
      this.handleClickConfirmaDeletar.bind(this);
    this.onChangeInputFiltro = this.onChangeInputFiltro.bind(this);
    this.loadUsuarios = this.loadUsuarios.bind(this);
    this.limparFiltros = this.limparFiltros.bind(this);
    this.loadObras = this.loadObras.bind(this);
    this.toggleModalFinalizacao = this.toggleModalFinalizacao.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
    this.handleClickFinalizarPedidoMaterial =
      this.handleClickFinalizarPedidoMaterial.bind(this);
    this.abrirPaginaEditar = this.abrirPaginaEditar.bind(this);
    this.gerarRelatorio = this.gerarRelatorio.bind(this);
    this.gerarRelatorioExcel = this.gerarRelatorioExcel.bind(this);
    this.toggleNovo = this.toggleNovo.bind(this);

    this.state = {
      pedidosMaterial: [],
      pedidoMaterialDeletar: null,
      modal: false,
      filtro: {
        usuario: null,
        situacao: "99", //99todos 0=pendente e 1 = finalizada
        obra: null,
      },
      usuarios: [],
      obras: [],
      modalFinalizacao: false,
      obs: "",
      idPedidoMaterial: null,
      modalNovo: {
        [MODAL_IMPRESSAO]: false,
      },
    };
  }

  async componentDidMount() {
    const userLogado = JSON.parse(localStorage.getItem("usuario"));
    if (userLogado.nivel === "4") {
      return this.props.history.push("/nao-conformidades");
    }
    if (userLogado.nivel === "5") {
      return this.props.history.push("/despesas");
    }
    await this.load();
    await this.loadUsuarios();
    await this.loadObras();
  }

  async load() {
    let filtro = this.state.filtro;

    let data = {};

    if (filtro.usuario !== null) {
      data.usuario = filtro.usuario._id;
    }

    if (filtro.situacao !== "99") {
      data.situacao = filtro.situacao;
    }

    if (filtro.obra !== null) {
      data.obra = filtro.obra._id;
    }

    await axios
      .post(
        `${this.props.getBaseUrlApi()}/retaguarda/lista-pedidos-material`,
        data,
        this.props.parameters()
      )
      .then((response) => {
        let pedidosMaterial = response.data.pedidosMaterial;

        for (let item of pedidosMaterial) {

          if(item.situacao === '0'){
            item.situacaoDesc = 'Pendente';
            item.situacaoStyle = 'pendente';
          }else if(item.situacao === '1'){
            item.situacaoDesc = 'Finalizado';
            item.situacaoStyle = 'aprovado';
          }else if(item.situacao  === '2'){
            item.situacaoDesc = 'Cancelado';
            item.situacaoStyle = 'cancelado';
          }


          // item.situacaoDesc = item.situacao === "0" ? "Pendente" : "Finalizado";
          // item.situacaoStyle = item.situacao === "0" ? "pendente" : "aprovado";
        }

        this.setState({ pedidosMaterial });
      });
  }

  notificar(type, message) {
    toast[type](message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  handleClickDeletar(id) {
    this.setState({ pedidoMaterialDeletar: id, modal: true });
  }

  toggle() {
    this.setState({ modal: !this.state.modal });
  }

  async handleClickConfirmaDeletar() {
    await axios
      .delete(
        `${this.props.getBaseUrlApi()}/retaguarda/pedidos-material/${
          this.state.pedidoMaterialDeletar
        }`,
        this.props.parameters()
      )
      .then((response) => {
        this.setState({ pedidoMaterialDeletar: null, modal: false }, () => {
          this.loadObras();
          this.notificar("success", "Pedido de Material cancelado com sucesso!");
          this.load();
        });
      })
      .catch((error) => {
        this.setState({ pedidoMaterialDeletar: null, modal: false }, () => {
          this.notificar("warning", error.response.data.erro);
        });
      });
  }

  onChangeInputFiltro(type, value) {
    let filtro = this.state.filtro;
    filtro[type] = value;
    this.setState({ filtro });
  }

  async loadUsuarios() {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/retaguarda/usuarios`,
        this.props.parameters()
      )
      .then((response) => {
        let usuarios = response.data.usuarios;

        for (let item of usuarios) {
          item.label = item.desc;
          item.value = item._id;
        }

        this.setState({ usuarios });
      });
  }

  limparFiltros() {
    this.setState(
      {
        filtro: {
          usuario: null,
          situacao: "99",
          obra: null,
        },
      },
      () => {
        this.load();
      }
    );
  }

  async loadObras() {
    await axios
      .post(
        `${this.props.getBaseUrlApi()}/retaguarda/lista-obras`,
        {},
        this.props.parameters()
      )
      .then((response) => {
        let obras = response.data.obras;

        for (let item of obras) {
          // item.label = item.desc;
          item.label = `${item.cod.toString().padStart(4, '0')} - ${item.desc}`;
          item.value = item._id;
        }

        this.setState({ obras });
      });
  }

  toggleModalFinalizacao() {
    this.setState({ modalFinalizacao: !this.state.modalFinalizacao });
  }

  onChangeInput(type, value) {
    this.setState({ [type]: value });
  }

  async handleClickFinalizarPedidoMaterial() {
    let idPedidoMaterial = this.state.idPedidoMaterial;
    let obs = this.state.obs;

    if (obs === "") {
      this.notificar("warning", "Favor informar a OBS da finalização");
      return false;
    }

    let pedidoMaterial = {
      _id: idPedidoMaterial,
      obs,
    };

    await axios
      .post(
        `${this.props.getBaseUrlApi()}/retaguarda/pedidos-material-finalizar`,
        { pedidoMaterial },
        this.props.parameters()
      )
      .then((response) => {
        this.notificar("success", "Pedido de material finalizado com sucesso!");
        this.setState({ obs: "", idPedidoMaterial: null }, () => {
          this.toggleModalFinalizacao();
        });
      });
  }

  abrirPaginaEditar(id) {
    return this.props.history.push(`/pedido-material-novo?id=${id}`);
  }

  async gerarRelatorioExcel() {
    let itensFormatados = [];
    let itens = this.state.pedidosMaterial;

    for (let item of itens) {
      let dataFinalizacao = "";

      if (item.dtFinalizacao !== null) {
        dataFinalizacao = moment(item.dtFinalizacao).format("DD/MM/YYYY");
      }

      itensFormatados.push({
        DATA: moment(item.dtCadastro).format("DD/MM/YYYY"),
        ITEM: item.desc,
        QUANTIDADE: item.quantidade,
        ["U.M"]: item.um.desc,
        ["OBRA"]: item.obra.desc,
        ["USUÁRIO"]: item.usuario.desc,
        ["SITUAÇÃO"]: item.situacaoDesc,
        ["DATA FINALIZAÇÃO"]: dataFinalizacao,
      });
    }

    let dataInicial = moment().toDate();
    let dataFinal = moment().toDate();

    let nomeArquivo = `pedidos-material-${moment(dataInicial)
      .startOf("day")
      .format("YYYY-MM-DD")}-${moment(dataFinal)
      .startOf("day")
      .format("YYYY-MM-DD")}.xlsx`;

    let params = {};
    params.responseType = "blob";

    await axios
      .post(
        `${this.props.getBaseUrlApi()}/retaguarda/relatorios`,
        { lista: itensFormatados },
        params
      )
      .then(async (response) => {
        this.toggleNovo(MODAL_IMPRESSAO);
        FileDownload(response.data, nomeArquivo);
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          this.notificar("warning", error.response.data.erro);
        }
      });
  }

  async gerarRelatorio() {
    let listaFormatada = [];
    let lista = this.state.pedidosMaterial;

    for (let item of lista) {
      listaFormatada.push({
        data: moment(item.dtCadastro).format("DD/MM/YYYY"),
        item: item.desc,
        quantidade: item.quantidade,
        um: item.um.desc,
        obra: item.obra.desc,
        usuario: item.usuario.desc,
        situacao: item.situacaoDesc,
        dataFinalizacao:
          item.dtFinalizacao === null
            ? ""
            : moment(item.dtFinalizacao).format("DD/MM/YYYY"),
      });
    }

    let usuario = this.state.filtro.usuario;
    let obra = this.state.filtro.obra;
    let situacao = this.state.filtro.situacao;

    if (usuario === null) {
      usuario = "Todos";
    } else {
      usuario = usuario.desc;
    }
    if (obra === null) {
      obra = "Todas";
    } else {
      obra = obra.desc;
    }
    if (situacao === "99") {
      situacao = "Todas";
    } else {
      // <option value="0">Pendente</option>
      // <option value="1">Finalizado</option>
      if (situacao === "0") {
        situacao = "Pedente";
      } else if (situacao === "1") {
        situacao = "Finalizado";
      }
    }

    let parametros = {
      pedidosMaterial: listaFormatada,
      usuario,
      obra,
      situacao,
    };

    let nomeArquivo = `relatorio-pedidos-material`;

    await axios
      .post(`${this.props.getBaseUrlImpressao()}/pedidos-material-pdf`, {
        parametros,
      })
      .then(async (response) => {
        // FileDownload(response.data, nomeArquivo);
        localStorage.setItem("html", response.data.html);
        localStorage.setItem("tituloPagina", nomeArquivo);
        this.toggleNovo(MODAL_IMPRESSAO);
        window.open("/impressao", "_blank");
      })
      .catch((error) => {
        console.log(error);
        if (error.response !== undefined) {
          // this.notificar("warning", error.response.data.erro);
        }
      });
  }

  toggleNovo(type) {
    let modalNovo = this.state.modalNovo;
    modalNovo[type] = !modalNovo[type];
    this.setState({ modalNovo });
  }

  render() {
    return (
      <React.Fragment>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />

        <div className="container-scroller font-app">
          <NavBar title="Pedidos de Material" />
          <div className="container-fluid page-body-wrapper">
            <SideBar itemActive={"pedidosMaterial"} />
            <div className="main-panel">
              <div className="content-wrapper">
                {/* <TitleAndDescription title="Usuários" description="" /> */}

                <div
                  className="row"
                  style={{ marginBottom: "0.8rem", alignItems: "center" }}
                >
                  <div className="col-md-12 stretch-card">
                    {true && (
                      <div className="card">
                        <div
                          className="card-body5"
                          style={{ position: "relative" }}
                        >
                          <React.Fragment>
                            <div className="row">
                              <div className="col-md-2 pd-veiculo2">
                                <div className="form-group form-group-chegoo2">
                                  <label>Usuário</label>
                                  <Select
                                    value={this.state.filtro.usuario}
                                    onChange={(item) =>
                                      this.onChangeInputFiltro("usuario", item)
                                    }
                                    options={this.state.usuarios}
                                    placeholder="Selecione"
                                    styles={{
                                      control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        fontSize: "14px",
                                        height: "35px",
                                        minHeight: "35px",
                                        backgroundColor: "#fffef6",
                                      }),
                                      menu: (baseStyles, state) => ({
                                        ...baseStyles,
                                        fontSize: "14px",
                                      }),
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-4 pd-veiculo">
                                <div className="form-group form-group-chegoo2">
                                  <label>Obra</label>
                                  <Select
                                    value={this.state.filtro.obra}
                                    onChange={(item) =>
                                      this.onChangeInputFiltro("obra", item)
                                    }
                                    options={this.state.obras}
                                    placeholder="Selecione a obra"
                                    styles={{
                                      control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        fontSize: "14px",
                                        height: "35px",
                                        minHeight: "35px",
                                        backgroundColor: "#fffef6",
                                      }),
                                      menu: (baseStyles, state) => ({
                                        ...baseStyles,
                                        fontSize: "14px",
                                      }),
                                    }}
                                  />
                                </div>
                              </div>

                              <div className="col-md-2 pd-veiculo">
                                <div className="form-group form-group-chegoo2">
                                  <label>Situação</label>
                                  <select
                                    className="form-control form-control-chegoo font-size-14"
                                    value={this.state.filtro.situacao}
                                    onChange={(e) =>
                                      this.onChangeInputFiltro(
                                        "situacao",
                                        e.target.value
                                      )
                                    }
                                  >
                                    <option value="99">Todos</option>
                                    <option value="0">Pendente</option>
                                    <option value="1">Finalizado</option>
                                    <option value="2">Cancelado</option>
                                  </select>
                                </div>
                              </div>
                              <div
                                className="col-md-2 pd-veiculo"
                                style={{ display: "flex", marginTop: "15px" }}
                              >
                                <div
                                  className="form-group-chegoo2"
                                  style={{ float: "left" }}
                                >
                                  <label></label>
                                  <button
                                    className="btn btn-secondary btn-sm"
                                    style={{
                                      padding: "0.275rem 0.55rem",
                                      margin: "10px 5px 0px",
                                    }}
                                    onClick={() => this.load()}
                                    id={`tooltip-filtro-atendimentos`}
                                  >
                                    <i
                                      className="fa fa-search"
                                      aria-hidden="true"
                                      style={{ fontSize: "px" }}
                                    ></i>
                                  </button>
                                  <UncontrolledTooltip
                                    placement="bottom"
                                    target={`tooltip-filtro-atendimentos`}
                                  >
                                    Filtrar
                                  </UncontrolledTooltip>
                                </div>
                                <div className="form-group-chegoo2">
                                  <label></label>
                                  <button
                                    className="btn btn-warning btn-sm"
                                    style={{
                                      padding: "0.275rem 0.55rem",
                                      margin: "10px 5px 0px",
                                      color: "#FFF",
                                    }}
                                    onClick={() => this.limparFiltros()}
                                    id={`tooltip-limpar-filtro-atendimentos`}
                                  >
                                    <i
                                      className="fa fa-eraser"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                  <UncontrolledTooltip
                                    placement="bottom"
                                    target={`tooltip-limpar-filtro-atendimentos`}
                                  >
                                    Limpar filtro
                                  </UncontrolledTooltip>
                                </div>

                                <div className="form-group-chegoo2">
                                  <label></label>
                                  <button
                                    className="btn btn-secondary btn-sm"
                                    style={{
                                      padding: "0.275rem 0.55rem",
                                      margin: "10px 5px 0px",
                                      color: "#FFF",
                                    }}
                                    onClick={() =>
                                      this.toggleNovo(MODAL_IMPRESSAO)
                                    }
                                    id={`tooltip-limpar-filtro-atendimentos3`}
                                  >
                                    <i
                                      className="fa fa-print"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                  <UncontrolledTooltip
                                    placement="bottom"
                                    target={`tooltip-limpar-filtro-atendimentos3`}
                                  >
                                    Imprimir
                                  </UncontrolledTooltip>
                                </div>
                              </div>
                              <div
                                className="col-md-2 pd-veiculo3"
                                style={{
                                  marginTop: "15px",
                                  marginTop: "15px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "end",
                                }}
                              >
                                <button
                                  className="btn btn-success btn-app-verde-sm"
                                  onClick={() => {
                                    this.props.history.push(
                                      "/pedido-material-novo"
                                    );
                                  }}
                                >
                                  CADASTRAR
                                </button>
                              </div>
                            </div>
                          </React.Fragment>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12 stretch-card">
                    {this.state.obras.length > -1 && (
                      <div className="card">
                        <div className="card-body2">
                          <React.Fragment>
                            <div className="table-responsive">
                              <table
                                id="recent-purchases-listing"
                                className="table"
                              >
                                <thead className="thead-dark">
                                  <tr>
                                    <th>Item</th>
                                    <th className="text-center">Quantidade</th>
                                    <th>Obra</th>
                                    <th>Usuário</th>
                                    <th className="text-center">Situação</th>
                                    <th className="text-center">
                                      Data Pedido
                                    </th>
                                    <th className="text-center">
                                      Data Finalização
                                    </th>

                                    <th>Ações</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.pedidosMaterial.map(
                                    (pedido, key) => (
                                      <tr key={key}>
                                        <td>
                                          <span
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                              this.abrirPaginaEditar(pedido._id)
                                            }
                                          >
                                            {pedido.desc}
                                          </span>
                                        </td>
                                        <td className="text-center">
                                          <span
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                              this.abrirPaginaEditar(pedido._id)
                                            }
                                          >
                                            {pedido.quantidade} {pedido.um?.desc}
                                          </span>
                                        </td>
                                        <td className="">
                                          <span
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                              this.abrirPaginaEditar(pedido._id)
                                            }
                                          >
                                            {pedido.obra.cod.toString().padStart(4, '0')} - {pedido.obra.desc}
                                          </span>
                                        </td>
                                        <td className="">
                                          <span
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                              this.abrirPaginaEditar(pedido._id)
                                            }
                                          >
                                            {pedido.usuario.desc}
                                          </span>
                                        </td>
                                        <td className="text-center">
                                          <div
                                            className="d-flex"
                                            style={{
                                              alignItems: "center",
                                              justifyContent: "center",
                                            }}
                                          >
                                            <span
                                              style={{ cursor: "pointer" }}
                                              onClick={() =>
                                                this.abrirPaginaEditar(
                                                  pedido._id
                                                )
                                              }
                                              className={`tag-despesa ${pedido.situacaoStyle}`}
                                            >
                                              {pedido.situacaoDesc}
                                            </span>
                                          </div>
                                        </td>

                                        <td className="text-center">
                                          <span
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                              this.abrirPaginaEditar(pedido._id)
                                            }
                                          >
                                            {moment(pedido.dtCadastro).format(
                                              "DD/MM/YYYY"
                                            )}
                                          </span>
                                        </td>
                                        <td className="text-center">
                                          {pedido.dtFinalizacao !== null && (
                                            <span
                                              style={{ cursor: "pointer" }}
                                              onClick={() =>
                                                this.abrirPaginaEditar(
                                                  pedido._id
                                                )
                                              }
                                            >
                                              {moment(
                                                pedido.dtFinalizacao
                                              ).format("DD/MM/YYYY")}
                                            </span>
                                          )}
                                        </td>

                                        <td
                                          className="d-flex"
                                          style={{ fontSize: "18px" }}
                                        >
                                          <div
                                            style={{
                                              margin: "2px",
                                              cursor: "pointer",
                                            }}
                                          >
                                            <i
                                              className="fa fa-check"
                                              style={{
                                                cursor: "pointer",
                                                color:
                                                  pedido.situacao === "0"
                                                    ? "green"
                                                    : "grey",
                                              }}
                                              onClick={() => {
                                                if (pedido.situacao === "0") {
                                                  this.setState(
                                                    {
                                                      idPedidoMaterial:
                                                        pedido._id,
                                                      obs: "",
                                                    },
                                                    () => {
                                                      this.toggleModalFinalizacao();
                                                    }
                                                  );
                                                }
                                              }}
                                              id={`btn-finalizar-pedido-${pedido._id}`}
                                            ></i>

                                            <UncontrolledTooltip
                                              placement="bottom"
                                              target={`btn-finalizar-pedido-${pedido._id}`}
                                            >
                                              Finalizar
                                            </UncontrolledTooltip>
                                          </div>
                                          <div
                                            style={{
                                              margin: "2px",
                                              cursor: "pointer",
                                            }}
                                          >
                                            <i
                                              className="fa fa-edit"
                                              onClick={() => {
                                                this.props.history.push(
                                                  `/pedido-material-novo?id=${pedido._id}`
                                                );
                                              }}
                                              id={`btn-editar-pedido-${pedido._id}`}
                                            ></i>

                                            <UncontrolledTooltip
                                              placement="bottom"
                                              target={`btn-editar-pedido-${pedido._id}`}
                                            >
                                              Editar
                                            </UncontrolledTooltip>
                                          </div>
                                          <div
                                            style={{
                                              margin: "2px",
                                              cursor: "pointer",
                                            }}
                                          >
                                            <i
                                              className="fa fa-ban"
                                              style={{ color: "red" }}
                                              onClick={() => {
                                                this.handleClickDeletar(
                                                  pedido._id
                                                );
                                              }}
                                              id={`btn-excluir-pedido-${pedido._id}`}
                                            ></i>

                                            <UncontrolledTooltip
                                              placement="bottom"
                                              target={`btn-excluir-pedido-${pedido._id}`}
                                            >
                                              Cancelar
                                            </UncontrolledTooltip>
                                          </div>
                                        </td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </React.Fragment>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>

        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>
            Cancelar Pedido de Material?
          </ModalHeader>
          <ModalBody>Tem certeza que deseja cancelar este pedido?</ModalBody>
          <ModalFooter>
            <Button
              className="btn-modal-recuperar"
              color="secondary"
              onClick={this.toggle}
            >
              Não
            </Button>
            <Button
              className="btn-modal-recuperar"
              color="primary"
              onClick={this.handleClickConfirmaDeletar}
            >
              Sim
            </Button>{" "}
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.modalFinalizacao}
          toggle={this.toggleModalFinalizacao}
        >
          <ModalHeader toggle={this.toggleModalFinalizacao}>
            Finalizar pedido de Material
          </ModalHeader>
          <ModalBody>
            <div className="form-group form-group-chegoo">
              <label>Obs Resolução</label>
              <input
                type="text"
                className="form-control form-control-chegoo font-size-14"
                value={this.state.obs}
                onChange={(e) => this.onChangeInput("obs", e.target.value)}
                //  onFocus={this.handleFocus}
                // ref={(node) => {
                //   if (node) {
                //     if (this.state.errors.obs) {
                //       node.style.setProperty(
                //         "border-color",
                //         "red",
                //         "important"
                //       );
                //     } else {
                //       node.style.setProperty(
                //         "border-color",
                //         "#ced4da",
                //         "important"
                //       );
                //     }
                //   }
                // }}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              className="btn-modal-recuperar"
              color="secondary"
              onClick={this.toggleModalFinalizacao}
            >
              Cancelar
            </Button>
            <Button
              className="btn-modal-recuperar"
              color="primary"
              // onClick={this.handleClickConfirmaDeletar}
              onClick={(e) => {
                this.handleClickFinalizarPedidoMaterial();
              }}
            >
              Salvar
            </Button>{" "}
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.modalNovo[MODAL_IMPRESSAO]}
          toggle={() => this.toggleNovo(MODAL_IMPRESSAO)}
        >
          <ModalHeader toggle={() => this.toggleNovo(MODAL_IMPRESSAO)}>
            Impressão
          </ModalHeader>
          <ModalBody>Escolha abaixo as opções de impressão</ModalBody>
          <ModalFooter>
            <Button
              className="btn-modal-recuperar2"
              color="success"
              onClick={() => this.gerarRelatorioExcel()}
            >
              <i className="fa fa-file-excel-o"></i>&nbsp;&nbsp;EXCEL
            </Button>
            <Button
              className="btn-modal-recuperar2"
              color="danger"
              onClick={this.gerarRelatorio}
            >
              <i className="fa fa-file-pdf-o"></i>&nbsp;&nbsp;PDF
            </Button>{" "}
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default PedidoMaterial;
