import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import { SideBar, NavBar, Footer } from "../../components";
import "react-toastify/dist/ReactToastify.css";

class Template4 extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  async componentDidMount() {
    // await axios
    //   .post(
    //     `${this.props.getBaseUrlRoteirizador()}/limpar-rotas`,
    //     {},
    //     this.props.parameters()
    //   )
    //   .then((response) => {});
    // let roteiros = localStorage.getItem("roteirosAgrupados");
    // if (roteiros === null) {
    //   return this.props.history.push("/roteiro");
    // }
    // roteiros = JSON.parse(roteiros);
    // this.setState({ roteiros });
    // await this.load();
    // // await this.loadXmlsBanco();
  }

  notificar(type, message) {
    toast[type](message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  render() {
    return (
      <React.Fragment>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />

        <div className="container-scroller font-app">
          <NavBar title="Roteirizador" />
          <div className="container-fluid page-body-wrapper">
            <SideBar itemActive={"roteirizador"} />
            <div className="main-panel">
              <div className="content-wrapper">
                {/* <TitleAndDescription title="Usuários" description="" /> */}

                <div className="row">
                  <div className="col-md-12 stretch-card">
                    <div className="card">
                      <div className="card-body">
                        <React.Fragment>
                          <div
                            className="d-flex"
                            style={{
                              justifyContent: "center",
                              flexWrap: "wrap",
                            }}
                          >
                            <div
                              className="col-12 d-flex"
                              style={{ justifyContent: "center" }}
                            >
                              <div
                                className="sc-tracking-status-timeline2"
                                style={{
                                  display: "flex",
                                  width: "100%",
                                  paddingBottom: "35px",
                                  marginLeft: "30px",
                                  justifyContent: "center",
                                }}
                              >
                                <div className="sc-tracking-status-timeline__box2">
                                  <div
                                    data-desc="Selecionar Entregas"
                                    className="sc-tracking-status-timeline__step sc-tracking-status-timeline__animation-1"
                                  >
                                    <i className="sc-tracking-status-timeline__number animated sc-tracking-status-timeline__number-1">
                                      1
                                    </i>
                                    <i className="sc-tracking-status-timeline__icon animated fa fa-file sc-tracking-status-timeline__icon-1 zoomInUp"></i>
                                  </div>
                                  <div
                                    data-desc="Gerar Roteiros"
                                    className="sc-tracking-status-timeline__step sc-tracking-status-timeline__animation-2"
                                  >
                                    <i className="sc-tracking-status-timeline__number animated sc-tracking-status-timeline__number-2">
                                      2
                                    </i>
                                    <i className="sc-tracking-status-timeline__icon animated fa fa-check sc-tracking-status-timeline__icon-2 zoomInDown"></i>
                                  </div>
                                  <div
                                    data-desc="Rotas Geradas"
                                    className="sc-tracking-status-timeline__step sc-tracking-status-timeline__animation-3 active"
                                  >
                                    <i className="sc-tracking-status-timeline__number animated sc-tracking-status-timeline__number-3">
                                      3
                                    </i>
                                    <i className="sc-tracking-status-timeline__icon animated fa fa-truck sc-tracking-status-timeline__icon-3 zoomInUp"></i>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div
                              className="col-12 d-flex"
                              style={{
                                justifyContent: "center",
                                flexWrap: "wrap",
                              }}
                            >
                              <div
                                style={{
                                  width: "100%",
                                  textAlign: "center",
                                  fontSize: "18px",
                                  fontWeight: "bold",
                                  paddingBottom: "5px",
                                }}
                              >
                                Rotas Geradas com sucesso !
                              </div>
                              <div
                                style={{
                                  width: "100%",
                                  textAlign: "center",
                                  fontSize: "16px",
                                  paddingBottom: "20px",
                                }}
                              >
                                Codigo da solicitação: {Math.random().toString().split('.')[1].substr(0, 5)}
                              </div>
                              <div
                                style={{
                                  width: "100%",
                                  textAlign: "center",
                                  paddingBottom: "5px",
                                }}
                              >
                                <button
                                  className="btn btn-success btn-app-verde btn-large-verde"
                                  type="button"
                                  onClick={() => {
                                    return this.props.history.push("/roteiros");
                                  }}
                                >
                                  Visualizar Entregas
                                </button>
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Template4;
