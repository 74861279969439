import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ButtonDropdown,
} from "reactstrap";
import TitleAndDescription from "../TitleAndDescription";

const NavBar = (props) => {
  const [linkImagem, setLinkImagem] = useState("images/no-image.png");
  const [dropdownOpen, setOpen] = useState(false);
  const [menuEncolhido, setMenuEncolhido] = useState(localStorage.getItem('menuEncolhido') === "true" ? true : false)

  const toggle = () => setOpen(!dropdownOpen);

  useEffect(() => {
    let imagemStorage = localStorage.getItem("imagem");
    if (imagemStorage !== null) {
      setLinkImagem(imagemStorage);
    }
  }, []);

  return (
    <nav className="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
      <div className="navbar-brand-wrapper d-flex justify-content-center">
        <div 
          className={`navbar-brand-inner-wrapper d-flex align-items-center w-100 ${menuEncolhido ? 'navbar-brand-chegoo-icone' : 'justify-content-between'}`} id="navbar-brand-chegoo">
          <Link className="navbar-brand brand-logo" to="/home">
            {/* <img src="images/logo-branca.png" alt="logo" /> */}
            <div style={{color:"white"}}>REGISTROO</div>

          </Link>
          <Link className="navbar-brand brand-logo-mini" to="/home">
            <img src="images/logo-mini.png" alt="logo" />
          </Link>
          <button
            className="navbar-toggler align-self-center btn-navbar"
            type="button"
            data-toggle="minimize"
          >
            <span className="mdi mdi-sort-variant"></span>
            <i
              className="fa fa-bars"
              id="fbars-menu"
              onClick={(e) => {
                let menuEncolhidoStorage = localStorage.getItem("menuEncolhido");

                if (menuEncolhidoStorage === null) {
                  menuEncolhidoStorage = "false";
                }

                if (menuEncolhidoStorage === "true") {
                  document.body.classList.remove("sidebar-icon-only");
                  localStorage.setItem("menuEncolhido", false);
                  setMenuEncolhido(false);

                  // document.getElementById(
                  //   "item-menu-abc-clientes"
                  // ).style.display = "none";
                  // document.getElementById(
                  //   "item-menu-abc-produtos"
                  // ).style.display = "none";
                  // document.getElementById(
                  //   "item-menu-relatorios"
                  // ).style.display = "block";
                } else {
                  document.body.classList.add("sidebar-icon-only");
                  localStorage.setItem("menuEncolhido", true);
                  setMenuEncolhido(true);
                  // document.getElementById(
                  //   "item-menu-abc-clientes"
                  // ).style.display = "block";
                  // document.getElementById(
                  //   "item-menu-abc-produtos"
                  // ).style.display = "block";
                  // document.getElementById(
                  //   "item-menu-relatorios"
                  // ).style.display = "none";
                }
              }}
              style={{ cursor: "pointer" }}
            ></i>
          </button>
        </div>
      </div>
      <div className="navbar-menu-wrapper d-flex align-items-center justify-content-end">
        <ul className="navbar-nav mr-lg-4 w-100">
          <li
            className="nav-item nav-search d-none d-lg-block w-100"
            // style={{ visibility: "hidden" }}
          >
            {/* <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text" id="search">
                  <i className="mdi mdi-magnify"></i>
                </span>
              </div> */}
            {/* <input
                type="text"
                className="form-control"
                placeholder="Search now"
                aria-label="search"
                aria-describedby="search"
              /> */}
            {/* 
Usuários / Novo usuário */}

            <TitleAndDescription title={props.title} description="" />
            {/* </div> */}
          </li>
         {props.exibirData !== undefined && (
           <li
           className="nav-item nav-search d-none d-lg-block w-100 text-right"
           // style={{ visibility: "hidden" }}
         >
           Data: {props.exibirData}
         </li>
         )}
        </ul>
        <ul className="navbar-nav navbar-nav-right">
          {/* <li className="nav-item dropdown mr-4">
            <a
              className="nav-link count-indicator dropdown-toggle d-flex align-items-center justify-content-center notification-dropdown"
              id="notificationDropdown"
              href="#"
              data-toggle="dropdown"
            >
              <Link to="/mensageria">
                <i
                  className="fa fa-envelope"
                  style={{ fontSize: "30px", color: "#666" }}
                ></i>
              </Link>
            </a>
            <div
              className="dropdown-menu dropdown-menu-right navbar-dropdown"
              aria-labelledby="notificationDropdown"
            >
              <p className="mb-0 font-weight-normal float-left dropdown-header">
                Notifications
              </p>
              <a className="dropdown-item">
                <div className="item-thumbnail">
                  <div className="item-icon bg-success">
                    <i className="mdi mdi-information mx-0"></i>
                  </div>
                </div>
                <div className="item-content">
                  <h6 className="font-weight-normal">Application Error</h6>
                  <p className="font-weight-light small-text mb-0 text-muted">
                    Just now
                  </p>
                </div>
              </a>
              <a className="dropdown-item">
                <div className="item-thumbnail">
                  <div className="item-icon bg-warning">
                    <i className="mdi mdi-settings mx-0"></i>
                  </div>
                </div>
                <div className="item-content">
                  <h6 className="font-weight-normal">Settings</h6>
                  <p className="font-weight-light small-text mb-0 text-muted">
                    Private message
                  </p>
                </div>
              </a>
              <a className="dropdown-item">
                <div className="item-thumbnail">
                  <div className="item-icon bg-info">
                    <i className="mdi mdi-account-box mx-0"></i>
                  </div>
                </div>
                <div className="item-content">
                  <h6 className="font-weight-normal">New user registration</h6>
                  <p className="font-weight-light small-text mb-0 text-muted">
                    2 days ago
                  </p>
                </div>
              </a>
            </div>
          </li> */}
          <li className="nav-item nav-profile">
            <a className="nav-link">
              <img src={linkImagem} alt="profile" />
              <span className="nav-profile-name">
                {localStorage.getItem("descricaoVendedor")}
                <ButtonDropdown
                  isOpen={dropdownOpen}
                  toggle={toggle}
                  className="botao-drop"
                >
                  <DropdownToggle>
                    <i
                      class="fa fa-chevron-down font-size-14"
                      aria-hidden="true"
                    ></i>
                  </DropdownToggle>
                  <DropdownMenu>
                    <Link to={`/configuracoes`} style={{ color: "inherit", fontSize: "14px" }}>
                      <DropdownItem>Configurações</DropdownItem>
                    </Link>

                    <DropdownItem divider />

                    <Link to={`/logout`} style={{ color: "inherit", fontSize:"14px" }}>
                      <DropdownItem>Sair</DropdownItem>
                    </Link>
                  </DropdownMenu>
                </ButtonDropdown>
              </span>
              {/* <span className="nav-profile-name">
                {localStorage.getItem("cargoVendedor")}
              </span> */}
            </a>
          </li>
        </ul>
        <button
          className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
          type="button"
          data-toggle="offcanvas"
        >
          <span className="mdi mdi-menu"></span>
        </button>
      </div>
    </nav>
  );
};

export default NavBar;
