import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import { SideBar, NavBar, Footer } from "../../components";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import axios from "axios";
import Select from "react-select";
import queryString from "query-string";
import { phoneMask, typeIsObject } from "../../utils";
import moment from "moment";
import { Modal, ModalBody, ModalFooter, Button, ModalHeader } from "reactstrap";
import ImageGallery from "react-image-gallery";
import DatePicker from "react-datepicker";
import "react-image-gallery/styles/css/image-gallery.css";

const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

export default class NaoConformidadeNova extends Component {
  constructor(props) {
    super(props);
    this.hiddenFileInput = React.createRef();
    this.notificar = this.notificar.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
    this.buscarNaoConformidade = this.buscarNaoConformidade.bind(this);
    this.handleFocus = this.handleFocus.bind(this);
    this.loadUsuarios = this.loadUsuarios.bind(this);
    this.loadSequenciaNaoConformidade =
      this.loadSequenciaNaoConformidade.bind(this);
    this.getDefaultNaoConformidade = this.getDefaultNaoConformidade.bind(this);
    this.onClickProximaEtapa = this.onClickProximaEtapa.bind(this);
    this.onClickSalvar = this.onClickSalvar.bind(this);
    this.onChangeInputCausaRaiz = this.onChangeInputCausaRaiz.bind(this);
    this.onChangeInputNaoConformidade =
      this.onChangeInputNaoConformidade.bind(this);
    this.handleChangeResponsavel = this.handleChangeResponsavel.bind(this);
    this.handleClickCheckAnaliseCausaRaiz =
      this.handleClickCheckAnaliseCausaRaiz.bind(this);
    this.loadNaoConformidades = this.loadNaoConformidades.bind(this);
    this.onClickAbrirNaoConformidade =
      this.onClickAbrirNaoConformidade.bind(this);
    this.validarNaoConformidade = this.validarNaoConformidade.bind(this);
    this.onClickAbrirAba = this.onClickAbrirAba.bind(this);
    this.handleChangeAvaliacaoRisco =
      this.handleChangeAvaliacaoRisco.bind(this);
    this.loadImagens = this.loadImagens.bind(this);
    this.toggleModalExcluirFoto = this.toggleModalExcluirFoto.bind(this);

    this.input1 = React.createRef();
    this.input2 = React.createRef();
    this.input3 = React.createRef();
    this.input4 = React.createRef();
    this.input5 = React.createRef();

    this.state = {
      usuario: null,
      usuarios: [],

      naoConformidade: this.getDefaultNaoConformidade(),
      abaAtual: "dados", // dados, imagens, riscoCausa, corretivaPreventiva, encerramento

      errors: {
        desc: false,
        usuario: false,
      },

      action: "CADASTRAR",
      sequencia: "",
      listaNaoConformidades: [],
      naoConformidadeReincidencia: null,
      naoConformidadeVinculacao: null,
      responsavelErro: null,
      modalExcluirFoto: false,
      imagens: [],
      imagemDeletar: null,
      dtEncerramento: new Date(),
      isSaving: false,
      isFinishing: false,
    };
  }

  async componentDidMount() {
    const userLogado = JSON.parse(localStorage.getItem("usuario"));
    if (userLogado.nivel === "3") {
      return this.props.history.push("/pedidos-material");
    }
    if (userLogado.nivel === "5") {
      return this.props.history.push("/despesas");
    }

    let id = queryString.parse(this.props.location.search).id;
    let aba = queryString.parse(this.props.location.search).aba;
    let action = "CADASTRAR";

    if (id !== undefined) {
      await this.buscarNaoConformidade(id);
      action = "EDITAR";
    }

    if (aba !== undefined) {
      this.setState({ abaAtual: aba });
    }

    this.setState({ action }, async () => {
      this.loadUsuarios();
      this.loadNaoConformidades();

      if (action === "CADASTRAR") {
        this.loadSequenciaNaoConformidade();
      }
    });
  }

  notificar(type, message) {
    toast[type](message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  onChangeInput(type, value) {
    if (typeIsObject(type)) {
      const [objectName, propName] = type.split(".");

      let object = this.state[objectName];

      object[propName] = value;
      this.setState({ [objectName]: object });
    } else {
      this.setState({ [type]: value });
    }
  }

  onChangeInputNaoConformidade(type, value) {
    let variavel = "naoConformidade";
    let objectState = this.state[variavel];

    if (typeIsObject(type)) {
      const [objectName, propName] = type.split(".");

      let object = objectState[objectName];

      object[propName] = value;
      objectState[objectName] = object;
    } else {
      objectState[type] = value;
    }

    this.setState({ [variavel]: objectState });
  }

  async buscarNaoConformidade(id) {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/retaguarda/nao-conformidade/${id}`,
        this.props.parameters()
      )
      .then((response) => {
        const naoConformidade = response.data.naoConformidade;

        if (naoConformidade === null) {
          this.props.history.push("/nao-conformidades");
          return false;
        }

        let usuario = null;
        let responsavelErro = null;
        let naoConformidadeReincidencia = null;
        let naoConformidadeVinculacao = null;
        let dtEncerramento = new Date();

        if (naoConformidade.usuario !== null) {
          usuario = naoConformidade.usuario;
          usuario.value = usuario._id;
          usuario.label = usuario.desc;
        }

        if (naoConformidade.responsavelErro !== null) {
          let listaResponsaveis = [];
          for (let item of naoConformidade.responsavelErro) {
            listaResponsaveis.push({
              value: item,
              label: item,
            });
          }
          responsavelErro = listaResponsaveis;
        }

        if (naoConformidade.naoConformidadeReincidencia !== null) {
          naoConformidadeReincidencia = {
            value: naoConformidade.naoConformidadeReincidencia._id,
            label: naoConformidade.naoConformidadeReincidencia.codigo,
            _id: naoConformidade.naoConformidadeReincidencia._id,
            codigo: naoConformidade.naoConformidadeReincidencia.codigo,
          };
        }
        if (naoConformidade.naoConformidadeVinculacao !== null) {
          naoConformidadeVinculacao = {
            value: naoConformidade.naoConformidadeVinculacao._id,
            label: naoConformidade.naoConformidadeVinculacao.codigo,
            codigo: naoConformidade.naoConformidadeVinculacao.codigo,
            _id: naoConformidade.naoConformidadeVinculacao._id,
          };
        }

        if (naoConformidade.dtEncerramento !== null) {
          dtEncerramento = moment(naoConformidade.dtEncerramento).toDate();
        }

        this.setState(
          {
            usuario,
            naoConformidade,
            sequencia: naoConformidade.codigo,
            responsavelErro,
            naoConformidadeReincidencia,
            naoConformidadeVinculacao,
            dtEncerramento,
          },
          () => {
            if (naoConformidade.descricao.fotos.length) {
              this.loadImagens(naoConformidade.descricao2.fotos);
            }
          }
        );
      });
  }

  handleFocus(e) {
    e.target.select();
  }

  async loadUsuarios() {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/retaguarda/usuarios`,
        this.props.parameters()
      )
      .then((response) => {
        let usuarios = response.data.usuarios;

        for (let item of usuarios) {
          item.label = item.desc;
          item.value = item._id;
        }

        this.setState({ usuarios });
      });
  }

  async loadSequenciaNaoConformidade() {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/retaguarda/sequencia-nao-conformidade`,
        this.props.parameters()
      )
      .then((response) => {
        let sequencia = response.data.sequencia;
        this.setState({ sequencia });
      });
  }

  getDefaultNaoConformidade() {
    return {
      _id: null,
      type: "naoConformidade",
      codigo: "00001", // codigo apos sinc com backend
      codigoTemp: "", // vai ser vazio se gravado na retaguarda, codigo criado no app quando offline
      origem: "RETAGUARDA", //APP ou RETAGUARDA

      dtCadastro: new Date(),
      usuario: null,
      obra: "",
      fornecedorOrdemCompra: "",
      contato: "",

      responsavelErro: null,

      avaliacaoRisco: {
        financeiro: "",
        imagem: "",
        seguranca: "",
        prazo: "",
        prazoCalculado: "",
      },

      descricao: {
        desc: "",
        fotos: [],
      },

      analiseCausaRaiz: [
        {
          codigo: "1",
          desc: "Processo ou método",
          ativo: false,
          analise: "",
        },
        {
          codigo: "2",
          desc: "Material utilizado",
          ativo: false,
          analise: "",
        },
        {
          codigo: "3",
          desc: "Mão de obra",
          ativo: false,
          analise: "",
        },
        {
          codigo: "4",
          desc: "Máquinas ou ferramentas",
          ativo: false,
          analise: "",
        },
        {
          codigo: "5",
          desc: "Ambiente de trabalho",
          ativo: false,
          analise: "",
        },
      ],

      acaoCorretiva: {
        desc: "",
        responsavel: "",
        dtCadastro: null,
      },

      acaoPreventiva: {
        desc: "",
        responsavel: "",
        dtCadastro: null,
      },

      naoConformidadeReincidencia: null,

      naoConformidadeVinculacao: null,

      status: "A",
      situacao: "1", // 0- pendente, 1 - aberta, 2- finalizada

      dtEncerramento: null,
      responsavelEncerramento: "",
      usuarioFinalizacao: null, // usuario logado na retaguarda

      dtSincronizacao: null, // vai ser sempre null se aberto pela retagua
    };
  }

  onClickProximaEtapa() {
    let abaAtual = this.state.abaAtual;
    let listaAbas = [
      "dados",
      "imagens",
      "riscoCausa",
      "corretivaPreventiva",
      "encerramento",
    ];

    let index = listaAbas.indexOf(abaAtual);
    if (index < listaAbas.length - 1) {
      this.setState({ abaAtual: listaAbas[index + 1] });
      let url = `/nao-conformidades-nova?aba=${listaAbas[index + 1]}`;
      if (this.state.naoConformidade._id !== null) {
        url = `${url}&id=${this.state.naoConformidade._id}`;
      }
      this.props.history.push(url);
    }
  }

  async onClickSalvar(isFinalizarNaoConformidade) {
    let isSaving = this.state.isSaving;
    let isFinishing = this.state.isFinishing;
    if (isSaving || isFinishing) {
      return false;
    }

    if (isFinalizarNaoConformidade) {
      isFinishing = true;
    } else {
      isSaving = true;
    }

    this.setState({ isSaving, isFinishing }, async () => {
      let naoConformidade = this.state.naoConformidade;
      let usuario = this.state.usuario;
      let responsavelErro = this.state.responsavelErro;
      let naoConformidadeReincidencia = this.state.naoConformidadeReincidencia;
      let naoConformidadeVinculacao = this.state.naoConformidadeVinculacao;

      if (!this.validarNaoConformidade(isFinalizarNaoConformidade)) {
        isSaving = false;
        isFinishing = false;
        this.setState({ isSaving, isFinishing });
        return false;
      }

      if (this.state.action === "CADASTRAR") {
        naoConformidade.codigo = this.state.sequencia;
      }

      naoConformidade.usuario = {
        _id: usuario._id,
        _rev: usuario._rev,
        desc: usuario.desc,
        login: usuario.login,
      };

      if (responsavelErro !== null) {
        naoConformidade.responsavelErro = [];

        if(responsavelErro.length > 0){
          for (let item of responsavelErro) {
            naoConformidade.responsavelErro.push(item.label);
          }
        }else{
          naoConformidade.responsavelErro = null;
        }
      }

      if (naoConformidadeReincidencia !== null) {
        naoConformidade.naoConformidadeReincidencia = {
          _id: naoConformidadeReincidencia._id,
          codigo: naoConformidadeReincidencia.codigo,
        };
      }
      if (naoConformidadeVinculacao !== null) {
        naoConformidade.naoConformidadeVinculacao = {
          _id: naoConformidadeVinculacao._id,
          codigo: naoConformidadeVinculacao.codigo,
        };
      }

      //COLOCAR DATA NAS DESCRICOES SE NAO HOUVER

      if (isFinalizarNaoConformidade) {
        const usuarioLogado = JSON.parse(localStorage.getItem("usuario"));

        naoConformidade.situacao = "2";
        naoConformidade.dtEncerramento = this.state.dtEncerramento;
        naoConformidade.usuarioFinalizacao = {
          _id: usuarioLogado._id,
          _rev: usuarioLogado._rev,
          desc: usuarioLogado.desc,
          login: usuarioLogado.login,
        };
      }

      let imagens = this.state.imagens;
      let imagensNovas = imagens.filter((it) => it._id === null);

      for (let item of imagensNovas) {
        let identificador = new Date().getTime().toString();
        item._id = identificador;
        naoConformidade.descricao.fotos.push(identificador);

        if (naoConformidade.descricao2 === undefined) {
          let descricao2 = {
            fotos: [],
          };

          naoConformidade.descricao2 = descricao2;
        }

        naoConformidade.descricao2.fotos.push({
          identificador,
          extensao: "." + item.extensaoImagem,
        });


        await axios
        .post(
          `${this.props.getBaseUrlApi()}/app/cadastrar-imagem`,
          {
            imagem: item.base64,
            identificador,
            extensao: "." + item.extensaoImagem,
          },
          {}
        )
        .then(async (response) => {
          // alert("foi");
        })
        .catch((error) => {
          console.log("responseError", error.response.data.erro.msg);
          this.notificar("warning", error.response.data.erro.msg);
        });


      }

      await axios
        .post(
          `${this.props.getBaseUrlApi()}/retaguarda/nao-conformidade`,
          { naoConformidade, imagens: [] },
          this.props.parameters()
        )
        .then((response) => {
          if (this.state.action === "CADASTRAR") {
            this.notificar(
              "success",
              "Não conformidade cadastrada com sucesso"
            );

            setTimeout(() => {
              this.props.history.push(
                `/nao-conformidades-nova?id=${response.data.id}&aba=${this.state.abaAtual}`
              );
              window.location.reload();
            }, 1500);
          } else {
            this.notificar("success", "Não conformidade editada com sucesso");
            setTimeout(() => {
              let aba = this.state.abaAtual;
              if (isFinalizarNaoConformidade) {
                aba = "dados";
              }
              let url = `/nao-conformidades-nova?id=${response.data.id}&aba=${aba}`;

              this.props.history.push(url);
              window.location.reload();
            }, 1500);
          }
        })
        .catch((error) => {
          this.notificar("warning", error.response.data.erro);
        });
    });
  }

  onChangeInputCausaRaiz(value, codigo) {
    let naoConformidade = this.state.naoConformidade;
    for (let item of naoConformidade.analiseCausaRaiz) {
      if (item.codigo === codigo) {
        item.analise = value;
        if (!item.ativo) {
          item.ativo = true;
        }
      }
    }
    this.setState({ naoConformidade });
  }

  handleChangeResponsavel(value) {
    let naoConformidade = this.state.naoConformidade;
    naoConformidade.responsavelErro = value;
    this.setState({ naoConformidade });
  }

  handleChangeAvaliacaoRisco(value, type) {
    let naoConformidade = this.state.naoConformidade;
    if (naoConformidade.situacao === "2") {
      return false;
    }
    naoConformidade.avaliacaoRisco[type] = value;
    this.setState({ naoConformidade });
  }

  handleClickCheckAnaliseCausaRaiz(codigo, isChecked) {
    let naoConformidade = this.state.naoConformidade;
    if (naoConformidade.situacao === "2") {
      return false;
    }
    for (let item of naoConformidade.analiseCausaRaiz) {
      if (item.codigo === codigo) {
        item.ativo = isChecked;

        if (!isChecked) {
          item.analise = "";
        }
      }
    }
    this.setState({ naoConformidade }, () => {
      if (isChecked) {
        this[`input${codigo}`].current.focus();
      }
    });
  }

  async loadNaoConformidades() {
    let naoConformidadeAtual = null;

    if (this.state.naoConformidade._id !== null) {
      naoConformidadeAtual = this.state.naoConformidade._id;
    }

    await axios
      .post(
        `${this.props.getBaseUrlApi()}/retaguarda/lista-nao-conformidade`,
        {
          naoConformidadeAtual,
        },
        this.props.parameters()
      )
      .then((response) => {
        let listaNaoConformidades = response.data.naoConformidades;

        for (let item of listaNaoConformidades) {
          item.label = item.codigo;
          item.value = item._id;
        }

        this.setState({ listaNaoConformidades });
      });
  }

  onClickAbrirNaoConformidade(idNaoconformidade) {
    window.open(`/nao-conformidades-nova?id=${idNaoconformidade}`, "_blank");
  }

  validarNaoConformidade(isFinalizarNaoConformidade) {
    let naoConformidade = this.state.naoConformidade;
    let usuario = this.state.usuario;
    let responsavelErro = this.state.responsavelErro;

    if (usuario === null) {
      this.notificar("warning", "Favor informar o usuário!");
      return false;
    }

    if (isFinalizarNaoConformidade) {
      // if (naoConformidade.fornecedorOrdemCompra === "") {
      //   this.notificar("warning", "Favor informar Fornecedor/Ordem de compra");
      //   return false;
      // }

      if (naoConformidade.descricao.desc === "") {
        this.notificar("warning", "Favor informar a Descrição!");
        return false;
      }

      // if (responsavelErro === null) {
      //   this.notificar("warning", "Favor informar o responsável pelo erro");
      //   return false;
      // } else {
      //   if (responsavelErro.length === 0) {
      //     this.notificar("warning", "Favor informar o responsável pelo erro");
      //     return false;
      //   }
      // }

      if (naoConformidade.avaliacaoRisco.financeiro === "") {
        this.notificar(
          "warning",
          "Favor informar o campo Financeiro da avaliação de risco!"
        );
        return false;
      }

      if (naoConformidade.avaliacaoRisco.imagem === "") {
        this.notificar(
          "warning",
          "Favor informar o campo imagem da avaliação de risco!"
        );
        return false;
      }
      if (naoConformidade.avaliacaoRisco.seguranca === "") {
        this.notificar(
          "warning",
          "Favor informar o campo segurança da avaliação de risco!"
        );
        return false;
      }
      if (naoConformidade.avaliacaoRisco.prazo === "") {
        this.notificar(
          "warning",
          "Favor informar o campo prazo da avaliação de risco!"
        );
        return false;
      }

      let ativosAnaliseCausa = naoConformidade.analiseCausaRaiz.filter(
        (it) => it.ativo && it.analise === ""
      );
      if (ativosAnaliseCausa.length) {
        this.notificar(
          "warning",
          "Favor informar análise dos tipos de causa selecionados"
        );
        return false;
      }

      // if (naoConformidade.acaoCorretiva.desc === "") {
      //   this.notificar("warning", "Favor informar a ação corretiva");
      //   return false;
      // }
      // if (naoConformidade.acaoCorretiva.responsavel === "") {
      //   this.notificar(
      //     "warning",
      //     "Favor informar o responsável pela ação corretiva"
      //   );
      //   return false;
      // }

      // if (naoConformidade.acaoPreventiva.desc === "") {
      //   this.notificar("warning", "Favor informar a ação preventiva");
      //   return false;
      // }

      // if (naoConformidade.acaoPreventiva.responsavel === "") {
      //   this.notificar(
      //     "warning",
      //     "Favor informar o responsável pela ação preventiva"
      //   );
      //   return false;
      // }

      if (naoConformidade.responsavelEncerramento === "") {
        this.notificar(
          "warning",
          "Favor informar o responsável pela finalização da não conformidade"
        );
        return false;
      }
    }

    return true;
  }

  onClickAbrirAba(abaNova) {
    let url = `/nao-conformidades-nova?aba=${abaNova}`;
    if (this.state.naoConformidade._id !== null) {
      url = `${url}&id=${this.state.naoConformidade._id}`;
    }
    this.props.history.push(url);
    this.setState({ abaAtual: abaNova });
  }

  async loadImagens(fotos) {
    // await axios
    //   .post(
    //     `${this.props.getBaseUrlApi()}/retaguarda/nao-conformidades-fotos`,
    //     { imagens },
    //     this.props.parameters()
    //   )
    //   .then((response) => {
    //     this.setState({ imagens: response.data.imagens }, () => {
    //       console.log("ffff", this.state.imagens);
    //     });
    //   });

    let imagens = []

    for (let item of fotos) {
      await axios
        .post(
          `${this.props.getBaseUrlApi()}/app/buscar-imagem`,
          {
            identificador: item.identificador,
            extensao: item.extensao,
          },
          {}
        )
        .then(async (response) => {
          if(response.data.imagem !== null){
            let novaExtensao = item.extensao.replace(".", "");
            let imagem = `data:image/${novaExtensao};base64,${response.data.imagem}`;
            imagens.push({
              _id: item.identificador,
              base64: imagem,
              extensaoImagem: item.extensao,
            });
          }
        })
        .catch((error) => {
          console.log("responseError", error.response.data.erro.msg);
          this.notificar("warning", error.response.data.erro.msg);
        });
    }

    this.setState({ imagens });
  }

  toggleModalExcluirFoto() {
    this.setState({ modalExcluirFoto: !this.state.modalExcluirFoto });
  }

  render() {
    const InputRequired = () => {
      return <span className="required-icone">*</span>;
    };
    return (
      <React.Fragment>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
        <div className="container-scroller font-app">
          <NavBar
            title={`Não Conformidade N° ${this.state.sequencia}`}
            exibirData={moment(this.state.naoConformidade.dtCadastro).format(
              "DD/MM/YYYY"
            )}
          />
          <div className="container-fluid page-body-wrapper">
            <SideBar itemActive={"naoConformidades"} />
            <div className="main-panel">
              <div className="content-wrapper">
                <div className="row">
                  <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body5 dashboard-tabs">
                        <div className="tab-content py-0 px-0">
                          <div
                            className="tab-pane fade show active"
                            id="overview"
                            role="tabpanel"
                            aria-labelledby="overview-tab"
                          >
                            <div className="d-flex flex-wrap justify-content-xl-between">
                              <div
                                style={{
                                  float: "left",
                                  width: "100%",
                                }}
                              >
                                <div className="row d-flex">
                                  <div className="col-12">
                                    <div
                                      className="sc-tracking-status-timeline2"
                                      style={{
                                        display: "flex",
                                        width: "100%",
                                        paddingBottom: "35px",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <div className="sc-tracking-status-timeline__box2">
                                        <div
                                          data-desc="Registro"
                                          className={`sc-tracking-status-timeline__step sc-tracking-status-timeline__animation-1 ${
                                            this.state.abaAtual === "dados"
                                              ? "active"
                                              : ""
                                          }`}
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            this.onClickAbrirAba("dados")
                                          }
                                        >
                                          <i className="sc-tracking-status-timeline__number animated sc-tracking-status-timeline__number-1">
                                            1
                                          </i>
                                          <i className="sc-tracking-status-timeline__icon animated fa fa-file sc-tracking-status-timeline__icon-1 zoomInUp"></i>
                                        </div>
                                        <div
                                          data-desc="Imagens"
                                          className={`sc-tracking-status-timeline__step sc-tracking-status-timeline__animation-2 ${
                                            this.state.abaAtual === "imagens"
                                              ? "active"
                                              : ""
                                          }`}
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            this.onClickAbrirAba("imagens")
                                          }
                                        >
                                          <i className="sc-tracking-status-timeline__number animated sc-tracking-status-timeline__number-2">
                                            2
                                          </i>
                                          <i className="sc-tracking-status-timeline__icon animated fa fa-photo sc-tracking-status-timeline__icon-2 zoomInUp"></i>
                                        </div>
                                        <div
                                          data-desc="Risco e Causa"
                                          className={`sc-tracking-status-timeline__step sc-tracking-status-timeline__animation-3 ${
                                            this.state.abaAtual === "riscoCausa"
                                              ? "active"
                                              : ""
                                          }`}
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            this.onClickAbrirAba("riscoCausa")
                                          }
                                        >
                                          <i className="sc-tracking-status-timeline__number animated sc-tracking-status-timeline__number-3">
                                            2
                                          </i>
                                          <i className="sc-tracking-status-timeline__icon animated fa fa-warning sc-tracking-status-timeline__icon-3 zoomInDown"></i>
                                        </div>
                                        <div
                                          data-desc="Corretiva e Preventiva"
                                          className={`sc-tracking-status-timeline__step sc-tracking-status-timeline__animation-4 ${
                                            this.state.abaAtual ===
                                            "corretivaPreventiva"
                                              ? "active"
                                              : ""
                                          }`}
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            this.onClickAbrirAba(
                                              "corretivaPreventiva"
                                            )
                                          }
                                        >
                                          <i className="sc-tracking-status-timeline__number animated sc-tracking-status-timeline__number-4">
                                            3
                                          </i>
                                          <i className="sc-tracking-status-timeline__icon animated fa fa-wrench sc-tracking-status-timeline__icon-4 zoomInUp"></i>
                                        </div>
                                        <div
                                          data-desc="Encerramento"
                                          className={`sc-tracking-status-timeline__step sc-tracking-status-timeline__animation-5 ${
                                            this.state.abaAtual ===
                                            "encerramento"
                                              ? "active"
                                              : ""
                                          }`}
                                          style={{ cursor: "pointer" }}
                                          onClick={() =>
                                            this.onClickAbrirAba("encerramento")
                                          }
                                        >
                                          <i className="sc-tracking-status-timeline__number animated sc-tracking-status-timeline__number-5">
                                            4
                                          </i>
                                          <i className="sc-tracking-status-timeline__icon animated fa fa-check sc-tracking-status-timeline__icon-5 zoomInUp"></i>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  {/* <div className="col-6">
                                    <div>RNC N°: {this.state.sequencia}</div>
                                  </div> */}
                                </div>

                                {this.state.abaAtual === "dados" && ( //ABA_DADOS
                                  <React.Fragment>
                                    <div className="row">
                                      <div className="col-md-2 pd-veiculo2">
                                        <div
                                          className="form-group form-group-chegoo"
                                          style={{ position: "relative" }}
                                        >
                                          <label>
                                            Emitente&nbsp;
                                            <InputRequired />
                                          </label>
                                          <Select
                                            value={this.state.usuario}
                                            onChange={(item) =>
                                              this.onChangeInput(
                                                "usuario",
                                                item
                                              )
                                            }
                                            options={this.state.usuarios}
                                            placeholder="Selecione"
                                            className={`${
                                              this.state.errors.usuario
                                                ? "select-error"
                                                : ""
                                            } required-chegoo`}
                                            isDisabled={
                                              this.state.naoConformidade
                                                .situacao === "2"
                                            }
                                            styles={{
                                              control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                fontSize: "14px",
                                                height: "35px",
                                                minHeight: "35px",
                                                backgroundColor: "#fffef6",
                                              }),
                                              menu: (baseStyles, state) => ({
                                                ...baseStyles,
                                                fontSize: "14px",
                                              }),
                                            }}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-4 pd-veiculo2">
                                        <div className="form-group form-group-chegoo">
                                          <label>Cliente/Obra</label>
                                          <input
                                            type="text"
                                            className="form-control form-control-chegoo font-size-14"
                                            value={
                                              this.state.naoConformidade.obra
                                            }
                                            onChange={(e) =>
                                              this.onChangeInput(
                                                "naoConformidade.obra",
                                                e.target.value
                                              )
                                            }
                                            disabled={
                                              this.state.naoConformidade
                                                .situacao === "2"
                                            }
                                          />
                                        </div>
                                      </div>{" "}
                                      <div className="col-md-3 pd-veiculo2">
                                        <div className="form-group form-group-chegoo">
                                          <label>Fornecedor/Ordem Compra</label>

                                          <input
                                            type="text"
                                            className="form-control form-control-chegoo font-size-14 required-chegoo"
                                            value={
                                              this.state.naoConformidade
                                                .fornecedorOrdemCompra
                                            }
                                            onChange={(e) =>
                                              this.onChangeInput(
                                                "naoConformidade.fornecedorOrdemCompra",
                                                e.target.value
                                              )
                                            }
                                            disabled={
                                              this.state.naoConformidade
                                                .situacao === "2"
                                            }
                                          />
                                        </div>
                                      </div>{" "}
                                      <div className="col-md-3 pd-veiculo3">
                                        <div className="form-group form-group-chegoo">
                                          <label>Contato</label>

                                          <input
                                            type="text"
                                            value={
                                              this.state.naoConformidade.contato
                                            }
                                            label=""
                                            className={`form-control form-control-chegoo font-size-14 required-chegoo`}
                                            onChange={(e) => {
                                              let value = e.target.value;

                                              // value = phoneMask(value);

                                              this.onChangeInput(
                                                "naoConformidade.contato",
                                                value
                                              );
                                            }}
                                            disabled={
                                              this.state.naoConformidade
                                                .situacao === "2"
                                            }
                                          />
                                        </div>
                                      </div>{" "}
                                      {/* <div className="col-md-3 pd-veiculo2">
                                        <div className="form-group form-group-chegoo">
                                          <label>
                                            Responsável Erro&nbsp;
                                            <InputRequired />
                                          </label>

                                          <Select
                                            value={this.state.responsavelErro}
                                            onChange={(item) =>
                                              this.onChangeInput(
                                                "responsavelErro",
                                                item
                                              )
                                            }
                                            options={[
                                              {
                                                value: "Administração",
                                                label: "Administração",
                                              },
                                              {
                                                value: "Cliente",
                                                label: "Cliente",
                                              },
                                              {
                                                value: "Comercial",
                                                label: "Comercial",
                                              },
                                              {
                                                value: "Coordenação",
                                                label: "Coordenação",
                                              },
                                              {
                                                value: "Fornecedor",
                                                label: "Fornecedor",
                                              },

                                              {
                                                value: "Obras/Engenharia",
                                                label: "Obras/Engenharia",
                                              },

                                              {
                                                value: "Produção",
                                                label: "Produção",
                                              },
                                            ]}
                                            placeholder="Selecione"
                                            className={`${
                                              this.state.errors.usuario
                                                ? "select-error"
                                                : ""
                                            } required-chegoo`}
                                            isDisabled={
                                              this.state.naoConformidade
                                                .situacao === "2"
                                            }
                                            styles={{
                                              control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                fontSize: "14px",
                                                height: "35px",
                                                minHeight: "35px",
                                                backgroundColor: "#fffef6",
                                              }),
                                              menu: (baseStyles, state) => ({
                                                ...baseStyles,
                                                fontSize: "14px",
                                              }),
                                            }}
                                          />
                                        </div>
                                      </div>{" "} */}
                                    </div>

                                    <div className="row">
                                      <div className="col-md-12 pd-veiculo4">
                                        <div className="form-group form-group-chegoo">
                                          <label>
                                            Descrição&nbsp;
                                            <InputRequired />
                                          </label>

                                          <textarea
                                            rows="5"
                                            type="text"
                                            label=""
                                            className={`form-control form-control-chegoo2 font-size-14 required-chegoo text-area-form2`}
                                            value={
                                              this.state.naoConformidade
                                                .descricao.desc
                                            }
                                            onChange={(e) => {
                                              let value = e.target.value;

                                              this.onChangeInputNaoConformidade(
                                                "descricao.desc",
                                                value
                                              );
                                            }}
                                            ref={(node) => {
                                              if (node) {
                                                node.style.setProperty(
                                                  "height",
                                                  "auto",
                                                  "important"
                                                );
                                              }
                                            }}
                                            disabled={
                                              this.state.naoConformidade
                                                .situacao === "2"
                                            }
                                          />
                                        </div>
                                      </div>{" "}
                                    </div>
                                  </React.Fragment>
                                )}

                                {this.state.abaAtual === "imagens" && (
                                  <React.Fragment>
                                    <div
                                      className="row"
                                      style={{ padding: "30px 0" }}
                                    >
                                      {/* <div className="col-md-6">
                                  <table className="table">
                                          <thead className="thead-dark">
                                            <th>Descrição</th>
                                            <th className="text-center">Sim</th>
                                            <th>Análise</th>
                                          </thead>
                                          <tbody>
                                            {this.state.naoConformidade.analiseCausaRaiz.map(
                                              (item) => (
                                                <tr>
                                                  <td>
                                                    {item.codigo} - {item.desc}
                                                  </td>
                                                  <td className="text-center">
                                                    <input
                                                      type="checkbox"
                                                      checked={item.ativo}
                                                      onChange={(e) =>
                                                        this.handleClickCheckAnaliseCausaRaiz(
                                                          item.codigo,
                                                          e.target.checked
                                                        )
                                                      }
                                                      disabled={
                                                        this.state
                                                          .naoConformidade
                                                          .situacao === "2"
                                                      }
                                                    ></input>
                                                  </td>
                                                  <td>
                                                    <input
                                                      ref={
                                                        this[
                                                          `input${item.codigo}`
                                                        ]
                                                      }
                                                      type="text"
                                                      className="form-control"
                                                      value={item.analise}
                                                      onChange={(e) =>
                                                        this.onChangeInputCausaRaiz(
                                                          e.target.value,
                                                          item.codigo
                                                        )
                                                      }
                                                      disabled={
                                                        this.state
                                                          .naoConformidade
                                                          .situacao === "2"
                                                      }
                                                    ></input>
                                                  </td>
                                                </tr>
                                              )
                                            )}
                                          </tbody>
                                        </table>
                                  </div> */}

                                      <div className="col-md-12">
                                        {this.state.imagens.length > 0 && (
                                          <ImageGallery
                                            showPlayButton={false}
                                            items={this.state.imagens.map(
                                              (it) => {
                                                return {
                                                  original: it.base64,
                                                  thumbnail: it.base64,
                                                };
                                              }
                                            )}
                                            showBullets={true}
                                            showIndex={true}
                                            originalClass="teste-felipe2"
                                          />
                                        )}
                                      </div>

                                      <input
                                        type="file"
                                        ref={this.hiddenFileInput}
                                        onChange={(e) => {
                                          const extension = e.target.files[0].name
                            .split(".")
                            .pop();
                                          getBase64(e.target.files[0]).then(
                                            (data) => {
                                              let imagens = this.state.imagens;

                                              imagens.push({
                                                base64: data,
                                                _id: null,
                                                status: "A",
                                                type: "anexoNaoConformidade",
                                                extensaoImagem: extension,
                                              });
                                              this.setState(
                                                { imagens },
                                                () => {}
                                              );
                                            }
                                          );
                                        }}
                                        style={{ display: "none" }}
                                      />

                                      <div
                                        className="d-flex"
                                        style={{
                                          justifyContent: "center",
                                          marginTop: "30px",
                                          width: "100%",
                                        }}
                                      >
                                        <div style={{ margin: "0 3px" }}>
                                          {/* <a
                        className="btn btn-success mb-2 mx-auto rounded"
                        onClick={() => {
                          this.hiddenFileInput.current.click();
                        }}
                      > */}

                                          <button
                                            className="btn btn-info btn-app-azul"
                                            onClick={() => {
                                              this.hiddenFileInput.current.click();
                                            }}
                                          >
                                            <i className="fa fa-photo"></i>
                                            &nbsp;&nbsp;Adicionar
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </React.Fragment>
                                )}

                                {this.state.abaAtual === "riscoCausa" && ( // ABA_RISCO
                                  <React.Fragment>
                                    <div className="row">
                                      <div className="col-md-4">
                                        <div className="col-md-12">
                                          <div
                                            style={{
                                              fontWeight: "bold",
                                              fontSize: "18px",
                                              textAlign: "center",
                                              // width: "100%"
                                            }}
                                          >
                                            Avaliação de Risco
                                          </div>
                                        </div>

                                        <div
                                          className="row"
                                          style={{ justifyContent: "center" }}
                                        >
                                          <div className="col-md-6">
                                            <div
                                              style={{
                                                marginBottom: "5px",
                                                // textDecoration: "underline",
                                                marginTop: "10px",
                                                fontWeight: "bold",
                                                position: "relative",
                                              }}
                                            >
                                              <span
                                                style={{
                                                  textDecoration: "underline",
                                                }}
                                              >
                                                Financeiro
                                              </span>
                                              &nbsp;
                                              <InputRequired />
                                            </div>
                                            <div className="checkbox-group">
                                              <div className="form-check form-check2">
                                                <input
                                                  className="form-check-input form-check-input2"
                                                  type="checkbox"
                                                  id="check10"
                                                  checked={
                                                    this.state.naoConformidade
                                                      .avaliacaoRisco
                                                      .financeiro === "100000"
                                                  }
                                                  onChange={() =>
                                                    this.handleChangeAvaliacaoRisco(
                                                      "100000",
                                                      "financeiro"
                                                    )
                                                  }
                                                />
                                                <label
                                                  className="form-check-label2"
                                                  for="check10"
                                                >
                                                  100.000,00
                                                </label>
                                              </div>
                                              <div className="form-check form-check2">
                                                <input
                                                  className="form-check-input form-check-input2"
                                                  type="checkbox"
                                                  id="check11"
                                                  checked={
                                                    this.state.naoConformidade
                                                      .avaliacaoRisco
                                                      .financeiro === "10000"
                                                  }
                                                  onChange={() =>
                                                    this.handleChangeAvaliacaoRisco(
                                                      "10000",
                                                      "financeiro"
                                                    )
                                                  }
                                                />
                                                <label
                                                  className="form-check-label2"
                                                  for="check11"
                                                >
                                                  10.000,00
                                                </label>
                                              </div>
                                              <div className="form-check form-check2">
                                                <input
                                                  className="form-check-input form-check-input2"
                                                  type="checkbox"
                                                  id="check12"
                                                  checked={
                                                    this.state.naoConformidade
                                                      .avaliacaoRisco
                                                      .financeiro === "1000"
                                                  }
                                                  onChange={() =>
                                                    this.handleChangeAvaliacaoRisco(
                                                      "1000",
                                                      "financeiro"
                                                    )
                                                  }
                                                />
                                                <label
                                                  className="form-check-label2"
                                                  for="check12"
                                                >
                                                  1.000,00
                                                </label>
                                              </div>
                                              <div className="form-check form-check2">
                                                <input
                                                  className="form-check-input form-check-input2"
                                                  type="checkbox"
                                                  id="check13"
                                                  checked={
                                                    this.state.naoConformidade
                                                      .avaliacaoRisco
                                                      .financeiro === "100"
                                                  }
                                                  onChange={() =>
                                                    this.handleChangeAvaliacaoRisco(
                                                      "100",
                                                      "financeiro"
                                                    )
                                                  }
                                                />
                                                <label
                                                  className="form-check-label2"
                                                  for="check13"
                                                >
                                                  100,00
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-md-6">
                                            <div
                                              style={{
                                                marginBottom: "5px",
                                                // textDecoration: "underline",
                                                marginTop: "10px",
                                                fontWeight: "bold",
                                                position: "relative",
                                              }}
                                            >
                                              <span
                                                style={{
                                                  textDecoration: "underline",
                                                }}
                                              >
                                                Imagem
                                              </span>
                                              &nbsp;
                                              <InputRequired />
                                            </div>
                                            <div className="checkbox-group">
                                              <div className="form-check form-check2">
                                                <input
                                                  className="form-check-input form-check-input2"
                                                  type="checkbox"
                                                  id="check14"
                                                  checked={
                                                    this.state.naoConformidade
                                                      .avaliacaoRisco.imagem ===
                                                    "Crítico"
                                                  }
                                                  onChange={() =>
                                                    this.handleChangeAvaliacaoRisco(
                                                      "Crítico",
                                                      "imagem"
                                                    )
                                                  }
                                                />
                                                <label
                                                  className="form-check-label2"
                                                  for="check14"
                                                >
                                                  Crítico
                                                </label>
                                              </div>
                                              <div className="form-check form-check2">
                                                <input
                                                  className="form-check-input form-check-input2"
                                                  type="checkbox"
                                                  id="check15"
                                                  checked={
                                                    this.state.naoConformidade
                                                      .avaliacaoRisco.imagem ===
                                                    "Alto"
                                                  }
                                                  onChange={() =>
                                                    this.handleChangeAvaliacaoRisco(
                                                      "Alto",
                                                      "imagem"
                                                    )
                                                  }
                                                />
                                                <label
                                                  className="form-check-label2"
                                                  for="check15"
                                                >
                                                  Alto
                                                </label>
                                              </div>
                                              <div className="form-check form-check2">
                                                <input
                                                  className="form-check-input form-check-input2"
                                                  type="checkbox"
                                                  id="check16"
                                                  checked={
                                                    this.state.naoConformidade
                                                      .avaliacaoRisco.imagem ===
                                                    "Médio"
                                                  }
                                                  onChange={() =>
                                                    this.handleChangeAvaliacaoRisco(
                                                      "Médio",
                                                      "imagem"
                                                    )
                                                  }
                                                />
                                                <label
                                                  className="form-check-label2"
                                                  for="check16"
                                                >
                                                  Médio
                                                </label>
                                              </div>
                                              <div className="form-check form-check2">
                                                <input
                                                  className="form-check-input form-check-input2"
                                                  type="checkbox"
                                                  id="check17"
                                                  checked={
                                                    this.state.naoConformidade
                                                      .avaliacaoRisco.imagem ===
                                                    "Baixo"
                                                  }
                                                  onChange={() =>
                                                    this.handleChangeAvaliacaoRisco(
                                                      "Baixo",
                                                      "imagem"
                                                    )
                                                  }
                                                />
                                                <label
                                                  className="form-check-label2"
                                                  for="check17"
                                                >
                                                  Baixo
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <div
                                          className="row"
                                          style={{
                                            marginBottom: "50px",
                                            justifyContent: "center",
                                          }}
                                        >
                                          <div className="col-md-6">
                                            <div
                                              style={{
                                                marginBottom: "5px",
                                                // textDecoration: "underline",
                                                marginTop: "10px",
                                                fontWeight: "bold",
                                                position: "relative",
                                              }}
                                            >
                                              <span
                                                style={{
                                                  textDecoration: "underline",
                                                }}
                                              >
                                                Segurança
                                              </span>
                                              &nbsp;
                                              <InputRequired />
                                            </div>
                                            <div className="checkbox-group">
                                              <div className="form-check form-check2">
                                                <input
                                                  className="form-check-input form-check-input2"
                                                  type="checkbox"
                                                  id="check18"
                                                  checked={
                                                    this.state.naoConformidade
                                                      .avaliacaoRisco
                                                      .seguranca ===
                                                    "Morte Iminente"
                                                  }
                                                  onChange={() =>
                                                    this.handleChangeAvaliacaoRisco(
                                                      "Morte Iminente",
                                                      "seguranca"
                                                    )
                                                  }
                                                />
                                                <label
                                                  className="form-check-label2"
                                                  for="check18"
                                                >
                                                  Morte Iminente
                                                </label>
                                              </div>
                                              <div className="form-check form-check2">
                                                <input
                                                  className="form-check-input form-check-input2"
                                                  type="checkbox"
                                                  id="check19"
                                                  checked={
                                                    this.state.naoConformidade
                                                      .avaliacaoRisco
                                                      .seguranca === "Alto"
                                                  }
                                                  onChange={() =>
                                                    this.handleChangeAvaliacaoRisco(
                                                      "Alto",
                                                      "seguranca"
                                                    )
                                                  }
                                                />
                                                <label
                                                  className="form-check-label2"
                                                  for="check19"
                                                >
                                                  Alto
                                                </label>
                                              </div>
                                              <div className="form-check form-check2">
                                                <input
                                                  className="form-check-input form-check-input2"
                                                  type="checkbox"
                                                  id="check20"
                                                  checked={
                                                    this.state.naoConformidade
                                                      .avaliacaoRisco
                                                      .seguranca === "Médio"
                                                  }
                                                  onChange={() =>
                                                    this.handleChangeAvaliacaoRisco(
                                                      "Médio",
                                                      "seguranca"
                                                    )
                                                  }
                                                />
                                                <label
                                                  className="form-check-label2"
                                                  for="check20"
                                                >
                                                  Médio
                                                </label>
                                              </div>
                                              <div className="form-check form-check2">
                                                <input
                                                  className="form-check-input form-check-input2"
                                                  type="checkbox"
                                                  id="check21"
                                                  checked={
                                                    this.state.naoConformidade
                                                      .avaliacaoRisco
                                                      .seguranca === "Baixo"
                                                  }
                                                  onChange={() =>
                                                    this.handleChangeAvaliacaoRisco(
                                                      "Baixo",
                                                      "seguranca"
                                                    )
                                                  }
                                                />
                                                <label
                                                  className="form-check-label2"
                                                  for="check21"
                                                >
                                                  Baixo
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-md-6">
                                            <div
                                              style={{
                                                marginBottom: "5px",
                                                // textDecoration: "underline",
                                                marginTop: "10px",
                                                fontWeight: "bold",
                                                position: "relative",
                                              }}
                                            >
                                              <span
                                                style={{
                                                  textDecoration: "underline",
                                                }}
                                              >
                                                Prazo
                                              </span>
                                              &nbsp;
                                              <InputRequired />
                                            </div>
                                            <div className="checkbox-group">
                                              <div className="form-check form-check2">
                                                <input
                                                  className="form-check-input form-check-input2"
                                                  type="checkbox"
                                                  id="check22"
                                                  checked={
                                                    this.state.naoConformidade
                                                      .avaliacaoRisco.prazo ===
                                                    "Imediato"
                                                  }
                                                  onChange={() =>
                                                    this.handleChangeAvaliacaoRisco(
                                                      "Imediato",
                                                      "prazo"
                                                    )
                                                  }
                                                />
                                                <label
                                                  className="form-check-label2"
                                                  for="check22"
                                                >
                                                  Imediato
                                                </label>
                                              </div>
                                              <div className="form-check form-check2">
                                                <input
                                                  className="form-check-input form-check-input2"
                                                  type="checkbox"
                                                  id="check23"
                                                  checked={
                                                    this.state.naoConformidade
                                                      .avaliacaoRisco.prazo ===
                                                    "15 dias"
                                                  }
                                                  onChange={() =>
                                                    this.handleChangeAvaliacaoRisco(
                                                      "15 dias",
                                                      "prazo"
                                                    )
                                                  }
                                                />
                                                <label
                                                  className="form-check-label2"
                                                  for="check23"
                                                >
                                                  15 dias
                                                </label>
                                              </div>
                                              <div className="form-check form-check2">
                                                <input
                                                  className="form-check-input form-check-input2"
                                                  type="checkbox"
                                                  id="check24"
                                                  checked={
                                                    this.state.naoConformidade
                                                      .avaliacaoRisco.prazo ===
                                                    "60 dias"
                                                  }
                                                  onChange={() =>
                                                    this.handleChangeAvaliacaoRisco(
                                                      "60 dias",
                                                      "prazo"
                                                    )
                                                  }
                                                />
                                                <label
                                                  className="form-check-label2"
                                                  for="check24"
                                                >
                                                  60 dias
                                                </label>
                                              </div>
                                              <div className="form-check form-check2">
                                                <input
                                                  className="form-check-input form-check-input2"
                                                  type="checkbox"
                                                  id="check25"
                                                  checked={
                                                    this.state.naoConformidade
                                                      .avaliacaoRisco.prazo ===
                                                    "180 dias"
                                                  }
                                                  onChange={() =>
                                                    this.handleChangeAvaliacaoRisco(
                                                      "180 dias",
                                                      "prazo"
                                                    )
                                                  }
                                                />
                                                <label
                                                  className="form-check-label2"
                                                  for="check25"
                                                >
                                                  180 dias
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-8">
                                        <div
                                          style={{
                                            fontWeight: "bold",
                                            fontSize: "18px",
                                            textAlign: "center",
                                          }}
                                        >
                                          Análise de Causa Raiz
                                        </div>
                                        <table className="table">
                                          <thead className="thead-dark">
                                            <th>Descrição</th>
                                            <th className="text-center">Sim</th>
                                            <th>Análise</th>
                                          </thead>
                                          <tbody>
                                            {this.state.naoConformidade.analiseCausaRaiz.map(
                                              (item) => (
                                                <tr>
                                                  <td>
                                                    {item.codigo} - {item.desc}
                                                  </td>
                                                  <td className="text-center">
                                                    <input
                                                      type="checkbox"
                                                      checked={item.ativo}
                                                      onChange={(e) =>
                                                        this.handleClickCheckAnaliseCausaRaiz(
                                                          item.codigo,
                                                          e.target.checked
                                                        )
                                                      }
                                                      disabled={
                                                        this.state
                                                          .naoConformidade
                                                          .situacao === "2"
                                                      }
                                                    ></input>
                                                  </td>
                                                  <td>
                                                    <input
                                                      ref={
                                                        this[
                                                          `input${item.codigo}`
                                                        ]
                                                      }
                                                      type="text"
                                                      className="form-control"
                                                      value={item.analise}
                                                      onChange={(e) =>
                                                        this.onChangeInputCausaRaiz(
                                                          e.target.value,
                                                          item.codigo
                                                        )
                                                      }
                                                      disabled={
                                                        this.state
                                                          .naoConformidade
                                                          .situacao === "2"
                                                      }
                                                    ></input>
                                                  </td>
                                                </tr>
                                              )
                                            )}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </React.Fragment>
                                )}

                                {this.state.abaAtual ===
                                  "corretivaPreventiva" && (
                                  <React.Fragment>
                                    <div className="row">
                                      <div className="col-md-6 pd-veiculo2">
                                        <div className="form-group form-group-chegoo">
                                          <label>
                                            Responsável Erro
                                          </label>

                                          <Select
                                            value={this.state.responsavelErro}
                                            onChange={(item) =>
                                              this.onChangeInput(
                                                "responsavelErro",
                                                item
                                              )
                                            }
                                            options={[
                                              {
                                                value: "Administração",
                                                label: "Administração",
                                              },
                                              {
                                                value: "Cliente",
                                                label: "Cliente",
                                              },
                                              {
                                                value: "Comercial",
                                                label: "Comercial",
                                              },
                                              {
                                                value: "Coordenação",
                                                label: "Coordenação",
                                              },
                                              {
                                                value: "Fornecedor",
                                                label: "Fornecedor",
                                              },

                                              {
                                                value: "Obras/Engenharia",
                                                label: "Obras/Engenharia",
                                              },

                                              {
                                                value: "Produção",
                                                label: "Produção",
                                              },
                                            ]}
                                            placeholder="Selecione"
                                            className={`${
                                              this.state.errors.usuario
                                                ? "select-error"
                                                : ""
                                            } required-chegoo`}
                                            isDisabled={
                                              this.state.naoConformidade
                                                .situacao === "2"
                                            }
                                            styles={{
                                              control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                fontSize: "14px",
                                                // height: "35px",
                                                minHeight: "35px",
                                                backgroundColor: "#fffef6",
                                              }),
                                              menu: (baseStyles, state) => ({
                                                ...baseStyles,
                                                fontSize: "14px",
                                              }),
                                            }}
                                            isMulti={true}
                                          />
                                        </div>
                                      </div>{" "}
                                    </div>

                                    <div className="row">
                                      <div className="col-md-6 no-padding">
                                        <div className="col-md-12">
                                          <div className="form-group form-group-chegoo">
                                            <label>Responsável Corretiva</label>

                                            <input
                                              type="text"
                                              className="form-control form-control-chegoo font-size-14 required-chegoo"
                                              value={
                                                this.state.naoConformidade
                                                  .acaoCorretiva.responsavel
                                              }
                                              onChange={(e) =>
                                                this.onChangeInputNaoConformidade(
                                                  "acaoCorretiva.responsavel",
                                                  e.target.value
                                                )
                                              }
                                              disabled={
                                                this.state.naoConformidade
                                                  .situacao === "2"
                                              }
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-12 pd-veiculo2">
                                          <div className="form-group form-group-chegoo">
                                            <label>Ação Corretiva</label>

                                            <textarea
                                              rows="5"
                                              type="text"
                                              label=""
                                              className={`form-control form-control-chegoo2 font-size-14 required-chegoo text-area-form2`}
                                              value={
                                                this.state.naoConformidade
                                                  .acaoCorretiva.desc
                                              }
                                              onChange={(e) => {
                                                let value = e.target.value;

                                                this.onChangeInputNaoConformidade(
                                                  "acaoCorretiva.desc",
                                                  value
                                                );
                                              }}
                                              ref={(node) => {
                                                if (node) {
                                                  node.style.setProperty(
                                                    "height",
                                                    "auto",
                                                    "important"
                                                  );
                                                }
                                              }}
                                              disabled={
                                                this.state.naoConformidade
                                                  .situacao === "2"
                                              }
                                            />
                                          </div>
                                        </div>{" "}
                                      </div>
                                      <div className="col-md-6 no-padding">
                                        <div className="col-md-12">
                                          <div className="form-group form-group-chegoo">
                                            <label>
                                              Responsável Preventiva
                                            </label>

                                            <input
                                              type="text"
                                              className="form-control form-control-chegoo font-size-14 required-chegoo"
                                              value={
                                                this.state.naoConformidade
                                                  .acaoPreventiva.responsavel
                                              }
                                              onChange={(e) =>
                                                this.onChangeInputNaoConformidade(
                                                  "acaoPreventiva.responsavel",
                                                  e.target.value
                                                )
                                              }
                                              disabled={
                                                this.state.naoConformidade
                                                  .situacao === "2"
                                              }
                                            />
                                          </div>
                                        </div>
                                        <div className="col-md-12 pd-veiculo2">
                                          <div className="form-group form-group-chegoo">
                                            <label>Ação Preventiva</label>

                                            <textarea
                                              rows="5"
                                              type="text"
                                              label=""
                                              className={`form-control form-control-chegoo2 font-size-14 required-chegoo text-area-form2`}
                                              value={
                                                this.state.naoConformidade
                                                  .acaoPreventiva.desc
                                              }
                                              onChange={(e) => {
                                                let value = e.target.value;

                                                this.onChangeInputNaoConformidade(
                                                  "acaoPreventiva.desc",
                                                  value
                                                );
                                              }}
                                              ref={(node) => {
                                                if (node) {
                                                  node.style.setProperty(
                                                    "height",
                                                    "auto",
                                                    "important"
                                                  );
                                                }
                                              }}
                                              disabled={
                                                this.state.naoConformidade
                                                  .situacao === "2"
                                              }
                                            />
                                          </div>
                                        </div>{" "}
                                      </div>
                                    </div>
                                  </React.Fragment>
                                )}

                                {this.state.abaAtual === "encerramento" && (
                                  <React.Fragment>
                                    <div
                                      className="row"
                                      style={{ paddingBottom: "50px" }}
                                    >
                                      <div className="col-md-3 pd-veiculo2">
                                        <div
                                          className="form-group form-group-chegoo"
                                          style={{ position: "relative" }}
                                        >
                                          <label>Reincidência</label>
                                          <Select
                                            value={
                                              this.state
                                                .naoConformidadeReincidencia
                                            }
                                            onChange={(item) =>
                                              this.onChangeInput(
                                                "naoConformidadeReincidencia",
                                                item
                                              )
                                            }
                                            options={
                                              this.state.listaNaoConformidades
                                            }
                                            placeholder="Selecione"
                                            className={`${
                                              this.state.errors.usuario
                                                ? "select-error"
                                                : ""
                                            } required-chegoo`}
                                            isDisabled={
                                              this.state.naoConformidade
                                                .situacao === "2"
                                            }
                                            styles={{
                                              control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                fontSize: "14px",
                                                height: "35px",
                                                minHeight: "35px",
                                                backgroundColor: "#fffef6",
                                              }),
                                              menu: (baseStyles, state) => ({
                                                ...baseStyles,
                                                fontSize: "14px",
                                              }),
                                            }}
                                          />
                                          {this.state
                                            .naoConformidadeReincidencia !==
                                            null && (
                                            <small
                                              style={{ cursor: "pointer" }}
                                              onClick={() =>
                                                this.onClickAbrirNaoConformidade(
                                                  this.state
                                                    .naoConformidadeReincidencia
                                                    ._id
                                                )
                                              }
                                            >
                                              Visualizar
                                            </small>
                                          )}
                                        </div>
                                      </div>
                                      <div className="col-md-3 pd-veiculo2">
                                        <div
                                          className="form-group form-group-chegoo"
                                          style={{ position: "relative" }}
                                        >
                                          <label>Vinculação</label>
                                          <Select
                                            value={
                                              this.state
                                                .naoConformidadeVinculacao
                                            }
                                            onChange={(item) =>
                                              this.onChangeInput(
                                                "naoConformidadeVinculacao",
                                                item
                                              )
                                            }
                                            options={
                                              this.state.listaNaoConformidades
                                            }
                                            placeholder="Selecione"
                                            className={`${
                                              this.state.errors.usuario
                                                ? "select-error"
                                                : ""
                                            } required-chegoo`}
                                            isDisabled={
                                              this.state.naoConformidade
                                                .situacao === "2"
                                            }
                                            styles={{
                                              control: (baseStyles, state) => ({
                                                ...baseStyles,
                                                fontSize: "14px",
                                                height: "35px",
                                                minHeight: "35px",
                                                backgroundColor: "#fffef6",
                                              }),
                                              menu: (baseStyles, state) => ({
                                                ...baseStyles,
                                                fontSize: "14px",
                                              }),
                                            }}
                                          />
                                          {this.state
                                            .naoConformidadeVinculacao !==
                                            null && (
                                            <small
                                              style={{ cursor: "pointer" }}
                                              onClick={() =>
                                                this.onClickAbrirNaoConformidade(
                                                  this.state
                                                    .naoConformidadeVinculacao
                                                    ._id
                                                )
                                              }
                                            >
                                              Visualizar
                                            </small>
                                          )}
                                        </div>
                                      </div>
                                      <div className="col-md-3 pd-veiculo2">
                                        <div className="form-group form-group-chegoo">
                                          <label>
                                            Responsável&nbsp;
                                            <InputRequired />
                                          </label>

                                          <input
                                            type="text"
                                            className="form-control form-control-chegoo font-size-14 required-chegoo"
                                            value={
                                              this.state.naoConformidade
                                                .responsavelEncerramento
                                            }
                                            onChange={(e) =>
                                              this.onChangeInput(
                                                "naoConformidade.responsavelEncerramento",
                                                e.target.value
                                              )
                                            }
                                            disabled={
                                              this.state.naoConformidade
                                                .situacao === "2"
                                            }
                                          />
                                        </div>
                                      </div>{" "}
                                      <div className="col-md-3 pd-veiculo3">
                                        <div className="form-group form-group-chegoo">
                                          <label>
                                            Data Finalização&nbsp;
                                            <InputRequired />
                                          </label>

                                          <DatePicker
                                            selected={this.state.dtEncerramento}
                                            customInput={
                                              <input
                                                className="form-control font-size-14 form-control-chegoo text-center"
                                                style={{ width: "100%" }}
                                              />
                                            }
                                            dateFormat="dd/MM/yyyy"
                                            onChange={(date) => {
                                              this.onChangeInput(
                                                "dtEncerramento",
                                                date
                                              );
                                            }}
                                            locale="pt-BR"
                                            disabled={
                                              this.state.naoConformidade
                                                .situacao === "2"
                                            }
                                          />
                                        </div>
                                      </div>{" "}
                                    </div>
                                  </React.Fragment>
                                )}

                                <div className="row">
                                  <div className="col-md-6 pd-veiculo2 d-flex">
                                    <div
                                      className="form-group form-group-chegoo2"
                                      style={{ margin: "3px" }}
                                    >
                                      <button
                                        className="btn btn-success btn-app-verde"
                                        onClick={() => {
                                          this.onClickSalvar(false);
                                        }}
                                        disabled={
                                          this.state.naoConformidade
                                            .situacao === "2"
                                        }
                                      >
                                        {this.state.isSaving
                                          ? "Salvando..."
                                          : "Salvar"}
                                      </button>
                                    </div>

                                    {this.state.abaAtual !== "encerramento" && (
                                      <div
                                        className="form-group form-group-chegoo2"
                                        style={{ margin: "3px" }}
                                      >
                                        <button
                                          className="btn btn-secondary btn-app-cinza"
                                          onClick={() => {
                                            this.onClickProximaEtapa();
                                          }}
                                        >
                                          Próximo
                                        </button>
                                      </div>
                                    )}
                                    {this.state.abaAtual === "encerramento" && (
                                      <div
                                        className="form-group form-group-chegoo2"
                                        style={{ margin: "3px" }}
                                      >
                                        <button
                                          className="btn btn-info btn-app-cinza"
                                          onClick={() => {
                                            this.onClickSalvar(true);
                                          }}
                                          disabled={
                                            this.state.naoConformidade
                                              .situacao === "2"
                                          }
                                        >
                                          {this.state.isFinishing
                                            ? "Finalizando..."
                                            : "Finalizar"}
                                        </button>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>

        <Modal
          isOpen={this.state.modalExcluirFoto}
          toggle={this.toggleModalExcluirFoto}
        >
          <ModalHeader toggle={this.toggleModalExcluirFoto}>
            Deletar Foto ?
          </ModalHeader>
          <ModalBody>
            Tem certeza que deseja deletar esta foto da Não Conformidade
          </ModalBody>
          <ModalFooter>
            <Button
              className="btn-modal-recuperar"
              color="secondary"
              onClick={this.toggleModalExcluirFoto}
            >
              Não
            </Button>
            <Button
              className="btn-modal-recuperar"
              color="primary"
              onClick={this.handleClickConfirmaDeletar}
            >
              Sim
            </Button>{" "}
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}
