import React, { Component } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { SideBar, NavBar, Footer } from "../../components";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import Select from "react-select";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  UncontrolledTooltip,
} from "reactstrap";

class Obra extends Component {
  constructor(props) {
    super(props);
    this.load = this.load.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleClickDeletar = this.handleClickDeletar.bind(this);
    this.handleClickConfirmaDeletar =
      this.handleClickConfirmaDeletar.bind(this);
    this.onChangeInputFiltro = this.onChangeInputFiltro.bind(this);
    this.loadUsuarios = this.loadUsuarios.bind(this);
    this.limparFiltros = this.limparFiltros.bind(this);

    this.state = {
      obras: [],
      obraDeletar: null,
      modal: false,
      filtro: {
        usuario: null,
        situacao: "99", //99todos 0=aberta e 1 = finalizada
      },
      usuarios: [],
    };
  }

  async componentDidMount() {
    const userLogado = JSON.parse(localStorage.getItem('usuario'));
    if(userLogado.nivel === "3"){
      return this.props.history.push('/pedidos-material')
    }
    if (userLogado.nivel === "4") {
      return this.props.history.push("/nao-conformidades");
    }
    if (userLogado.nivel === "5") {
      return this.props.history.push("/despesas");
    }
    await this.load();
    await this.loadUsuarios();
  }

  async load() {
    let filtro = this.state.filtro;

    let data = {};

    if (filtro.usuario !== null) {
      data.usuario = filtro.usuario._id;
    }

    if (filtro.situacao !== "99") {
      data.situacao = filtro.situacao;
    }

    await axios
      .post(
        `${this.props.getBaseUrlApi()}/retaguarda/lista-obras`,
        data,
        this.props.parameters()
      )
      .then((response) => {
        let obras = response.data.obras;

        console.log("aaa", obras);

        for (let item of obras) {
          item.situacaoDesc = item.situacao === "0" ? "Aberta" : "Finalizada";
        }

        this.setState({ obras });
      });
  }

  notificar(type, message) {
    toast[type](message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  handleClickDeletar(id) {
    this.setState({ obraDeletar: id, modal: true });
  }

  toggle() {
    this.setState({ modal: !this.state.modal });
  }

  async handleClickConfirmaDeletar() {
    await axios
      .delete(
        `${this.props.getBaseUrlApi()}/retaguarda/obras/${
          this.state.obraDeletar
        }`,
        this.props.parameters()
      )
      .then((response) => {
        this.setState({ obraDeletar: null, modal: false }, () => {
          this.load();
          this.notificar("success", "Obra deletada com sucesso!");
        });
      })
      .catch((error) => {
        this.setState({ obraDeletar: null, modal: false }, () => {
          this.notificar("warning", error.response.data.erro);
        });
      });
  }

  onChangeInputFiltro(type, value) {
    let filtro = this.state.filtro;
    filtro[type] = value;
    this.setState({ filtro });
  }

  async loadUsuarios() {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/retaguarda/usuarios`,
        this.props.parameters()
      )
      .then((response) => {
        let usuarios = response.data.usuarios;

        for (let item of usuarios) {
          item.label = item.desc;
          item.value = item._id;
        }

        this.setState({ usuarios });
      });
  }

  limparFiltros() {
    this.setState(
      {
        filtro: {
          usuario: null,
          situacao: "99",
        },
      },
      () => {
        this.load();
      }
    );
  }

  render() {
    return (
      <React.Fragment>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />

        <div className="container-scroller font-app">
          <NavBar title="Obras" />
          <div className="container-fluid page-body-wrapper">
            <SideBar itemActive={"obras"} />
            <div className="main-panel">
              <div className="content-wrapper">
                {/* <TitleAndDescription title="Usuários" description="" /> */}

                <div
                  className="row"
                  style={{ marginBottom: "0.8rem", alignItems: "center" }}
                >
                  <div className="col-md-12 stretch-card">
                    {true && (
                      <div className="card">
                        <div
                          className="card-body5"
                          style={{ position: "relative" }}
                        >
                          <React.Fragment>
                            <div className="row">
                              <div className="col-md-3 pd-veiculo2">
                                <div className="form-group form-group-chegoo2">
                                  <label>Usuário</label>
                                  <Select
                                    value={this.state.filtro.usuario}
                                    onChange={(item) =>
                                      this.onChangeInputFiltro("usuario", item)
                                    }
                                    options={this.state.usuarios}
                                    placeholder="Selecione o usuário"
                                    styles={{
                                      control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        fontSize: "14px",
                                        height: "35px",
                                        minHeight: "35px",
                                        backgroundColor: "#fffef6",
                                      }),
                                      menu: (baseStyles, state) => ({
                                        ...baseStyles,
                                        fontSize: "14px",
                                      }),
                                    }}
                                  />
                                </div>
                              </div>

                              <div className="col-md-2 pd-veiculo">
                                <div className="form-group form-group-chegoo2">
                                  <label>Situacao</label>
                                  <select
                                    className="form-control form-control-chegoo font-size-14"
                                    value={this.state.filtro.situacao}
                                    onChange={(e) =>
                                      this.onChangeInputFiltro(
                                        "situacao",
                                        e.target.value
                                      )
                                    }
                                  >
                                    <option value="99">Todos</option>
                                    <option value="0">Aberta</option>
                                    <option value="1">Finalizada</option>
                                  </select>
                                </div>
                              </div>
                              <div
                                className="col-md-2 pd-veiculo"
                                style={{ display: "flex", marginTop: "15px" }}
                              >
                                <div
                                  className="form-group-chegoo2"
                                  style={{ float: "left" }}
                                >
                                  <label></label>
                                  <button
                                    className="btn btn-secondary btn-sm"
                                    style={{
                                      padding: "0.275rem 0.55rem",
                                      margin: "10px 5px 0px",
                                    }}
                                    onClick={() => this.load()}
                                    id={`tooltip-filtro-atendimentos`}
                                  >
                                    <i
                                      className="fa fa-search"
                                      aria-hidden="true"
                                      style={{ fontSize: "px" }}
                                    ></i>
                                  </button>
                                  <UncontrolledTooltip
                                    placement="bottom"
                                    target={`tooltip-filtro-atendimentos`}
                                  >
                                    Filtrar
                                  </UncontrolledTooltip>
                                </div>
                                <div className="form-group-chegoo2">
                                  <label></label>
                                  <button
                                    className="btn btn-warning btn-sm"
                                    style={{
                                      padding: "0.275rem 0.55rem",
                                      margin: "10px 5px 0px",
                                      color: "#FFF",
                                    }}
                                    onClick={() => this.limparFiltros()}
                                    id={`tooltip-limpar-filtro-atendimentos`}
                                  >
                                    <i
                                      className="fa fa-eraser"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                  <UncontrolledTooltip
                                    placement="bottom"
                                    target={`tooltip-limpar-filtro-atendimentos`}
                                  >
                                    Limpar filtro
                                  </UncontrolledTooltip>
                                </div>
                              </div>
                              <div
                                className="offset-md-3 col-md-2 pd-veiculo3"
                                style={{
                                  marginTop: "15px",
                                  marginTop: "15px",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "end",
                                }}
                              >
                                <button
                                  className="btn btn-success btn-app-verde-sm"
                                  onClick={() => {
                                    this.props.history.push("/obras-nova");
                                  }}
                                >
                                  CADASTRAR
                                </button>
                              </div>
                            </div>
                          </React.Fragment>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12 stretch-card">
                    {this.state.obras.length > -1 && (
                      <div className="card">
                        <div className="card-body2">
                          <React.Fragment>
                            <div className="table-responsive">
                              <table
                                id="recent-purchases-listing"
                                className="table"
                              >
                                <thead className="thead-dark">
                                  <tr>
                                    <th className="text-right">Código</th>
                                    <th>Obra</th>
                                    <th>Usuário</th>
                                    <th>Situação</th>
                                    <th className="text-right">
                                      Data Cadastro
                                    </th>
                                    <th className="text-right">
                                      Data Finalização
                                    </th>
                                    <th>Ações</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.obras.map((obra, key) => (
                                    <tr key={key}>
                                      <td className="text-right">
                                        {obra.cod.toString().padStart(4, '0')}
                                      </td>
                                      <td>{obra.desc}</td>
                                      <td>
                                        {" "}
                                        {obra.usuario !== null
                                          ? obra.usuario.desc
                                          : ""}
                                      </td>
                                      <td> {obra.situacaoDesc}</td>
                                      <td className="text-right">
                                        {" "}
                                        {moment(obra.dtCadastro).format(
                                          "DD/MM/YYYY"
                                        )}
                                      </td>
                                      <td className="text-right">
                                        {" "}
                                        {obra.dtFinalizacao !== null &&
                                        obra.situacao === "1"
                                          ? moment(obra.dtFinalizacao).format(
                                              "DD/MM/YYYY"
                                            )
                                          : ""}
                                      </td>

                                      <td
                                        className="d-flex"
                                        style={{ fontSize: "18px" }}
                                      >
                                        <div
                                          style={{
                                            margin: "2px",
                                            cursor: "pointer",
                                          }}
                                        >
                                          <i
                                            className="fa fa-edit"
                                            onClick={() => {
                                              this.props.history.push(
                                                `/obras-nova?id=${obra._id}`
                                              );
                                            }}
                                            id={`btn-editar-${obra._id}`}
                                          ></i>

                                          <UncontrolledTooltip
                                            placement="bottom"
                                            target={`btn-editar-${obra._id}`}
                                          >
                                            Editar
                                          </UncontrolledTooltip>
                                        </div>
                                        <div
                                          style={{
                                            margin: "2px",
                                            cursor: "pointer",
                                          }}
                                        >
                                          <i
                                            className="fa fa-trash"
                                            style={{ color: "red" }}
                                            onClick={() => {
                                              this.handleClickDeletar(obra._id);
                                            }}
                                            id={`btn-excluir-${obra._id}`}
                                          ></i>

                                          <UncontrolledTooltip
                                            placement="bottom"
                                            target={`btn-excluir-${obra._id}`}
                                          >
                                            Excluir
                                          </UncontrolledTooltip>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </React.Fragment>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>

        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Deletar Obra?</ModalHeader>
          <ModalBody>Tem certeza que deseja deletar esta obra?</ModalBody>
          <ModalFooter>
            <Button
              className="btn-modal-recuperar"
              color="secondary"
              onClick={this.toggle}
            >
              Não
            </Button>
            <Button
              className="btn-modal-recuperar"
              color="primary"
              onClick={this.handleClickConfirmaDeletar}
            >
              Sim
            </Button>{" "}
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}

export default Obra;
