import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import { SideBar, NavBar, TitleAndDescription, Footer } from "../../components";
import "react-toastify/dist/ReactToastify.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Switch from "react-switch";
import axios from "axios";
import { truncateWithEllipsis } from "@amcharts/amcharts4/.internal/core/utils/Utils";
import Select from "react-select";
import queryString from "query-string";
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from "reactstrap";
const { v4: uuidv4 } = require("uuid");
const { phoneMask } = require("../../utils");
const { cpfMask, cnpjMask, placaVeicularMask } = require("../../mask");


export default class VeiculoNovo extends Component {
  constructor(props) {
    super(props);
    this.notificar = this.notificar.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
    this.load = this.load.bind(this);
    this.load2 = this.load2.bind(this);
    this.onChangeMotorista = this.onChangeMotorista.bind(this);
    this.cadastrarVeiculo = this.cadastrarVeiculo.bind(this);
    this.validarVeiculo = this.validarVeiculo.bind(this);
    this.buscarVeiculo = this.buscarVeiculo.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleClickDeletar = this.handleClickDeletar.bind(this);
    this.handleClickConfirmaDeletar =
      this.handleClickConfirmaDeletar.bind(this);
    this.handleFocus = this.handleFocus.bind(this);

    this.state = {
      _id: null,
      placa: "",
      veiculo: "",
      motorista: null,
      idIntegracao: "",

      anoFabricacao: new Date(),

      motoristas: [],
      errors: {
        placa: false,
        veiculo: false,
        motorista: false,
        anoFabricacao: false,
        tipoCombustivel: false,
        capacidade: false,
        tara: false,
        capacidadeM3: false,
        tanque: false,
      },
      veiculoDeletar: null,
      modal: false,

      tiposVeiculos: [
        {
          value: 0,
          label: "Caminhão",
        },
        {
          value: 1,
          label: "Carro",
        },
        {
          value: 2,
          label: "Moto",
        },

        {
          value: 3,
          label: "Trator",
        },
        {
          value: 4,
          label: "Utilitário",
        },
        {
          value: 5,
          label: "Caminhonete",
        },
        {
          value: 6,
          label: "Reboque",
        },
        {
          value: 7,
          label: "Semi-Reboque",
        },
      ],

      tiposCaracteristica: [
        {
          value: 0,
          label: "Tração",
        },
        {
          value: 1,
          label: "Reboque",
        },
      ],

      tiposRodado: [
        {
          value: 0,
          label: "Não aplicável",
        },
        {
          value: 1,
          label: "Truck",
        },
        {
          value: 2,
          label: "Toco",
        },
        {
          value: 3,
          label: "Cavalo Mecânico",
        },
        {
          value: 4,
          label: "Van",
        },
        {
          value: 5,
          label: "Utilitário",
        },
        {
          value: 6,
          label: "Outros",
        },
      ],

      tiposCarroceria: [
        {
          value: 0,
          label: "Não aplicável",
        },
        {
          value: 1,
          label: "Aberta",
        },
        {
          value: 2,
          label: "Fechada / Bau",
        },
        {
          value: 3,
          label: "Granelera",
        },
        {
          value: 4,
          label: "Porta Container",
        },
        {
          value: 5,
          label: "Sider",
        },
      ],

      tiposCombustivel: [
        {
          value: 0,
          label: "Diesel",
        },
        {
          value: 1,
          label: "Gasolina",
        },
        {
          value: 2,
          label: "Álcool",
        },
        {
          value: 3,
          label: "Flex",
        },
        {
          value: 4,
          label: "Gás Natural",
        },
        {
          value: 5,
          label: "Elétrico",
        },
      ],

      tiposPropriedade: [
        {
          value: 0,
          label: "Próprio",
        },
        {
          value: 1,
          label: "Terceiros",
        },
      ],

      tiposStatus: [
        {
          value: 0,
          label: "Ativo",
        },
        {
          value: 1,
          label: "Inativo",
        },
      ],

      capacidade: "0",
      tara: "0",
      capacidadeM3: "0",
      tanque: "0",

      tipoVeiculoSelecionado: null,
      tipoCaracteristicaSelecionado: null,
      tipoRodadoSelecionado: null,
      tipoCarroceriaSelecionado: null,
      tipoCombustivelSelecionado: null,
      tipoPropriedadeSelecionado: null,
      tipoStatusSelecionado: null,
      checklists: [],
      checklistsSelecionados: [],
    };
  }

  async componentDidMount() {
    let id = queryString.parse(this.props.location.search).id;

    if (id !== undefined) {
      await this.buscarVeiculo(id);
    }

    await this.load();
    await this.load2();

    if (id === undefined) {
      this.setState({
        tipoVeiculoSelecionado: this.state.tiposVeiculos[0],
        tipoCaracteristicaSelecionado: this.state.tiposCaracteristica[0],
        tipoRodadoSelecionado: this.state.tiposRodado[0],
        tipoCarroceriaSelecionado: this.state.tiposCarroceria[0],
        tipoPropriedadeSelecionado: this.state.tiposPropriedade[0],
        tipoStatusSelecionado: this.state.tiposStatus[0],
      });
    }
  }

  async load() {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/retaguarda/usuarios`,
        this.props.parameters()
      )
      .then((response) => {
        const data = response.data;
        let motoristaSelecionado = null;

        let motoristas = data.usuarios;

        for (let motorista of motoristas) {
          motorista.label = motorista.desc;
          motorista.value = motorista._id;
        }

        motoristas.unshift({
          label: "Indefinido",
          value: "indefinido",
        });

        if (this.state._id !== null) {
          let motoristaFind = motoristas.find(
            (motorista) => motorista._id === this.state.idMotorista
          );

          if (motoristaFind !== undefined) {
            motoristaSelecionado = motoristaFind;
          }
        } else {
          motoristaSelecionado = {
            label: "Indefinido",
            value: "indefinido",
          };
        }

        this.setState({ motoristas, motorista: motoristaSelecionado });
      });
  }

  async load2() {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/retaguarda/checklists`,
        this.props.parameters()
      )
      .then((response) => {
        let data = response.data.checklists;
        //let motoristaSelecionado = null;

        // // ////console.clear();
        /// console.log(data);

        data = data.map((item) => {
          return {
            ...item,
            label: item.desc,
            value: item._id,
          };
        });

        this.setState({ checklists: data });
        let lista = [];

        if (this.state._id !== null) {
          let idsChecklists = this.state.idsChecklists;

          for (let item of idsChecklists) {
            for (let itemChecklist of data) {
              if (item._id === itemChecklist._id) {
                lista.push(itemChecklist);
              }
            }
          }

          this.setState({ checklistsSelecionados: lista });
        }
      });
  }

  notificar(type, message) {
    toast[type](message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }

  onChangeInput(tipo, value) {
    if (tipo === "placa") {
      value = value.toUpperCase();
      value = placaVeicularMask(value)
    }
    this.setState({ [tipo]: value });
  }

  onChangeMotorista(value) {
    this.setState({ motorista: value });
  }

  validarVeiculo() {
    let errors = this.state.errors;
    let valido = true;

    if (this.state.placa === "") {
      valido = false;
      errors.placa = true;
    } else {
      errors.placa = false;
    }

    if (this.state.veiculo === "") {
      valido = false;
      errors.veiculo = true;
    } else {
      errors.veiculo = false;
    }

    if (this.state.anoFabricacao === null) {
      valido = false;
      errors.anoFabricacao = true;
    } else {
      errors.anoFabricacao = false;
    }

    if (this.state.tipoCombustivelSelecionado === null) {
      valido = false;
      errors.tipoCombustivel = true;
    } else {
      errors.tipoCombustivel = false;
    }

    if (this.state.motorista === null) {
      valido = false;
      errors.motorista = true;
    } else {
      errors.motorista = false;
    }

    let capacidade = this.state.capacidade;
    let tara = this.state.tara;
    let capacidadeM3 = this.state.capacidadeM3;
    let tanque = this.state.tanque;

    if (capacidade === "") {
      capacidade = "0";
    }

    if (tara === "") {
      tara = "0";
    }

    if (capacidadeM3 === "") {
      capacidadeM3 = "0";
    }

    if (tanque === "") {
      tanque = "0";
    }

    if (capacidade === "0") {
      valido = false;
      errors.capacidade = true;
    } else {
      errors.capacidade = false;
    }

    if (tara === "0") {
      valido = false;
      errors.tara = true;
    } else {
      errors.tara = false;
    }

    if (capacidadeM3 === "0") {
      valido = false;
      errors.capacidadeM3 = true;
    } else {
      errors.capacidadeM3 = false;
    }

    if (tanque === "0") {
      valido = false;
      errors.tanque = true;
    } else {
      errors.tanque = false;
    }

    // capacidade = parseFloat(capacidade.replace(',', '.'))
    // tara = parseFloat(tara.replace(',', '.'))
    // capacidadeM3 = parseFloat(tara.replace(',', '.'))

    // if (this.state.idIntegracao === "") {
    //   valido = false;
    //   errors.idIntegracao = true;
    // } else {
    //   errors.idIntegracao = false;
    // }

    this.setState({ errors });
    return valido;
  }

  async cadastrarVeiculo() {
    if (!this.validarVeiculo()) {
      this.notificar("warning", "Favor preencher todos os dados!");
      return false;
    }

    let acaoPagina = this.state._id === null ? "cadastrar" : "editar";

    let tipoVeiculo = this.state.tipoVeiculoSelecionado;
    tipoVeiculo.codigo = tipoVeiculo.value;
    tipoVeiculo.desc = tipoVeiculo.label;
    delete tipoVeiculo.value;
    delete tipoVeiculo.label;

    let tipoCaracteristica = this.state.tipoCaracteristicaSelecionado;
    tipoCaracteristica.codigo = tipoCaracteristica.value;
    tipoCaracteristica.desc = tipoCaracteristica.label;
    delete tipoCaracteristica.value;
    delete tipoCaracteristica.label;

    let tipoRodado = this.state.tipoRodadoSelecionado;
    tipoRodado.codigo = tipoRodado.value;
    tipoRodado.desc = tipoRodado.label;
    delete tipoRodado.value;
    delete tipoRodado.label;

    let tipoCarroceria = this.state.tipoCarroceriaSelecionado;
    tipoCarroceria.codigo = tipoCarroceria.value;
    tipoCarroceria.desc = tipoCarroceria.label;
    delete tipoCarroceria.value;
    delete tipoCarroceria.label;

    let tipoCombustivel = this.state.tipoCombustivelSelecionado;
    tipoCombustivel.codigo = tipoCombustivel.value;
    tipoCombustivel.desc = tipoCombustivel.label;
    delete tipoCombustivel.value;
    delete tipoCombustivel.label;

    let tipoPropriedade = this.state.tipoPropriedadeSelecionado;
    tipoPropriedade.codigo = tipoPropriedade.value;
    tipoPropriedade.desc = tipoPropriedade.label;
    delete tipoPropriedade.value;
    delete tipoPropriedade.label;

    let status = this.state.tipoStatusSelecionado.label;

    let checklists = this.state.checklistsSelecionados;

    checklists = checklists.map((item) => {
      return {
        _id: item._id,
        desc: item.desc,
      };
    });

    let capacidade = this.state.capacidade;
    let tara = this.state.tara;
    let capacidadeM3 = this.state.capacidadeM3;
    let tanque = this.state.tanque;

    capacidade = parseFloat(capacidade.replace(",", "."));
    tara = parseFloat(tara.replace(",", "."));
    capacidadeM3 = parseFloat(capacidadeM3.replace(",", "."));

    let veiculoNovo = {
      placa: this.state.placa,
      desc: this.state.veiculo,
      anoFabricacao: moment(this.state.anoFabricacao).format("yyyy"),
      tipoVeiculo,
      tipoCaracteristica,
      tipoRodado,
      tipoCarroceria,
      tipoCombustivel,
      tipoPropriedade,
      motorista: this.state.motorista,
      status: status === "Ativo" ? "A" : "I",
      idIntegracao:
        this.state.idIntegracao === "" ? null : this.state.idIntegracao,
      checklists,
      capacidade,
      tara,
      capacidadeM3,
      tanque,
    };

    // // ////console.clear();

    //console.log(veiculoNovo);

    // console.log(this.state);

    if (veiculoNovo.motorista.value === "indefinido") {
      veiculoNovo.motorista = null;
    } else {
      veiculoNovo.motorista = {
        _id: veiculoNovo.motorista._id,
        desc: veiculoNovo.motorista.desc,
      };
    }

    if (acaoPagina === "editar") {
      veiculoNovo._id = this.state._id;
    }

    let urlAtualizar = `${this.props.getBaseUrlApi()}/retaguarda/veiculos`;

    if (this.state._id !== null) {
      urlAtualizar = `${this.props.getBaseUrlApi()}/retaguarda/veiculos/${
        this.state._id
      }`;
    }

    await axios
      .post(urlAtualizar, veiculoNovo, this.props.parameters())
      .then((response) => {
        this.setState(
          {
            placa: "",
            veiculo: "",
            motorista: null,
            idIntegracao: "",
          },
          () => {
            if (acaoPagina === "cadastrar") {
              this.notificar("success", "Veículo cadastrado com sucesso!");
              setTimeout(() => {
                this.props.history.push("/veiculos");
              }, 1000);
            } else {
              this.notificar("success", "Veículo editado com sucesso!");
              setTimeout(() => {
                this.props.history.push("/veiculos");
              }, 1000);
            }
          }
        );
      })
      .catch((error) => {
        this.notificar("warning", error.response.data.erro);
      });
  }

  async buscarVeiculo(id) {
    await axios
      .get(
        `${this.props.getBaseUrlApi()}/retaguarda/veiculos/${id}`,
        this.props.parameters()
      )
      .then((response) => {
        const veiculo = response.data.veiculo;

        let tipoVeiculoSelecionado = veiculo.tipoVeiculo;
        tipoVeiculoSelecionado.value = tipoVeiculoSelecionado.codigo;
        tipoVeiculoSelecionado.label = tipoVeiculoSelecionado.desc;
        delete tipoVeiculoSelecionado.codigo;
        delete tipoVeiculoSelecionado.desc;

        let tipoCaracteristicaSelecionado = veiculo.tipoCaracteristica;
        tipoCaracteristicaSelecionado.value =
          tipoCaracteristicaSelecionado.codigo;
        tipoCaracteristicaSelecionado.label =
          tipoCaracteristicaSelecionado.desc;
        delete tipoCaracteristicaSelecionado.codigo;
        delete tipoCaracteristicaSelecionado.desc;

        let tipoRodadoSelecionado = veiculo.tipoRodado;
        tipoRodadoSelecionado.value = tipoRodadoSelecionado.codigo;
        tipoRodadoSelecionado.label = tipoRodadoSelecionado.desc;
        delete tipoRodadoSelecionado.codigo;
        delete tipoRodadoSelecionado.desc;

        let tipoCarroceriaSelecionado = veiculo.tipoCarroceria;
        tipoCarroceriaSelecionado.value = tipoCarroceriaSelecionado.codigo;
        tipoCarroceriaSelecionado.label = tipoCarroceriaSelecionado.desc;
        delete tipoCarroceriaSelecionado.codigo;
        delete tipoCarroceriaSelecionado.desc;

        let tipoCombustivelSelecionado = veiculo.tipoCombustivel;
        tipoCombustivelSelecionado.value = tipoCombustivelSelecionado.codigo;
        tipoCombustivelSelecionado.label = tipoCombustivelSelecionado.desc;
        delete tipoCombustivelSelecionado.codigo;
        delete tipoCombustivelSelecionado.desc;

        let tipoPropriedadeSelecionado = veiculo.tipoPropriedade;
        tipoPropriedadeSelecionado.value = tipoPropriedadeSelecionado.codigo;
        tipoPropriedadeSelecionado.label = tipoPropriedadeSelecionado.desc;
        delete tipoPropriedadeSelecionado.codigo;
        delete tipoPropriedadeSelecionado.desc;

        let tipoStatusSelecionado = veiculo.status;

        if (tipoStatusSelecionado === "A") {
          tipoStatusSelecionado = {
            value: 0,
            label: "Ativo",
          };
        } else {
          tipoStatusSelecionado = {
            value: 1,
            label: "Inativo",
          };
        }

        // console.log("ccc", veiculo.anoFabricacao);

        let checklists = veiculo.checklists;

        let idMotorista = null;

        if (veiculo.motorista !== null) {
          idMotorista = veiculo.motorista._id;
        }

        this.setState({
          _id: veiculo._id,
          placa: veiculo.placa,
          anoFabricacao: moment().set("years", veiculo.anoFabricacao).toDate(),
          tipoVeiculoSelecionado,
          tipoCaracteristicaSelecionado,
          tipoRodadoSelecionado,
          tipoCarroceriaSelecionado,
          tipoCombustivelSelecionado,
          tipoPropriedadeSelecionado,
          tipoStatusSelecionado,
          veiculo: veiculo.desc,
          idIntegracao: veiculo.idIntegracao,
          idMotorista,
          idsChecklists: checklists,
          capacidade: veiculo.capacidade.toString().replace(".", ","),
          capacidadeM3: veiculo.capacidadeM3.toString().replace(".", ","),
          tara: veiculo.tara.toString().replace(".", ","),
          tanque: veiculo.tanque.toString(),
        });
      });
  }

  handleClickDeletar(id) {
    this.setState({ veiculoDeletar: id, modal: true });
  }

  toggle() {
    this.setState({ modal: !this.state.modal });
  }

  async handleClickConfirmaDeletar() {
    await axios
      .delete(
        `${this.props.getBaseUrlApi()}/retaguarda/veiculos/${
          this.state.veiculoDeletar
        }`,
        this.props.parameters()
      )
      .then((response) => {
        this.setState({ veiculoDeletar: null, modal: false }, () => {
          this.notificar("success", "Veículo deletado com sucesso!");
          setTimeout(() => {
            this.props.history.push("/veiculos");
          }, 1000);
        });
      })
      .catch((error) => {
        this.setState({ veiculoDeletar: null, modal: false }, () => {
          this.notificar("warning", error.response.data.erro);
        });
      });
  }

  handleFocus(e) {
    e.target.select();
  }

  render() {

    const InputRequired = () => {
      return <span className="required-icone">*</span>;
    };

    return (
      <React.Fragment>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
        <div className="container-scroller font-app">
          <NavBar title="Veículos / Novo veículo" />
          <div className="container-fluid page-body-wrapper">
            <SideBar itemActive={"veiculos"} />
            <div className="main-panel">
              <div className="content-wrapper">
                {/* <TitleAndDescription
                  title="Veículos / Novo veículo"
                  description=""
                /> */}

                <div className="row">
                  <div className="col-md-12 grid-margin stretch-card">
                    <div className="card">
                      <div className="card-body5 dashboard-tabs p-0">
                        <div className="tab-content py-0 px-0">
                          <div
                            className="tab-pane fade show active"
                            id="overview"
                            role="tabpanel"
                            aria-labelledby="overview-tab"
                          >
                            <div className="d-flex flex-wrap justify-content-xl-between">
                              <div
                                style={{
                                  padding: "10px",
                                  float: "left",
                                  width: "100%",
                                }}
                              >
                                {/* <div
                                  className="float-left width-100"
                                  style={{
                                    borderBottom: "1px solid #CCC",
                                    padding: "5px",
                                    marginBottom: "20px",
                                  }}
                                >
                                  DADOS DO VEÍCULO
                                </div> */}
                                <div className="row">
                                  <div className="col-md-2 pd-veiculo2">
                                    <div className="form-group form-group-chegoo">
                                      <label>Placa<InputRequired/></label>
                                      <input
                                        type="text"
                                        value={this.state.placa}
                                        label="Nome do usuário"
                                        className={`form-control font-size-14 form-control-chegoo text-center required-chegoo`}
                                        onChange={(e) =>
                                          this.onChangeInput(
                                            "placa",
                                            e.target.value
                                          )
                                        }
                                        ref={(node) => {
                                          if (node) {
                                            if (this.state.errors.placa) {
                                              node.style.setProperty(
                                                "border-color",
                                                "red",
                                                "important"
                                              );
                                            }else{
                                              node.style.setProperty(
                                                "border-color",
                                                "#ced4da",
                                                "important"
                                              );
                                            }
                                          }
                                        }}
                                    
                                      />
                                     
                                    </div>
                                  </div>

                                  <div className="col-md-2 pd-veiculo">
                                    <div className="form-group form-group-chegoo">
                                      <label>Veículo<InputRequired/></label>
                                      <input
                                        type="text"
                                        value={this.state.veiculo}
                                        label="email@email.com.br"
                                        className={`form-control font-size-14 form-control-chegoo required-chegoo`}
                                        onChange={(e) =>
                                          this.onChangeInput(
                                            "veiculo",
                                            e.target.value
                                          )
                                        }
                                        ref={(node) => {
                                          if (node) {
                                            if (this.state.errors.veiculo) {
                                              node.style.setProperty(
                                                "border-color",
                                                "red",
                                                "important"
                                              );
                                            }else{
                                              node.style.setProperty(
                                                "border-color",
                                                "#ced4da",
                                                "important"
                                              );
                                            }
                                          }
                                        }}
                                    
                                      />
                                      
                                    </div>
                                  </div>

                                  <div className="col-md-1 pd-veiculo">
                                    <div className="form-group form-group-chegoo">
                                      <label>Ano<InputRequired/></label>
                                      <DatePicker
                                        selected={this.state.anoFabricacao}
                                        // withPortal
                                        customInput={
                                          <input
                                            className={`form-control font-size-14 form-control-chegoo text-center required-chegoo`}
                                          />
                                        }
                                        dateFormat="yyyy"
                                        onChange={(date) => {
                                          this.setState({
                                            anoFabricacao: date,
                                          });
                                        }}
                                        locale="pt-BR"
                                        showYearPicker
                                      />
                                      {this.state.errors.anoFabricacao && (
                                        <small className="small-error">
                                          Favor preencher o ano.
                                        </small>
                                      )}
                                    </div>
                                  </div>

                                  <div className="col-md-4 pd-veiculo">
                                    <div className="form-group form-group-chegoo">
                                      <label>Motorista</label>
                                      <Select
                                        value={this.state.motorista}
                                        onChange={this.onChangeMotorista}
                                        options={this.state.motoristas}
                                        placeholder="Selecione o motorista"
                                        className={`${
                                          this.state.errors.motorista
                                            ? "select-error"
                                            : ""
                                        }`}
                                        styles={{
                                          control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            fontSize: "14px",
                                            height: "35px",
                                            minHeight: "35px",
                                          }),
                                          menu: (baseStyles, state) => ({
                                            ...baseStyles,
                                            fontSize: "14px",
                                          }),
                                        }}
                                      />
                                      {this.state.errors.motorista && (
                                        <small className="small-error">
                                          Favor escolher o motorista.
                                        </small>
                                      )}
                                    </div>
                                  </div>

                                  <div className="col-md-2 pd-veiculo3">
                                    <div className="form-group form-group-chegoo">
                                      <label>Tipo veículo<InputRequired/></label>

                                      <Select
                                        value={
                                          this.state.tipoVeiculoSelecionado
                                        }
                                        onChange={(tipoVeiculoSelecionado) =>
                                          this.setState({
                                            tipoVeiculoSelecionado,
                                          })
                                        }
                                        options={this.state.tiposVeiculos}
                                        styles={{
                                          control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            fontSize: "14px",
                                            height: "35px",
                                            minHeight: "35px",
                                            backgroundColor: "#fffef6" 
                                          }),
                                          menu: (baseStyles, state) => ({
                                            ...baseStyles,
                                            fontSize: "14px",
                                          }),
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-2 pd-veiculo2">
                                    <div className="form-group form-group-chegoo">
                                      <label>Tipo característica<InputRequired/></label>

                                      <Select
                                        value={
                                          this.state
                                            .tipoCaracteristicaSelecionado
                                        }
                                        onChange={(
                                          tipoCaracteristicaSelecionado
                                        ) =>
                                          this.setState({
                                            tipoCaracteristicaSelecionado,
                                          })
                                        }
                                        options={this.state.tiposCaracteristica}
                                        styles={{
                                          control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            fontSize: "14px",
                                            height: "35px",
                                            minHeight: "35px",
                                            backgroundColor: "#fffef6" 
                                          }),
                                          menu: (baseStyles, state) => ({
                                            ...baseStyles,
                                            fontSize: "14px",
                                          }),
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-2 pd-veiculo">
                                    <div className="form-group form-group-chegoo">
                                      <label>Tipo rodado<InputRequired/></label>

                                      <Select
                                        value={this.state.tipoRodadoSelecionado}
                                        onChange={(tipoRodadoSelecionado) =>
                                          this.setState({
                                            tipoRodadoSelecionado,
                                          })
                                        }
                                        options={this.state.tiposRodado}
                                        styles={{
                                          control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            fontSize: "14px",
                                            height: "35px",
                                            minHeight: "35px",
                                            backgroundColor: "#fffef6" 
                                          }),
                                          menu: (baseStyles, state) => ({
                                            ...baseStyles,
                                            fontSize: "14px",
                                          }),
                                        }}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-md-2 pd-veiculo">
                                    <div className="form-group form-group-chegoo">
                                      <label>Tipo carroceria <InputRequired/></label>

                                      <Select
                                        value={
                                          this.state.tipoCarroceriaSelecionado
                                        }
                                        onChange={(tipoCarroceriaSelecionado) =>
                                          this.setState({
                                            tipoCarroceriaSelecionado,
                                          })
                                        }
                                        options={this.state.tiposCarroceria}
                                        styles={{
                                          control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            fontSize: "14px",
                                            height: "35px",
                                            minHeight: "35px",
                                            backgroundColor: "#fffef6" 
                                          }),
                                          menu: (baseStyles, state) => ({
                                            ...baseStyles,
                                            fontSize: "14px",
                                          }),
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-2 pd-veiculo">
                                    <div className="form-group form-group-chegoo">
                                      <label>Tipo combustível<InputRequired/></label>

                                      <Select
                                        value={
                                          this.state.tipoCombustivelSelecionado
                                        }
                                        onChange={(
                                          tipoCombustivelSelecionado
                                        ) =>
                                          this.setState({
                                            tipoCombustivelSelecionado,
                                          })
                                        }
                                        className={`${
                                          this.state.errors.tipoCombustivel
                                            ? "select-error"
                                            : ""
                                        }`}
                                        options={this.state.tiposCombustivel}
                                        placeholder="Selecione"
                                        styles={{
                                          control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            fontSize: "14px",
                                            height: "35px",
                                            minHeight: "35px",
                                            backgroundColor: "#fffef6" 
                                          }),
                                          menu: (baseStyles, state) => ({
                                            ...baseStyles,
                                            fontSize: "14px",
                                          }),
                                        }}
                                      />

                                    </div>
                                  </div>

                                  <div className="col-md-2 pd-veiculo">
                                    <div className="form-group form-group-chegoo">
                                      <label>Tipo de propriedade<InputRequired/></label>
                                      <Select
                                        value={
                                          this.state.tipoPropriedadeSelecionado
                                        }
                                        onChange={(
                                          tipoPropriedadeSelecionado
                                        ) =>
                                          this.setState({
                                            tipoPropriedadeSelecionado,
                                          })
                                        }
                                        options={this.state.tiposPropriedade}
                                        styles={{
                                          control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            fontSize: "14px",
                                            height: "35px",
                                            minHeight: "35px",
                                            backgroundColor: "#fffef6" 
                                          }),
                                          menu: (baseStyles, state) => ({
                                            ...baseStyles,
                                            fontSize: "14px",
                                          }),
                                        }}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-md-1 pd-veiculo3">
                                    <div className="form-group form-group-chegoo">
                                      <label>Peso (KG)<InputRequired/></label>
                                      <input
                                        type="text"
                                        className={`form-control font-size-14 form-control-chegoo text-center required-chegoo`}
                                        value={this.state.capacidade}
                                        onChange={(e) =>
                                          this.onChangeInput(
                                            "capacidade",
                                            e.target.value
                                          )
                                        }
                                        onFocus={this.handleFocus}
                                        ref={(node) => {
                                          if (node) {
                                            if (this.state.errors.capacidade) {
                                              node.style.setProperty(
                                                "border-color",
                                                "red",
                                                "important"
                                              );
                                            }else{
                                              node.style.setProperty(
                                                "border-color",
                                                "#ced4da",
                                                "important"
                                              );
                                            }
                                          }
                                        }}
                                    
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-1 pd-veiculo2">
                                    <div className="form-group form-group-chegoo">
                                      <label>Tara (KG)<InputRequired/></label>
                                      <input
                                        type="text"
                                        className={`form-control font-size-14 form-control-chegoo text-center required-chegoo`}
                                        value={this.state.tara}
                                        onChange={(e) =>
                                          this.onChangeInput(
                                            "tara",
                                            e.target.value
                                          )
                                        }
                                        onFocus={this.handleFocus}
                                        ref={(node) => {
                                          if (node) {
                                            if (this.state.errors.tara) {
                                              node.style.setProperty(
                                                "border-color",
                                                "red",
                                                "important"
                                              );
                                            }else{
                                              node.style.setProperty(
                                                "border-color",
                                                "#ced4da",
                                                "important"
                                              );
                                            }
                                          }
                                        }}
                                      />
                                    </div>
                                  </div>

                                  <div className="col-md-1 pd-veiculo">
                                    <div className="form-group form-group-chegoo">
                                      <label>Cubos (M³) <InputRequired/></label>
                                      <input
                                        type="text"
                                        className={`form-control font-size-14 form-control-chegoo text-center required-chegoo`}
                                        value={this.state.capacidadeM3}
                                        onChange={(e) =>
                                          this.onChangeInput(
                                            "capacidadeM3",
                                            e.target.value
                                          )
                                        }
                                        onFocus={this.handleFocus}

                                        ref={(node) => {
                                          if (node) {
                                            if (this.state.errors.capacidadeM3) {
                                              node.style.setProperty(
                                                "border-color",
                                                "red",
                                                "important"
                                              );
                                            }else{
                                              node.style.setProperty(
                                                "border-color",
                                                "#ced4da",
                                                "important"
                                              );
                                            }
                                          }
                                        }}


                                      />

                                    </div>
                                  </div>

                                  <div className="col-md-1 pd-veiculo">
                                    <div className="form-group form-group-chegoo">
                                      <label>Tanque (L)<InputRequired/></label>
                                      <input
                                        type="text"
                                        className={`form-control font-size-14 form-control-chegoo text-center required-chegoo`}
                                        value={this.state.tanque}
                                        onChange={(e) =>
                                          this.onChangeInput(
                                            "tanque",
                                            e.target.value
                                          )
                                        }
                                        onFocus={this.handleFocus}

                                        ref={(node) => {
                                          if (node) {
                                            if (this.state.errors.tanque) {
                                              node.style.setProperty(
                                                "border-color",
                                                "red",
                                                "important"
                                              );
                                            }else{
                                              node.style.setProperty(
                                                "border-color",
                                                "#ced4da",
                                                "important"
                                              );
                                            }
                                          }
                                        }}
                                    


                                      />

                                    </div>
                                  </div>

                                  <div className="col-md-2 pd-veiculo">
                                    <div className="form-group form-group-chegoo">
                                      <label>Status<InputRequired/></label>

                                      <Select
                                        value={this.state.tipoStatusSelecionado}
                                        onChange={(tipoStatusSelecionado) =>
                                          this.setState({
                                            tipoStatusSelecionado,
                                          })
                                        }
                                        options={this.state.tiposStatus}
                                        placeholder="Selecione"
                                        styles={{
                                          control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            fontSize: "14px",
                                            height: "35px",
                                            minHeight: "35px",
                                            backgroundColor: "#fffef6"
                                          }),
                                          menu: (baseStyles, state) => ({
                                            ...baseStyles,
                                            fontSize: "14px",
                                          }),
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-2 pd-veiculo3">
                                    <div className="form-group form-group-chegoo">
                                      <label>ID Integração</label>
                                      <input
                                        type="text"
                                        value={this.state.idIntegracao}
                                        label="email@email.com.br"
                                        className={`form-control font-size-14 form-control-chegoo ${
                                          this.state.errors.idIntegracao
                                            ? "input-error"
                                            : ""
                                        }`}
                                        onChange={(e) =>
                                          this.onChangeInput(
                                            "idIntegracao",
                                            e.target.value
                                          )
                                        }
                                      />
                                      {this.state.errors.idIntegracao && (
                                        <small className="small-error">
                                          Favor preencher o Id Integração.
                                        </small>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-8 pd-veiculo2">
                                    <div className="form-group form-group-chegoo">
                                      <label>Checklists</label>

                                      <Select
                                        value={
                                          this.state.checklistsSelecionados
                                        }
                                        onChange={(checklistsSelecionados) =>
                                          this.setState({
                                            checklistsSelecionados,
                                          })
                                        }
                                        options={this.state.checklists}
                                        placeholder="Selecione"
                                        isMulti
                                        styles={{
                                          control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            fontSize: "14px",
                                            height: "35px",
                                            minHeight: "35px",
                                          }),
                                          menu: (baseStyles, state) => ({
                                            ...baseStyles,
                                            fontSize: "14px",
                                          }),
                                        }}
                                      />
                                    </div>
                                  </div>

                                  {/* <div className="col-md-4">
                                        <div className="form-group form-group-chegoo">
                                          <label>Checklist</label>
                                          <Select
                                            value={this.state.motorista}
                                            onChange={this.onChangeMotorista}
                                            options={{
                                              label: "Checklist 1",
                                              value: "checlist1",
                                              desc: "cliente 1"
                                            }}
                                            placeholder="Selecione o motorista"
                                            className={`${
                                              this.state.errors.motorista
                                                ? "select-error"
                                                : ""
                                            }`}
                                            multi
                                          />
                                          {this.state.errors.motorista && (
                                            <small className="small-error">
                                              Favor escolher o motorista.
                                            </small>
                                          )}
                                        </div>
                                      </div> */}
                                </div>

                                <div className="row">
                                  {/* <div className="col-md-8"></div>
                                  <div className="col-md-2 no-padding text-right">
                                    <div className="form-group form-group-chegoo">
                                      <button
                                        className="btn btn-danger btn-app-vermelho"
                                        onClick={() => {
                                          this.handleClickDeletar(
                                            this.state._id
                                          );
                                        }}
                                        disabled={
                                          this.state._id === null ? true : false
                                        }
                                      >
                                        Deletar
                                      </button>
                                    </div>
                                  </div>{" "} */}
                                  <div className="col-md-2 pd-veiculo2">
                                    <div className="form-group form-group-chegoo2">
                                      <button
                                        className="btn btn-success btn-app-verde"
                                        onClick={this.cadastrarVeiculo}
                                      >
                                        Salvar
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </div>

        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader toggle={this.toggle}>Deletar veículo</ModalHeader>
          <ModalBody>Tem certeza que deseja deletar este veículo?</ModalBody>
          <ModalFooter>
            <Button
              className="btn-modal-recuperar"
              color="secondary"
              onClick={this.toggle}
            >
              Não
            </Button>
            <Button
              className="btn-modal-recuperar"
              color="primary"
              onClick={this.handleClickConfirmaDeletar}
            >
              Sim
            </Button>{" "}
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  }
}
